<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:11px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Purchase order</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="printbutton" src="../../../assets/img/printer.svg" class="saveimgbtn_inpatinfo"
              (click)="print_area()" style="width: 25px !important;height: 25px !important;" />
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo"
              (click)="backClicked()" />
            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
              (click)="saveClick('')" />
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" [hidden]="cancelFlag"
              class="saveimgbtn_inpatinfo" (click)="cancelClick()" />
            <!-- <button (click)="po_generator()">PO Generator</button> -->

          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div>
          <div class="cover_div" style="padding-bottom: 13px;">
            <!-- <div class="header_lable">Vendor details</div> -->
            <div class="content_cover">
              <div class="row" *ngIf="paid != undefined && paid != ''">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <mat-label class="matlabel">PO #: {{paid}}</mat-label>
                </div><br>
              </div>
              <div class="row">


                <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Address
                    <textarea #matInput class="ipcss obsttxtareaheight " [(ngModel)]="orderAddress" type="text">
                  </textarea>
                  </mat-label>
                </div> -->

                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">PO base<br>
                    <mat-select disableOptionCentering class="ipcss " [disabled]="PObaseFlag" [(ngModel)]="poBaseTXT"
                      (selectionChange)="po_basechange($event)">
                      <!-- <mat-option value="Purchase Request">Purchase request</mat-option>
                      <mat-option value="No Purchase Request">No purchase request</mat-option> -->
                      <mat-option value="Purchase Request">Purchase request</mat-option>
                      <mat-option value="No Purchase Request">No Purchase request</mat-option>
                    </mat-select>
                  </mat-label>
                </div>

                <!-- (selectionChange)="po_basechange($event)" -->
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Department<br>
                    <mat-select class="ipcss" [(ngModel)]="podepartname" (selectionChange)="pobase_department($event)"
                      required>
                      <mat-option *ngFor="let depart of departmentarray"
                        value={{depart.department_id}}>{{depart.description}}</mat-option>

                    </mat-select>
                  </mat-label>
                </div>

                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Store name<br>
                    <mat-select class="ipcss " [(ngModel)]="postorename" required>
                      <mat-option *ngFor="let stro of storearray" placeholder={{stro.store_desc}}
                        value={{stro.store_names_id}}>{{stro.store_desc}}</mat-option>

                    </mat-select>
                  </mat-label>
                </div>
              </div>
            </div>
          </div>
          <div class="cover_div" style="padding-bottom: 13px;" [hidden]="prReq">
            <div class="header_lable">Purchase indents</div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-6">
                  <div class="col-12 col-md-12 col-sm-12 col-xl-12 col-lg-12" [hidden]="prReq">

                    <div class="dig_table_overflow" style="height: 200px !important;">
                      <div class="table-responsive ">

                        <!-- <table *ngIf="prList.length != 0" mdbTable datatable [dtOptions]="dtOptions"
                          class="table table-nowrap table-sm dataTable billcreae_table table_border"
                          style="margin-top: 10px;">
                          <thead class="tableheading">
                            <tr>
                              <th>Indent no</th>
                              <th class="tbl_txt_center">Date</th>
                              <th class="tbl_txt_center" *ngIf="viewbutton">Select</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let person of prList" class="tdvalues">
                              <td>{{person.purchase_request_id}}</td>
                              <td class="tbl_txt_center">{{person.created_date}}</td>
                              
                              <td class="tbl_txt_center" *ngIf="viewbutton">
                                <mat-checkbox color="primary" id="{{person.purchase_request_id}}"
                                  (change)="pr_change($event,person)"></mat-checkbox>
                              </td>
                            </tr>
                          </tbody>
                        </table> -->


                        <mat-accordion displayMode="flat" multi="false" class="mat-table" hideToggle>
                          <section matSort class="mat-elevation-z2 mat-header-row"
                            style="height:40px;background-color: #c1e6fb; position: sticky; top: 0px; z-index: 2;">
                            <span class="mat-header-cell" style="display: inline-block;width: 20%;">Indent no</span>
                            <span class="mat-header-cell" style="display: inline-block;width: 20%;">Date</span>
                            <span class="mat-header-cell" style="display: inline-block;width: 20%;">Department</span>
                            <span class="mat-header-cell" style="display: inline-block;width: 20%;"><br></span>
                            <span class="mat-header-cell" *ngIf="viewbutton"
                              style="display: inline-block;width: 20%;">Select</span>
                          </section>

                          <mat-expansion-panel *ngFor="let person of prList">
                            <mat-expansion-panel-header class="mat-row" (click)="pr_changeproduct($event, person)">
                              <span class="mat-cell"
                                style="display: inline-block;width: 20%;">{{person.purchase_request_id}}</span>
                              <span class="mat-cell"
                                style="display: inline-block;width: 20%;">{{person.created_date}}</span>
                              <span class="mat-cell"
                                style="display: inline-block;width: 20%;">{{person.department_desc}}</span>
                              <span class="mat-header-cell" style="display: inline-block;width: 20%;"><br></span>
                              <span class="mat-cell" *ngIf="viewbutton"
                                style="display: inline-block;width: 20%;text-align:right">
                                <mat-checkbox color="primary" id="{{person.purchase_request_id}}"
                                  (change)="pr_change($event, person)">
                                </mat-checkbox>
                              </span>
                            </mat-expansion-panel-header>

                            <div style="height: 30vh;overflow-y: scroll; width: 100%;">
                              <table style="font-size: 12px;" id="tbl" class="headerfixed">
                                <thead>
                                  <tr>
                                    <th style="width:20%">Product id</th>
                                    <th style="width:30%">Product name</th>
                                    <th style="width:5%"> </th>
                                    <th style="width:20%">Per unit</th>
                                    <th style="width:20%;text-align:left">Quantity</th>
                                    <th style="width:20%"></th>
                                  </tr>
                                </thead>
                                <tbody>

                                  <ng-container *ngIf="person.product_list.length === 0; else productData">
                                    <tr>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                    </tr>
                                  </ng-container>
                                  <ng-template #productData>
                                    <tr *ngFor="let product of person.product_list" style="padding:5px">
                                      <td class="align_left tdpadding" style="width:20%">{{product.product_id}}</td>
                                      <td class="align_left tdpadding" style="width:30%">{{product.product_name}}</td>
                                      <td style="width:5%">
                                        <img src="../../../assets/img/Page_icon/Info_icon.png"
                                          style="width:25px;position: relative; top: 0px;"
                                          (click)="productpopup(product.product_name)">
                                      </td>
                                      <td class="tdpadding" style="width:20%">{{product.per_unit || '-'}}</td>
                                      <td class="tdpadding" style="text-align: right;width:20%">{{product.quantity}}
                                      </td>
                                      <td class="tdpadding" style="text-align: center;width:20%"
                                        *ngIf="(deletebutton) || (editbutton)" [hidden]="actionflag">
                                        <a *ngIf="deletebutton" class="curser_pointer">
                                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            class="deletebtnaccrd saveimgbtn_doctreg" alt=""
                                            (click)="deleteprod(product)">
                                        </a> &nbsp;&nbsp;&nbsp;
                                      </td>
                                    </tr>
                                  </ng-template>
                                </tbody>
                              </table>
                              <!-- <p class="nodata" *ngIf="person.product_list.length == 0">No records found</p> -->
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-6">
                  <p></p>
                  <div [hidden]="prReq">
                    <div [hidden]="noPrReq">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                          <mat-label class="matlabel">Product Name<br>
                            <input type="text" class="ipcss " required maxlength="50" (keyup)="get_product_details()"
                              maxlength="50" [(ngModel)]="productName" matInput [matAutocomplete]="auto" />
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option (click)="get_product_name(prod)" *ngFor="let prod of productArray"
                                value={{prod.name}}>
                                {{prod.short_name}} - {{prod.name}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">Product Type<br>

                            <select disableOptionCentering class="ipcss " [(ngModel)]="productType">
                              <option *ngFor="let pro_type of productTypeListArray" value={{pro_type.med_code}}>
                                {{pro_type.med_name}}</option>
                            </select>

                          </mat-label>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                            <img src="../../../assets/img/Page_icon/Info_icon.png" style="width:25px;position: relative;
                            top: 20px;" (click)=" productpopup(productName)">
                          </div> -->
                        <div class="col-12  col-sm-6 col-md-4 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">HSN no
                            <input matInput class="ipcss " [(ngModel)]="orderProduct" disabled="true" type="text"
                              maxlength="5">
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                          <!-- <mat-label class="matlabel">UOM
                            <input matInput class="ipcss " [(ngModel)]="orderUOM" type="text" disabled="true">
                          </mat-label> -->
                          <mat-label class="matlabel">Per unit
                            <input matInput class="ipcss " [(ngModel)]="prod_perunit" type="text">
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                          <div class="row">
                            <div>
                              <mat-label class="matlabel">Quantity<br>
                                <input type="text" class="ipcss widthappt" required [(ngModel)]="quanorder" matInput
                                  list="perunit">
                                <datalist [(ngModel)]="quanorder" id="perunit">
                                  <option *ngFor="let unit of perunit" value={{unit.perunit_desc}}>
                                    {{unit.perunit_desc}}
                                  </option>
                                </datalist>
                              </mat-label>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" style="padding: 20px 4px;">
                          <img src="../../../assets/ui_icons/buttons/add_button.svg" (click)="AddClick()"
                            class="saveimgbtn_inpatinfo  ">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="cover_div" style="padding-bottom: 13px;" *ngIf="productReqest">
            <div class="header_lable">Product details</div>
            <div class="content_cover">
              <div [hidden]="!prReq">
                <div [hidden]="noPrReq">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Product name<br>
                        <input type="text" class="ipcss " required maxlength="50" (keyup)="get_product_details()"
                          maxlength="50" [(ngModel)]="productName" matInput [matAutocomplete]="autonorequest" />
                        <mat-autocomplete #autonorequest="matAutocomplete">
                          <mat-option (click)="get_product_name(prod)" *ngFor="let prod of productArray"
                            value={{prod.name}}>
                            {{prod.short_name}} - {{prod.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">Product type<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="productType">
                          <mat-option *ngFor="let pro_type of productTypeListArray" value={{pro_type.med_code}}>
                            {{pro_type.med_name}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">HSN no
                        <input matInput class="ipcss " [(ngModel)]="orderProduct" disabled="true" type="text"
                          maxlength="5">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">Per unit
                        <input matInput class="ipcss " [(ngModel)]="prod_perunit" type="text" disabled="true">
                      </mat-label>
                    </div>
                   
                    <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1">
                      <div class="row">
                        <div>
                          <mat-label class="matlabel">Quantity<br>
                            <input type="text" class="ipcss widthappt" required [(ngModel)]="quanorder" matInput
                              list="perunit">
                            <datalist [(ngModel)]="quanorder" id="perunit">
                              <option *ngFor="let unit of perunit" value={{unit.perunit_desc}}>
                                {{unit.perunit_desc}}
                              </option>
                            </datalist>
                          </mat-label>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Supplier/Vendor<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="vendor">
                          <mat-option *ngFor="let vendor of supplierData" (click)="vendoraddress_change(vendor)"
                            value={{vendor.name}}>
                            {{vendor.name}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                      <mat-label>
                        <img src="../../../assets/ui_icons/buttons/add_button.svg" (click)="AddClick()"
                          class="saveimgbtn_inpatinfo marginvalue">
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="content_cover1" *ngIf="purchaseOrderArray.length != 0 && purchaseOrderArray != undefined">
              <table id="tbl"  mdbTable class="table table-nowrap table-sm billcreae_table table_border">
                <thead class="tableheading">
                  <tr>
                    <th>Product id</th>
                    <th>Product name</th>
                    <th>
                      Per unit
                    </th>
                    <th>Quantity</th>
                    <th *ngIf="(deletebutton) || (editbutton)" [hidden]="actionflag">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let puorder of purchaseOrderArray">
                    <td class="align_left">{{puorder.product_id}}</td>

                    <td class="align_left">
                      {{puorder.shortname}} {{puorder.product_name}}
                    </td>
                    <td class="align_left">
                      {{puorder.per_unit_desc}}
                    </td>
                    <td style="text-align: right;">{{puorder.quantity}}</td>
                    <td style="text-align: center;" *ngIf="(deletebutton) || (editbutton)" [hidden]="actionflag">
                      <a *ngIf="deletebutton" (click)="purchase_orderdelete(puorder.product_id,puorder.quantity)"
                        class="curser_pointer">
                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg" class=" deletebtn saveimgbtn_doctreg"
                          alt="">
                      </a> &nbsp;&nbsp;&nbsp;
                      <a *ngIf="editbutton" (click)="purchase_edit(puorder.product_id,puorder.quantity)"
                        class="curser_pointer">
                        <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                          class=" deletebtn saveimgbtn_doctreg" alt="">
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>

          <div class="cover_div" style="padding-bottom: 13px;height: 300px;width: 100%;"
            [hidden]="purchaseOrderArray.length==0">
            <div class="header_lable">Product list</div>
            <div class="content_cover1">
              <table id="tbl" *ngIf="purchaseOrderArray.length != 0 && purchaseOrderArray != undefined" mdbTable
                class="table table-nowrap table-sm billcreae_table table_border">
                <thead class="tableheading">
                  <tr>
                    <th>Product id</th>
                    <th>Product name</th>

                    <th>
                      Per unit
                    </th>
                    <th>Quantity</th>
                    <th *ngIf="(deletebutton) || (editbutton)" [hidden]="actionflag">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let puorder of purchaseOrderArray">
                    <td class="align_left">{{puorder.product_id}}
                    </td>

                    <td class="align_left">
                      {{puorder.shortname}} {{puorder.product_name}}
                    </td>
                    <td class="align_left">
                      {{puorder.per_unit_desc}}
                    </td>
                    <td style="text-align: right;">{{puorder.quantity}}</td>
                    <td style="text-align: center;" *ngIf="(deletebutton) || (editbutton)" [hidden]="actionflag">
                      <a *ngIf="deletebutton" (click)="purchase_orderdelete(puorder.product_id,puorder.quantity)"
                        class="curser_pointer">
                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg" class=" deletebtn saveimgbtn_doctreg"
                          alt="">
                      </a> &nbsp;&nbsp;&nbsp;
                      <a *ngIf="editbutton" (click)="purchase_edit(puorder.product_id,puorder.quantity)"
                        class="curser_pointer">
                        <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                          class=" deletebtn saveimgbtn_doctreg" alt="">
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <!-- print option content -->
            <div hidden>
              <div>
                <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                    <td style="width: 450px; vertical-align: top;">
                      <img alt="image" src={{hospImg}} width="100px" height="100px">
                      <p style="margin-top: -100px;margin-left: 110px;">{{pharName}}</p>
                      <p style="margin-left: 110px;">{{Address1}},{{Address2}}</p>
                      <p style="margin-left: 110px;">{{location}} {{city}}</p>
                      <p style="margin-left: 110px;">{{state}} {{country}}</p>
                    </td>
                    <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                    <td style="width: 300px;vertical-align: top;">
                      <p><b>PO # : {{paid}}</b></p>
                      <p><b>Date : </b>{{poDate}}</p>
                    </td>
                  </tr>
                </table>
                <br /><br />
                <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                    <td style="width: 400px; vertical-align: top;">
                      <p style="margin-left: 10px;"><b>Vendor</b></p>
                    </td>
                    <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                    <td style="width: 300px;vertical-align: top;">
                      <p><b>Ship to</b></p>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 400px; vertical-align: top;">
                      <p style="margin-left: 10px;">{{vendor}}</p>
                      <p style="margin-left: 10px;">{{orderAddress}}</p>
                    </td>
                    <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                    <td style="width: 300px;vertical-align: top;">
                      <p style="margin-right: 20px;">{{Address1}},{{Address2}}</p>
                      <p style="margin-right: 20px;">{{location}} {{city}}</p>
                      <p style="margin-right: 20px;">{{state}} {{country}}</p>
                    </td>
                  </tr>
                </table>
                <br /><br />
                <table style="margin-left: 50px;">
                  <tr>
                  </tr>
                </table>
                <br /><br />
                <h4 style="margin-left: 45px;">Product details</h4>
                <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                    <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">Product id</th>
                    <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Product name</th>
                    <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">
                      <!-- UOM  -->
                      Per unit
                    </th>
                    <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                    <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">Unit price</th>
                    <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">Amount</th>
                  </tr>
                  <tr *ngFor="let puorder of purchaseOrderArray">
                    <td style="border: 1px solid black;border-collapse: collapse;">{{puorder.product_id}}</td>
                    <td style="border: 1px solid black;border-collapse: collapse;">{{puorder.product_name}}
                    </td>
                    <td style="border: 1px solid black;border-collapse: collapse;">
                      <!-- {{puorder.uom_desc}} -->
                      {{puorder.per_unit_desc}}
                    </td>
                    <td style="border: 1px solid black;border-collapse: collapse;">{{puorder.quantity}}</td>
                    <td style="border: 1px solid black;border-collapse: collapse;text-align: right;">
                      {{puorder.unit_price}}</td>
                    <td style="border: 1px solid black;border-collapse: collapse; text-align: right;">
                      {{puorder.tot_amount}}</td>
                  </tr>
                </table>
                <br />
                <br />
              </div>
            </div>



            <div id="divprint" #divprint class="divprint_style" hidden>
              <div class="container">
                <p class="details_heading">
                  {{this.hospitalName}}-{{this.user_type}}
                </p>

                <p class="details_heading">
                  {{ this.address1 }},{{this.address2 }},{{this.vendorlocation}},{{this.city_desc }}-{{this.zipcode}},
                </p>
                <hr class="print-line" />
                <p class="details_center">
                  PURCHASE ORDER
                </p>
                <p class="details_left">
                  Hospital GSTIN No:
                </p>
                <div class="row">
                  <div class="col-6 details">
                    Purchase Order No : {{this.purchase_order_id}}
                  </div>
                  <div class="col-6 details">
                    Date/Time: {{this.presentdate }}/{{this.current_time1}}
                  </div>
                </div>
                <br>
                <p class="details">
                  To<br>
                  {{this.vendorname}}<br>
                  {{this.vendoraddress}} {{this.vendorcity}}
                </p>
                <p class="details">
                  {{this.venderstate}}<br>
                  {{this.vendorcon}}<br>
                  Phone: {{this.vendor_Mobile1}} {{this.vendor_Mobile2}}<br>
                  Supplier GSTIN No: {{this.vendor_gst}}
                </p>

                <p class="details">
                  Dear Sir/Mam,<br>
                  Sub: Supply of items required for our Hospital<br>
                  We are pleased to place purchse order for supply of the following items<br>
                </p>
              </div>
              <div class="container">
                <table style="margin-top:-15px">
                  <thead>
                    <tr>
                      <th>S NO</th>
                      <th>Item Name</th>
                      <th>HSN CODE</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>Disc</th>
                      <th>GST%</th>
                      <th colspan="3">
                        GST Amt
                        <table class="nested-table">
                          <tr style="border:none">
                            <th>CGST</th>
                            <th>SGST</th>
                            <th>TOTAL</th>
                          </tr>
                        </table>
                      </th>
                      <th>Amt</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let puorder of documentarray;let i = index">
                      <td>{{i+1}}</td>
                      <td>{{puorder.product_name}}</td>
                      <td></td>
                      <td>{{puorder.quantity}}</td>
                      <td>{{puorder.unit_price}}</td>
                      <td>{{puorder.disc}}</td>
                      <td>{{puorder.gst}}</td>
                      <td>{{puorder.cgst}}</td>
                      <td>{{puorder.sgst}}</td>
                      <td>{{puorder.total}}</td>
                      <td>{{puorder.amount}}</td>
                    </tr>
                    <tr>
                      <th>CGST%</th>
                      <th>CGST</th>
                      <th>SGST%</th>
                      <th>SGST</th>
                      <th>IGST%</th>
                      <th>IGST</th>
                      <th>Total GST</th>
                      <th colspan="2">Total AMT</th>
                      <td colspan="2">{{this.totalAMT}}</td>
                    </tr>
                    <tr>
                      <td>{{this.totalcgstpercent}}</td>
                      <td>{{this.totalcgst}}</td>
                      <td>{{this.totalsgstpercent}}</td>
                      <td>{{this.totalsgst}}</td>
                      <td><br></td>
                      <td><br></td>
                      <td>{{this.totalGST}}</td>
                      <th colspan="2">Discount</th>
                      <td colspan="2"><br></td>
                    </tr>
                    <tr>
                      <th colspan="7" style="text-align:left">Total Amount in Words:</th>
                      <td colspan="2">Round off</td>
                      <td colspan="2">{{this.roundoff}}</td>
                    </tr>
                    <tr>
                      <td colspan="7" style="text-align:left">{{this.totalamountinword}}</td>
                      <td colspan="2">Grand total</td>
                      <td colspan="2">{{this.totalAMT}}</td>
                    </tr>
                  </tbody>

                </table>
                <div>
                  Please expedite supplies accordingly, and arrange to send the Bill of cost in duplicate for arranging
                  <br>
                  Payment of the same on receipt and inspectio of materials at our end.
                  <div class="row">
                    <div class="col-6 details">
                      Remarks:
                    </div>
                    <div class="col-6 details">
                      Prepared By: {{this.username}}
                    </div>
                  </div>
                </div>
              </div>
            </div>





          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>