import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Date_Formate, Time_Formate, convertNumberToWords } from '../../../../assets/js/common.js';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { pharmacy_helper } from '../../../../app/pharma/Pharmcy_Helper';
import { Helper_Class } from '../../../helper_class';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service.js';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { error } from 'console';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ConsolidatedBillsComponent } from 'src/app/Doctor_module/consolidated-bills/consolidated-bills.component.js';
import { Doc_Helper } from 'src/app/Doctor_module/Doc_Helper.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { PurchaseOrderpopupComponent } from '../purchase-orderpopup/purchase-orderpopup.component';



@Component({
  selector: 'app-purchaseorderview',
  templateUrl: './purchaseorderview.component.html',
  styleUrls: ['./purchaseorderview.component.css']
})
export class PurchaseorderviewComponent implements OnInit {
  @ViewChild('divprint') divprint: ElementRef;
  poDate: string;
  addBtn: boolean = false;
  getPrList = [];
  prDate: any;
  prTxt: any;
  purcaOrderIndex: any;
  prReq: boolean = true;
  productReqest: boolean = false;
  noPrReq: boolean;
  public orderAddress: string;
  vendor: any;
  public purchaseOrderArray: any = [];
  purchaseOrderArray1: any = [];
  purchaseRequestRetrivelArray: any = [];
  poBaseTXT: any;
  orderProduct: any;
  orderUOM: any;
  totalAmount: any;
  finalamount: any;
  sendPurchaseOrderRequestarry: any = [];
  purchaseOrderRequestArray: any = [];
  quanorder: any = null;
  unitPrice: any;
  productorderName: any;
  gpcodeList: any = [];
  organizations: any = [];
  prList: any = [];
  supplerMasterArray: any = [];
  getItemArray: any = [];
  productTypeListArray: any = [];
  productArray: any = [];
  cancelFlag: boolean;
  vendorFlag: boolean;
  PObaseFlag: boolean;
  printFlag: boolean;
  public pharName: string;
  public Address1: string;
  public Address2: string;
  public location: string;
  public city: string;
  public state: string;
  public country: string;
  public telephone: string;
  public hospImg: string;
  public productID: string;
  public productName: string;
  public vendorID: string;
  public hsnNO: string;
  public productType: string
  public paid: string;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public pharmacyID;
  public pharmacistID;
  public userID;
  public getInfo;
  public ipaddress;
  public supplierData: any = [];
  public saveFlag: boolean = true;
  // added by me
  public purchaseOrderArrayname;
  public approval;
  public poapproved;
  public approvalboxandbutton: boolean;
  public podepartname;
  public postorename;
  public departmentarray = [];
  public storearray = [];
  public hsp_id;
  public stores_drpdwn = true;
  public user_type;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public perunit = [];
  public print_template: string;
  public pdfObj;
  public vendor_Mobile1;
  public vendor_Mobile2;
  public hospitalgst;
  public vendor_gst;
  public totalgst;
  public totalamt;
  public totaldiscount;
  public roundoff: number;
  public grandtotal;
  public user;
  public venderstate;
  public vendorcon;
  public amountinwords;
  public vendorcity;
  public vendorname;
  public vendoraddress;
  public current_time1;
  public prod_cgst;
  public prod_sgst;
  public documentarray = [];
  public totalAMT = 0;
  public totalGST = 0;
  public totalamountinword;
  public username;
  public currentDate;
  public fromDate;
  public toDate;
  public currenttime;
  public presentdate;
  public pono;
  public hosp_img;
  public imagestring;
  public hospitalName;
  usertype: any;
  public address1;
  public address2
  public city_desc;
  public zipcode;
  public pdf_req;
  public calculationtype;
  public vendorlocation;
  public resultarray = [];
  public pdfdata = [];
  public purchase_order_id;
  public actionflag: boolean = false;
  public editflag: boolean;
  public prod_perunit;
  public totalcgst: number;
  public totalsgst: number;
  public totalcgstpercent: number;
  public totalsgstpercent: number;
  public user_depart_desc;
  public user_depart_id;
  public user_store_desc;
  public user_store_id;
  public diag_center_id;
  public data_type;
  public hospitalid;
  public accordin_product: any[] = [];
  public removeddata: any[] = [];
  public prod_perunit_id;
  public productnameurl:string;

  constructor(public messageservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, public httpclt: HttpClient, public http: Http, public router: Router, public dialog: MatDialog) {
    this.prReq = true;
    this.noPrReq = true;
    this.purcaOrderIndex = 0;
  }

  ngOnInit() {
    this.productnameurl="inv/gpbp";
    // this.productnameurl="pharmacontrol/gpcbypharn/";
    this.paid = pharmacy_helper.getpoid();
    this.pono = pharmacy_helper.getpono();
    this.getInfo = Helper_Class.getInfo();
    this.hospitalName = this.getInfo.hospitals[0].pat_ident_prefix;
    this.user_type = Helper_Class.getInfo().user_type;
    this.userID = this.getInfo.user_id;
    this.hsp_id = this.getInfo.hospitals[0].hosp_id;
    this.diag_center_id = this.getInfo.hospitals[0].diag_centre_id;
    this.editflag = false;
    console.log("hsp id" + this.hsp_id)
    if (this.getInfo.user_type == "Admin") {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.data_type = "pharmacy"

    } else if (this.getInfo.user_type == "pharmacy") {
      this.pharmacyID = this.getInfo.hospitals[0].hptl_clinic_id;
      this.hospitalid = Helper_Class.getInfo().hospitals[0].hosp_id;
      this.data_type = "pharmacy"
    }
    else {
      this.pharmacyID = this.getInfo.hospitals[0].hptl_clinic_id;
      this.hospitalid = Helper_Class.getInfo().hospitals[0].hosp_id;
      this.data_type = "pharmacy"
    }

    
    if (this.getInfo.user_type == "Admin") {
      this.newbutton = true;
      this.deletebutton = true;
      this.editbutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    } else {

      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "56" && Helper_Class.getmodulelist()[i].description == "Purchase orders") {
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.newbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }
            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
            }
          } else {
            this.newbutton = true;
            this.deletebutton = true;
            this.editbutton = true;
            this.printbutton = true;
            this.viewbutton = true;
          }

        }
      }
    }

    this.ipaddress = Helper_Class.getIPAddress();
    this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;
    this.address1 = this.getInfo.hospitals[0].address1;
    this.address2 = this.getInfo.hospitals[0].address2;
    this.vendorlocation = this.getInfo.hospitals[0].location_desc;
    this.city_desc = this.getInfo.hospitals[0].city_desc;
    this.zipcode = this.getInfo.hospitals[0].zipcode;
    this.pdf_req = this.getInfo.hospitals[0].req_pdf;
    this.user_depart_desc = this.getInfo.hospitals[0].dept_desc
    this.user_depart_id = this.getInfo.hospitals[0].department_id
    this.user_store_desc = this.getInfo.hospitals[0].store_desc
    this.user_store_id = this.getInfo.hospitals[0].store_id

    if (this.getInfo.middle_name != undefined) {
      this.username = this.getInfo.first_name + " " + this.getInfo.middle_name + " " + this.getInfo.last_name;
    }
    else {
      if (this.getInfo.last_name != undefined) {
        this.username = this.getInfo.first_name + " " + this.getInfo.last_name;
      }
      else {
        this.username = this.getInfo.first_name;
      }
    }
    if (Helper_Class.getpodetails() != undefined || null) {
      this.podepartname = Helper_Class.getpodetails().depart;
      this.getstorename(this.podepartname);
      // this.postorename=Helper_Class.getpodetails().storename;
    }

    if (pharmacy_helper.getpoidlist() != undefined || null) {
      this.postorename = pharmacy_helper.getpoidlist().store_id;
      this.podepartname = pharmacy_helper.getpoidlist().dept_id;
      this.getstorename(this.podepartname);
      console.log(" paid = " + JSON.stringify(this.postorename))
    }

    console.log("po approved =" + this.poapproved)
    if (this.poapproved == "1") {
      this.approval = "1"
      this.approvalboxandbutton = true;
    }
    else {
      this.approval = "0"
      this.approvalboxandbutton = false;
    }


    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by PR no or date"
      },
      "paging": false,
      "dom": "lfrti",
    };
    //this.SupplierLoad();
    this.product_retrieval();
    this.getsuppfromprod();
    if (this.paid.length != 0) {
      this.PODetails();
      this.cancelFlag = false;
      if (pharmacy_helper.getpoidlist() != undefined || null) {
        if( pharmacy_helper.getpoidlist().status=='Complete'){
          this.cancelFlag=true
        }
      }
      this.vendorFlag = true;
      this.PObaseFlag = true;
      this.printFlag = false;
      this.saveFlag = false;

    } else {
      this.addBtn = true;
      this.vendorFlag = false;
      this.PObaseFlag = false;
      this.cancelFlag = true;
      this.printFlag = true;
      this.saveFlag = true;
      this.printbutton=false;
      this.poBaseTXT = "No Purchase Request"
      this.po_basechange(this.poBaseTXT)
    }
    this.getdepart();
    this.perunitfunction();


    if (Helper_Class.getInfo().hospitals[0].bill_template != undefined) {
      this.print_template = Helper_Class.getInfo().hospitals[0].bill_template;
    } else {
      this.print_template = "noheader";
    }
    this.getCurrentDate();

    if (Helper_Class.getInfo().hospitals[0].bill_logo != undefined) {
      this.hosp_img = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].bill_logo;
      console.log("hosp_img = " + this.hosp_img)
      this.getBase64Image(this.hosp_img);
    }
  

  }

  savePurchaseOrder() {
    if (this.pdf_req == 1) {
      this.po_generator();

    } else {
      this.saveClick("");
    }
  }



  public getCurrentDate() {
    var headers = new Headers();
    var date
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          var currentDate1 = obj.current_date.split('-');
          this.current_time1 = Time_Formate(obj.current_time);
          this.currenttime = currenttime1[0]
          this.presentdate = currentDate1[2] + "-" + currentDate1[1] + "-" + currentDate1[0];
          console.log('presentdate1', this.presentdate)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  get_type() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "pharmacontrol/mtypes/").subscribe(
      data => {
        this.productTypeListArray = [];
        if (data.json().med_details != null) {
          this.productTypeListArray = data.json().med_details;
        }
      },
      error => { });
  }

  perunitfunction() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inv/gump',
      JSON.stringify({
        pharma_id: this.hospitalid,
        type: this.data_type
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("per unit = " + JSON.stringify(obj))
          for (let i = 0; i < obj.measures.length; i++) {
            this.perunit.push({
              perunit_id: obj.measures[i].perunit_id,
              perunit_desc: obj.measures[i].perunit_desc
            })
          }
          console.log("perunit = " + JSON.stringify(obj))
        })
  }

  getsuppfromprod(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsbpp", JSON.stringify({
      // product_id:id,
      pharma_id:this.pharmacyID
    }), { headers: headers }).subscribe(
      data => {
        console.log("send data supplier = " + JSON.stringify({}))
        var suppler_index = 0;
        var obj = data.json();
        this.supplierData = data.json().suppliers;
        console.log("supplier data" + JSON.stringify(this.supplierData))
        if (this.supplierData.length != 0) {
          for (var i = 0; i < this.supplierData.length; i++) {
            if (this.supplierData[i].address2 != null && this.supplierData[i].address2 != undefined) {
              var add_txt = this.supplierData[i].address1 + "," + this.supplierData[i].address2;
            } else {
              add_txt = this.supplierData[i].address1;
            }

            // {"prod_supp_id":"97","prod_supp_code":"SP0222","name":"S P Meditech Pvt ltd"},
            // {"prod_supp_id":"17","prod_supp_code":"SP0006","name":"A.K.ENTERPRISES"}


            this.supplerMasterArray.push({
              Index: suppler_index,
              name: this.supplierData[i].name,
              mfg_code: this.supplierData[i].prod_supp_code,
              address: add_txt,
              city_desc: this.supplierData[i].city_desc,
              state_desc: this.supplierData[i].state_desc,
              country_desc: this.supplierData[i].country_desc,
              telephone: this.supplierData[i].telephone,
              mobile: this.supplierData[i].mobile,
              supp_id: this.supplierData[i].prod_supp_id,
              city: this.supplierData[i].city,
              state: this.supplierData[i].state,
              country: this.supplierData[i].country,
            });

            suppler_index++;
          }
        }
      },
      error => { });
  }

  getVendor() {
    if (this.vendor != undefined && this.vendor.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/gsupp/", JSON.stringify({
        pharmacist_id: this.userID,
        pharmacy_id: this.pharmacyID,
        imei: this.ipaddress,
        name: this.vendor,
        type: this.data_type,
        dept_id: this.user_depart_id,
        store_id: this.user_store_id
      }), { headers: headers }).subscribe(
        data => {
          console.log("send data supplier = " + JSON.stringify({
            pharmacist_id: this.userID,
            pharmacy_id: this.pharmacyID,
            imei: this.ipaddress,
            name: this.vendor,
            type: this.data_type,
            dept_id:this.podepartname,
            store_id:this.postorename
          }))
          var suppler_index = 0;
          var obj = data.json();
          this.supplierData = data.json().suppliers;
          console.log("supplier data" + JSON.stringify(this.supplierData))
          if (this.supplierData.length != 0) {
            for (var i = 0; i < this.supplierData.length; i++) {
              if (this.supplierData[i].address2 != null && this.supplierData[i].address2 != undefined) {
                var add_txt = this.supplierData[i].address1 + "," + this.supplierData[i].address2;
              } else {
                add_txt = this.supplierData[i].address1;
              }
              this.supplerMasterArray.push({
                Index: suppler_index,
                name: this.supplierData[i].name,
                mfg_code: this.supplierData[i].supp_code,
                address: add_txt,
                city_desc: this.supplierData[i].city_desc,
                state_desc: this.supplierData[i].state_desc,
                country_desc: this.supplierData[i].country_desc,
                telephone: this.supplierData[i].telephone,
                mobile: this.supplierData[i].mobile,
                supp_id: this.supplierData[i].supp_id,
                city: this.supplierData[i].city,
                state: this.supplierData[i].state,
                country: this.supplierData[i].country,
              });

              suppler_index++;
            }
            // this.vendor = this.supplierData[0].name;
            // this.vendoraddress_change(this.vendor);
          }
        },
        error => { });
    }
  }

  getdepart() {
    console.log("pharmacy Id =" + this.pharmacyID)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gdbh",
      JSON.stringify({ hptl_clinic_id: this.pharmacyID, type: this.user_type }), { headers: headers }).subscribe(
        response => {
          var obj = response.json()
          console.log(" DEPARTMENT DATA" + JSON.stringify(obj))
          this.departmentarray = obj.departments;
          this.podepartname = this.user_depart_id;
          if (this.getInfo.hospitals[0].department_id != undefined) {
            this.getstorename(this.podepartname)
          }

        }
      )


  }

  pobase_department(value) {

    this.stores_drpdwn = false;
    this.getstorename(this.podepartname)
  }
  getstorename(value) {
    var senddata = {
      hptl_clinic_id: this.pharmacyID,
      department_id: value
    }
    console.log("send data" + JSON.stringify(senddata))
    // console.log("pharmacy Id ="+this.pharmacyID)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gsdbh", JSON.stringify({ hptl_clinic_id: this.hsp_id, department_id: value }), { headers: headers }).subscribe(

      response => {
        var obj = response.json()
        console.log(" store DATA" + JSON.stringify(obj));
        this.storearray = obj.stores;

        if (this.storearray != undefined) {

          if (this.getInfo.hospitals[0].store_id != undefined) {
            this.postorename = this.user_store_id
          } else {
            if (this.storearray.length == 1) {
              this.postorename = this.storearray[0].store_names_id
            }
          }
        }
        // this.departmentarray=obj.departments;
      },
      error => {
        console.log("error on retriving the store name")
      }
    )
  }


  product_retrieval() {
    this.gpcodeList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpcbyphar/", JSON.stringify({
      pharmacist_id: this.userID,
      pharmacy_id: this.pharmacyID,
      imei: this.ipaddress,
      start_no: "0",
      limit: "1000",
      is_approve: "1"
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().products != null) {

          this.gpcodeList = data.json().products;
          console.log("lilst = " + JSON.stringify(this.gpcodeList))
        }
      },
      error => { });
  }

  purchase_orderdelete(id, qty) {
    if (this.purchaseOrderArray.length != 0) {
      for (var i = 0; i < this.purchaseOrderArray.length; i++) {
        if (this.purchaseOrderArray[i].product_id == id && this.purchaseOrderArray[i].quantity == qty) {
          for (var j = 0; j < this.sendPurchaseOrderRequestarry.length; j++) {
            if (this.purchaseOrderArray[i].purchase_request_id == this.sendPurchaseOrderRequestarry[j].purchase_request_id) {
              this.sendPurchaseOrderRequestarry.splice(j, 1);
            }
          }
          this.purchaseOrderArray1.splice(i, 1);
          this.purchaseOrderArray.splice(i, 1);
        }
      }
    }
    var totamount = 0;
    for (var i = 0; i < this.purchaseOrderArray.length; i++) {
      totamount = parseFloat(totamount.toString()) + parseFloat(this.purchaseOrderArray[i].tot_amount);
    }
    var fin_amt = 0;
    this.totalAmount = parseFloat(totamount.toString()) + parseFloat(fin_amt.toString()).toFixed(2);
  }


  AddClick() {

    this.purchaseOrderRequestArray = [];
    var qty_data = 0;
    var amount_txt = this.quanorder * this.unitPrice;
    this.supplerMasterArray.forEach(element => {
      element.supp_id;
      if (element.name == this.vendor) {
        this.vendorID = element.supp_id
      }
    });

    var shortname;
    // purchaseOrderArray

    for (var l = 0; l < this.productTypeListArray.length; l++) {
      console.log("product type = " + JSON.stringify(this.productTypeListArray))
      if (this.productTypeListArray[l].med_code == this.productType) {

        shortname = this.productTypeListArray[l].short_name
      }
    }



    if (this.poBaseTXT == "Purchase Request") {

      if (this.purchaseOrderArray.length != 0) {
        if (this.editflag == true) {

          var tot_amt = parseFloat(this.quanorder) * parseFloat(this.unitPrice);

          this.purchaseOrderArray.push({
            Index: this.purcaOrderIndex,
            product_id: this.productID,
            product_name: this.productName,
            shortname: shortname,
            product_type_id: this.productType,
            uom_desc: this.orderUOM,
            per_unit_desc:this.prod_perunit,
            per_unit: this.prod_perunit_id,
            quantity: this.quanorder,
            unit_price: parseFloat(this.unitPrice).toFixed(2),
            total_amount: tot_amt.toFixed(2),
            supp_id: this.vendor.supp_id,
            po_base: this.poBaseTXT,
            address: this.orderAddress,
            product_code: this.orderProduct,
            order_uom: this.orderUOM,
            purchase_request_id: this.prTxt,
          });
          this.purchaseOrderArray1.push({
            product_id: this.productID,
            quantity: this.quanorder,
            unit_price: parseFloat(this.unitPrice).toFixed(2),
            total_amount: parseFloat(tot_amt.toString()).toFixed(2),
            purchase_order_id: this.paid,
            po_no: this.pono
          });
          this.purchaseOrderRequestArray.push({
            product_id: this.productID,
            quantity: this.quanorder,
          });
        }
        if (this.editflag == false) {
          var flag_cgh = true;
          for (var j = 0; j < this.purchaseOrderArray.length; j++) {
            if (this.purchaseOrderArray[j].product_id == this.productID) {


              flag_cgh = false;
              var found = false;

              if (this.productID === this.purchaseOrderArray[j].product_id) {
                this.purchaseOrderArray[j].quantity += parseInt(this.quanorder);
                found = true;
                break;
              }


              this.purchaseOrderRequestArray.push({
                product_id: this.purchaseOrderArray[j].product_id,
                quantity: this.quanorder,
              });

              var qty_data = parseFloat(this.purchaseOrderArray[j].quantity);// + parseFloat(this.quanorder_txt);
              var tot_amt = parseFloat(qty_data.toString()) * parseFloat(this.unitPrice);
              this.purchaseOrderArray.splice(j, 1);
              this.purchaseOrderArray1.splice(j, 1);


              if (!found) {

                this.purchaseOrderArray.push({
                  shortname: shortname,
                  Index: this.purcaOrderIndex,
                  product_id: this.productID,
                  product_type_id: this.productType,
                  product_name: this.productName,
                  uom_desc: this.orderUOM,
                  quantity: qty_data,
                  unit_price: parseFloat(this.unitPrice).toFixed(2),
                  total_amount: tot_amt.toFixed(2),
                  supp_id: this.vendor.supp_id,
                  po_base: this.poBaseTXT,
                  address: this.orderAddress,
                  product_code: this.orderProduct,
                  order_uom: this.orderUOM,
                  per_unit_desc:this.prod_perunit,
                  per_unit: this.prod_perunit_id,
                  purchase_request_id: this.prTxt,
                  cgst: this.prod_cgst,
                  sgst: this.prod_sgst,
                  gst: parseFloat(this.prod_cgst) + parseFloat(this.prod_sgst)
                });
              }
              this.purchaseOrderArray1.push({
                product_id: this.productID,
                quantity: qty_data,
                unit_price: parseFloat(this.unitPrice).toFixed(2),
                total_amount: parseFloat(tot_amt.toString()).toFixed(2),
                purchase_order_id: this.paid,
                po_no: this.pono,
                cgst: this.prod_cgst,
                sgst: this.prod_sgst,
                gst: parseFloat(this.prod_cgst) + parseFloat(this.prod_sgst)
              });
              break;
            }

            if (this.purchaseOrderArray[j].product_id != this.productID) {
              var productexistflag = false;
              for (var q = 0; q < this.purchaseOrderArray.length; q++) {

                console.log("purchaseorder 1 = " + JSON.stringify(this.purchaseOrderArray))
                if (this.productID === this.purchaseOrderArray[q].product_id) {
                  productexistflag = true;
                  break;
                }

              }

              if (!productexistflag) {
                var qty_data = parseFloat(this.quanorder);// + parseFloat(this.quanorder_txt);
                var tot_amt = parseFloat(qty_data.toString()) * parseFloat(this.unitPrice);

                this.purchaseOrderArray.push({
                  shortname: shortname,
                  Index: this.purcaOrderIndex,
                  product_id: this.productID,
                  product_type_id: this.productType,
                  product_name: this.productName,
                  uom_desc: this.orderUOM,
                  per_unit_desc:this.prod_perunit,
                  per_unit: this.prod_perunit_id,
                  quantity: qty_data,
                  unit_price: parseFloat(this.unitPrice).toFixed(2),
                  total_amount: tot_amt.toFixed(2),
                  supp_id: this.vendor.supp_id,
                  po_base: this.poBaseTXT,
                  address: this.orderAddress,
                  product_code: this.orderProduct,
                  order_uom: this.orderUOM,
                  purchase_request_id: this.prTxt,
                  cgst: this.prod_cgst,
                  sgst: this.prod_sgst,
                  gst: parseFloat(this.prod_cgst) + parseFloat(this.prod_sgst)
                });
                this.purchaseOrderArray1.push({
                  product_id: this.productID,
                  quantity: qty_data,
                  unit_price: parseFloat(this.unitPrice).toFixed(2),
                  total_amount: parseFloat(tot_amt.toString()).toFixed(2),
                  purchase_order_id: this.paid,
                  po_no: this.pono,
                  cgst: this.prod_cgst,
                  sgst: this.prod_sgst,
                  gst: parseFloat(this.prod_cgst) + parseFloat(this.prod_sgst)
                });
                break;
              }
            }
          }
        }

      }
      else {

        var tot_amt = parseFloat(this.quanorder) * parseFloat(this.unitPrice);

        this.purchaseOrderArray.push({
          Index: this.purcaOrderIndex,
          product_id: this.productID,
          product_name: this.productName,
          shortname: shortname,
          product_type_id: this.productType,
          uom_desc: this.orderUOM,
          per_unit_desc:this.prod_perunit,
          per_unit: this.prod_perunit_id,
          quantity: this.quanorder,
          unit_price: parseFloat(this.unitPrice).toFixed(2),
          total_amount: tot_amt.toFixed(2),
          supp_id: this.vendor.supp_id,
          po_base: this.poBaseTXT,
          address: this.orderAddress,
          product_code: this.orderProduct,
          order_uom: this.orderUOM,
          purchase_request_id: this.prTxt,
        });
        this.purchaseOrderArray1.push({
          product_id: this.productID,
          quantity: this.quanorder,
          unit_price: parseFloat(this.unitPrice).toFixed(2),
          total_amount: parseFloat(tot_amt.toString()).toFixed(2),
          purchase_order_id: this.paid,
          po_no: this.pono
        });
        this.purchaseOrderRequestArray.push({
          product_id: this.productID,
          quantity: this.quanorder,
        });
      }

    }
    else if (this.poBaseTXT == "No Purchase Request") {

      var nameallreadyther = false;

      for (var i = 0; i < this.purchaseOrderArray.length; i++) {

        if (this.productName.length == 0 && this.productName == "") {
          nameallreadyther = true
          this.toastr.error(Message_data.fillPrdAndQty)
        }
        else if (this.productName == undefined && this.quanorder == undefined) {
          nameallreadyther = true;
          this.toastr.error(Message_data.fillPrdAndQty)
        }
        else if (this.purchaseOrderArray[i].product_id == this.productID) {
          nameallreadyther = true;
          this.toastr.error("Product already added to the list");
        }
      }


      if (this.purchaseOrderArray.length == 0 && this.productName == undefined && this.quanorder == undefined && this.productType == undefined) {
        nameallreadyther = true
        this.toastr.error(Message_data.fillPrdAndQty)
      }
      else if (this.purchaseOrderArray.length == 0 && this.productName == "" && this.quanorder == "" && this.productType == "") {
        nameallreadyther = true
        this.toastr.error(Message_data.fillPrdAndQty)
      }
      else if (this.purchaseOrderArray.length == 0 && this.productName == undefined) {
        nameallreadyther = true;
        this.toastr.error("Enter the product name")
      }
      else if (this.purchaseOrderArray.length == 0 && this.productName == "") {
        nameallreadyther = true;
        this.toastr.error("Enter the product name")
      }
      else if (this.purchaseOrderArray.length == 0 && this.productName == undefined && this.quanorder == undefined) {
        nameallreadyther = true;
        this.toastr.error(Message_data.fillPrdAndQty)
      }
      else if (this.purchaseOrderArray.length == 0 && this.productName == "" && this.quanorder == undefined) {
        nameallreadyther = true;
        this.toastr.error(Message_data.fillPrdAndQty)
      }
      // else if (this.purchaseOrderArray.length == 0 && this.purchaseOrderArray[i].product_id == this.productID) {
      //   nameallreadyther = true;
      //   this.toastr.error("Product already added to the list");
      // }

      if (nameallreadyther != true) {
        var tot_amt = parseFloat(this.quanorder) * parseFloat(this.unitPrice);

        this.purchaseOrderArray.push({
          shortname: shortname,
          Index: this.purcaOrderIndex,
          product_id: this.productID,
          product_type_id: this.productType,
          product_name: this.productName,
          uom_desc: this.orderUOM,
          per_unit_desc:this.prod_perunit,
          per_unit: this.prod_perunit_id,
          quantity: this.quanorder,
          unit_price: parseFloat(this.unitPrice).toFixed(2),
          total_amount: tot_amt.toFixed(2),
          supp_id: this.vendor.supp_id,
          po_base: this.poBaseTXT,
          address: this.orderAddress,
          product_code: this.orderProduct,
          order_uom: this.orderUOM,
          purchase_request_id: this.prTxt,
          cgst: this.prod_cgst,
          sgst: this.prod_sgst,
          gst: parseFloat(this.prod_cgst) + parseFloat(this.prod_sgst)
        });

        this.purchaseOrderArray1.push({
          product_id: this.productID,
          quantity: this.quanorder,
          unit_price: parseFloat(this.unitPrice).toFixed(2),
          total_amount: parseFloat(tot_amt.toString()).toFixed(2),
          purchase_order_id: this.paid,
          po_no: this.pono,
          cgst: this.prod_cgst,
          sgst: this.prod_sgst,
          gst: parseFloat(this.prod_cgst) + parseFloat(this.prod_sgst)
        });
      }
      // }else{
      //   this.toastr.error(Message_data.fillPrdAndQty)
      // }
      // this.productName="";
    }

    //Tax
    this.sendPurchaseOrderRequestarry.push({
      purchase_request_id: this.prTxt,
      pr_items: this.purchaseOrderRequestArray,
    });
    var totamount = 0;
    var fin_amt = 0;
    this.totalAmount = parseFloat(totamount.toString()) + parseFloat(fin_amt.toString());
    this.purcaOrderIndex++;
    this.prTxt = undefined;
    this.productorderName = "";
    this.orderProduct = "";
    this.orderUOM = "";
    this.prod_perunit = "";
    this.unitPrice = "";
    this.quanorder = "";
    //added by christo
    this.productName = "";
    this.productType = undefined;
    this.editflag = false;
    console.log(" ADD CLICK JSON = " + JSON.stringify(this.purchaseOrderArray))

  }

  clearProdName() {
    this.productName = "";
  }

  get_product_details() {
    for (var i = 0; i < this.supplerMasterArray.length; i++) {
      if (this.supplierData[i].name == this.vendor) {
        this.vendorID = this.supplierData[i].supp_id
        break;
      }
    }
    var headers = new Headers();
    var data1 = JSON.stringify(
      {
        // pharmacist_id: this.userID,
        pharmacy_id: this.pharmacyID,
        // prod_code: this.orderProduct,
        prod_name: this.productName,
        // supp_id: this.vendorID,
       // prod_type: this.productType,

      })
    console.log("data1" + JSON.stringify(data1));
    if (this.productName.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + this.productnameurl, data1, { headers: headers }).subscribe(
        data => {
          // this.productArray = [];
          //products
          var dataval = data.json();
          console.log("dataval" + JSON.stringify(dataval));
          if (data.json() != undefined && data.json().products != null) {

            this.productArray = data.json().products;
            // for(var p=0;p < dataval.med_details.length;p++){
            //   this.productArray.push({
            //     product_type_id:dataval.med_details[p].med_type_id,
            //     name:dataval.med_details[p].short_name +" "+ dataval.med_details[p].med_name,
            //     uom_desc:dataval.med_details[p].,
            //     per_unit_desc:dataval.med_details[p].,
            //     per_unit:dataval.med_details[p].,
            //     product_id:dataval.med_details[p].med_id,
            //     hsn_no:dataval.med_details[p].hsn_no,
            //     reorder_level:dataval.med_details[p].,
            //     prod_cgst:dataval.med_details[p].cgst,
            //     prod_sgst:dataval.med_details[p].sgst,
            //   })
            // }


            console.log("PRODUCTS = " + JSON.stringify(this.productArray))

          }
        });
    }

  }


//   med_id	:	2066
// med_name	:	DOLOPAR
// generic_name	:	Caffeine (25mg) + Paracetamol (500mg)
// med_type_id	:	1
// hsn_no	:	30049069
// type_name	:	TABLET
// short_name	:	TAB
// short_form	:	
// show_short_form	:	1
// show_intake	:	1
// cgst	:	6
// sgst	:	6
// rack_id	:	6
// bin_id	:	19
// rack_desc	:	6
// bin_desc	:	10 D2
// quantity	:	339



  get_product_name(data) {
    // per_unit_desc
    if (data.cost_price == undefined) {
      this.productType = data.product_type_id;
      this.productName = data.name;
      this.orderUOM = data.uom_desc;
      this.prod_perunit = data.per_unit_desc;
      this.prod_perunit_id = data.per_unit;
      this.productID = data.product_id;
      this.orderProduct = data.hsn_no;
      this.unitPrice = 0;
      this.hsnNO = data.hsn_no;
      this.quanorder = data.reorder_level;
      this.prod_cgst = data.prod_cgst;
      this.prod_sgst = data.prod_sgst;
      this.productArray = [];
    } else {
      this.productType = data.product_type_id;
      this.productName = data.name;
      this.orderUOM = data.uom_desc;
      this.prod_perunit = data.per_unit_desc;
      this.prod_perunit_id = data.per_unit;
      this.productID = data.product_id;
      this.orderProduct = data.hsn_no;
      this.unitPrice = data.cost_price;
      this.hsnNO = data.hsn_no;
      this.quanorder = data.reorder_level;
      this.prod_cgst = data.prod_cgst;
      this.prod_sgst = data.prod_sgst;
      this.productArray = [];
    }
    // this.getsuppfromprod(this.productID)
  }

  PODetails() {

    this.actionflag = true
    if (this.paid != null && this.paid != undefined && this.paid != "") {
      var poidvalue = this.paid;
    }
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpo/", JSON.stringify({ purchase_order_id: poidvalue }), { headers: headers }).subscribe(
      data => {
        var obj = data.json()
        console.log(" data = " + JSON.stringify(obj))
        if (data.json().supp_id != null) {

          // for (var i = 0; i < this.supplierData.length; i++) {
          //   if (data.json().supp_id == this.supplierData[i].supp_id) {
          //     this.vendor = this.supplierData[i].name;
          //     break;
          //   }
          // }
          this.documentarray = [];
          this.totalAMT = 0;
          this.totalGST = 0;
          this.totalcgstpercent = 0;
          this.totalsgstpercent = 0;
          this.totalcgst = 0;
          this.totalsgst = 0;
          this.roundoff = 0;
          
          this.vendorID = data.json().supp_id;
          // this.vendor = data.json().name;

          this.printFlag = false;
          var podate = data.json().po_date.split('-');
          this.poDate = podate[2] + "-" + podate[1] + "-" + podate[0];
          if (data.json().logo != undefined) {
            this.hospImg = ipaddress.Ip_with_img_address + data.json().logo;
          } else {
            this.hospImg = "assets/imgs/HospitalIcon.png";
          }

          this.pharName = data.json().pharmacy_name;
          this.Address1 = data.json().phar_address1;
          if (data.json().phar_address2 != undefined) {
            this.Address2 = data.json().phar_address2;
          } else {
            this.Address2 = "";
          }
          this.location = data.json().phar_location;
          this.city = data.json().phar_city;
          this.state = data.json().phar_state;
          this.country = data.json().phar_country;
          this.telephone = data.json().phar_telephone;
          if (data.json().name != undefined) {
            this.vendorname = data.json().name;
          } else {
            this.vendorname = "";
          }

          if (data.json().mobile != undefined) {
            this.vendor_Mobile1 = data.json().mobile;
          } else {
            this.vendor_Mobile1 = "";
          }
          
          if (data.json().telephone != undefined) {
            this.vendor_Mobile2 = data.json().telephone;
          }
          else {
            this.vendor_Mobile2 = "";
          }

          if (data.json().city_desc != undefined) {
            this.vendorcity = data.json().city_desc;
          } else {
            this.vendorcity = "";
          }
          if (data.json().gst_no != undefined) {
            this.vendor_gst = data.json().gst_no;
          } else {
            this.vendor_gst = "";
          }
          
          if (data.json().state_desc != undefined) {
            this.venderstate = data.json().state_desc;
          } else {
            this.venderstate = "";
          }
      
          if (data.json().country_desc != undefined) {
            this.vendorcon =data.json().country_desc;
          } else {
            this.vendorcon = "";
          }
      
          if (data.json().address1 != undefined && data.json().address2 != undefined) {
            this.vendoraddress = data.json().address1 + "," + data.json().address2
          } else if (data.json().address1 != undefined) {
            this.vendoraddress = data.json().address1
          } else {
            this.vendoraddress = ""
          }
          this.purchase_order_id=data.json().po_no
          var purchaseadd = new StringBuilder("");
          if (data.json().address1 != null) {
            purchaseadd.append(data.json().address1);
            purchaseadd.append("\n");
            if (data.json().address2 != null && data.json().address2 != undefined) {
              purchaseadd.append(data.json().address2);
              purchaseadd.append("\n");
            }
            purchaseadd.append(data.json().city_desc);
            purchaseadd.append("\n");
            purchaseadd.append(data.json().state_desc);
            purchaseadd.append("\n");
            purchaseadd.append(data.json().country_desc);
            purchaseadd.append("\n");
            purchaseadd.append("Mobile :" + data.json().mobile);
            purchaseadd.append("\n");
            purchaseadd.append("Telephone :" + data.json().telephone);
            purchaseadd.append("\n");
            this.orderAddress = purchaseadd.toString();
          }
          if (data.json().po_base != undefined) {
            this.poBaseTXT = data.json().po_base;
          }
          if (data.json().po_base != undefined) {
            this.poBaseTXT = data.json().po_base;
          }
          // purchase order list table and GST table view
          this.purchaseOrderArray = [];
          if (data.json().purchase_items != null) {
            var mfgid;
            if (this.vendor != undefined) {
              mfgid = this.vendor.supp_id;
            }
            for (var i = 0; i < data.json().purchase_items.length; i++) {
              this.purchaseOrderArray.push({
                product_id: data.json().purchase_items[i].product_id,
                product_name: data.json().purchase_items[i].product_name,
                uom_code: data.json().purchase_items[i].uom_code,
                uom_desc: data.json().purchase_items[i].uom_desc,
                quantity: data.json().purchase_items[i].quantity,
              
                unit_price: data.json().purchase_items[i].unit_price,
                purchase_request_id: data.json().purchase_items[i].purchase_request_id,
                per_unit: data.json().purchase_items[i].per_unit,
                per_unit_desc: data.json().purchase_items[i].per_unit_desc,
                supp_id: mfgid,
                po_base: this.poBaseTXT,
                address: this.orderAddress,
                hsn_no: this.orderProduct,
                order_uom: this.orderUOM,
                // per_unit:this.prod_perunit,
                total_amount: "0.00",
                cgst:data.json().purchase_items[i].cgst,
                sgst:data.json().purchase_items[i].sgst
              });
              this.purchaseOrderRequestArray.push({
                product_id: data.json().purchase_items[i].product_id,
                quantity: data.json().purchase_items[i].quantity,
              });
              this.purchaseOrderArray1.push({
                product_id: data.json().purchase_items[i].product_id,
                quantity: data.json().purchase_items[i].quantity,
                unit_price: data.json().purchase_items[i].unit_price,
                total_amount: "0.00",
                purchase_order_id: this.paid,
                po_no: this.pono
              });
            }
          }
          this.sendPurchaseOrderRequestarry.push({
            purchase_request_id: this.paid,
            po_no: this.pono,
            pr_items: this.purchaseOrderRequestArray,
          });
          var prec_tx = 0.0;
          for (var i = 0; i < this.purchaseOrderArray.length; i++) {
            prec_tx = parseFloat(prec_tx.toString()) + parseFloat(this.purchaseOrderArray[i].tot_amount);
          }
          this.finalamount = 0;
          var x = parseFloat(prec_tx.toString()) + parseFloat(this.finalamount);
          x = Math.floor(x * 100) / 100;
          this.totalAmount = x.toFixed(2);
          if (data.json().purchase_requests != null) {
            for (var i = 0; i < data.json().purchase_requests.length; i++) {
              this.purchaseRequestRetrivelArray.push({
                purchase_request_id: data.json().purchase_requests[i].purchase_request_id,
                created_date: data.json().purchase_requests[i].created_date,
              })
            }
          }


          for (var i = 0; i < this.purchaseOrderArray.length; i++) {
            var cgst;
            var sgst;
            if (this.purchaseOrderArray[i].cgst != undefined) {
              cgst = this.purchaseOrderArray[i].cgst
            } else {
              cgst = 0
            }
      
      
            if (this.purchaseOrderArray[i].cgst != undefined) {
              sgst = this.purchaseOrderArray[i].sgst
            } else {
              sgst = 0
            }
      
            if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst == undefined) {
              this.purchaseOrderArray[i].gst = 1
              this.totalcgstpercent += 0
              this.totalsgstpercent += 0
              this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
              this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
            } else if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst != undefined) {
              this.purchaseOrderArray[i].gst = 0 + parseInt(this.purchaseOrderArray[i].sgst)
              this.totalcgstpercent += 0
              this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
              this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
              this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
            } else if (this.purchaseOrderArray[i].cgst != undefined && this.purchaseOrderArray[i].sgst == undefined) {
              this.purchaseOrderArray[i].gst = parseInt(this.purchaseOrderArray[i].cgst) + 0
              this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
              this.totalsgstpercent += 0
              this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
              this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
            } else {
              this.purchaseOrderArray[i].gst = parseInt(this.purchaseOrderArray[i].cgst) + parseInt(this.purchaseOrderArray[i].sgst)
              this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
              this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
              this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
              this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
            }
      
            var totalamount = parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].gst) / 100)
            var amount = parseFloat(this.purchaseOrderArray[i].quantity) * parseFloat(this.purchaseOrderArray[i].unit_price) + totalamount
            var amountfixed = amount.toFixed(2)
            var totalfixed = totalamount.toFixed(2)
            var amountbfround = amount
            var amountaftround = Math.round(amount)
            this.roundoff += amount - Math.round(amount)
            this.roundoff = this.spliceDecimal(this.roundoff)
            this.totalAMT += Math.round(amount)
            this.totalGST += totalamount
            var totalAMT = this.totalAMT
      
            var totalGST = this.totalGST.toFixed(2)
            this.totalamountinword = convertNumberToWords(totalAMT, ipaddress.country_code);
            this.documentarray.push({
              sn: [i + 1],
              Index: this.purchaseOrderArray[i].Index,
              product_id: this.purchaseOrderArray[i].product_id,
              hsn_no: this.purchaseOrderArray[i].product_code,
              product_name: this.purchaseOrderArray[i].product_name,
              quantity: this.purchaseOrderArray[i].quantity,
              unit_price: this.purchaseOrderArray[i].unit_price,
              total_amount: this.purchaseOrderArray[i].total_amount,
              cgst: this.purchaseOrderArray[i].cgst,
              sgst: this.purchaseOrderArray[i].sgst,
              gst: this.purchaseOrderArray[i].gst,
              total: totalfixed,
              amount: amountfixed
            })
          }



        }
      },
      error => { })
  }

  cancelClick() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/cpo/", JSON.stringify({ purchase_order_id: this.paid, po_no: this.pono }), { headers: headers }).subscribe(
      data => {
        if (data.json().key != null && data.json().key == "1") {
          this.toastr.success(Message_data.poCancelSuccess);
          this.messageservice.sendMessage("pharmacyresponse");
        }
      },
      error => { })
  }

  array_exits(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].test_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  saveClick(pdffile) {
    var flag = true;
    if (this.sendPurchaseOrderRequestarry.length != 0) {
      var pur_id_tmp_arr = [];
      for (var i = 0; i < this.sendPurchaseOrderRequestarry.length; i++) {
        if (this.array_exits(this.sendPurchaseOrderRequestarry, this.sendPurchaseOrderRequestarry[i].purchase_request_id)) {
          //do nothing
        } else {
          pur_id_tmp_arr.push(this.sendPurchaseOrderRequestarry[i].purchase_request_id);
        }
      }
      var main_arr = [];
      for (var i = 0; i < pur_id_tmp_arr.length; i++) {
        var add_new_tmp = [];
        for (var j = 0; j < this.sendPurchaseOrderRequestarry.length; j++) {
          if (pur_id_tmp_arr[i] == this.sendPurchaseOrderRequestarry[j].purchase_request_id) {
            add_new_tmp.push(this.sendPurchaseOrderRequestarry[j].pr_items);
          }
        }
        var myString = JSON.stringify(add_new_tmp);
        var sillyString = myString.substr(1).slice(0, -1);
        main_arr.push({
          purchase_request_id: pur_id_tmp_arr[i],
          pr_items: sillyString,
        });
      }
    }

    if (this.purchaseOrderArray.length > 0) {
      console.log("json objo of purchase order 1 = " + JSON.stringify(this.purchaseOrderArray))
      flag = true;
      this.saveFlag = true;
    }

    if (flag == true) {
      // this.saveFlag = false;
      var mfg_txt = undefined;
      if (this.supplierData != undefined) {
        for (var i = 0; i < this.supplierData.length; i++) {
          if (this.vendor == this.supplierData[i].name) {
            mfg_txt = this.supplierData[i].supp_id;
            break;
          }
        }
      }
      var upturl = "";
      if (this.paid.length != 0) {
        upturl = "inv/upo/"
      } else {
        upturl = "inv/spo/"
      }
      if (this.paid != '') {
        var data_poid = this.paid;
      }
      if (main_arr != null || main_arr != undefined) {
        main_arr = main_arr;
      }
      if (this.podepartname == undefined || null || "") {
        this.toastr.error(Message_data.unableToSavedept);
      }
      else if (this.postorename == undefined || null || "") {
        this.toastr.error(Message_data.unableToSavestore);
      }
      else {
        var sendda = JSON.stringify({
          supp_id: mfg_txt,
          purchase_requests: main_arr,
          ordered_items: this.purchaseOrderArray,
          pharmacist_id: this.userID,
          po_base: this.poBaseTXT,
          purchase_order_id: data_poid,
          country: ipaddress.country_code,
          pharmacy_id: this.pharmacyID,
          department_id: this.podepartname,
          store_id: this.postorename,
          is_approval: this.approval,
          // pdfdoc: pdffile
        });

        console.log("data = " + JSON.stringify(sendda))
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + upturl, sendda, { headers: headers }).subscribe(
          data => {
            console.log("data SAVE CLICK = " + JSON.stringify(data))
            if (data.json().key != null && data.json().key == "1") {
              this.saveFlag = false;
              this.toastr.success(Message_data.poSaveSuccess);
              this.purchase_order_id = data.json().po_no
              this.resultarray.push({
                supplier_id: data.json().supplier_id,

                po_no: data.json().po_no,
                pur_order_id: data.json().purchase_order_id
              })
              this.po_generator()
              this.messageservice.sendMessage("pharmacyresponse");
            } else {
              this.saveFlag = true;
              this.toastr.error(Message_data.unableToSave);
            }
          },
          error => { this.saveFlag = true; }

        );
      }
    }

  }

  pr_change(e, dataval) {
    var obj2 = e;
    var obj = dataval;
    if (e.checked != false) {
      this.prDate = undefined;
      this.productorderName = undefined;
      this.orderUOM = undefined;
      this.prod_perunit = undefined;
      this.orderProduct = undefined;
      this.unitPrice = undefined;
      this.quanorder = undefined;
      this.organizations = [];
      for (var i = 0; i < this.prList.length; i++) {
        if (dataval.purchase_request_id == this.prList[i].purchase_request_id) {
          this.prDate = Date_Formate(this.prList[i].created_date);
          this.prList[i].checked = true;
          break;
        }
      }
      var get_item_list: any = [];
      var supp_id = null;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      for (var i = 0; i < this.supplierData.length; i++) {
        if (this.vendor == this.supplierData[i].name) {
          supp_id = this.supplierData[i].supp_id;
          break;
        }
      }
      console.log("get purchase_order product = " + JSON.stringify({ pr_id: dataval.purchase_request_id, pharma_id: this.pharmacyID, suppid: supp_id }))
      this.http.post(ipaddress.getIp + "inv/gprid/", JSON.stringify(
        {
          pr_id: dataval.purchase_request_id,
          // pharma_id: this.pharmacyID,
          pharma_id: `${this.pharmacyID},${this.hsp_id},${this.diag_center_id}`,
          suppid: supp_id
        }), { headers: headers }).subscribe(
          data => {
            console.log(
              "send dta = " + JSON.stringify(
                {
                  pr_id: dataval.purchase_request_id,
                  // pharma_id: this.pharmacyID,
                  pharma_id: `${this.pharmacyID},${this.hsp_id},${this.diag_center_id}`,
                  suppid: supp_id
                })
            )

            get_item_list = data.json().item_details;
            console.log("GET _ITEM _LIST = " + JSON.stringify(get_item_list))
            if (data.json().item_details != undefined) {
              for (var i = 0; i < get_item_list.length; i++) {
                var found = false;
                for (var j = 0; j < this.purchaseOrderArray.length; j++) {
                  if (get_item_list[i].product_id === this.purchaseOrderArray[j].product_id) {
                    this.purchaseOrderArray[j].quantity += parseInt(get_item_list[i].quantity);
                    found = true;
                    break;
                  }
                }
                if (!found) {
  
                  this.purchaseOrderArray.push({
                    product_type_id: get_item_list[i].product_type_id,
                    product_id: get_item_list[i].product_id,
                    product_name: get_item_list[i].product_name,
                    product_type: get_item_list[i].product_id,
                    uom_desc: get_item_list[i].uom_desc,
                    quantity: parseInt(get_item_list[i].quantity),
                    unit_price: parseFloat(get_item_list[i].cost_price).toFixed(2),
                    supp_id: this.vendor.supp_id,
                    po_base: this.poBaseTXT,
                    address: this.orderAddress,
                    hsn_no: get_item_list[i].hsn_no,
                    purchase_request_id: dataval.purchase_request_id,
                    per_unit: get_item_list[i].perunit,
                    per_unit_desc:get_item_list[i].per_unit_desc,
                    total_amount: "0.00",
                    cgst: get_item_list[i].cgst,
                    sgst: get_item_list[i].sgst,
                    gst: parseFloat(get_item_list[i].cgst) + parseFloat(get_item_list[i].cgst)
                  });
                }
              }

              for (var i = 0; i < this.getItemArray.length; i++) {
                this.organizations.push({
                  name: this.getItemArray[i].product_name,
                  hsn_no: this.getItemArray[i].hsn_no,
                  product_id: this.getItemArray[i].product_id
                });
              }
            }

            setTimeout(() => {
              if (this.removeddata.length != 0) {
                console.log("removed data = "+JSON.stringify(this.removeddata))
                this.removeddata.forEach(removedItem => {
                  let purchaseOrderItem = this.purchaseOrderArray.find(item => item.product_id === removedItem.product_id && item.purchase_request_id === removedItem.purchase_request_id);
                  if (purchaseOrderItem) {
                    // purchaseOrderItem.quantity -= removedItem.quantity;
                    // if (purchaseOrderItem.quantity <= 0) {
                    //   this.purchaseOrderArray = this.purchaseOrderArray.filter(item => item.product_id !== purchaseOrderItem.product_id);
                    // }
                    if (purchaseOrderItem !== -1) {
                      // Remove the object from purchaseOrderArray using splice
                      this.purchaseOrderArray.splice(purchaseOrderItem, 1);
                    }
                  }
                });
              }
              // this.removeddata=[];
            }, 200);
            this.sendPurchaseOrderRequestarry.push({
              purchase_request_id: dataval.purchase_request_id,
              pr_items: this.purchaseOrderRequestArray,
            });
          },
          error => { });
    } else {
      this.prDate = undefined;
      this.productorderName = undefined;
      this.orderUOM = undefined;
      this.prod_perunit = undefined
      this.orderProduct = undefined;
      this.unitPrice = undefined;
      this.quanorder = undefined;
      this.organizations = [];
      for (var i = 0; i < this.prList.length; i++) {
        if (dataval.purchase_request_id == this.prList[i].purchase_request_id) {
          this.prDate = Date_Formate(this.prList[i].created_date);
          this.prList[i].checked = true;
          break;
        }
      }
      var get_item_list: any = [];
      supp_id = null;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      for (var i = 0; i < this.supplierData.length; i++) {
        if (this.vendor == this.supplierData[i].name) {
          supp_id = this.supplierData[i].supp_id;
          break;
        }
      }
      this.http.post(ipaddress.getIp + "inv/gprid/", JSON.stringify(
        {
          pr_id: dataval.purchase_request_id,
          // pharma_id: this.pharmacyID,
          pharma_id: `${this.pharmacyID},${this.hsp_id},${this.diag_center_id}`,
          suppid: supp_id
        }), { headers: headers }).
        subscribe
        (
          data => {
            get_item_list = data.json().item_details;
            if (data.json().item_details != undefined) {
              for (var i = 0; i < get_item_list.length; i++) {
                var found = false;
                console.log("OBJECT =" + JSON.stringify(this.purchaseOrderArray))
                for (var j = 0; j < this.purchaseOrderArray.length; j++) {
                  if (get_item_list[i].product_id === this.purchaseOrderArray[j].product_id) {
                    this.purchaseOrderArray[j].quantity -= parseInt(get_item_list[i].quantity);
                    if (parseInt(this.purchaseOrderArray[j].quantity) == 0) {
                      this.purchaseOrderArray.splice(j, 1);
                    }
                    found = true;
                  }
                }
              }

              for (var i = 0; i < this.getItemArray.length; i++) {
                this.organizations.push({
                  name: this.getItemArray[i].product_name,
                  hsn_no: this.getItemArray[i].hsn_no,
                  product_id: this.getItemArray[i].product_id
                });
              }
            }
            this.sendPurchaseOrderRequestarry.push({
              purchase_request_id: dataval.purchase_request_id,
              pr_items: this.purchaseOrderRequestArray,
            });
          })
    }
  }

  pr_changeproduct(e, dataval) {

    this.accordin_product = [];
    var get_item_list: any = [];
    var supp_id = null;
    for (var i = 0; i < this.supplierData.length; i++) {
      if (this.vendor == this.supplierData[i].name) {
        supp_id = this.supplierData[i].supp_id;
        break;
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gprid/", JSON.stringify({ pr_id: dataval.purchase_request_id, pharma_id: `${this.pharmacyID},${this.hsp_id},${this.diag_center_id}`, suppid: supp_id }), { headers: headers }).subscribe(
      data => {
        this.accordin_product = [];
        console.log("send_data" +
          JSON.stringify({
            pr_id: dataval.purchase_request_id,
            pharma_id: `${this.pharmacyID},
                ${this.hsp_id},
                ${this.diag_center_id}`,
            suppid: supp_id
          })
        )
        var obj = JSON.parse(data["_body"]);
        console.log("pr data = " + JSON.stringify(obj))
        get_item_list = data.json().item_details;
        console.log("get_item_list = " + JSON.stringify(get_item_list))
        for (var i = 0; i < get_item_list.length; i++) {
          this.accordin_product.push({
            product_type_id: get_item_list[i].product_type_id,
            product_id: get_item_list[i].product_id,
            product_name: get_item_list[i].product_name,
            product_type: get_item_list[i].product_id,
            uom_desc: get_item_list[i].uom_desc,
            quantity: parseInt(get_item_list[i].quantity),
            unit_price: parseFloat(get_item_list[i].cost_price).toFixed(2),
            supp_id: this.vendor.supp_id,
            po_base: this.poBaseTXT,
            address: this.orderAddress,
            hsn_no: get_item_list[i].hsn_no,
            purchase_request_id: dataval.purchase_request_id,
            per_unit: get_item_list[i].per_unit,
            total_amount: "0.00",
            cgst: get_item_list[i].cgst,
            sgst: get_item_list[i].sgst,
            gst: parseFloat(get_item_list[i].cgst) + parseFloat(get_item_list[i].cgst)
          })
        }
      },
      error => { })
  }


  purchase_edit(id, qty) {
    for (var i = 0; i < this.purchaseOrderArray.length; i++) {
      if (this.purchaseOrderArray[i].product_id == id && this.purchaseOrderArray[i].quantity == qty) {
        this.productID = this.purchaseOrderArray[i].product_id;
        this.productType = this.purchaseOrderArray[i].product_type_id;
        this.orderProduct = this.purchaseOrderArray[i].hsn_no;
        this.productName = this.purchaseOrderArray[i].product_name;
        this.orderUOM = this.purchaseOrderArray[i].uom_desc;
        this.prod_perunit = this.purchaseOrderArray[i].per_unit_desc
        this.prod_perunit_id = this.purchaseOrderArray[i].per_unit
        this.unitPrice = this.purchaseOrderArray[i].unit_price;
        this.quanorder = this.purchaseOrderArray[i].quantity;
        this.editflag = true;
        this.purchaseOrderArray.splice(i, 1);
      }
    }
  }

  po_basechange(e) {
    this.sendPurchaseOrderRequestarry = [];
    this.organizations = [];
    this.purchaseOrderArray = [];
    var supp_id = null;
    for (var i = 0; i < this.supplierData.length; i++) {
      if (this.vendor == this.supplierData[i].name) {
        supp_id = this.supplierData[i].supp_id;
        break;
      }
    }
    
    if (this.poBaseTXT == "Purchase Request") {
      this.prReq = false;
      this.noPrReq = false;
      this.productReqest = false;
      //PR number
      this.getPrList = []
      this.prList = [];
      this.productArray = [];
      this.purchaseOrderArray = [];

      this.get_type();
      var senddata;
      if (this.approval == "1") {
        senddata = {
          // pharma_id:this.pharmacyID,
          pharma_id: `${this.pharmacyID},${this.hsp_id},${this.diag_center_id}`,
          pharmacist_id: this.userID,
          supp_id: supp_id,
          is_approve: this.approval
        }
      }
      else {

        senddata = {
          pharma_id: `${this.pharmacyID},${this.hsp_id},${this.diag_center_id}`,
          pharmacist_id: this.userID,
          supp_id: supp_id,
        }
      }
      console.log("purchase order = " + JSON.stringify(senddata))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.httpclt.post(ipaddress.getIp + "inv/gprs/",
        senddata, { headers: headers }).subscribe(
          data => {
            var obj = JSON.parse(JSON.stringify(data));
            console.log("pr list = " + JSON.stringify(obj))
            this.getPrList = obj.purchase_requests;

            if (this.getPrList != undefined) {
              for (var i = 0; i < this.getPrList.length; i++) {
                var dataval = this.getPrList[i].created_date.split('-');

                var updatedProductList = this.getPrList[i].product_list ? 
                this.getPrList[i].product_list.map(product => {
                  return {
                    ...product,
                    purchase_request_id: this.getPrList[i].purchase_request_id
                  };
                }) : [];


                this.prList.push({
                  purchase_request_id: this.getPrList[i].purchase_request_id,
                  created_date: dataval[2] + "-" + dataval[1] + "-" + dataval[0],
                  department_desc: this.getPrList[i].department_desc,
                  department_id: this.getPrList[i].department_id,
                  store_id: this.getPrList[i].store_id,
                  store_desc: this.getPrList[i].store_desc,
                  product_list:updatedProductList, 
                  // this.getPrList[i].product_list || '',
                  checked: false,
                });
              }
            }
            console.log(" data = " + JSON.stringify(this.prList))
          },
          error => { });
    } else if (this.poBaseTXT == "No Purchase Request") {
      this.clear_variables()
      this.get_type();
      this.prReq = true;
      this.productReqest = true;
      this.noPrReq = false;
      this.purchaseOrderArray = [];
      for (var i = 0; i < this.gpcodeList.length; i++) {
        this.organizations.push({
          name: this.gpcodeList[i].name,
          hsn_no: this.gpcodeList[i].hsn_no,
          product_id: this.gpcodeList[i].product_id
        });
      }
    } else {
      this.prReq = true;
      this.noPrReq = true;
    }
  }

  product_namechange() {
    this.orderUOM = "";
    this.orderProduct = "";
    this.unitPrice = "";
    this.quanorder = "";
    this.prod_perunit = "";
    if (this.poBaseTXT == "Purchase Request") {
      for (var i = 0; i < this.getItemArray.length; i++) {
        if (this.productorderName == this.getItemArray[i].product_name) {
          this.orderUOM = this.getItemArray[i].uom_desc;
          this.orderProduct = this.getItemArray[i].hsn_no;
          this.unitPrice = this.getItemArray[i].cost_price;
          this.quanorder = this.getItemArray[i].quantity;
          this.prod_perunit = this.getItemArray[i].per_unit;
          break;
        }
      }
    } else {
      for (var i = 0; i < this.gpcodeList.length; i++) {
        if (this.productorderName == this.gpcodeList[i].name) {
          this.orderUOM = this.gpcodeList[i].uom_desc;
          this.prod_perunit = this.getItemArray[i].per_unit;
          this.orderProduct = this.gpcodeList[i].hsn_no;
          this.unitPrice = this.gpcodeList[i].cost_price;
          break;
        }
      }
    }
  }

  clear_variables(){
    this.purchaseOrderArray1 = [];
    this.prReq = true;
    this.noPrReq = true;
    this.organizations = [];
    this.purchaseOrderArray = [];
    this.removeddata=[];
    this.orderUOM = undefined;
    this.prod_perunit = undefined;
    this.orderProduct = undefined;
    this.unitPrice = undefined;
    this.quanorder = undefined;
    this.orderAddress = undefined;
    this.prTxt = undefined;
    this.prDate = undefined;
    this.productName='';
    this.productType='';
    this.vendor='';
  }

  vendoraddress_change(vendor) {
    console.log("VENDOR details = " + JSON.stringify(vendor))
    this.vendor = vendor.name;
    // this.purchaseOrderArray1 = [];
    // this.prReq = true;
    // this.noPrReq = true;
    // this.organizations = [];
    // this.purchaseOrderArray = [];
    // this.removeddata=[];
    // this.orderUOM = undefined;
    // this.prod_perunit = undefined;
    // this.orderProduct = undefined;
    // this.unitPrice = undefined;
    // this.quanorder = undefined;
    // this.orderAddress = undefined;
    // this.poBaseTXT = undefined;
    // this.prTxt = undefined;
    // this.prDate = undefined;
    //for document;
    if (vendor.mobile != undefined) {
      this.vendor_Mobile1 = vendor.mobile;
    } else {
      this.vendor_Mobile1 = "";
    }

    if (vendor.telephone != undefined) {
      this.vendor_Mobile2 = vendor.telephone;
    }
    else {
      this.vendor_Mobile2 = "";
    }

    if (vendor.city_desc != undefined) {
      this.vendorcity = vendor.city_desc;
    } else {
      this.vendorcity = "";
    }

    if (vendor.name != undefined) {
      this.vendorname = vendor.name;
    } else {
      this.vendorname = "";
    }

    if (vendor.gst_no != undefined) {
      this.vendor_gst = vendor.gst_no;
    } else {
      this.vendor_gst = "";
    }

    if (vendor.state_desc != undefined) {
      this.venderstate = vendor.state_desc;
    } else {
      this.venderstate = "";
    }

    if (vendor.country_desc != undefined) {
      this.vendorcon = vendor.country_desc;
    } else {
      this.vendorcon = "";
    }

    if (vendor.address1 != undefined && vendor.address2 != undefined) {
      this.vendoraddress = vendor.address1 + "," + vendor.address2
    } else if (vendor.address1 != undefined) {
      this.vendoraddress = vendor.address1
    } else {
      this.vendoraddress = ""
    }


    for (var i = 0; i < this.supplerMasterArray.length; i++) {
      if (this.vendor == this.supplerMasterArray[i].name) {
        if (this.supplerMasterArray[i].address != undefined) {
          var split_data = this.supplerMasterArray[i].address.split(",");
          if (split_data[1] != " ") {
            this.orderAddress = split_data[0] + ", " + this.supplerMasterArray[i].city_desc + ", " + this.supplerMasterArray[i].state_desc + ", " + this.supplerMasterArray[i].country_desc;
          } else {
            this.orderAddress = split_data[0] + ", " + split_data[1] + ", " + this.supplerMasterArray[i].city_desc + ", " + this.supplerMasterArray[i].state_desc + ", " + this.supplerMasterArray[i].country_desc;
          }
        } else {
          console.log("address detail is not retrived")
          break;
        }

      }
    }
    // this.poBaseTXT = "No Purchase Request"
    // this.po_basechange("No Purchase Request")
  }

  backClicked() {
    this.actionflag = false
    pharmacy_helper.setpoidlist(null)
    this.messageservice.sendMessage("pharmacyresponse");
  }

  print_area() {
    let printContents, popupWin;
    printContents = document.getElementById('divprint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Purchase order ${this.currentDate} ${this.current_time1}</title>
        <link rel="stylesheet" media="screen" href="">
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
        <style>
        @page { size: auto;  margin: 0mm; }
 table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #000;
    padding: 2px;
    text-align: center;
    font-size: 0.9em;
  }
  th {
    background-color: #f2f2f2;
  }
  .nested-table {
    width: 100%;
    border: none;
  }
    .details{
    font-size:13px;
    }
    .details_center{
    font-size:13px;
    text-align:center
    }
    .details_left{
    font-size:13px;
    text-align:left
    }
    .details_heading{
    font-size:17px;
    text-align:center
    }
      .print-line {
    display: block;
    border: 1px solid #000; /* Customize thickness and color */
  }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`
    );
    popupWin.document.close();
  }

  back_Nav() {
    //  this.router.navigateByUrl("/pharmahome")
  }


  getBase64Image(data) {
    var header = new Headers();
    header.append('content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gb64/', {
      imagepath: data
    }, { headers: header })
      .subscribe(response => {

        var data = response.json();
        console.log("response = " + JSON.stringify(data))
        this.imagestring = "data:image/jpeg;base64," + data.imagestr;
        console.log("image string = " + this.imagestring)
      })
  }

  spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part

    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number
      return parseFloat(newNumberStr);

    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }



  po_generator() {

    if (this.print_template != undefined && this.print_template == "banner") {
      this.bannerTemplateforpodata();

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      this.printlogowithnamepdfforpodata();

    } else {

      this.printnoheaderdf();
    }
  }

  bannerTemplateforpodata() {
    console.log(" PURCHASE ORDER ARRAY = " + JSON.stringify(this.purchaseOrderArray))

    this.documentarray = [];
    this.totalAMT = 0;
    this.totalGST = 0;
    this.totalcgstpercent = 0;
    this.totalsgstpercent = 0;
    this.totalcgst = 0;
    this.totalsgst = 0;
    this.roundoff = 0;
    for (var i = 0; i < this.purchaseOrderArray.length; i++) {
      var cgst;
      var sgst;
      if (this.purchaseOrderArray[i].cgst != undefined) {
        cgst = this.purchaseOrderArray[i].cgst
      } else {
        cgst = 0
      }


      if (this.purchaseOrderArray[i].cgst != undefined) {
        sgst = this.purchaseOrderArray[i].sgst
      } else {
        sgst = 0
      }

      if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst == undefined) {
        this.purchaseOrderArray[i].gst = 1
        this.totalcgstpercent += 0
        this.totalsgstpercent += 0
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
      } else if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst != undefined) {
        this.purchaseOrderArray[i].gst = 0 + parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgstpercent += 0
        this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
      } else if (this.purchaseOrderArray[i].cgst != undefined && this.purchaseOrderArray[i].sgst == undefined) {
        this.purchaseOrderArray[i].gst = parseInt(this.purchaseOrderArray[i].cgst) + 0
        this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
        this.totalsgstpercent += 0
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
      } else {
        this.purchaseOrderArray[i].gst = parseInt(this.purchaseOrderArray[i].cgst) + parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
        this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
      }

      var totalamount = parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].gst) / 100)
      var amount = parseFloat(this.purchaseOrderArray[i].quantity) * parseFloat(this.purchaseOrderArray[i].unit_price) + totalamount
      var amountfixed = amount.toFixed(2)
      var totalfixed = totalamount.toFixed(2)
      this.roundoff += amount - Math.round(amount)
      this.roundoff = this.spliceDecimal(this.roundoff)
      this.totalAMT += Math.round(amount)
      this.totalGST += totalamount
      var totalAMT = this.totalAMT.toFixed(2)
      var totalGST = this.totalGST.toFixed(2)
      this.totalamountinword = convertNumberToWords(totalAMT, ipaddress.country_code);

      this.documentarray.push({
        sn: [i + 1],
        Index: this.purchaseOrderArray[i].Index,
        product_id: this.purchaseOrderArray[i].product_id,
        hsn_no: this.purchaseOrderArray[i].product_code,
        product_name: this.purchaseOrderArray[i].product_name,
        quantity: this.purchaseOrderArray[i].quantity,
        unit_price: this.purchaseOrderArray[i].unit_price,
        total_amount: this.purchaseOrderArray[i].total_amount,
        cgst: this.purchaseOrderArray[i].cgst,
        sgst: this.purchaseOrderArray[i].sgst,
        gst: this.purchaseOrderArray[i].gst,
        total: totalfixed,
        amount: amountfixed
      })
    }
    console.log(" PURCHASE ORDER ARRAY 2 = " + JSON.stringify(this.documentarray))

    var tempArr = [];
    var pdffile;
    let popupWin;
    let printContents;

    let tableData = [];
    let tablebody = [];
    let tablefooter = [];
    let tableheader1Data = [];
    let tableheader2Data = [];


    // Push header rows into the table data array
    tableheader1Data = [
      'SI NO', 'Item Name', 'HSN CODE', 'Qty', 'Rate', 'Disc', 'GST%', 'GST Amount (Rs)', 'Amount'];
    tableheader2Data = [
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: 'CGST' },
      { text: 'SGST' },
      { text: 'TOTAL' },
      { text: ' ' },
      { text: ' ' },
    ];

    this.documentarray.forEach(data => {
      tablebody.push([
        { text: data.sn, alignment: 'center', fontSize: 8 },  // SN
        { text: data.product_name, fontSize: 8 },// Product Name
        { text: data.hsn_no, fontSize: 8 },  // Product Supplier ID
        { text: data.quantity, fontSize: 8 },// Quantity
        { text: data.unit_price, fontSize: 8 },
        { text: data.disc, fontSize: 8 },
        { text: data.gst, alignment: 'right', fontSize: 8 },
        {
          table: {
            widths: [20, 20, 25],
            body: [
              [{ text: data.cgst, alignment: 'right', fontSize: 8 }, { text: data.sgst, alignment: 'right', fontSize: 8 }, { text: data.total, alignment: 'right', fontSize: 8 }]
            ]
          }, layout: {
            defaultBorder: false,
          }

        },
        { text: data.amount, alignment: 'right', fontSize: 8 }
        // table:{
        //   body:[
        // [{text:'CGST',fontSize: 10},{text:'SGST',fontSize: 10},{text:'TOTAL',fontSize: 10}]
        //   ]
        // },	layout: {
        //   defaultBorder: false,
        // }

      ]);
    });
    let po_data = {
      content: [
        {
          image: this.imagestring,
          width: 550,
          height: 90,
          alignment: 'center',
          // margin: [-5, -20, 0, 20],
          layout: 'noBorders'
        },
        {
          fontSize: 10,
          text: this.hospitalName + "-" + this.user_type,
          style: '',
          alignment: 'center'
        }
        ,
        {
          text: ' ',
          style: ''
        }
        ,
        {
          text: ' ',
          style: ''
        }

        , {
          fontSize: 10,
          text: this.address1 + ',' + this.address2 + ',' + this.vendorlocation + ',' + this.city_desc + '-' + this.zipcode,
          style: '',
          alignment: 'center'
        },
        {
          text: '______________________________________________________________________________________________'
        }
        , {
          fontSize: 9,
          text: 'PURCHASE ORDER',
          style: '',
          alignment: 'center'
        },
        {
          columns: [
            {
              fontSize: 9,
              text: 'Hospital GSTIN No:',

            },
            {

              text: '',

            },

          ]

        },
        {
          columns: [
            {
              fontSize: 9,
              width: '*',
              text: 'Purchase Order No:' + this.purchase_order_id,

            },
            //  {
            //   width: '*',
            //   text: '',

            // }, {
            //   width: 50,
            //   text: '',

            // },
            {
              fontSize: 9,
              width: '*',
              text: 'Date/Time: ' + this.presentdate + "/" + this.current_time1,

            },
            // {
            //   width: '*',
            //   text: 'Time: ' + this.current_time1,

            // },
          ]
        },
        {
          text: ' ',
          style: ''
        },
        {
          fontSize: 9,
          text: 'To',
          style: ''
        }, {
          fontSize: 9,
          text: this.vendorname,
          style: ''
        },
        , {
          fontSize: 9,
          text: this.vendoraddress + "  " + this.vendorcity,
          style: ''
        },
        {
          text: ' ',
          style: ''
        },
        {
          fontSize: 9,
          text: this.venderstate,
          style: ''
        },
        {
          fontSize: 9,
          text: this.vendorcon,
          style: ''
        },
        {
          fontSize: 9,
          text: 'Phone: ' + this.vendor_Mobile1 + "  " + this.vendor_Mobile2,
          style: ''
        }
        ,
        {
          text: ' ',
          style: ''
        },
        {
          table: {
            body: [
              { text: 'Supplier GSTIN No: ' + this.vendor_gst, fontSize: 9 }
            ]
          }, layout: {
            defaultBorder: false,
          }
        },
        {
          text: ' ',
          style: ''
        }, {
          fontSize: 9,
          text: 'Dear Sir/Mam,',
          style: ''
        },
        {
          fontSize: 9,
          text: 'Sub: Supply of items required for our Hospital',
          style: ''
        }, {
          fontSize: 9,
          text: 'We are pleased to place purchse order for supply of the following items',
          style: ''
        },
        //  {
        this.po_productdetailstable(tablebody, [
          { text: 'S N', fontSize: 9 },
          { text: 'Item Name', fontSize: 9 },
          { text: 'HSN CODE', fontSize: 9 },
          { text: 'Qty', fontSize: 9 },
          { text: 'Rate', fontSize: 9 },
          { text: 'Disc', fontSize: 9 },
          { text: 'GST%', fontSize: 9 },
          // { text: 'GST Amt (Rs)', colspan: 3, fontSize: 9  },
          [
            { text: 'GST Amt', fontSize: 9 },
            {
              table: {
                widths: [20, 20, 20],
                body: [
                  [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }],
                ]
              }, layout: {
                defaultBorder: false,
              }
            },
          ],
          { text: 'Amt', fontSize: 9 }
        ],

        ),

        {
          table: {
            // widths: [45, 45, 45, 45, 50, 45, 45, 60, 90],
            widths: [40, 40, 40, 40, 45, 40, 40, 55, 90],

            body: [
              [{ text: 'CGST%', fontSize: 9 },
              { text: 'CGST', fontSize: 9 },
              { text: 'SGST%', fontSize: 9 },
              { text: 'SGST', fontSize: 9 },
              { text: 'IGST%', fontSize: 9 },
              { text: 'IGST', fontSize: 9 },
              { text: 'Total GST', alignment: "LEFT", fontSize: 9 },
              { text: 'Total AMT', alignment: "right", fontSize: 9 },
              { text: ": " + totalAMT, alignment: "right", fontSize: 8 }
              ],
              [{ text: this.totalcgstpercent, alignment: "right", fontSize: 8 },
              { text: this.totalcgst, alignment: "right", fontSize: 8 },
              { text: this.totalsgstpercent, alignment: "right", fontSize: 8 },
              { text: this.totalsgst, alignment: "right", fontSize: 8 },
              { text: ' ' },
              { text: ' ' },
              { text: totalGST, alignment: "LEFT", fontSize: 8 },
              { text: 'Discount', alignment: "right", fontSize: 9 },
              { text: ' ' }
              ]
            ]
          }
        },

        {
          table: {
            widths: [339, 60, 85],

            body: [

              [
                { text: 'Total Amount in Words: ', fontSize: 9 }, {
                  text: 'Round off', alignment: "right", fontSize: 9
                }, { text: this.roundoff, alignment: "right", fontSize: 8 }
              ],
              [
                { text: this.totalamountinword, aligment: "left", fontSize: 9 }, {
                  text: 'Grand total', alignment: "right", fontSize: 9
                }, { text: this.totalAMT, alignment: "right", fontSize: 8 }
              ]
            ]
          }
        },
        {
          text: ' ',
          style: ''
        },
        , {
          text: 'Please expedite supplies accordingly, and arrange to send the Bill of cost in duplicate for arranging', fontSize: 9
        }, {
          text: 'Payment of the same on receipt and inspectio of materials at our end.', fontSize: 9
        }, {
          text: ' ',
          style: ''
        },
        {
          columns: [
            {
              width: '*',
              text: 'Remarks: ' + "", fontSize: 9
            }, {
              width: '*',
              text: 'Prepared By: ' + this.username, fontSize: 9
            }
          ]
        },
      ]
    };

    this.pdfObj = pdfMake.createPdf(po_data);
    console.log(JSON.stringify(po_data))
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any,) {
        pdffile = reader.result;
        //   this.Medical_prescription_save(pdffile);
        this.pdfdata = this.resultarray.map(item => ({
          supp_id: item.supplier_id,

          purchase_order_id: item.pur_order_id,
          pdfdoc: pdffile,
          ordered_items: this.purchaseOrderArray,
          country: ipaddress.country_code
        }));

        this.save_pdf()
      }.bind(this);
      // var a = document.createElement('a');
      //     document.body.appendChild(a);
      //     // Set the download attribute and file name
      //      a.href = window.URL.createObjectURL(blob);
      //      a.download = 'purchase_order.pdf';
      //      // Trigger the click event
      //      a.click();
      //      // Clean up
      //     window.URL.revokeObjectURL(a.href);
      //      document.body.removeChild(a);
    });
    printContents = this.pdfObj
  }



  printlogowithnamepdfforpodata() {
    console.log(" PURCHASE ORDER ARRAY = " + JSON.stringify(this.purchaseOrderArray))
    this.documentarray = [];
    this.totalAMT = 0;
    this.totalGST = 0;
    this.totalcgstpercent = 0;
    this.totalsgstpercent = 0;
    this.totalcgst = 0;
    this.totalsgst = 0;
    this.roundoff = 0;
    for (var i = 0; i < this.purchaseOrderArray.length; i++) {
      var cgst;
      var sgst;
      if (this.purchaseOrderArray[i].cgst != undefined) {
        cgst = this.purchaseOrderArray[i].cgst
      } else {
        cgst = 0
      }


      if (this.purchaseOrderArray[i].cgst != undefined) {
        sgst = this.purchaseOrderArray[i].sgst
      } else {
        sgst = 0
      }

      if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst == undefined) {
        this.purchaseOrderArray[i].gst = 1
        this.totalcgstpercent += 0
        this.totalsgstpercent += 0
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
      } else if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst != undefined) {
        this.purchaseOrderArray[i].gst = 0 + this.purchaseOrderArray[i].sgst
        this.totalcgstpercent += 0
        this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
      } else if (this.purchaseOrderArray[i].cgst != undefined && this.purchaseOrderArray[i].sgst == undefined) {
        this.purchaseOrderArray[i].gst = this.purchaseOrderArray[i].cgst + 0
        this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
        this.totalsgstpercent += 0
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
      } else {
        this.purchaseOrderArray[i].gst = this.purchaseOrderArray[i].cgst + this.purchaseOrderArray[i].sgst
        this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
        this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
      }

      var totalamount = parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].gst) / 100)
      var amount = parseFloat(this.purchaseOrderArray[i].quantity) * parseFloat(this.purchaseOrderArray[i].unit_price) + totalamount
      var amountfixed = amount.toFixed(2)
      var totalfixed = totalamount.toFixed(2)
      this.roundoff += amount - Math.round(amount)
      this.roundoff = this.spliceDecimal(this.roundoff)
      this.totalAMT += Math.round(amount)
      this.totalGST += totalamount
      var totalAMT = this.totalAMT.toFixed(2)
      var totalGST = this.totalGST.toFixed(2)
      this.totalamountinword = convertNumberToWords(totalAMT, ipaddress.country_code);
      this.documentarray.push({
        sn: [i + 1],
        Index: this.purchaseOrderArray[i].Index,
        product_id: this.purchaseOrderArray[i].product_id,
        hsn_no: this.purchaseOrderArray[i].product_code,
        product_name: this.purchaseOrderArray[i].product_name,
        quantity: this.purchaseOrderArray[i].quantity,
        unit_price: this.purchaseOrderArray[i].unit_price,
        total_amount: this.purchaseOrderArray[i].total_amount,
        cgst: this.purchaseOrderArray[i].cgst,
        sgst: this.purchaseOrderArray[i].sgst,
        gst: this.purchaseOrderArray[i].gst,
        total: totalfixed,
        amount: amountfixed
      })
    }
    console.log(" PURCHASE ORDER ARRAY 2 = " + JSON.stringify(this.documentarray))

    var tempArr = [];
    var pdffile;
    let popupWin;
    let printContents;

    let tableData = [];
    let tablebody = [];
    let tablefooter = [];
    let tableheader1Data = [];
    let tableheader2Data = [];


    // Push header rows into the table data array
    tableheader1Data = [
      'SI NO', 'Item Name', 'HSN CODE', 'Qty', 'Rate', 'Disc', 'GST%', 'GST Amount (Rs)', 'Amount'];
    tableheader2Data = [
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: 'CGST' },
      { text: 'SGST' },
      { text: 'TOTAL' },
      { text: ' ' },
      { text: ' ' },
    ];

    this.documentarray.forEach(data => {
      tablebody.push([
        { text: data.sn, alignment: 'center', fontSize: 8 },  // SN
        { text: data.product_name, fontSize: 8 },// Product Name
        { text: data.hsn_no, fontSize: 8 },  // Product Supplier ID
        { text: data.quantity, fontSize: 8 },// Quantity
        { text: data.unit_price, fontSize: 8 },
        { text: data.disc, fontSize: 8 },
        { text: data.gst, alignment: 'right', fontSize: 8 },
        {
          table: {
            widths: [20, 20, 25],
            body: [
              [{ text: data.cgst, alignment: 'right', fontSize: 8 }, { text: data.sgst, alignment: 'right', fontSize: 8 }, { text: data.total, alignment: 'right', fontSize: 8 }]
            ]
          }, layout: {
            defaultBorder: false,
          }

        },
        { text: data.amount, alignment: 'right', fontSize: 8 }
        // table:{
        //   body:[
        // [{text:'CGST',fontSize: 10},{text:'SGST',fontSize: 10},{text:'TOTAL',fontSize: 10}]
        //   ]
        // },	layout: {
        //   defaultBorder: false,
        // }

      ]);
    });
    let po_data = {
      content: [
        {
          style: 'personaltable',
          table: {
            widths: [60, 600],
            body: [
              [
                {
                  rowspan: 4,
                  image: this.imagestring,
                  width: 50,
                  height: 50,
                },
                [
                  {
                    table: {
                      widths: [310, 250],
                      style: 'personaltable',
                      body: [
                        [this.hospitalName + "-" + this.user_type, '' + ""],
                        [this.address1 + "," + this.address2 + "," + this.vendorlocation, 'Date: ' + this.presentdate],
                        [this.vendorcity, ' '],
                        [this.venderstate, ' '],
                      ]
                    },
                    layout: 'noBorders'
                  }
                ],
              ],
            ]
          },
          layout: 'noBorders'
        },
        {
          fontSize: 10,
          text: this.hospitalName + "-" + this.user_type,
          style: '',
          alignment: 'center'
        }
        ,
        {
          text: ' ',
          style: ''
        }
        ,
        {
          text: ' ',
          style: ''
        }

        , {
          fontSize: 10,
          text: this.address1 + ',' + this.address2 + ',' + this.vendorlocation + ',' + this.city_desc + '-' + this.zipcode,
          style: '',
          alignment: 'center'
        },
        {
          text: '______________________________________________________________________________________________'
        }
        , {
          fontSize: 9,
          text: 'PURCHASE ORDER',
          style: '',
          alignment: 'center'
        },
        {
          columns: [
            {
              fontSize: 9,
              text: 'Hospital GSTIN No:',

            },
            {

              text: '',

            },

          ]

        },
        {
          columns: [
            {
              fontSize: 9,
              width: '*',
              text: 'Purchase Order No:' + this.purchase_order_id,

            }, {
              fontSize: 9,
              width: '*',
              text: 'Date/Time: ' + this.presentdate + "/" + this.current_time1,

            },
          ]
        },
        {
          text: ' ',
          style: ''
        },
        {
          fontSize: 9,
          text: 'To',
          style: ''
        }, {
          fontSize: 9,
          text: this.vendorname,
          style: ''
        },
        , {
          fontSize: 9,
          text: this.vendoraddress + "  " + this.vendorcity,
          style: ''
        },
        {
          text: ' ',
          style: ''
        },
        {
          fontSize: 9,
          text: this.venderstate,
          style: ''
        },
        {
          fontSize: 9,
          text: this.vendorcon,
          style: ''
        },
        {
          fontSize: 9,
          text: 'Phone: ' + this.vendor_Mobile1 + "  " + this.vendor_Mobile2,
          style: ''
        }
        ,
        {
          text: ' ',
          style: ''
        },
        {
          table: {
            body: [
              { text: 'Supplier GSTIN No: ' + this.vendor_gst, fontSize: 9 }
            ]
          }, layout: {
            defaultBorder: false,
          }
        },
        {
          text: ' ',
          style: ''
        }, {
          fontSize: 9,
          text: 'Dear Sir/Mam,',
          style: ''
        },
        {
          fontSize: 9,
          text: 'Sub: Supply of items required for our Hospital',
          style: ''
        }, {
          fontSize: 9,
          text: 'We are pleased to place purchse order for supply of the following items',
          style: ''
        },
        //  {
        this.po_productdetailstable(tablebody, [
          { text: 'S NO', fontSize: 9 },
          { text: 'Item Name', fontSize: 9 },
          { text: 'HSN CODE', fontSize: 9 },
          { text: 'Qty', fontSize: 9 },
          { text: 'Rate', fontSize: 9 },
          { text: 'Disc', fontSize: 9 },
          { text: 'GST%', fontSize: 9 },
          // { text: 'GST Amt (Rs)', colspan: 3, fontSize: 9 },
          [
            { text: 'GST Amt', fontSize: 9 },
            {
              table: {
                widths: [20, 20, 20],
                body: [
                  [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }],
                ]
              }, layout: {
                defaultBorder: false,
              }
            },
          ],
          { text: 'Amt', fontSize: 9 }
        ],
          // [
          //   { text: ' ' },
          //   { text: ' ' },
          //   { text: ' ' },
          //   { text: ' ' },
          //   { text: ' ' },
          //   { text: ' ' },
          //   { text: ' ' },
          //   {
          //     table: {
          //       widths: [20, 20, 20],
          //       body: [
          //         [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }]
          //       ]
          //     }, layout: {
          //       defaultBorder: false,
          //     }
          //   },
          //   { text: ' ' }
          // ]
        ),
        // },
        {
          table: {
            // widths: [45, 45, 45, 45, 50, 45, 45, 60, 90],
            widths: [40, 40, 40, 40, 45, 40, 40, 55, 90],
            body: [
              [{ text: 'CGST%', fontSize: 9 },
              { text: 'CGST', fontSize: 9 },
              { text: 'SGST%', fontSize: 9 },
              { text: 'SGST', fontSize: 9 },
              { text: 'IGST%', fontSize: 9 },
              { text: 'IGST', fontSize: 9 },
              { text: 'Total GST', alignment: "LEFT", fontSize: 9 },
              { text: 'Total AMT', alignment: "right", fontSize: 9 },
              { text: ": " + totalAMT, alignment: "right", fontSize: 8 }
              ],
              [{ text: this.totalcgstpercent, alignment: "right", fontSize: 8 },
              { text: this.totalcgst, alignment: "right", fontSize: 8 },
              { text: this.totalsgstpercent, alignment: "right", fontSize: 8 },
              { text: this.totalsgst, alignment: "right", fontSize: 8 },
              { text: ' ' },
              { text: ' ' },
              { text: totalGST, alignment: "LEFT", fontSize: 8 },
              { text: 'Discount', alignment: "right", fontSize: 9 },
              { text: ' ' }
              ]
            ]
          }
        },

        {
          table: {
            widths: [339, 60, 85],

            body: [

              [
                { text: 'Total Amount in Words: ', fontSize: 9 },
                { text: 'Round off', alignment: "right", fontSize: 9 },
                { text: this.roundoff, alignment: "right", fontSize: 8 }
              ],
              [
                { text: this.totalamountinword, aligment: "left", fontSize: 9 }, {
                  text: 'Grand total', alignment: "right", fontSize: 9
                }, { text: this.totalAMT, alignment: "right", fontSize: 8 }
              ]
            ]
          }
        },
        {
          text: ' ',
          style: ''
        },
        , {
          fontSize: 9,
          text: 'Please expedite supplies accordingly, and arrange to send the Bill of cost in duplicate for arranging',
        }, {
          fontSize: 9,
          text: 'Payment of the same on receipt and inspectio of materials at our end.'
        }, {
          text: ' ',
          style: ''
        },
        {
          columns: [
            {
              width: '*',
              text: 'Remarks: ' + "", fontSize: 9
            }, {
              width: '*',
              text: 'Prepared By: ' + this.username, fontSize: 9
            }
          ]
        },
      ],
      styles: {
        personaltable: {
          fontSize: 12,
          width: 800,
        }
      }
    };

    this.pdfObj = pdfMake.createPdf(po_data);
    console.log(JSON.stringify(po_data))
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any,) {
        pdffile = reader.result;
        //   this.Medical_prescription_save(pdffile);
        this.pdfdata = this.resultarray.map(item => ({
          supp_id: item.supplier_id,

          purchase_order_id: item.pur_order_id,
          pdfdoc: pdffile,
          ordered_items: this.purchaseOrderArray,
          country: ipaddress.country_code
        }));

        this.save_pdf()
      }.bind(this);
      // var a = document.createElement('a');
      //     document.body.appendChild(a);
      //     // Set the download attribute and file name
      //      a.href = window.URL.createObjectURL(blob);
      //      a.download = 'purchase_order.pdf';
      //      // Trigger the click event
      //      a.click();
      //      // Clean up
      //     window.URL.revokeObjectURL(a.href);
      //      document.body.removeChild(a);
    });
    printContents = this.pdfObj
  }

  printnoheaderdf() {
    console.log(" PURCHASE ORDER ARRAY = " + JSON.stringify(this.purchaseOrderArray))
    this.documentarray = [];
    this.totalAMT = 0;
    this.totalGST = 0;
    this.totalcgstpercent = 0;
    this.totalsgstpercent = 0;
    this.totalcgst = 0;
    this.totalsgst = 0;
    this.roundoff = 0;
    for (var i = 0; i < this.purchaseOrderArray.length; i++) {
      var cgst;
      var sgst;
      if (this.purchaseOrderArray[i].cgst != undefined) {
        cgst = this.purchaseOrderArray[i].cgst
      } else {
        cgst = 0
      }


      if (this.purchaseOrderArray[i].cgst != undefined) {
        sgst = this.purchaseOrderArray[i].sgst
      } else {
        sgst = 0
      }

      if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst == undefined) {
        this.purchaseOrderArray[i].gst = 1
        this.totalcgstpercent += 0
        this.totalsgstpercent += 0
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
      } else if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst != undefined) {
        this.purchaseOrderArray[i].gst = 0 + parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgstpercent += 0
        this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
      } else if (this.purchaseOrderArray[i].cgst != undefined && this.purchaseOrderArray[i].sgst == undefined) {
        this.purchaseOrderArray[i].gst = parseInt(this.purchaseOrderArray[i].cgst) + 0
        this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
        this.totalsgstpercent += 0
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
      } else {
        this.purchaseOrderArray[i].gst = parseInt(this.purchaseOrderArray[i].cgst) + parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
        this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
      }

      var totalamount = parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].gst) / 100)
      var amount = parseFloat(this.purchaseOrderArray[i].quantity) * parseFloat(this.purchaseOrderArray[i].unit_price) + totalamount
      var amountfixed = amount.toFixed(2)
      var totalfixed = totalamount.toFixed(2)
      var amountbfround = amount
      var amountaftround = Math.round(amount)
      this.roundoff += amount - Math.round(amount)
      this.roundoff = this.spliceDecimal(this.roundoff)
      this.totalAMT += Math.round(amount)
      this.totalGST += totalamount
      var totalAMT = this.totalAMT

      var totalGST = this.totalGST.toFixed(2)
      this.totalamountinword = convertNumberToWords(totalAMT, ipaddress.country_code);
      this.documentarray.push({
        sn: [i + 1],
        Index: this.purchaseOrderArray[i].Index,
        product_id: this.purchaseOrderArray[i].product_id,
        hsn_no: this.purchaseOrderArray[i].product_code,
        product_name: this.purchaseOrderArray[i].product_name,
        quantity: this.purchaseOrderArray[i].quantity,
        unit_price: this.purchaseOrderArray[i].unit_price,
        total_amount: this.purchaseOrderArray[i].total_amount,
        cgst: this.purchaseOrderArray[i].cgst,
        sgst: this.purchaseOrderArray[i].sgst,
        gst: this.purchaseOrderArray[i].gst,
        total: totalfixed,
        amount: amountfixed
      })
    }
    console.log(" PURCHASE ORDER ARRAY 2 = " + JSON.stringify(this.documentarray))

    var tempArr = [];
    var pdffile;
    let popupWin;
    let printContents;

    let tableData = [];
    let tablebody = [];
    let tablefooter = [];
    let tableheader1Data = [];
    let tableheader2Data = [];


    // Push header rows into the table data array
    tableheader1Data = [
      'SI NO', 'Item Name', 'HSN CODE', 'Qty', 'Rate', 'Disc', 'GST%', 'GST Amount (Rs)', 'Amount'];
    tableheader2Data = [
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: 'CGST' },
      { text: 'SGST' },
      { text: 'TOTAL' },
      { text: ' ' },
      { text: ' ' },
    ];

    this.documentarray.forEach(data => {
      tablebody.push([
        { text: data.sn, alignment: 'center', fontSize: 8 },  // SN
        { text: data.product_name, fontSize: 8 },// Product Name
        { text: data.hsn_no, fontSize: 8 },  // Product Supplier ID
        { text: data.quantity, fontSize: 8 },// Quantity
        { text: data.unit_price, fontSize: 8 },
        { text: data.disc, fontSize: 8 },
        { text: data.gst, alignment: 'right', fontSize: 8 },
        {
          table: {
            widths: [20, 20, 25],
            body: [
              [{ text: data.cgst, alignment: 'right', fontSize: 8 }, { text: data.sgst, alignment: 'right', fontSize: 8 }, { text: data.total, alignment: 'right', fontSize: 8 }]
            ]
          }, layout: {
            defaultBorder: false,
          }

        },
        { text: data.amount, alignment: 'right', fontSize: 8 }
        // table:{
        //   body:[
        // [{text:'CGST',fontSize: 10},{text:'SGST',fontSize: 10},{text:'TOTAL',fontSize: 10}]
        //   ]
        // },	layout: {
        //   defaultBorder: false,
        // }

      ]);
    });
    let po_data = {
      content: [
        {
          fontSize: 10,
          text: this.hospitalName + "-" + this.user_type,
          style: '',
          alignment: 'center'
        }
        ,
        {
          text: ' ',
          style: ''
        }
        ,
        {
          text: ' ',
          style: ''
        }

        , {
          fontSize: 10,
          text: this.address1 + ',' + this.address2 + ',' + this.vendorlocation + ',' + this.city_desc + '-' + this.zipcode,
          style: '',
          alignment: 'center'
        },
        {
          text: '______________________________________________________________________________________________'
        }
        , {
          fontSize: 9,
          text: 'PURCHASE ORDER',
          style: '',
          alignment: 'center'
        },
        {
          columns: [
            {
              fontSize: 9,
              text: 'Hospital GSTIN No:',

            },
            {

              text: '',

            },

          ]

        },
        {
          columns: [
            {
              fontSize: 9,
              width: '*',
              text: 'Purchase Order No :' + this.purchase_order_id,

            },
            // {
            //   width: '*',
            //   text: '',

            // }, {
            //   width: 50,
            //   text: '',

            // },
            {
              fontSize: 9,
              width: '*',
              text: 'Date/Time: ' + this.presentdate + "/" + this.current_time1,

            },
          ]
        },
        {
          text: ' ',
          style: ''
        },
        {
          fontSize: 9,
          text: 'To',
          style: ''
        }, {
          fontSize: 9,
          text: this.vendorname,
          style: ''
        },
        , {
          fontSize: 9,
          text: this.vendoraddress + "  " + this.vendorcity,
          style: ''
        },
        {
          text: ' ',
          style: ''
        },
        {
          fontSize: 9,
          text: this.venderstate,
          style: ''
        },
        {
          fontSize: 9,
          text: this.vendorcon,
          style: ''
        },
        {
          fontSize: 9,
          text: 'Phone: ' + this.vendor_Mobile1 + "  " + this.vendor_Mobile2,
          style: ''
        }
        ,
        {
          text: ' ',
          style: ''
        },
        {
          table: {
            body: [
              { text: 'Supplier GSTIN No: ' + this.vendor_gst, fontSize: 9 }
            ]
          }, layout: {
            defaultBorder: false,
          }
        },
        {
          text: ' ',
          style: ''
        }, {
          fontSize: 9,
          text: 'Dear Sir/Mam,',
          style: ''
        },
        {
          fontSize: 9,
          text: 'Sub: Supply of items required for our Hospital',
          style: ''
        }, {
          fontSize: 9,
          text: 'We are pleased to place purchse order for supply of the following items',
          style: ''
        },
        //  {
        this.po_productdetailstable(tablebody,
          [
            { text: 'S NO', fontSize: 9 },
            { text: 'Item Name', fontSize: 9 },
            { text: 'HSN CODE', fontSize: 9 },
            { text: 'Qty', fontSize: 9 },
            { text: 'Rate', fontSize: 9 },
            { text: 'Disc', fontSize: 9 },
            { text: 'GST%', fontSize: 9 },

            // { text: 'GST Amt (Rs)\n ', colspan: 3, fontSize: 9 },
            [
              { text: 'GST Amt', fontSize: 9 },
              {
                table: {
                  widths: [20, 20, 20],
                  body: [
                    [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }],
                  ]
                }, layout: {
                  defaultBorder: false,
                }
              },
            ],
            { text: 'Amt', fontSize: 9 }
          ]
          ,
          // [
          // { text: ' ' },
          // { text: ' ' },
          // { text: ' ' },
          // { text: ' ' },
          // { text: ' ' },
          // { text: ' ' },
          // { text: ' ' },
          // {text:" "
          // table: {
          //   widths: [20, 20, 20],
          //   body: [
          //     [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }]
          //   ]
          // }, layout: {
          //   defaultBorder: false,
          // }
          // },
          // { text: ' ' }
          // ]
        ),
        // },
        {
          table: {
            // widths: [45, 45, 45, 45, 50, 45, 45, 60, 90],
            widths: [40, 40, 40, 40, 45, 40, 40, 55, 90],

            body: [
              [{ text: 'CGST%', fontSize: 9 },
              { text: 'CGST', fontSize: 9 },
              { text: 'SGST%', fontSize: 9 },
              { text: 'SGST', fontSize: 9 },
              { text: 'IGST%', fontSize: 9 },
              { text: 'IGST', fontSize: 9 },
              { text: 'Total GST', alignment: "LEFT", fontSize: 9 },
              { text: 'Total AMT', alignment: "right", fontSize: 9 },
              { text: ": " + totalAMT, alignment: "right", fontSize: 8 }
              ],
              [{ text: this.totalcgstpercent, alignment: "right", fontSize: 8 },
              { text: this.totalcgst, alignment: "right", fontSize: 8 },
              { text: this.totalsgstpercent, alignment: "right", fontSize: 8 },
              { text: this.totalsgst, alignment: "right", fontSize: 8 },
              { text: ' ' },
              { text: ' ' },
              { text: totalGST, alignment: "LEFT", fontSize: 8 },
              { text: 'Discount', alignment: "right", fontSize: 9 },
              { text: ' ' }
              ]
            ]
          }
        },

        {
          table: {
            widths: [339, 60, 85],

            body: [

              [
                { text: 'Total Amount in Words: ', fontSize: 9 }, {
                  text: 'Round off', alignment: "right", fontSize: 9
                }, { text: this.roundoff, alignment: "right", fontSize: 8 }
              ],
              [
                { text: this.totalamountinword, aligment: "left", fontSize: 9 }, {
                  text: 'Grand total', alignment: "right", fontSize: 9
                }, { text: this.totalAMT, alignment: "right", fontSize: 8 }
              ]
            ]
          }
        },
        {
          text: ' ',
          style: ''
        },
        , {
          text: 'Please expedite supplies accordingly, and arrange to send the Bill of cost in duplicate for arranging', fontSize: 9,
        }, {
          text: 'Payment of the same on receipt and inspectio of materials at our end.', fontSize: 9,
        }, {
          text: ' ',
          style: ''
        },
        {
          columns: [
            {
              width: '*',
              text: 'Remarks: ' + "", fontSize: 9
            }, {
              width: '*',
              text: 'Prepared By: ' + this.username, fontSize: 9,
            }
          ]
        },
      ]
    };

    this.pdfObj = pdfMake.createPdf(po_data);
    console.log(JSON.stringify(po_data))
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any,) {
        pdffile = reader.result;
        this.pdfdata = this.resultarray.map(item => ({
          supp_id: item.supplier_id,

          purchase_order_id: item.pur_order_id,
          pdfdoc: pdffile,
          ordered_items: this.purchaseOrderArray,
          country: ipaddress.country_code
        }));

        this.save_pdf()
      }.bind(this);
      var a = document.createElement('a');
          document.body.appendChild(a);
          // Set the download attribute and file name
          //  a.href = window.URL.createObjectURL(blob);
          //  a.download = 'purchase_order.pdf';
          //  // Trigger the click event
          //  a.click();
          //  // Clean up
          // window.URL.revokeObjectURL(a.href);
          //  document.body.removeChild(a);
    });
    printContents = this.pdfObj
  }

  po_productdetailstable(data, header1) {
    return {
      table: {
        // widths: [20, 100, 70, 40, 30, 30, 40, 100, 40],
        widths: [20, 90, 65, 35, 25, 25, 35, 100, 35],
        headerRows: 2,

        body: this.buildTableBody(data, header1)
      }
    }
  }

  buildTableBody(data, header1) {

    var body = [];

    // Push header1
    body.push(header1);

    // Push header2
    // body.push(header2);

    // Push data rows
    data.forEach(function (row) {
      var dataRow = [];
      row.forEach(function (cell) {
        dataRow.push(cell);
      });
      body.push(dataRow);
    });


    return body;
  }

  save_pdf() {
    var send_data = {
      po_base: this.poBaseTXT,
      purchase_orders: this.pdfdata
    }
    console.log("pdf_save_send_data = " + JSON.stringify(send_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.httpclt.post(ipaddress.getIp + "inv/upo/", send_data, { headers: headers }).subscribe(
      data => {
        console.log("data savepdf = " + JSON.stringify(data))
        // this.toastr.success("PDF Saved sucessfully")
      },
      error => {

        // this.toastr.success("Unable to save pdf data")
        console.log("error to retrive")
      }

    );
  }

  productpopup(prodname) {
    const dialogRef = this.dialog.open(PurchaseOrderpopupComponent, {
      width: '65%',
      height: '510px',
      data: {
        pro_name: prodname,
        pharmacy_id: this.pharmacyID
      }
    });
    dialogRef.afterClosed().subscribe(data => { });
  }

  deleteprod(data) {
    console.log("delete product json="+JSON.stringify(data))
    for (var j = 0; j < this.prList.length; j++) {
      
      if (data.purchase_request_id == this.prList[j].purchase_request_id) {
        if (Array.isArray(this.prList[j].product_list)) {
          for (var list = 0; list < this.prList[j].product_list.length; list++) {
            if (data.product_id == this.prList[j].product_list[list].product_id) {
              this.prList[j].product_list.splice(list, 1);
              let existingProduct = this.removeddata.find(item => item.product_id === data.product_id);
              if (existingProduct) {
                existingProduct.quantity += data.quantity;
              } else {
                this.removeddata.push({
                  product_id: data.product_id,
                  purchase_request_id:data.purchase_request_id,
                  product_name: data.product_name,
                  quantity: data.quantity
                });
              }
              break;
            }
          }
        }
        break; // Break outer loop after finding the matching purchase request
      }
    }
  }

}
