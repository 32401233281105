<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">
                           {{pagetitle}}
                        </h5>
                    </div>
                    <div class="headerButtons">
                        <!-- <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;"
                            (click)="todisplayassignedproduct()" *ngIf="backbutton"  /> -->
                        <!-- <img src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="saveimgbtn_inpatinfo" (click)="saveSupplerProducts()" /> -->
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div *ngIf="supprod" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="forassignedprod" style="margin-bottom: 10px !important;">
                        <mat-label class="matlabel">Supplier / distributor name<br>
                            <select disableOptionCentering class="ipcss " [(ngModel)]="suppID" required
                                (change)="getProductCount()">
                                <option disabled>Select</option>
                                <option *ngFor="let supplier of supplierList" value={{supplier.supp_id}}>
                                    {{supplier.name}}</option>
                            </select>
                        </mat-label>
                    </div>
                    <div *ngIf="supprod" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel" style="margin-top: 7px;">Product name 
                            <input type="text" class="ipcss " [(ngModel)]="productName" (keyup)="productFilter()" matInput [matAutocomplete]="auto1" />
                            <mat-autocomplete #auto1="matAutocomplete">
                                <mat-option (click)="selectItem(item)" *ngFor="let item of productarray" > {{item.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-label>
                    </div>
                    <div  *ngIf="prostr" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Department<br>
                          <mat-select  class="ipcss"  [(ngModel)]="departname" (selectionChange)="pobase_department($event)">
                            <mat-option *ngFor="let depart of departmentarray" value={{depart.department_id}}>{{depart.description}}</mat-option>
                          </mat-select>
                        </mat-label>
                      </div>
      
                     <div *ngIf="prostr" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Store name<br>
                          <mat-select  class="ipcss "  [(ngModel)]="storename">
                            <!-- <mat-option value="Purchase Request">Purchase request</mat-option>
                            <mat-option value="No Purchase Request">No purchase request</mat-option> -->
                            <mat-option *ngFor="let stro of storearray" placeholder={{stro.store_desc}} value={{stro.store_names_id}}>{{stro.store_desc}}</mat-option>
                          </mat-select>
                        </mat-label>
                      </div>
                </div>
                <div *ngIf="productMasterAddArray.length == 0" class="nodata">No products found</div>

                <div class="row" *ngIf="productMasterAddArray.length != 0">
                    <!-- <div class="col-12">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"> -->
                                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="forassignedprod">
                                    <mat-label class="matlabel">Filter products
                                        <input type="text" class="ipcss " [(ngModel)]="productName" maxlength="50"
                                            (keyup)=product_filter() matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="backbutton">
                                    <mat-label class="matlabel">Filter products
                                        <input type="text" class="ipcss" [(ngModel)]="productNameTxt" maxlength="50"
                                            (keyup)="productFilter()" matInput />
                                    </mat-label>
                                </div> -->
                            <!-- </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="float: right;">
                                    <mat-label class="matlabel">Show<br>
                                        <select disableOptionCentering class="ipcss perpage" [(ngModel)]="pagelist"
                                            (change)="pagelistChange()">
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-12" [hidden]="forassignedprod" style="margin-top:10px;">
                        <div class="table-responsive">
                            <table style="font-size: 13px;" id="product_table"
                                [hidden]="productMasterAddArray.length==0" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm billlisttable">
                                <thead>
                                    <tr [disabled]="headerdisable">
                                        <th>HSN no</th>
                                        <th>Product name</th>
                                        <th>Product type</th>
                                        <th>Supplier</th>
                                        <th>Qty/Unit</th>
                                        <th>MRP</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let productmaster of productMasterAddArray">
                                        <td class="align_left">{{productmaster.hsn_no}}</td>
                                        <td class="align_left">{{productmaster.name}}</td>
                                        <td class="align_left">{{productmaster.product_type_desc}}</td>
                                        <td class="align_left">{{productmaster.supp_name}}</td>
                                        <td>{{productmaster.per_unit}}</td>
                                        <td>{{productmaster.MRP}}</td>
                                        <td style="font-size: 13px;" *ngIf="this.pagetype == 'suppro'">
                                            <mdb-checkbox [default]="true" [(ngModel)]="productmaster.checked"
                                                name="list_name" value="{{productmaster.product_id}}" [ngModel]="true"
                                                (change)="isAllSelected(productmaster,productmaster.hsn_no,productmaster.product_id, productmaster.checked)"></mdb-checkbox>
                                            <!-- <a (click)="productmaster_edit(productmaster.Index)" id="view" style="color: #2c7fe1;"><i class="fas fa-edit"></i></a> -->
                                        </td>
                                        <td style="font-size: 13px;" *ngIf="this.pagetype == 'prodstore'">
                                            <mdb-checkbox  [(ngModel)]="productmaster.checked"
                                                name="list_name" value="{{productmaster.product_id}}"
                                                (change)="isAllSelected(productmaster,productmaster.hsn_no,productmaster.product_id, productmaster.checked)" [checked]="accepted"></mdb-checkbox>
                                            <!-- <a (click)="productmaster_edit(productmaster.Index)" id="view" style="color: #2c7fe1;"><i class="fas fa-edit"></i></a> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="table-responsive" *ngIf="backbutton">
                            <table style="font-size: 13px;" id="product_table" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm billlisttable">
                                <thead>
                                    <tr [disabled]="headerdisable">
                                        <th>HSN no</th>
                                        <th>Product name</th>
                                        <th>Product type</th>
                                        <th>Supplier</th>
                                        <th>schedule type</th>
                                        <th>Qty/Unit</th>
                                        <th>MRP</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let productmaster of productMasterAddArray">
                                        <td class="align_left">{{productmaster.hsn_no}}</td>
                                        <td class="align_left">{{productmaster.name}}</td>
                                        <td class="align_left">{{productmaster.product_type_desc}}</td>
                                        <td class="align_left">{{productmaster.supp_name}}</td>
                                        <td class="align_left">
                                            <span *ngIf="productmaster.schedule_type_desc != undefined ? productmaster.schedule_type_desc : ''">{{productmaster.schedule_type_desc}}</span>
                                        </td>
                                        <td>{{productmaster.per_unit}}</td>
                                        <td>{{productmaster.MRP}}</td>
                                        <td style="font-size: 13px;">
                                            <mdb-checkbox [default]="true" [(ngModel)]="productmaster.checked"
                                                name="list_name" value="{{productmaster.product_id}}"
                                                (change)="isAllSelected(productmaster,productmaster.hsn_no,productmaster.product_id, productmaster.checked)"></mdb-checkbox>

                                            <!-- <a (click)="editProductMaster(productmaster.Index)" id="view"
                                            style="color: #2c7fe1;"><i class="fas fa-edit"></i></a> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- <div [hidden]="pageNavigate || productMasterAddArray.length==0" class="col-12">
                        <div class="row">
                            <div class="col-3 col-lg-4 col-md-3 col-xl-4"></div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images"
                                    (click)="pageLimitPrevious()" src="../../../assets/img/pagnation_previous.png" />
                            </div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <span class="current_font">{{currentPage}}/{{numberOfPages()}}</span>
                            </div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images"
                                    (click)="pageLimitnext()" src="../../../assets/img/pagination_next.png" />
                            </div>
                        </div>
                    </div> -->
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>