import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { MasterHelperService } from '../MasterHelper.service';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { ServerApi } from 'src/app/server-api';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Master_Helper } from '../Master_Helper';
import { ReceptAppCreatePageService } from 'src/app/FrontDesk_module/recept-app-create-page/recept-app-create-page.service';
import moment from 'moment';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  public sliderValue;
  public temp_read;
  public doctorIdvalue;
  public allergy_data;
  currentTime_save;
  public diet_data;
  public Age_data;
  public Gender_data;
  public bmr;
  public allergyFlag;
  public surgicalvalue;
  public preHospFlag;
  public drugdetailsFlag;
  public envDetailsFlag;
  public currentTime24Hr;
  public currentTimeAMPM;
  public allergy;
  public drugDetailsFlag: boolean = false;
  public engDetailsFlag: boolean = false;
  public foodDetailsFlag: boolean = false;
  public ckgflag: boolean = false;
  //  public ckdflag:boolean=false;
  public drug_value;
  public drug_details;
  public env_details;
  public env_value;
  public food_details;
  public food_value;
  public generalexamination;
  spo2colorstyle: boolean;
  pulsecolorstyle: boolean;
  rrcolorstyle: boolean;
  cbgcolorstyle: boolean;
  public dataForApptBook;
  public middle_name;
  public opiniondoctor: boolean;
  public doctorname;
  public bpvalue;
  public rs_text;
  public bloodpressureky;
  public user_type;
  public notificationText;
  tempcolorstyle: boolean;
  public userinfo;
  public visitSession;
  public doctorDetailUrl: string;
  public sendDoctorDetailData;
  public doctorId;
  public splName;
  public splList;
  public splId;
  public doctorListData: any = [];
  public doctorList: any = []
  public appTypeTokenFlag: Boolean;
  public appTypeTimeFlag: Boolean;
  public docid;
  public doctorDetailData: any = [];
  public docUnavailableData: any = [];
  public hospitalListData: any = [];
  public visitingSessionData: any = [];
  public doctorProfileIdoctornamemg: string;
  public tempFee: string;
  public typeorTime: string;
  public hosptlId: string;
  public apptType;
  public hospitalName;
  public hospSltLocation;
  public hospSltFee;
  public hospitalId;
  //
  yoga_send_data = [];
  public phyex_hidden: boolean;
  public yoga_check = [];
  public life_exer;
  public yoga_div: boolean = true;
  public togg_family: string = "no";
  public life_exerdure: string;
  public life_exe_freq: string;
  public exer_run: string;
  public Physcial_exercise: any;
  public smoke_duration: string;
  public smoke_frequency: string;
  public alchol_duration: string;
  public alcohol_frequency: string;
  public gutka_duration: string;
  public gutka_frequency: string;
  public Alc_Quant: string;
  public Alc_Mix: string;
  public Quantity_array = [];
  public exercise_dur: string;
  public Mixing_array = [];
  public exercise_routine_arry = [];

  @Input() pattern: string | RegExp;
  public ynoptapp: boolean;
  public ynoptapp1: boolean;
  public existUserFlag: boolean = true;
  public family_hist_data = [];
  public doc_app_id;
  public hptl_clinic_id;
  public life_style_id;
  public relation_id;
  public apptype;
  public optiontype;
  public apptypedesc;
  public optiontypedesc;
  public toggExerciseFlag: boolean;
  public panelOpenState1: boolean;
  public panelOpenState3: boolean;
  public panelOpenState2: boolean;
  public panelOpenState4: boolean;
  public panelOpenState6: boolean;
  public cvdvalue;
  public panelOpenState5: boolean;
  public presentIllenessList;
  public present_ill;
  public pres_ill;
  public newpresill = [];
  public newmedhist = [];
  public med_hist_text;
  public medicalHistoryList = [];
  public newsymptoms = [];
  public med_hist;
  public exerRoutineList = [];
  public clntZipcode;
  public bloodPressureFlag;
  public diocolorstyle;
  public siscolorstyle;
  public colorstyle;
  public family_other;
  editorStyle = {
    height: '210px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
    ],
  };
  public appdisable;
  public apptTypeFlag;
  public mrno;
  public profileImg;
  public clientname;
  public clntDOB;
  public clntAge;
  public clntGender;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public aadharNumber;
  public whatsappNumber;
  public clntAddress1;
  public clntAddress2;
  public clntLocation;
  public clntCity;
  public clntState;
  public clntCountry;
  public ref_source;
  public referredDoctor;

  public complaints;
  public present_illness;
  public past_illness;
  public surgicaldata;

  public habitualFlag;
  public toggAlchol;
  public alcQuantity;
  public alcMix;
  public alcMissingList = [];
  public alcQuantityList = [];
  public alcholDuration;
  public alcholFrequency;
  public smokeDuration;
  public smokeFrequency;
  public gutkaDuration;
  public gutkaFrequency;
  public toggExercise;
  public exerciseShowFlag;
  public exerciseDuration = [];
  public exerRoutine;

  //family 
  public togg_heart_att1;
  public stroke1;
  public togg_endo1;
  public togg_hyper1;
  public togg_tuber1;
  public togg_hepat1;
  public togg_arter1;
  public togg_auto1;
  public togg_hemo1;
  public togg_hiv1;
  public togg_gla1;

  public togg_diab1;
  public togg_diab: string;
  public togg_storke: string;
  public togg_endo: string;
  public togg_hyper: string;
  public togg_tuber: string;
  public togg_hepat: string;
  public togg_heart_att: string;
  public togg_arter: string;
  public togg_auto: string;
  public togg_hemo: string;
  public togg_hiv: string;
  public togg_gla: string;

  //vitals
  public height;
  public hmeasure;
  public weight;
  public wmeasure;
  public bmi;
  public temperature;
  public BP01;
  public BP02;
  public spo2;
  public pulse;
  public cvs_txt;
  public rstxt;
  public cns_txt;
  public abd_txt;
  public pr_txt;

  //examination
  public local_examination;
  public prov_diag;
  public line_mgmt;
  public chief_comp;
  public symptoms_text;
  public chiefComplaintsList = [];
  public chief_data;
  public chief_margin_left;
  public otherflag;
  //
  public ynopt2: boolean = false;
  public ynopt3: boolean = false;
  public ynopt4: boolean = false;
  public ynopt5: boolean = false;
  public ynopt6: boolean = false;
  public ynopt7: boolean = false;
  public ynopt8: boolean = false;
  public ynopt9: boolean = false;
  public ynopt10: boolean = false;
  public ynopt11: boolean = false;
  public ynopt12: boolean = false;
  public ynopt15: boolean = false;
  public ynopt16: boolean = false;
  public ynopt17: boolean = false;
  public ynopt18: boolean = false;
  public ynopt19: boolean = false;
  public ynopt20: boolean = false;
  public ynopt21: boolean = false;
  public ynopt22: boolean = false;
  // public ynopt6: boolean = true;
  // public ynopt7: boolean = true;

  public diabetes;
  public diabflag;
  // public drugDetailsFlag;
  // public engDetailsFlag;
  // public foodDetailsFlag;
  public diabdetials;
  public hype_details;
  public hypertension;
  public hyperflag;
  public hyperdetails
  public hyper;
  public cadvalue;
  public ckdvalue;
  public cadflag: boolean;
  public ckdflag: boolean;
  public ckddetails;
  public caddetails;
  public tuberflag: boolean;
  public baflag: boolean;
  public ptbflag: boolean;
  public copdflag: boolean;
  public apdflag: boolean;
  public seizureflag: boolean;
  public thyroidflag: boolean;
  public jaundiceflag: boolean;
  public surgicalflag: boolean;
  public tuber;
  public ba;
  public ptb;
  public copd;
  public apd;
  public seizure;
  public thyroid;
  public jaundice;
  public surgical;
  public tuber_details;
  public ba_details;
  public ptb_details;
  public copd_details;
  public apd_details;
  public seizure_details;
  public thyroid_details;
  public jaundice_details;
  public surgical_details;
  public cvd_details;
  public cvdflag;
  public othervalue;
  public Others_details;
  public toggFamilyFlag: boolean;
  public toggleAlcholFlag: boolean;
  public toggleSmokingFlag: boolean;
  public toggGutkaFlag: boolean;
  // public exerciseDuration;
  public currentDateUrl;
  public retrivalurl;

  public asaveurl;
  public medTrackerUrl;
  public currentDate;
  public currentTime;
  public userInfo;
  public client_reg_id;
  public sub_id;
  public patient_name;
  public speclization_arry;
  public painLevels = [];
  public vitals_List = [];
  public patient_age;
  public patient_gender;
  public doctor_name;
  public mrnno;
  public inpatientflag: boolean = true;
  public encheight;
  public encweight;
  public relation_datas = [];
  public relation: any;
  public family_history; public patient_namevalue;
  public Get_theyoga: any = [];
  public doctorUrl;
  public splUrl;
  public apptBookUrl;
  private patientid: string;
  public barcode: string;
  public heartdisease;
  public cbd;
  public prov_diagnosis;
  public cbg_txt;
  public rr_txt;
  appointmentDate: any;
  show_write_rx: boolean;
  public hospital_name;
  public hospitalAddress;
  public hospital_details: any;
  public Hospital_location: string;
  public hosp_tel;
  public hospital_info: any;
  public address;
  public print_template;
  public userinfodata;
  public hospital_logo;
  public doc_reg_id;


  // Family History

  public familyhist;
  public familyHistFlag = "min";
  private diab_case_hist_id;
  public family;
  public client: boolean = false;
  public getHopitalfieldsRet;
  public patient_list;
  public spc;
  public personaldata: any;

  // Family hist new variables

  public togg_CKD_text: string = "No";
  public togg_CKD: boolean;
  public togg_CAD_text: string = "No";
  public togg_CAD: boolean;
  public togg_CVD_text: string = "No";
  public togg_CVD: boolean;
  public togg_BA_text: string = "No";
  public togg_BA: boolean;
  public togg_COPD_text: string = "No";
  public togg_COPD: boolean;
  public togg_APD_text: string = "No";
  public togg_APD: boolean;
  public togg_Seizure_text: string = "No";
  public togg_Seizure: boolean;
  public togg_Thyroid_text: string = "No";
  public togg_Thyroid: boolean;
  public togg_Jaundice_text: string = "No";
  public togg_Jaundice: boolean;
  public togg_Surgical_text: string = "No";
  public togg_Surgical: boolean;
  public togg_Other_text: string = "No";
  public togg_Other: boolean;

  public diab_details: string = "";
  public CKD_details: string = "";
  public CAD_details: string = "";
  public CVD_details: string = "";
  public BA_details: string = "";
  public TB_details: string = "";
  public COPD_details: string = "";
  public APD_details: string = "";
  public Seizure_details: string = "";
  public Thyroid_details: string = "";
  public hyper_details: string = "";
  public Jaundice_details: string = "";
  public Surgical_details: string = "";
  public Other_details: string = "";

  constructor(public dialog: MatDialog, public http: Http, public https: HttpClient, public doctormessageservice: MenuViewService,
    public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: MenuViewService, public cashsheet: CasesheetService,
    public frontservice: MenuViewService, public nurseservice: MenuViewService,
    public masterData: MasterHelperService, private receptAppService: ReceptAppCreatePageService, private cdRef: ChangeDetectorRef, public serviceAPI: ServerApi, public gservice: CommonDataService,) {
    this.togg_diab = "No";
    this.togg_storke = "No";
    this.togg_endo = "No";
    this.togg_hyper = "No";
    this.togg_tuber = "No";
    this.togg_hepat = "No";
    this.togg_heart_att = "No";
    this.togg_arter = "No";
    this.togg_auto = "No";
    this.togg_hemo = "No";
    this.togg_hiv = "No";
    this.togg_gla = "No";
  }

  ngOnInit() {
    this.temp_read = "F"
    this.doctorUrl = ipaddress.getIp + "search/docbyhs";
    this.doctorDetailUrl = ipaddress.getIp + "search/doctorbyid/";
    this.appTypeTokenFlag = true;
    this.splUrl = ipaddress.getIp + "adm/gsplbyhptl";
    this.apptBookUrl = ipaddress.getIp + "appointment/rapp";
    this.alchol_duration = "<1 Year";
    this.alcohol_frequency = "<5";
    this.smoke_duration = "<1 Year";
    this.smoke_frequency = "<5";
    this.gutka_duration = "<1 Year";
    this.gutka_frequency = "<5";
    this.exercise_dur = "15 Mins";
    this.encheight;
    this.encweight;
    this.height = '';
    this.hmeasure = 'cms';
    this.weight = '';
    this.wmeasure = 'kgs';
    this.BP01 = '';
    this.BP02 = '';
    this.spo2 = '';
    this.pulse = '';
    this.bmi = '';
    this.cvs_txt = '';
    this.bmi = '';
    this.address = '';
    this.userinfodata = Helper_Class.getInfo();
    if (this.userinfodata.bill_print_template != undefined) {
      this.print_template = this.userinfodata.bill_print_template;
    } else {
      this.print_template = 'noheader';
    }
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.retrivalurl = ipaddress.getIp + "appointment/gasl";
    this.asaveurl = ipaddress.getIp + "appointment/sinbd";
    //this.userInfo = Helper_Class.getassesment();
    this.userInfo = Doc_Helper.getClient_Info();
    this.life_style_id = Helper_Class.getassesment().life_style_id;
    this.client_reg_id = Helper_Class.getassesment().Client_id;
    this.sub_id = Helper_Class.getassesment().sub_id;
    this.relation_id = Helper_Class.getassesment().rel_id;
    this.doc_app_id = Helper_Class.getassesment().app_id;
    this.barcode = this.userInfo.barcode;
    console.log("userinfo" + JSON.stringify(this.userInfo))
    if (Helper_Class.getassesment().clientaddress2 != undefined) {
      this.clntAddress1 = encrypt_decript.Decript(Helper_Class.getassesment().clientaddress1) + ", " + encrypt_decript.Decript(Helper_Class.getassesment().clientaddress2) + ", " + Helper_Class.getassesment().clientlocation + ", " + Helper_Class.getassesment().clientcity + "-" + encrypt_decript.Decript(Helper_Class.getassesment().clientzipcode) + ", " + Helper_Class.getassesment().clientstate + "-" + Helper_Class.getassesment().clientcountry + '.'
    } else {
      this.clntAddress1 = encrypt_decript.Decript(Helper_Class.getassesment().clientaddress1) + ", " + Helper_Class.getassesment().clientlocation + ", " + Helper_Class.getassesment().clientcity + "-" + encrypt_decript.Decript(Helper_Class.getassesment().clientzipcode) + ", " + Helper_Class.getassesment().clientstate + "-" + Helper_Class.getassesment().clientcountry + '.';
    }

    if (Helper_Class.getassesment().clientaddress2 != undefined && Helper_Class.getassesment().clientaddress1 != undefined) {
      this.address = encrypt_decript.Decript(Helper_Class.getassesment().clientaddress1) + ", " + encrypt_decript.Decript(Helper_Class.getassesment().clientaddress2);
    } else if (Helper_Class.getassesment().clientaddress1 != undefined) {
      this.address = encrypt_decript.Decript(Helper_Class.getassesment().clientaddress1);
    } else {
      this.address = "";
    }

    if (Helper_Class.getassesment().clientlocation != undefined) {
      this.clntLocation = Helper_Class.getassesment().clientlocation + ",";
    }
    //
    if (Helper_Class.getassesment().clientcity != undefined) {
      this.clntCity = Helper_Class.getassesment().clientcity + " - ";
    }
    if (Helper_Class.getassesment().clientstate != undefined) {
      this.clntState = Helper_Class.getassesment().clientstate + " - ";
    }
    if (Helper_Class.getassesment().clientcountry != undefined) {
      this.clntCountry = Helper_Class.getassesment().clientcountry + ".";
    }

    if (encrypt_decript.Decript(Helper_Class.getassesment().clientzipcode) != undefined) {
      this.clntZipcode = encrypt_decript.Decript(Helper_Class.getassesment().clientzipcode) + ",";
    }

    if (Doc_Helper.getAppFlow() != undefined) {
      if (Doc_Helper.getAppFlow() == "Inpatient") {
        this.docid = this.userInfo.doc_reg_id

      } else if (Doc_Helper.getAppFlow() == "Assessment") {
        this.docid = this.userInfo.doc_reg_id
      }

      if (Doc_Helper.getClient_Info().AppFlow == "Inpatient" || Doc_Helper.getClient_Info().AppFlow == "InpatMedPres_dis" || Doc_Helper.getClient_Info().AppFlow == "InpatMedPres") {
        this.docid = this.userInfo.doc_reg_id

      } else if (Doc_Helper.getClient_Info().AppFlow == "InpatMedPres_dis" || Doc_Helper.getClient_Info().AppFlow == "InpatMedPres") {
        this.docid = this.userInfo.doc_reg_id

      } else if (Doc_Helper.getClient_Info().AppFlow == "InpatMedPres") {
        this.docid = this.userInfo.doc_reg_id

      } else if (Doc_Helper.getClient_Info().AppFlow == "Doc_app_list") {
        this.docid = this.userInfo.doc_reg_id

      } else {
        //this.userinfo = Helper_Class.getInfo();
        this.docid = this.userInfo.doc_reg_id;
      }

    } else {
      //this.userinfo = Helper_Class.getInfo();
      this.docid = this.userInfo.user_id;
    }

    this.toggFamilyFlag = false;
    this.toggleAlcholFlag = false;
    this.toggleSmokingFlag = false;
    this.toggGutkaFlag = false;
    this.toggExerciseFlag = false;
    this.alcholDuration = "<1 Year";
    this.alcholFrequency = "<5";
    this.smokeDuration = "<1 Year";
    this.smokeFrequency = "<5";
    this.gutkaDuration = "<1 Year";
    this.gutkaFrequency = "<5";
    this.patientid = Doc_Helper.getClient_Info().patient_id;
    console.log(this.userInfo);

    if (this.userInfo.client_name != "") {
      if (this.userInfo.salutation != undefined) {
        this.patient_name = this.userInfo.salutation + "." + this.userInfo.client_name;
      } else {
        this.patient_name = this.userInfo.client_name;
      }
      this.patient_namevalue = this.userInfo.client_name;
    }

    if (this.userInfo.Age_data != "") {
      this.patient_age = this.userInfo.Age_data;
      this.Age_data = this.userInfo.Age_data;
    }

    if (this.userInfo.Gender_data != "") {
      var gender = this.userInfo.Gender_data;
      this.Gender_data = this.userInfo.Gender_data;
      if (gender == "Male") {
        this.patient_gender = "M"
      } else if (gender == "Female") {
        this.patient_gender = "F"
      } else {
        this.patient_gender = "T"
      }
    }

    if (this.userInfo.doctor_name != "") {
      this.doctor_name = "Dr." + this.userInfo.doctor_name;
    }

    if (this.userInfo.pat_id != "" && this.userInfo.pat_id != undefined) {
      this.mrnno = this.userInfo.pat_id;
    }

    if (this.userInfo.image != undefined) {
      this.profileImg = this.userInfo.image;
    }

    this.painLevels = [
      { number: 0, active: "No pain", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/No_pain.svg" },
      { number: 1, active: "Very mild", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Very_mild.svg" },
      { number: 2, active: "Discomforting", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Discomforting.svg" },
      { number: 3, active: "Tolerable", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Tolerable.svg" },
      { number: 4, active: "Distressing", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Distressing.svg" },
      { number: 5, active: "Very distressing", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Very_distressing.svg" },
      { number: 6, active: "Intense", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Intense.svg" },
      { number: 7, active: "Very_intense", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Very_intense.svg" },
      { number: 8, active: "Horrible", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Horrible.svg" },
      { number: 9, active: "Unbearable", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Unbearable.svg" },
      { number: 10, active: "Unspeakable", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Unspeakable.svg" },
    ];

    if (this.userInfo.role != undefined) {
      if (this.userInfo.role == "Duty Medical Officer (DMO)") {
        this.opiniondoctor = true
      }
    }
    console.log(Helper_Class.getInfo());
    this.hospital_info = Helper_Class.getInfo();
    this.hospital_details = this.hospital_info.hospitals[0];
    this.hospital_name = this.hospital_details.hptl_name;

    var hospital;
    if (this.hospital_details.address2 != undefined) {
      hospital = this.hospital_details.address1 + ',' + this.hospital_details.address2 + ',';
    } else {
      hospital = this.hospital_details.address1 + ',';
    }
    this.hospitalAddress = hospital;
    this.Hospital_location =
      this.hospital_details.location_desc +
      ', ' +
      this.hospital_details.city_desc +
      ' - ' +
      this.hospital_details.zipcode +
      ', ' +
      this.hospital_details.state_desc +
      ' - ' +
      this.hospital_details.country_desc + '.';
    if (this.hospital_info.mobile != undefined) {
      this.hosp_tel = encrypt_decript.Decript(this.hospital_info.mobile);
    } else {
      this.hosp_tel = '-';
    }
    this.getSpecializations();
    this.getMixingtype();
    this.getQuantity();
    this.Get_exercise_routine();
    this.get_duration();
    this.getExerciseRoutine();
    this.get_yoga_type()
    this.getCurrentDate();
    this.changetype('app', 1, 'yes');
    this.changetype('admission', 3, 'yes');
    this.optiontype = "normal";
    this.changeAllery('Diabetes', 1, 'no');
    this.changeAllery('hyper', 1, 'no');
    this.changeAllery('cvd', 1, 'no');
    this.changeAllery('cad', 1, 'no');
    this.changeAllery('tuber', 1, 'no');
    this.changeAllery('ba', 1, 'no');
    this.changeAllery('ptb', 1, 'no');
    this.changeAllery('copd', 1, 'no');
    this.changeAllery('apd', 1, 'no');
    this.changeAllery('seizure', 1, 'no');
    this.changeAllery('thyroid', 1, 'no');
    this.changeAllery('jaundice', 1, 'no');
    this.changeAllery('surgical', 1, 'no');
    this.changeAllery('Others', 1, 'no');
    this.changeAllery('CKD', 1, 'no');
    this.getRelations();

    setTimeout(() => {
      if (this.userInfo.spl != "") {
        this.speclization_arry = this.userInfo.spl;
        this.splName = this.userInfo.spl;
        this.getDoctorList(this.userInfo.spl)
      }



      if (this.userInfo.spl_id != "") {
        this.splId = this.userInfo.spl_id
      }
    }, 100);
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, JSON.stringify({ country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        var currenttime
        this.currentDate = data.json().current_date;
        this.currentTime = data.json().current_time;
        this.currentTime_save = data.json().current_time;
        var currenttime = Master_Helper.getMasterCurrentDate().current_time.split(":");
        if (currenttime[0] >= 3 && currenttime[0] < 12) {
          this.visitSession = "Morning";
        } else if (currenttime[0] >= 12 && currenttime[0] < 16) {
          this.visitSession = "Afternoon";
        } else if (currenttime[0] >= 16 && currenttime[0] < 19) {
          this.visitSession = "Evening";
        } else if (currenttime[0] >= 19 && currenttime[0] < 24) {
          this.visitSession = "Evening";
        }

        // to hide write rx button

        this.appointmentDate = Doc_Helper.getAppointmentDate();
        if (this.appointmentDate == this.currentDate) {
          this.show_write_rx = true;
        } else {
          this.show_write_rx = false;
        }
        // to hide write rx button

        this.getretrival();
        // this.createdDate = data.json().current_date;
      }, error => { });
  }

  getretrival() {

    var sendata;
    var is_bpAbnormal: boolean;
    var is_spo2Abnormal: boolean = false;
    var is_tempAbnormal: boolean = false;
    var is_pulseRateAbnormal: boolean = false;
    var is_respiratoryRateAbnormal: boolean = false;
    var is_cbgAbnormal: boolean = false;

    if (this.sub_id != "") {
      sendata = {
        relation_id: this.relation_id,
        client_reg_id: this.client_reg_id,
        sub_rel_id: this.sub_id,
        country: ipaddress.country_code
      }
    } else {
      sendata = {
        relation_id: this.relation_id,
        client_reg_id: this.client_reg_id,
        country: ipaddress.country_code
        // sub_id:this.sub_id
      }
    }
    console.log("check datat ret" + JSON.stringify(sendata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.retrivalurl, JSON.stringify(sendata), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        console.log("check datat ret" + JSON.stringify(data))
        var obj = JSON.parse(data["_body"]);
        console.log("check datat ret *" + JSON.stringify(obj))

        if (obj != undefined) {
          if (obj.assess_app_type != undefined) {

            if (obj.assess_app_type == "op") {
              this.apptTypeFlag = true;
            } else {
              this.apptTypeFlag = false;
              this.inpatientflag = false;
              if (obj.assess_adm_type == "icu") {
                this.ynoptapp1 = false;
              } else {
                this.ynoptapp1 = true;
              }
            }
          }
          if (obj.family_history != undefined) {
            this.family_hist_data = obj.family_history;
          }
          if (obj.ckd_txt != undefined) {
            this.ckdvalue = obj.ckd_txt;
          }
          if (obj.cardio_txt != undefined) {
            this.heartdisease = obj.cardio_txt;
          }
          if (obj.medical_history != undefined) {
            this.past_illness = obj.medical_history;
          }
          if (obj.present_illness != undefined) {
            this.pres_ill = obj.present_illness;
          }
          if (obj.past_illness != undefined) {
            this.past_illness = obj.past_illness;
          }
          if (obj.local_examination != undefined) {
            this.local_examination = obj.local_examination;
          }
          if (obj.line_magement != undefined) {
            this.line_mgmt = obj.line_magement;
          }
          if (obj.complaints != undefined) {
            this.chief_comp = obj.complaints;
          }
          if (obj.weight != undefined) {
            this.weight = obj.weight;
            this.wmeasure = obj.weight_measure;

          }
          if (obj.height != undefined) {
            this.height = obj.height;
            this.hmeasure = obj.height_measure;

          }
          if (obj.bmi != undefined) {
            this.bmi = obj.bmi
          }
          if (obj.bmr != undefined) {
            this.bmr = obj.bmr
          }
          if (obj.pain_scale != undefined) {
            if (this.painLevels.length != 0) {
              for (var j = 0; j < this.painLevels.length; j++) {
                if (this.painLevels[j].number == obj.pain_scale) {
                  this.painLevels[j].selected = true;
                  this.selectedPainScaleLevel = this.painLevels[j].number;
                }
              }
            }

            // this.selectedPainScaleLevel =obj.pain_scale;

          }
          this.allergyFlag = obj.allergy == 'yes' ? true : false;
          this.allergy = obj.allergy;
          this.ynopt8 = obj.allergy == 'yes' ? true : false;
          if (obj.drug_details != undefined) {
            //this.allergyType('drug', 'yes');
            this.drug_value = 'yes';
            this.drug_details = obj.drug_details;
            this.drugDetailsFlag = true;
            this.ynopt9 = true;
          } else {
            this.drugdetailsFlag = false;
            this.drug_value = 'no';
            this.ynopt9 = false;
          }

          if (obj.env_details != undefined) {
            this.allergyType('evn', 'yes');
            this.env_value = 'yes';
            this.env_details = obj.env_details;
            this.envDetailsFlag = true;
            this.ynopt10 = true;
          } else {
            this.envDetailsFlag = false;
            this.env_value = 'no';
            this.ynopt10 = false;
          }

          if (obj.food_details != undefined) {
            // this.allergyType('food', 'yes');
            this.food_value = 'yes';
            this.food_details = obj.food_details;
            this.foodDetailsFlag = true;
            this.allergyFlag = true;
            this.ynopt11 = true;
          } else {
            this.envDetailsFlag = false;
            this.food_value = 'no';
            this.ynopt11 = false;
            this.allergyFlag = false;
          }
          if (obj.temparature != undefined) {
            var temp = obj.temparature.split(" ")
            this.temperature = temp[0];
            this.temp_read = temp[1];
          }
          if (obj.bp != undefined) {
            var tempvalue = obj.bp.split("/");
            this.BP01 = tempvalue[0];
            this.BP02 = tempvalue[1]
          }
          if (obj.spo2 != undefined) {
            this.spo2 = obj.spo2;
          }
          if (obj.surgical_details != undefined) {
            this.surgicalvalue = obj.surgical_details;
          }

          if (obj.diet_details != undefined) {
            this.diet_data = obj.diet_details;
          }
          if (obj.general_exam != undefined) {
            this.generalexamination = obj.general_exam;
          }
          if (obj.prov_diag != undefined) {
            this.prov_diagnosis = obj.prov_diag;
          }
          if (obj.pulse != undefined) {
            this.pulse = obj.pulse;
          }
          if (obj.cvs != undefined) {
            this.cvs_txt = obj.cvs;
          }
          if (obj.rrtxt != undefined) {
            this.rr_txt = obj.rrtxt;
          }
          if (obj.cns != undefined) {
            this.cns_txt = obj.cns;
          }
          if (obj.abd != undefined) {
            this.abd_txt = obj.abd;
          }
          if (obj.pr != undefined) {
            this.pr_txt = obj.pr;
          }
          if (obj.rstxt != undefined) {
            this.rs_text = obj.rstxt;
          }
          if (obj.cbg != undefined) {
            this.cbg_txt = obj.cbg;
            if (obj.bp != undefined) {
              this.bpvalue = obj.bp + "mmHg";
              this.bloodpressureky = obj.bp + " " + "mmHg";

              var bloodpressuresplit = obj.bp.split("/")
              if (parseInt(bloodpressuresplit[0]) > 160 || parseInt(bloodpressuresplit[0]) < 110 || parseInt(bloodpressuresplit[1]) > 90 || parseInt(bloodpressuresplit[1]) < 70) {
                is_bpAbnormal = true
              } else {
                is_bpAbnormal = false
              }
            }
            else {
              this.bpvalue = " "
              this.bloodpressureky = " "
            }
            //changed
            if (obj.temparature != "null" && obj.temparature != undefined) {
              var temp_split = obj.temparature.split(' ')
              if (parseInt(temp_split[0]) > 100 || parseInt(temp_split[0]) < 96) {
                is_tempAbnormal = true;
              }
            }

            if (obj.spo2 != "null" && obj.spo2 != undefined) {
              if (parseInt(obj.spo2) < 95) {
                is_spo2Abnormal = true;
              }
            }

            if (obj.pr != "null" && obj.pr != undefined) {
              if (parseInt(obj.pr) < 60 || parseInt(obj.pr) > 100) {
                is_pulseRateAbnormal = true;
              }
            }

            if (obj.rrtxt != "null" && obj.rrtxt != undefined) {
              if (parseInt(obj.rrtxt) < 14 || parseInt(obj.rrtxt) > 20) {
                is_respiratoryRateAbnormal = true;
              }
            }

            if (obj.cbg != "null" && obj.cbg != undefined) {
              if (parseInt(obj.cbg) < 90 || parseInt(obj.cbg) > 250) {
                is_cbgAbnormal = true;
              }
            }




            this.vitals_List.push({
              sNo: 0 + 1,
              height: this.height + " " + this.hmeasure,
              weight: this.weight + " " + this.wmeasure,
              bmi: this.bmi,
              bmr: this.bmr,
              temperature: this.temperature + " " + this.temp_read,
              bloodPressure: obj.bp + " " + "mmHg",
              bloodPressurekey: obj.bp + " " + "mmHg",
              SPO2: this.spo2,
              pulse: this.pulse,
              Cvs: this.cvs_txt,
              //Rs: this.rstxt,
              Cns: this.cns_txt,
              ABD: this.abd_txt,
              RR: this.rr_txt,
              PR: this.pr_txt,
              Rs: this.rs_text,
              cbg_txt: obj.cbg,
              //bloodPressurekey:this.bloodpressureky,
              // date: Date_Formate(this.currentDate),
              // time: Time_Formate(this.currentTime),
              date: Date_Formate(obj.created_date),
              time: Time_Formate(obj.created_time),
              is_bpAbnormal: is_bpAbnormal,
              is_tempAbnormal: is_tempAbnormal,
              is_spo2Abnormal: is_spo2Abnormal,
              is_pulseRateAbnormal: is_pulseRateAbnormal,
              is_respiratoryRateAbnormal: is_respiratoryRateAbnormal,
              is_cbgAbnormal: is_cbgAbnormal,
            })
            console.log("check data ret --vitals--" + JSON.stringify(this.vitals_List))
            this.height = "";
            this.weight = "";
            this.bmi = "";
            this.bmr = "";
            this.temperature = "";
            this.BP01 = "";
            this.BP02 = "";
            this.spo2 = "";
            this.pulse = "";
            this.cvs_txt = "";
            this.rstxt = "";
            this.cns_txt = "";
            this.abd_txt = "";
            this.rr_txt = "";
            this.pr_txt = "";
            this.rs_text = "";
            this.cbg_txt = ""
          }
          if (obj.diabetics != undefined) {
            if (obj.diabetics == "yes") {
              this.ynopt2 = true;
              this.diabflag = true;
              this.diabdetials = obj.diabetic_note;
              //
              this.diabetes = obj.diabetics
            } else {
              this.ynopt2 = false;
              this.diabflag = false;
            }
          }
          if (obj.hypertension != undefined) {
            if (obj.hypertension == "yes") {
              this.ynopt3 = true;
              this.hyperflag = true;
              this.hype_details = obj.hypertension_note;
              //
              this.hyper = obj.hypertension
            } else {
              this.ynopt3 = false;
              this.hyperflag = false;
            }
            //  this.changeAllery('Diabetes',1,obj.diabetics)
          }
          if (obj.CAD != undefined) {
            if (obj.CAD == "yes") {
              this.ynopt4 = true;
              this.cadflag = true;
              this.caddetails = obj.cad_note;
              //
              this.cadvalue = obj.CAD
            } else {
              this.ynopt4 = false;
              this.cadflag = false;
            }
          }
          //ckddetails
          if (obj.CKD != undefined) {
            if (obj.CKD == "yes") {
              this.ynopt12 = true;
              this.ckdflag = true;
              this.ckddetails = obj.CKD_note;
              //
              this.ckdvalue = obj.CKD
            } else {
              this.ynopt12 = false;
              this.ckdflag = false;
            }
          }
          if (obj.CVD != undefined) {
            if (obj.CVD == "yes") {
              this.ynopt5 = true;
              this.cvdflag = true;
              this.cvd_details = obj.cvd_note;
              //
              this.cvdvalue = obj.CVD
            } else {
              this.ynopt5 = false;
              this.cvdflag = false;
            }
          }
          if (obj.tuberculosis != undefined) {
            if (obj.tuberculosis == "yes") {
              this.ynopt6 = true;
              this.tuberflag = true;
              this.tuber_details = obj.tb_note;
              //
              this.tuber = obj.tuberculosis
            } else {
              this.ynopt6 = false;
              this.tuberflag = false;
            }
          }
          if (obj.ba_txt != undefined) {
            if (obj.ba_txt == "yes") {
              this.ynopt15 = true;
              this.baflag = true;
              this.ba_details = obj.ba_note;
              this.ba = obj.ba_txt;
            } else {
              this.ynopt15 = false;
              this.baflag = false;
            }
          }
          if (obj.ptb != undefined) {
            if (obj.ptb == "yes") {
              this.ynopt16 = true;
              this.ptbflag = true;
              this.ptb_details = obj.ptb_note;
              this.ptb = obj.ptb;
            } else {
              this.ynopt16 = false;
              this.ptbflag = false;
            }
          }
          if (obj.copd != undefined) {
            if (obj.copd == "yes") {
              this.ynopt17 = true;
              this.copdflag = true;
              this.copd_details = obj.copd_note;
              this.copd = obj.copd;
            } else {
              this.ynopt17 = false;
              this.copdflag = false;
            }
          }
          if (obj.apd != undefined) {
            if (obj.apd == "yes") {
              this.ynopt18 = true;
              this.apdflag = true;
              this.apd_details = obj.apd_note;
              this.apd = obj.apd;
            } else {
              this.ynopt18 = false;
              this.apdflag = false;
            }
          }
          if (obj.seizure != undefined) {
            if (obj.seizure == "yes") {
              this.ynopt19 = true;
              this.seizureflag = true;
              this.seizure_details = obj.seizure_note;
              this.seizure = obj.seizure;
            } else {
              this.ynopt19 = false;
              this.seizureflag = false;
            }
          }
          if (obj.thyroid != undefined) {
            if (obj.thyroid == "yes") {
              this.ynopt20 = true;
              this.thyroidflag = true;
              this.thyroid_details = obj.thyroid_note;
              this.thyroid = obj.thyroid;
            } else {
              this.ynopt20 = false;
              this.thyroidflag = false;
            }
          }
          if (obj.jaundice != undefined) {
            if (obj.jaundice == "yes") {
              this.ynopt21 = true;
              this.jaundiceflag = true;
              this.jaundice_details = obj.jaundice_note;
              this.jaundice = obj.jaundice;
            } else {
              this.ynopt21 = false;
              this.jaundiceflag = false;
            }
          }
          if (obj.surgical != undefined) {
            if (obj.surgical == "yes") {
              this.ynopt22 = true;
              this.surgicalflag = true;
              this.surgical_details = obj.surgical_note;
              this.surgical = obj.surgical;
            } else {
              this.ynopt22 = false;
              this.surgicalflag = false;
            }
          }
          if (obj.others != undefined) {
            if (obj.others == "yes") {
              this.ynopt7 = true;
              this.otherflag = true;
              this.Others_details = obj.other_note;
              //
              this.othervalue = obj.others
            } else {
              this.ynopt7 = false;
              this.otherflag = false;
            }
          }
          if (obj != undefined) {
            //Alchol mixinag
            if (obj.alcohol_mixing_id != null) {
              for (var i = 0; i < this.Mixing_array.length; i++) {
                for (var j = 0; j < obj.alcohol_mixing_id.length; j++) {
                  if (obj.alcohol_mixing_id[j] == this.Mixing_array[i].mixing_id) {
                    this.Alc_Mix = this.Mixing_array[i].description;
                  }
                }
              }
              var yes = true;
              this.panelOpenState1 = true;
            }

            //Alchol quantity
            if (obj.alcohol_qty_id != null) {
              for (var i = 0; i < this.Quantity_array.length; i++) {
                for (var j = 0; j < obj.alcohol_qty_id.length; j++) {
                  if (obj.alcohol_qty_id[j] == this.Quantity_array[i].alcohol_qty_id) {
                    this.Alc_Quant = this.Quantity_array[i].description;
                  }
                }
              }
              yes = true;
              this.panelOpenState1 = true;
            } else {
              yes = false;
            }

            //alchol duration
            if (obj.alc_duration != null) {
              this.alchol_duration = obj.alc_duration;
              this.panelOpenState1 = true;
              yes = true;
            }

            //Alchol frequency
            if (obj.alc_frequency != null) {
              this.alcohol_frequency = obj.alc_frequency;
              this.panelOpenState1 = true;
              yes = true;
            } else {
              yes = false;
            }

            //Smoke
            if (obj.smoke_duration != null) {
              this.smoke_duration = obj.smoke_duration;
              this.panelOpenState2 = true;
            }

            if (obj.smoke_frequency != null) {
              this.smoke_frequency = obj.smoke_frequency;
              this.panelOpenState2 = true;
            }

            //Gutka
            if (obj.gutka_duration != null) {
              this.gutka_duration = obj.gutka_duration;
              this.panelOpenState3 = true;
            }

            if (obj.gutka_frequency != null) {
              this.gutka_frequency = obj.gutka_frequency;
              this.panelOpenState3 = true;
            }

            if (obj.recom_exec_id != null && obj.recom_exec_id != undefined) {
              for (var i = 0; i < this.Physcial_exercise.length; i++) {
                for (var j = 0; j < obj.recom_exec_id.length; j++) {
                  if (obj.recom_exec_id[j] == this.Physcial_exercise[i].exercise_id) {
                    this.life_exer = this.Physcial_exercise[i].description;
                  }
                }
              }
              this.panelOpenState4 = true;
            }

            this.yoga_check = [];
            if (obj.yogasanas != null && obj.yogasanas != undefined) {
              for (var i = 0; i < this.Get_theyoga.length; i++) {
                for (var j = 0; j < obj.yogasanas.length; j++) {
                  if (obj.yogasanas[j].yoga_asana_id == this.Get_theyoga[i].yoga_asana_id) {
                    this.yoga_check.push(this.Get_theyoga[i].yoga_asana_id);
                  }
                }
                this.panelOpenState4 = true;
              }
              this.yoga_div = this.yoga_check.length != 0 ? false : true;
            }
            //Exercise
            if (obj.exercise_dur != null) {
              this.exercise_dur = obj.exercise_dur;
              this.panelOpenState4 = true;
            }

            if (obj.exercise_routine != null) {
              for (var i = 0; i < this.exercise_routine_arry.length; i++) {
                for (var j = 0; j < obj.exercise_routine.length; j++) {
                  if (obj.exercise_routine[j] == this.exercise_routine_arry[i].exer_rout_id) {
                    this.exer_run = this.exercise_routine_arry[i].description;
                  }
                }
              }
              this.panelOpenState4 = true;
            }

            if (obj.recom_exec_dur != null && obj.recom_exec_dur != undefined) {
              this.life_exerdure = obj.recom_exec_dur;
              this.panelOpenState4 = true;
            }

            if (obj.recom_exec_freq != null && obj.recom_exec_freq != undefined) {
              this.life_exe_freq = obj.recom_exec_freq;
              this.panelOpenState4 = true;
            }

            // if (obj.family_support != null) {
            //   this.ynopt1 = true;
            //   this.togg_family ="yes";
            // } else {
            //   this.ynopt1 = false;
            //   this.togg_family ="no";
            // }

            if (obj.life_style_id != null) {
              this.life_style_id = obj.life_style_id;
            }
          }
        }
        if (obj != undefined) {
          if (obj.vital_log != undefined) {

            for (var i = 0; i < obj.vital_log.length; i++) {
              var dateval = obj.vital_log[i].date.split("-");
              if (obj.vital_log[i].weight != "" || obj.vital_log[i].weight != undefined) {
                // if (this.BP_01 != undefined && this.BP_02 != undefined) {
                //   this.bpvalue = this.BP_01 + " / " + this.BP_02
                // }
                // else if (this.BP_01 != undefined) {
                //   this.bpvalue = this.BP_01
                // } else if (this.BP_02 != undefined) {
                //   this.bpvalue = this.BP_02
                // }
                Helper_Class.setvitalloginfo(obj.vital_log);
                console.log("obj.vital_log[i].bp bp" + JSON.stringify(obj.vital_log[i].bp) + typeof (obj.vital_log[i].bp))
                if (obj.vital_log[i].bp != "null" && obj.vital_log[i].bp != undefined) {

                  var bloodpressuresplit = obj.vital_log[i].bp.split("/")
                  // this.systole = bloodpressuresplit[0].trim();
                  // console.log("this.bloodpressuresplit" + JSON.stringify(bloodpressuresplit))
                  var check = bloodpressuresplit[1].split(" ")
                  //  this.diastole = check[1];
                  // console.log("this.bloodpressuresplit" + JSON.stringify(bloodpressuresplit))
                  //  console.log("this.splispacesplispace" + JSON.stringify(this.diastole))

                  this.bloodpressureky = obj.vital_log[i].bp + " " + "mmHg"

                  if (parseInt(bloodpressuresplit[0]) > 160 || parseInt(bloodpressuresplit[0]) < 110 || parseInt(bloodpressuresplit[1]) > 90 || parseInt(bloodpressuresplit[1]) < 70) {
                    is_bpAbnormal = true
                  } else {
                    is_bpAbnormal = false
                  }
                }

                if (obj.vital_log[i].temparature != "null" && obj.vital_log[i].temparature != undefined) {
                  var temp_split = obj.vital_log[i].temparature.split(' ')
                  if (parseInt(temp_split[0]) > 100 || parseInt(temp_split[0]) < 96) {
                    is_tempAbnormal = true;
                  }
                }

                if (obj.vital_log[i].spo2 != "null" && obj.vital_log[i].spo2 != undefined) {
                  if (parseInt(obj.vital_log[i].spo2) < 95) {
                    is_spo2Abnormal = true;
                  }
                }

                if (obj.vital_log[i].pr != "null" && obj.vital_log[i].pr != undefined) {
                  if (parseInt(obj.vital_log[i].pr) < 60 || parseInt(obj.vital_log[i].pr) > 100) {
                    is_pulseRateAbnormal = true;
                  }
                }

                if (obj.vital_log[i].rrtxt != "null" && obj.vital_log[i].rrtxt != undefined) {
                  if (parseInt(obj.vital_log[i].rrtxt) < 14 || parseInt(obj.vital_log[i].rrtxt) > 20) {
                    is_respiratoryRateAbnormal = true;
                  }
                }

                if (obj.vital_log[i].cbg != "null" && obj.vital_log[i].cbg != undefined) {
                  if (parseInt(obj.vital_log[i].cbg) < 90 || parseInt(obj.vital_log[i].cbg) > 250) {
                    is_cbgAbnormal = true;
                  }
                }


                this.vitals_List.push({
                  sNo: i + 1,
                  height: obj.vital_log[i].height + " " + obj.vital_log[i].hmeasure,
                  weight: obj.vital_log[i].weight + " " + obj.vital_log[i].wmeasure,
                  bmi: obj.vital_log[i].bmi,
                  bmr: obj.vital_log[i].bmr,
                  temperature: obj.vital_log[i].temparature,
                  bloodPressure: obj.vital_log[i].bp,
                  bloodPressurekey: this.bloodpressureky,
                  SPO2: obj.vital_log[i].spo2,
                  pulse: obj.vital_log[i].pulse,
                  RR: obj.vital_log[i].rrtxt,
                  Cvs: obj.vital_log[i].cvs,
                  Rs: obj.vital_log[i].rs,
                  Cns: obj.vital_log[i].cns,
                  ABD: obj.vital_log[i].abd,
                  // bpsys: this.systole,
                  // bpdia: this.diastole,
                  PR: obj.vital_log[i].pr_txt,
                  cbg_txt: obj.vital_log[i].cbg,
                  date: dateval[2] + "-" + dateval[1] + "-" + dateval[0],
                  time: Time_Formate(obj.vital_log[i].time),
                  is_bpAbnormal: is_bpAbnormal,
                  is_tempAbnormal: is_tempAbnormal,
                  is_spo2Abnormal: is_spo2Abnormal,
                  is_pulseRateAbnormal: is_pulseRateAbnormal,
                  is_respiratoryRateAbnormal: is_respiratoryRateAbnormal,
                  is_cbgAbnormal: is_cbgAbnormal,
                })
                // console.log("this.bpsysbpsys" + JSON.stringify(this.systole))
                console.log("this.anaest_types" + JSON.stringify(this.vitals_List))

              }
            }
          }
        }

        if (this.vitals_List != undefined && this.vitals_List.length != 0) {
          for (var i = 0; i < this.vitals_List.length; i++) {
            this.vitals_List[i].sNo = i + 1;
          }
        }
        // this.createdDate = data.json().current_date;
      }, error => { });
  }

  onContentChanged_chief_comp = (event) => {
    this.chief_comp = event.html;
    this.symptoms_text = event.text;
    var searchString = "";

    if (event.text.indexOf(',') > -1) {
      var dataval = event.text.split(",");
      searchString = dataval[dataval.length - 1].trim();
      this.chiefComplaintsList = [];
    } else {
      searchString = event.text;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.symptoms != null && obj.symptoms != undefined && obj.symptoms != "" && obj.symptoms.length != 0) {
              this.chief_data = [];
              this.chiefComplaintsList = [];
              // this.chiefComplaintsList = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.chief_data.push(obj.symptoms[i].description);
              }
              this.chiefComplaintsList = this.chief_data;
              if (this.chiefComplaintsList.length != 0) {
                this.chief_margin_left = event.text.length.toString() + "px";

              }
            } else {
              this.chiefComplaintsList = [];
            }
          },
          error => {

          })
    } else {
      this.chiefComplaintsList = [];
    }
  }

  changelinemgmt = (event) => {
    this.pres_ill = event.html;
    if (this.pres_ill.length > 250) {
      this.pres_ill = this.pres_ill.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  changeprov = (event) => {
    this.prov_diagnosis = event.html;
    if (this.prov_diagnosis.length > 250) {
      this.prov_diagnosis = this.prov_diagnosis.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changelinemgmt1 = (event) => {
    this.past_illness = event.html;
    if (this.past_illness.length > 250) {
      this.past_illness = this.past_illness.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changeckd = (event) => {
    this.ckdvalue = event.html;
    if (this.ckdvalue.length > 250) {
      this.ckdvalue = this.ckdvalue.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  changesurgical = (event) => {
    this.surgicalvalue = event.html;
    if (this.surgicalvalue.length > 250) {
      this.surgicalvalue = this.surgicalvalue.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  changeheartdisease = (event) => {
    this.heartdisease = event.html;
    if (this.heartdisease.length > 250) {
      this.heartdisease = this.heartdisease.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changelinemgmt2 = (event) => {
    this.family_history = event.html;
    if (this.family_history.length > 250) {
      this.family_history = this.family_history.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changelinemgmt3 = (event) => {
    this.line_mgmt = event.html;
    if (this.line_mgmt.length > 250) {
      this.line_mgmt = this.line_mgmt.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  updateUserprofile() {

  }

  changeexamination = (event) => {
    this.local_examination = event.html;
    if (this.local_examination.length > 250) {
      this.local_examination = this.local_examination.substring(0, 250);
    }
  };

  changeallergy = (event) => {
    this.allergy_data = event.html;
    if (this.allergy_data.length > 250) {
      this.allergy_data = this.allergy_data.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  changediet = (event) => {
    this.diet_data = event.html;
    if (this.diet_data.length > 250) {
      this.diet_data = this.diet_data.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  //changegen
  changegen = (event) => {
    this.generalexamination = event.html;
    if (this.generalexamination.length > 250) {
      this.generalexamination = this.generalexamination.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  //toggle
  changediab(e) {
    this.togg_diab1 = e;
    this.togg_diab = e == false ? "No" : "Yes";
  }

  changehypertension(e) {
    this.togg_hyper1 = e;
    this.togg_hyper = e == false ? "No" : "Yes";
  }

  changetuber(e) {
    this.togg_tuber1 = e;
    this.togg_tuber = e == false ? "No" : "Yes";
  }

  // new

  changeCKD(e) {
    this.togg_CKD = e;
    this.togg_CKD_text = e == false ? "No" : "Yes";
  }

  changeCAD(e) {
    this.togg_CAD = e;
    this.togg_CAD_text = e == false ? "No" : "Yes";
  }

  changeCVD(e) {
    this.togg_CVD = e;
    this.togg_CVD_text = e == false ? "No" : "Yes";
  }

  changeBA(e) {
    this.togg_BA = e;
    this.togg_BA_text = e == false ? "No" : "Yes";
  }

  changeCOPD(e) {
    this.togg_COPD = e;
    this.togg_COPD_text = e == false ? "No" : "Yes";
  }

  changeAPD(e) {
    this.togg_APD = e;
    this.togg_APD_text = e == false ? "No" : "Yes";
  }

  changeSeizure(e) {
    this.togg_Seizure = e;
    this.togg_Seizure_text = e == false ? "No" : "Yes";
  }

  changeThyroid(e) {
    this.togg_Thyroid = e;
    this.togg_Thyroid_text = e == false ? "No" : "Yes";
  }

  changeJaundice(e) {
    this.togg_Jaundice = e;
    this.togg_Jaundice_text = e == false ? "No" : "Yes";
  }

  changeSurgical(e) {
    this.togg_Surgical = e;
    this.togg_Surgical_text = e == false ? "No" : "Yes";
  }

  changeOther(e) {
    this.togg_Other = e;
    this.togg_Other_text = e == false ? "No" : "Yes";
  }

  // new

  send_data() {

  }

  calculateCalories() {
    if (
      (this.height != undefined && this.height.length != 0) ||
      (this.weight != undefined && this.weight.length != 0) ||
      (this.chief_comp != undefined && this.chief_comp.length != 0) ||

      (this.pres_ill != undefined && this.pres_ill.length != 0) ||
      (this.med_hist != undefined && this.med_hist.length != 0)) {


      if (this.height != undefined && this.height.length != 0) {
        this.encheight = encrypt_decript.Encript(this.height.toString());
      }
      if (this.weight != undefined && this.weight.length != 0) {
        this.encweight = encrypt_decript.Encript(this.weight.toString());
      }
    } else {
      //this.Confirm_btn = true;
    }

    if (
      this.height == undefined ||
      this.height == '' ||
      this.weight == undefined ||
      this.weight == ''
    ) {
      this.bmi = '';
    } else {
      if (this.height != undefined) {
        if (this.hmeasure == 'cms') {
          this.height = this.height.toString().replace(/[^0-9]/g, '');
        } else {
          this.height = this.height
            .toString()
            .replace('/[^-0-9.]/g', '');
          if (this.height.indexOf('.') !== -1) {
            if (this.height.split('.').length > 2) {
              this.height = this.height.height.slice(0, -1);
            }
          }
        }

        if (this.hmeasure == 'cms') {
          if (parseFloat(this.height) > 300) {
            this.height = this.height.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height) > 100) {
            this.height = this.height.slice(0, -1);
          }
        }
      }

      if (this.wmeasure == "kgs" && this.hmeasure == "inch") {
        var pound = this.weight * 2.20462;
        var Height_txt = parseFloat(this.height)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
        if (this.Gender_data == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.Gender_data == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.wmeasure == "kgs" && this.hmeasure == "cms") {
        var meter = parseFloat(this.height) / 100.00;
        var to = parseFloat(this.weight) / (meter * meter);
        this.bmi = Math.round(to);
        if (this.Gender_data == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.Gender_data == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.wmeasure == "lbs" && this.hmeasure == "inch") {
        var Height_txt = parseFloat(this.height);
        var dat = this.weight / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
        if (this.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.wmeasure == "lbs" && this.hmeasure == "cms") {
        var Height_txt = parseFloat(this.height);
        var inch = Height_txt * 0.393701;
        var dat = this.weight / (inch * inch);
        var to = dat * 703;
        this.bmi = Math.round(to);
        if (this.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      }

    }
  }

  validate(event: any) {
    const inputValue = event.target.value;
    const charCode = event.key.charCodeAt(0);

    if (
      (charCode >= 48 && charCode <= 57) || // Digits
      charCode === 46 || // Dot
      event.key === 'Backspace' || // Backspace key
      event.key === 'Delete' // Delete key
    ) {
      // Check if a dot already exists
      const dotIndex = inputValue.indexOf('.');

      if (charCode === 46) {
        if (dotIndex !== -1 || inputValue === '') {
          event.preventDefault(); // Prevent typing an additional dot or starting with a dot
        }
      } else if (dotIndex !== -1) {
        // If there's a dot, allow backspace and delete to remove the digit after the dot
        if (event.key === 'Backspace' || event.key === 'Delete') {
          const decimalPart = inputValue.substr(dotIndex + 2);
          if (decimalPart.length === 1) {
            event.preventDefault();
            event.target.value = inputValue.substr(0, dotIndex); // Remove the digit after the dot
          }
        } else if (inputValue.substr(dotIndex + 2).length >= 1) {
          event.preventDefault(); // Prevent typing more than one digit after the dot
        }
      }
    } else {
      event.preventDefault(); // Prevent typing non-digit and non-dot characters
    }
  }

  tempcolor(value) {
    if (this.temp_read === '°C') {

    } else if (this.temp_read === 'F') {
      // Temperature is in Fahrenheit
      if (value > 100 || value < 96) {
        this.colorstyle = true;
      } else {
        this.colorstyle = false;
      }
    }
  }

  diostol(value) {
    if (value > 90 || value < 70) {
      this.diocolorstyle = true;
    } else {
      this.diocolorstyle = false;
    }
  }

  sistol(value) {
    if (value > 160 || value < 110) {
      this.siscolorstyle = true;
    } else {
      this.siscolorstyle = false;
    }
  }

  addFamilyDetails() {
    if (this.family_other != "") {
      var others = this.family_other;
    }
    var checkRet = [];
    if (this.family_hist_data.length != 0)
      checkRet = this.family_hist_data.filter(o => o.relation_id === this.relation.relation_id);

    if (checkRet.length == 0) {
      var toast_text, flag:boolean = true;
      if(this.togg_diab != "No" && this.diab_details == "") {
        flag = false
        toast_text = "Please enter diabetics details"
      }
      if(this.togg_hyper != "No" && this.hyper_details == "") {
        flag = false
        toast_text = "Please enter hypertension details"
      }
      if(this.togg_tuber != "No" && this.TB_details == "") {
        flag = false
        toast_text = "Please enter tuberculosis details"
      }
      if(this.togg_CKD_text != "No" && this.CKD_details == "") {
        flag = false
        toast_text = "Please enter CKD details"
      }
      if(this.togg_CAD_text != "No" && this.CAD_details == "") {
        flag = false
        toast_text = "Please enter CKD details"
      }
      if(this.togg_CVD_text != "No" && this.CVD_details == "") {
        flag = false
        toast_text = "Please enter CVD details"
      }
      if(this.togg_BA_text != "No" && this.BA_details == "") {
        flag = false
        toast_text = "Please enter BA details"
      }
      if(this.togg_COPD_text != "No" && this.COPD_details == "") {
        flag = false
        toast_text = "Please enter COPD details"
      }
      if(this.togg_APD_text != "No" && this.APD_details == "") {
        flag = false
        toast_text = "Please enter APD details"
      }
      if(this.togg_Seizure_text != "No" && this.Seizure_details == "") {
        flag = false
        toast_text = "Please enter Seizure details"
      }
      if(this.togg_Thyroid_text != "No" && this.Thyroid_details == "") {
        flag = false
        toast_text = "Please enter Seizure details"
      }
      if(this.togg_Jaundice_text != "No" && this.Jaundice_details == "") {
        flag = false
        toast_text = "Please enter jaundice details"
      }
      if(this.togg_Surgical_text != "No" && this.Surgical_details == "") {
        flag = false
        toast_text = "Please enter Surgery details"
      }
      if(this.togg_Other_text != "No" && this.Other_details == "") {
        flag = false
        toast_text = "Please enter Other details"
      }

      if(flag == true) {
        if ((this.togg_diab != "No" && this.diab_details != "") || (this.togg_hyper != "No" && this.hyper_details != "") || (this.togg_tuber != "No" && this.TB_details != "") || (this.togg_CKD_text != "No" && this.CKD_details != "") || (this.togg_CAD_text != "No" && this.CAD_details != "")|| (this.togg_CVD_text != "No" && this.CVD_details != "") || (this.togg_BA_text != "No" && this.BA_details != "") || (this.togg_COPD_text != "No" && this.COPD_details != "") || (this.togg_APD_text != "No" && this.APD_details != "") || (this.togg_Seizure_text != "No" && this.Seizure_details != "") || (this.togg_Thyroid_text != "No" && this.Thyroid_details != "") || (this.togg_Jaundice_text != "No" && this.Jaundice_details != "") || (this.togg_Surgical_text != "No" && this.Surgical_details != "") || (this.togg_Other_text != "No" && this.Other_details != "")) {
          this.family_hist_data.push({
            relation_id: this.relation.relation_id,
            relationship_name: this.relation.relation_name,
  
            diabetics: this.togg_diab,
            CKD: this.togg_CKD_text,
            CAD: this.togg_CAD_text,
            CVD: this.togg_CVD_text,
            ba_txt: this.togg_BA_text,
            tuberculosis: this.togg_tuber,
            copd: this.togg_COPD_text,
            apd: this.togg_APD_text,
            seizure: this.togg_Seizure_text,
            thyroid: this.togg_Thyroid_text,
            hypertension: this.togg_hyper,
            jaundice: this.togg_Jaundice_text,
            surgical: this.togg_Surgical_text,
            others: this.togg_Other_text,
  
            diabetic_note: this.diab_details,
            CKD_note: this.CKD_details,
            cad_note: this.CAD_details,
            cvd_note: this.CVD_details,
            ba_note: this.BA_details,
            tb_note: this.TB_details,
            copd_note: this.COPD_details,
            apd_note: this.APD_details,
            seizure_note: this.Seizure_details,
            thyroid_note: this.Thyroid_details,
            hypertension_note: this.hyper_details,
            jaundice_note: this.Jaundice_details,
            surgical_note: this.Surgical_details,
            other_note: this.Other_details,
          });
          this.clearData();
        } else {
          this.toastr.error("Select and enter diesease details")
        }
      } else {
        this.toastr.error(toast_text, null, {timeOut: 3000});
      }
    } else {
      this.toastr.error(Message_data.alrdyExist);
    }
  }

  clearData() {
    this.togg_diab1 = false;
    this.changediab(false);

    this.togg_hyper1 = false;
    this.changehypertension(false);

    this.togg_tuber1 = false;
    this.changetuber(false);

    this.togg_CKD = false;
    this.changeCKD(false);

    this.togg_CAD = false;
    this.changeCAD(false);

    this.togg_CVD = false;
    this.changeCVD(false);

    this.togg_BA = false;
    this.changeBA(false);

    this.togg_COPD = false;
    this.changeCOPD(false);

    this.togg_APD = false;
    this.changeAPD(false);

    this.togg_Seizure = false;
    this.changeSeizure(false);

    this.togg_Thyroid = false;
    this.changeThyroid(false);

    this.togg_Jaundice = false;
    this.changeJaundice(false);

    this.togg_Surgical = false;
    this.changeSurgical(false);

    this.togg_Other = false;
    this.changeOther(false);

    this.diab_details = ""
    this.CKD_details = ""
    this.CAD_details = ""
    this.CVD_details = ""
    this.BA_details = ""
    this.TB_details = ""
    this.COPD_details = ""
    this.APD_details = ""
    this.Seizure_details = ""
    this.Thyroid_details = ""
    this.hyper_details = ""
    this.Jaundice_details = ""
    this.Surgical_details = ""
    this.Other_details = ""
  }

  deletList(action) {
    var index = this.family_hist_data.findIndex(x => x.relation_id == action.relation_id);
    this.family_hist_data.splice(index, 1);
  }

  editList(action) {
    var index = this.family_hist_data.findIndex(x => x.relation_id == action.relation_id);
    console.log(action)

    this.relation = {
      relation_name: action.relationship_name,
      relation_id: action.relation_id
    }

    if (action.diabetics == "Yes") {
      this.changediab(true);
      this.diab_details = action.diabetic_note;
    } else {
      this.changediab(false);
    }

    if (action.hypertension == "Yes") {
      this.changehypertension(true);
      this.hyper_details = action.hypertension_note;
    } else {
      this.changehypertension(false);
    }

    if (action.CKD == "Yes") {
      this.changeCKD(true);
      this.CKD_details = action.CKD_note;
    } else {
      this.changeCKD(false);
    }

    if (action.CAD == "Yes") {
      this.changeCAD(true);
      this.CAD_details = action.cad_note;
    } else {
      this.changeCAD(false);
    }

    if (action.CVD == "Yes") {
      this.changeCVD(true);
      this.CVD_details = action.cvd_note;
    } else {
      this.changeCVD(false);
    }

    if (action.tuberculosis == "Yes") {
      this.changetuber(true);
      this.TB_details = action.tb_note;
    } else {
      this.changetuber(false);
    }

    if (action.BA == "Yes") {
      this.changeBA(true);
      this.BA_details = action.ba_note;
    } else {
      this.changeBA(false);
    }

    if (action.copd == "Yes") {
      this.changeCOPD(true);
      this.COPD_details = action.copd_note;
    } else {
      this.changeCOPD(false);
    }

    if (action.apd == "Yes") {
      this.changeAPD(true);
      this.APD_details = action.apd_note;
    } else {
      this.changeAPD(false);
    }

    if (action.seizure == "Yes") {
      this.changeSeizure(true);
      this.Seizure_details = action.seizure_note;
    } else {
      this.changeSeizure(false);
    }

    if (action.surgical == "Yes") {
      this.changeSurgical(true);
      this.Surgical_details = action.surgical_note;
    } else {
      this.changeSurgical(false);
    }

    if (action.thyroid == "Yes") {
      this.changeThyroid(true);
      this.Thyroid_details = action.thyroid_note;
    } else {
      this.changeThyroid(false);
    }

    if (action.jaundice == "Yes") {
      this.changeJaundice(true);
      this.Jaundice_details = action.jaundice_note;
    } else {
      this.changeJaundice(false);
    }

    if (action.others == "Yes") {
      this.changeOther(true);
      this.Other_details = action.other_note;
    } else {
      this.changeOther(false);
    }

    this.family_hist_data.splice(index, 1);
  }

  compareById_2(object1: any, object2: any) {
    return object1 && object2 && object1.relation_id === object2.relation_id;
  }

  getExerciseRoutine() {
    this.exerRoutineList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exesrot/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.exerRoutineList = obj.exercise_routine;
          this.exerRoutine = this.exerRoutineList[0].description;
        }, error => { });
  }

  toggleExercise(e) {
    if (e == "Yes") {
      this.exerciseShowFlag = false;
      this.toggExerciseFlag = true
    } else {
      this.exerciseShowFlag = true;
      this.toggExerciseFlag = false;
    }
  }

  select_chiefComplaints(data) {
    this.newsymptoms.push(data);
    if (this.chief_comp.indexOf(",") > -1) {
      var dataval = this.chief_comp.split(",");
      dataval.pop();
      this.chief_comp = dataval.join(",");
      this.chief_comp = this.chief_comp + "," + data + "," + "</p>";
    } else {
      this.chief_comp = data + "," + "</p>";
    }
    this.chiefComplaintsList = [];
  }

  select_presentIlleness(data) {
    this.newpresill.push(data);
    if (this.pres_ill.indexOf(",") > -1) {
      var dataval = this.pres_ill.split(",");
      dataval.pop();
      this.pres_ill = dataval.join(",");
      this.pres_ill = this.pres_ill + "," + data + "," + "</p>";
    } else {
      this.pres_ill = data + "," + "</p>";
    }
    this.presentIllenessList = [];
  }

  select_medicalHistory(data) {
    this.newmedhist.push(data);
    if (this.med_hist.indexOf(",") > -1) {
      var dataval = this.med_hist.split(",");
      dataval.pop();
      this.med_hist = dataval.join(",");
      this.med_hist = this.med_hist + "," + data + "," + "</p>";
    } else {
      this.med_hist = data + "," + "</p>";
      this.med_hist_text = data;
    }
    this.medicalHistoryList = [];
  }

  changeAllery(type, id, value) {
    if (type == 'Diabetes') {
      if (value == 'yes') {
        this.ynopt2 = true;
        this.diabetes = 'yes';
        this.allergyType('Diabetes', 'Yes');
      } else {
        this.ynopt2 = false;
        this.diabetes = 'no';
        this.allergyType('Diabetes', 'No');
      }

    } else if (type == 'hyper') {
      if (value == 'yes') {
        this.ynopt3 = true;
        this.hyper = 'yes';
        this.allergyType('hyper', 'Yes');
      } else {
        this.ynopt3 = false;
        this.hyper = 'no';
        this.allergyType('hyper', 'No');
      }

    } else if (type == 'cad') {
      if (value == 'yes') {
        this.ynopt4 = true;
        this.cadvalue = 'yes';
        this.allergyType('cad', 'Yes');
      } else {
        this.ynopt4 = false;
        this.cadvalue = 'no';
        this.allergyType('cad', 'No');
      }

    } else if (type == 'cvd') {
      if (value == 'yes') {
        this.ynopt5 = true;
        this.cvdvalue = 'yes';
        this.allergyType('cvd', 'Yes');
      } else {
        this.ynopt5 = false;
        this.cvdvalue = 'no';
        this.allergyType('cvd', 'No');
      }
    } else if (type == 'tuber') {
      if (value == 'yes') {
        this.ynopt6 = true;
        this.tuber = 'yes';
        this.allergyType('tuber', 'Yes');
      } else {
        this.ynopt6 = false;
        this.tuber = 'no';
        this.allergyType('tuber', 'No');
      }
    } else if (type == 'ba') {
      if (value == 'yes') {
        this.ynopt15 = true;
        this.ba = 'yes';
        this.allergyType('ba', 'Yes');
      } else {
        this.ynopt15 = false;
        this.ba = 'no';
        this.allergyType('ba', 'No');
      }
    } else if (type == 'ptb') {
      if (value == 'yes') {
        this.ynopt16 = true;
        this.ptb = 'yes';
        this.allergyType('ptb', 'Yes');
      } else {
        this.ynopt16 = false;
        this.ptb = 'no';
        this.allergyType('ptb', 'No');
      }
    } else if (type == 'copd') {
      if (value == 'yes') {
        this.ynopt17 = true;
        this.copd = 'yes';
        this.allergyType('copd', 'Yes');
      } else {
        this.ynopt17 = false;
        this.copd = 'no';
        this.allergyType('copd', 'No');
      }
    } else if (type == 'apd') {
      if (value == 'yes') {
        this.ynopt18 = true;
        this.apd = 'yes';
        this.allergyType('apd', 'Yes');
      } else {
        this.ynopt18 = false;
        this.apd = 'no';
        this.allergyType('apd', 'No');
      }
    } else if (type == 'seizure') {
      if (value == 'yes') {
        this.ynopt19 = true;
        this.seizure = 'yes';
        this.allergyType('seizure', 'Yes');
      } else {
        this.ynopt19 = false;
        this.seizure = 'no';
        this.allergyType('seizure', 'No');
      }
    } else if (type == 'thyroid') {
      if (value == 'yes') {
        this.ynopt20 = true;
        this.thyroid = 'yes';
        this.allergyType('thyroid', 'Yes');
      } else {
        this.ynopt20 = false;
        this.thyroid = 'no';
        this.allergyType('thyroid', 'No');
      }
    } else if (type == 'jaundice') {
      if (value == 'yes') {
        this.ynopt21 = true;
        this.jaundice = 'yes';
        this.allergyType('jaundice', 'Yes');
      } else {
        this.ynopt21 = false;
        this.jaundice = 'no';
        this.allergyType('jaundice', 'No');
      }
    } else if (type == 'surgical') {
      if (value == 'yes') {
        this.ynopt22 = true;
        this.surgical = 'yes';
        this.allergyType('surgical', 'Yes');
      } else {
        this.ynopt22 = false;
        this.surgical = 'no';
        this.allergyType('surgical', 'No');
      }
    } else if (type == 'CKD') {
      if (value == 'yes') {
        this.ynopt12 = true;
        this.ckdvalue = 'yes';
        this.allergyType('CKD', 'Yes');
      } else {
        this.ynopt12 = false;
        this.ckdvalue = 'no';
        this.allergyType('CKD', 'No');
      }

    } else {
      if (value == 'yes') {
        this.ynopt7 = true;
        this.othervalue = 'yes';
        this.allergyType('others', 'Yes');
      } else {
        this.ynopt7 = false;
        this.othervalue = 'no';
        this.allergyType('others', 'No');
      }
    }
  }

  allergyType(type, value) {
    if (type == 'Diabetes') {
      this.diabflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'hyper') {
      this.hyperflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'cad') {
      this.cadflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'cvd') {
      this.cvdflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'tuber') {
      this.tuberflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'evn') {
      this.engDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'CKD') {
      this.ckdflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'ba') {
      this.baflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'ptb') {
      this.ptbflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'copd') {
      this.copdflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'apd') {
      this.apdflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'seizure') {
      this.seizureflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'thyroid') {
      this.thyroidflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'jaundice') {
      this.jaundiceflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'surgical') {
      this.surgicalflag = value.toLowerCase() == 'yes' ? true : false;
    } else {
      this.otherflag = value.toLowerCase() == 'yes' ? true : false;
    }
  }

  changetype(type, check, value) {
    if (type == "app") {
      if (value == "yes") {
        this.ynoptapp = true;
        this.apptype = "op";
        this.apptTypeFlag = true;
        this.apptypedesc =
          // this.appTypeSelect('New');
          this.existUserFlag = true;
        this.inpatientflag = true;
      } else {
        this.ynoptapp = false;
        this.apptTypeFlag = false;
        this.apptype = "ip";
        this.inpatientflag = false;
      }

    } else {
      if (value == "yes") {
        this.ynoptapp1 = true;
        this.optiontype = "normal";
      } else {
        this.ynoptapp1 = false;
        this.optiontype = "icu";
      }
    }
  }

  painScaleLevels = Array.from({ length: 10 }, (_, index) => index + 1);
  public selectedPainScaleLevel;
  handlePainScaleClick1(level: number): void {
    console.log(`Pain scale level ${level} clicked`);
    this.selectedPainScaleLevel = level;
  }

  handlePainScaleClick(number: number) {
    this.painLevels.forEach(level => {
      level.selected = level.number === number;
      this.selectedPainScaleLevel = number;
    });
  }

  getEmojiForLevel(level: number): string {
    if (level === this.selectedPainScaleLevel) {
      switch (level) {
        case 1:
          return '😉';
        case 2:
          return '😉';
        case 3:
          return '😃';
        case 4:
          return '😊';
        case 5:
          return '😱';
        case 6:
          return '😐';
        case 7:
          return '☹️';
        case 8:
          return '😞';
        case 9:
          return '😖';
        case 10:
          return '😭';
        default:
          return '😃'; // You can set a default emoji if needed
      }
    } else {
      return '😃'; // You can set a different emoji for non-selected levels
    }
  }

  preventKeyboardInput(event: KeyboardEvent) {
    event.preventDefault(); // Prevent keyboard input
  }

  writerx() {
    var dataa = {
      AppFlowkey: "Assessment"
    }
    Helper_Class.setassflow(dataa)
    Doc_Helper.setAppFlow('apptList')
    this.messageservice.sendMessage('docpresnocase');
  }

  view_prescription() { }

  back() {
    this.doctormessageservice.sendMessage("appointment_list")
  }
  public provisional_investigation;

  save() {
    var flag = true;
    var alch = "no", smoke = "no", gutka = "no", yoga_txt = [], yoga_flag, exercise = "no", gutka_frequency = undefined, smk_dur = undefined, smk_frq = undefined, alc_dur = undefined,
      alc_frq = undefined, qty_id = undefined, mix_id = undefined, exe_rout_id = undefined,
      exe_dur = undefined, gutka_dur = undefined, fam_supp = "no";

    exe_dur = undefined;
    if (this.panelOpenState1 == true) {
      alch = "yes";
      alc_dur = this.alchol_duration;
      alc_frq = this.alcohol_frequency;

      for (var i = 0; i < this.Quantity_array.length; i++) {
        if (this.Alc_Quant == this.Quantity_array[i].description) {
          qty_id = this.Quantity_array[i].alcohol_qty_id;
        }
      }

      for (var i = 0; i < this.Mixing_array.length; i++) {
        if (this.Alc_Mix == this.Mixing_array[i].description) {
          mix_id = this.Mixing_array[i].mixing_id;
        }
      }
    }
    if (this.panelOpenState3 == true) {
      gutka_dur = this.gutka_duration;
      gutka_frequency = this.gutka_frequency;
      gutka = "yes";
    }

    if (this.panelOpenState2 == true) {
      smk_dur = this.smoke_duration;
      smk_frq = this.smoke_frequency;
      smoke = "yes";
    }

    var exe_dur = undefined;
    if (this.panelOpenState4 == true) {
      exe_dur = this.exercise_dur;
      exercise = "yes"

      for (var i = 0; i < this.exercise_routine_arry.length; i++) {
        if (this.exer_run == this.exercise_routine_arry[i].description) {
          exe_rout_id = this.exercise_routine_arry[i].exer_rout_id;
        }
      }
      if (this.life_exer != undefined) {
        if (this.life_exer == "Yoga") {
          yoga_txt = this.yoga_send_data;
          yoga_flag = "yes";
        } else {
          yoga_flag = "no";
        }
      } else {
        yoga_flag = "";
      }
    }

    if (this.chief_comp == undefined) {
      flag = false;
      this.toastr.error("Enter complaints")
    }
    if (flag == true) {
      var sendata = {
        patient_name: this.patient_namevalue,
        created_time: this.currentTime_save,
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        country: ipaddress.country_code,
        doc_reg_id: this.docid,
        patient_id: this.patientid,
        complaints: this.chief_comp,
        present_illness: this.pres_ill,
        past_illness: this.past_illness,
        local_examination: this.local_examination,
        provisional_investigation: this.provisional_investigation,
        line_magement: this.line_mgmt,
        pain_scale: this.selectedPainScaleLevel,
        diabetics: this.diabetes,
        diabetic_note: this.diabdetials,
        hypertension: this.hyper,
        hypertension_note: this.hype_details,
        CAD: this.cadvalue,
        cad_note: this.caddetails,
        CKD: this.ckdvalue,
        CKD_note: this.ckddetails,
        CVD: this.cvdvalue,
        cvd_note: this.cvd_details,
        tuberculosis: this.tuber,
        tb_note: this.tuber_details,
        ba_txt: this.ba,
        ba_note: this.ba_details,
        ptb: this.ptb,
        ptb_note: this.ptb_details,
        copd: this.copd,
        copd_note: this.copd_details,
        apd: this.apd,
        apd_note: this.apd_details,
        seizure: this.seizure,
        seizure_note: this.seizure_details,
        thyroid: this.thyroid,
        thyroid_note: this.thyroid_details,
        jaundice: this.jaundice,
        jaundice_note: this.jaundice_details,
        surgical: this.surgical,
        surgical_note: this.surgical_details,
        others: this.othervalue,
        other_note: this.Others_details,
        surgical_history: this.surgicaldata,
        doc_appointment_id: this.doc_app_id,
        life_style_id: this.life_style_id,
        family_history: this.family_hist_data,
        gutka: gutka,
        gutka_duration: gutka_dur,
        gutka_frequency: gutka_frequency,
        smoke: smoke,
        smoke_duration: smk_dur,
        smoke_frequency: smk_frq,
        mixing_type: mix_id,
        quantity: qty_id,
        alc_duration: alc_dur,
        alc_frequency: alc_frq,
        alcohol: alch,
        family_support: fam_supp,
        exercise_dur: exe_dur,
        exercise_routine_des: this.life_exer,
        exercise_routine: exe_rout_id,
        yoga_asana_id: yoga_txt,
        created_date: this.currentDate,
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        app_type: this.apptype,
        adm_type: this.optiontype,
        barcode: this.barcode,
        cardio_txt: this.heartdisease,
        ckd_txt: this.ckdvalue,
        allergy: this.allergy,
        drug_details: this.drug_details,
        env_details: this.env_details,
        food_details: this.food_details,
        surgical_details: this.surgicalvalue,
        diet_details: this.diet_data,
        general_exam: this.generalexamination,
        prov_diag: this.prov_diagnosis,
        assigned_to: this.doctorIdvalue,
        assigned_spl: this.splId,
        // family_history: this.family_hist_data,
      }

      console.log("sendata", sendata)

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.asaveurl, JSON.stringify(sendata), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data['_body'])
          console.log("assesment_save = " + JSON.stringify(obj))
          if (obj.key == "1") {
            if (this.userInfo.role != undefined) {
              if (this.userInfo.role == "Duty Medical Officer (DMO)") {
                this.sendNotificationBilling();
                this.sendNotificationfrontdesk();
                if (this.apptype == "ip") {
                  this.sendNotificationnurse();
                }

                this.sendNotificationdoctor();
                if ((this.doctorname != undefined && this.apptype == "op") && this.doctorIdvalue != undefined) {
                  this.appointmentbooking();
                } else {
                  this.toastr.success(obj.result);
                }

              } else {
                if ((this.doctorname != undefined && this.apptype == "op") && this.doctorIdvalue != undefined) {
                  this.appointmentbooking();
                } else {
                  this.toastr.success(obj.result);
                }
              }

              this.doctormessageservice.sendMessage("appointment_list");
            } else {
              Helper_Class.setassesmentData(null);
              Helper_Class.setassesmentData(sendata);
              if (this.doctorname != undefined && this.doctorIdvalue != undefined) {
                this.appointmentbooking();
              } else {
                this.toastr.success(obj.result);
              }
              this.doctormessageservice.sendMessage("appointment_list");
            }

          } else {
            this.toastr.error(obj.result)
          }
        }, error => { });
    }
  }

  getQuantity() {
    this.alcQuantityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/aqty',
      { headers: headers })
      .subscribe(
        response => {
          console.log("quantity" + JSON.stringify(response))
          var obj = JSON.parse(response["_body"]);
          this.alcQuantityList = obj.mixing_quantity;
          this.Quantity_array = obj.mixing_quantity;
          this.alcQuantity = this.alcQuantityList[0].description;
        }, error => { });
  }

  getMixingtype() {
    this.alcMissingList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/amix',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.alcMissingList = obj.mixing_types;
          this.Mixing_array = obj.mixing_types;
          this.alcMix = this.alcMissingList[0].description;
        }, error => { });
  }

  sliderValue1: number = 1.5;

  getSmiley(value: number): string {
    if (value <= 1) {
      return '😊'; // Smiley for low values
    } else if (value <= 3) {
      return '😃'; // Smiley for medium values
    } else {
      return '😄'; // Smiley for high values
    }
  }

  displayValue(value: number): string {
    return value.toString(); // Display the slider value as a string
  }

  public vitals_List1 = [];
  addVitals(data) {
    var vital_flag = false;
    if (this.height == "") {
      vital_flag = true;
      this.toastr.error("Enter Height")
    }

    if (this.weight == "") {
      vital_flag = true;
      this.toastr.error("Enter Weight")
    }

    if (this.client_reg_id == "0") {
      vital_flag = true;
      this.toastr.error("Select patient")
    }

    if (this.vitals_List.length != 0) {
      this.saveVitals(data)

    } else {
      this.saveVitals(data)
    }
  }

  saveVitals(datachk) {
    var vital_flag1 = false;
    var data_nounit, databp;
    var is_bpAbn: boolean = false;
    var is_tempAbn: boolean = false;
    var is_spo2Abn: boolean = false;
    var is_pulseAbn: boolean = false;
    var is_respRateAbn: boolean = false;
    var is_cbgAbn: boolean = false;

    var send_data;
    if (this.BP01 != "" && this.BP02 != "") {
      databp = this.BP01 + " / " + this.BP02 + " " + "mmHg";
      data_nounit = this.BP01 + " / " + this.BP02;
    }
    this.currentTimeAMPM = moment().format("h:mm A");

    // Get the current time in 24-hour format
    this.currentTime24Hr = moment().format("HH:mm");

    send_data = {
      client_reg_id: this.client_reg_id,
      relation_id: "1",
      height: this.height,
      height_measure: this.hmeasure,
      weight: this.weight,
      weight_measure: this.wmeasure,
      bmi: this.bmi,
      bmr: this.bmr,
      temparature: this.temperature,
      pulse: this.pulse,
      bloodPressure: data_nounit,
      SPO2: this.spo2,
      cns_txt: this.cns_txt,
      abd_txt: this.abd_txt,
      pr_txt: this.pr_txt,
      cvs: this.cvs_txt,
      rstxt: this.rr_txt,
      cbd_txt: this.cbg_txt,
      date: (this.currentDate),
      rs_text: this.rs_text,
      time: this.currentTime24Hr,
      blood_pressure: data_nounit,
      spo2: this.spo2,
      country: ipaddress.country_code.toString()
    }

    if (this.pulse != " ") {
      send_data.pulse = this.pulse;
    }
    if (this.temperature != " ") {
      send_data.temparature = this.temperature + " " + this.temp_read;
    }
    // if (this.rr_txt != " ") {
    //   send_data.rstxt = this.rr_txt;
    // }
    if (this.rs_text != " ") {
      send_data.Rs = this.rs_text;
    }
    if (this.cvs_txt != " ") {
      send_data.Cvs = this.cvs_txt;
    }
    if (this.cns_txt != " ") {
      send_data.Cns = this.cns_txt;
    }
    if (this.rr_txt != " ") {
      send_data.rrtxt = this.rr_txt;
    }
    if (this.abd_txt != " ") {
      send_data.ABD = this.abd_txt
    }
    if (this.rs_text != " ") {
      send_data.rstxt = this.rs_text;
    }
    if (this.pr_txt != " ") {
      send_data.PR = this.pr_txt;
    }
    if (this.cbg_txt != " ") {
      send_data.cbg_txt = this.cbg_txt;
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'lifestyle/svt',
      JSON.stringify(send_data), { headers: headers }
    )
      .subscribe((data) => {
        var obj = JSON.parse(data["_body"]);
        this.currentTime = this.currentTimeAMPM;
        if (this.vitals_List.length != 0) {
          if (vital_flag1 == false) {
            var Sno = this.vitals_List.length + 1;
            if (this.BP01 != "" && this.BP02 != "") {
              this.bpvalue = this.BP01 + " / " + this.BP02 + " " + "mmHg"
              this.bloodpressureky = this.BP01 + " / " + this.BP02 + " " + "mmHg"

              if (parseInt(this.BP01) > 160 || parseInt(this.BP01) < 110 || parseInt(this.BP02) > 90 || parseInt(this.BP02) < 70) {
                is_bpAbn = true
              } else {
                is_bpAbn = false
              }

            } else {
              this.bpvalue = " "
              this.bloodpressureky = " "
            }

            if (this.temperature != "" && this.temperature != undefined) {
              if (parseInt(this.temperature) > 100 || parseInt(this.temperature) < 96) {
                is_tempAbn = true;
              }
            }

            if (this.spo2 != "" && this.spo2 != undefined) {
              if (parseInt(this.spo2) < 95) {
                is_spo2Abn = true;
              }
            }

            if (this.pulse != "" && this.pulse != undefined) {
              if (parseInt(this.pulse) < 60 || parseInt(this.pulse) > 100) {
                is_pulseAbn = true;
              }
            }

            if (this.rr_txt != "null" && this.rr_txt != undefined) {
              if (parseInt(this.rr_txt) < 14 || parseInt(this.rr_txt) > 20) {
                is_respRateAbn = true;
              }
            }

            if (this.cbg_txt != "null" && this.cbg_txt != undefined) {
              if (parseInt(this.cbg_txt) < 90 || parseInt(this.cbg_txt) > 250) {
                is_cbgAbn = true;
              }
            }

            this.vitals_List.push({
              sNo: Sno,
              height: this.height + " " + this.hmeasure,
              weight: this.weight + " " + this.wmeasure,
              bmi: this.bmi,
              bmr: this.bmr,
              temperature: this.temperature,
              pulse: this.pulse,
              RR: this.rr_txt,
              bloodPressure: this.BP01 + " / " + this.BP02 + " " + "mmHg",
              SPO2: this.spo2,
              Cns: this.cns_txt,
              ABD: this.abd_txt,
              PR: this.pr_txt,
              Cvs: this.cvs_txt,
              Rs: this.rs_text,
              cbg_txt: this.cbg_txt,
              bloodPressurekey: this.bloodpressureky,
              date: Date_Formate(this.currentDate),
              // time: Time_Formate(this.currentTime),
              time: this.currentTime,
              is_bpAbnormal: is_bpAbn,
              is_tempAbnormal: is_tempAbn,
              is_spo2Abnormal: is_spo2Abn,
              is_pulseRateAbnormal: is_pulseAbn,
              is_respiratoryRateAbnormal: is_respRateAbn,
              is_cbgAbnormal: is_cbgAbn,
            })

            this.height = '';
            this.hmeasure = '';
            this.weight = '';
            this.wmeasure = '';
            this.bmi = '';
            this.bmr = '';
            this.temperature = '';
            this.pulse = '';
            data_nounit = ''
            this.spo2 = '';
            this.cns_txt = '';
            this.abd_txt = '';
            this.pr_txt = '';
            this.cvs_txt = '';
            this.rr_txt = '';
            this.cbg_txt = '';
            this.rs_text = '';
            this.spo2 = '';
          }

        } else {
          if (vital_flag1 == false) {
            if (this.BP01 != "" && this.BP02 != "") {
              this.bpvalue = this.BP01 + " / " + this.BP02 + " " + "mmHg"
              this.bloodpressureky = this.BP01 + " / " + this.BP02 + " " + "mmHg"

              if (parseInt(this.BP01) > 160 || parseInt(this.BP01) < 110 || parseInt(this.BP02) > 90 || parseInt(this.BP02) < 70) {
                is_bpAbn = true
              } else {
                is_bpAbn = false
              }
            }
            else {
              this.bpvalue = " "
              this.bloodpressureky = " "
            }

            if (this.temperature != "" && this.temperature != undefined) {
              if (parseInt(this.temperature) > 100 || parseInt(this.temperature) < 96) {
                is_tempAbn = true;
              }
            }

            if (this.spo2 != "" && this.spo2 != undefined) {
              if (parseInt(this.spo2) < 95) {
                is_spo2Abn = true;
              }
            }

            if (this.pulse != "" && this.pulse != undefined) {
              if (parseInt(this.pulse) < 60 || parseInt(this.pulse) > 100) {
                is_pulseAbn = true;
              }
            }

            if (this.rr_txt != "null" && this.rr_txt != undefined) {
              if (parseInt(this.rr_txt) < 14 || parseInt(this.rr_txt) > 20) {
                is_respRateAbn = true;
              }
            }

            if (this.cbg_txt != "null" && this.cbg_txt != undefined) {
              if (parseInt(this.cbg_txt) < 90 || parseInt(this.cbg_txt) > 250) {
                is_cbgAbn = true;
              }
            }

            var Sno = this.vitals_List.length + 1;
            this.vitals_List.push({
              sNo: Sno,
              height: this.height + " " + this.hmeasure,
              weight: this.weight + " " + this.wmeasure,
              bmi: this.bmi,
              bmr: this.bmr,
              temperature: this.temperature,
              pulse: this.pulse,
              RR: this.rr_txt,
              bloodPressure: this.BP01 + " / " + this.BP02 + " " + "mmHg",
              SPO2: this.spo2,
              Cns: this.cns_txt,
              ABD: this.abd_txt,
              PR: this.pr_txt,
              Cvs: this.cvs_txt,
              Rs: this.rs_text,
              cbg_txt: this.cbg_txt,
              bloodPressurekey: this.bloodpressureky,
              date: Date_Formate(this.currentDate),
              time: this.currentTime,
              is_bpAbnormal: is_bpAbn,
              is_tempAbnormal: is_tempAbn,
              is_spo2Abnormal: is_spo2Abn,
              is_pulseRateAbnormal: is_pulseAbn,
              is_respiratoryRateAbnormal: is_respRateAbn,
              is_cbgAbnormal: is_cbgAbn,
            })

            this.height = '';
            this.hmeasure = '';
            this.weight = '';
            this.wmeasure = '';
            this.bmi = '';
            this.bmr = '';
            this.temperature = '';
            this.pulse = '';
            data_nounit = ''
            this.spo2 = '';
            this.cns_txt = '';
            this.abd_txt = '';
            this.pr_txt = '';
            this.cvs_txt = '';
            this.rr_txt = '';
            this.cbg_txt = '';
            this.rs_text = '';
            this.spo2 = '';
          }
        }
      });
  }

  backbutton() {
    this.doctormessageservice.sendMessage("appointment_list");
  }

  RelationChange() {
    this.clearData();
  }

  getRelations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.get(ipaddress.getIp + "usercontroller/getrelation/", { headers: headers }).subscribe(
      data => {
        this.relation_datas = [];
        for (var b = 0; b < data.json().relation.length; b++) {
          var obj = data.json().relation[b];
          this.relation_datas.push({
            relation_name: obj.relationship_name,
            relation_id: obj.relationship_id
          });
          this.relation = this.relation_datas[0];
        }

      }, error => { });
  }

  compareById(object1: any, object2: any) {
    return object1 && object2 && object1.relation_id === object2.relation_id;
  }

  settempcolor(temprature) {
    if (temprature > 100) {
      this.tempcolorstyle = true;
    } else {
      this.tempcolorstyle = false;
    }
  }

  get_duration() {
    this.Physcial_exercise = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exes/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.exercises.length != 0) {
            for (var i = 0; i < obj.exercises.length; i++) {
              this.Physcial_exercise.push({
                value: obj.exercises[i].exercise_id,
                description: obj.exercises[i].description,
                checked: false
              });
            }
            this.get_yoga_type();
          }

        },
        error => {
        }
      )
  }

  //Get exercise routine
  Get_exercise_routine() {
    this.exercise_routine_arry = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exesrot/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.exercise_routine_arry = obj.exercise_routine;
          this.exerRoutine = this.exercise_routine_arry[0].exer_rout_id;
        },
        error => {
        }
      )
  }

  get_yoga_type() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/ysna',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.Get_theyoga = obj.yogasanas;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  //Get quantity data
  Get_quantity() {
    this.Quantity_array = [];

    var obj = masterCSData_Helper.getMasterQunatity();
    if (obj != undefined) {
      this.Quantity_array = obj.mixing_quantity;
      this.Alc_Quant = this.Quantity_array[0].description;
    }
  }

  //Get mixing type
  Get_Mixingtype() {
    this.Mixing_array = [];

    var obj = masterCSData_Helper.getMasterMixingtype();
    if (obj != undefined) {
      this.Mixing_array = obj.mixing_types;
      this.Alc_Mix = this.Mixing_array[0].description;
    }
  }

  Exercise_change(description) {
    this.yoga_div = description == "Yoga" ? false : true;
  }

  yoga_click(valuecard, event) {
    if (event == true) {
      this.yoga_send_data.push(
        valuecard
      )
    } else {
      for (var i = 0; this.yoga_send_data.length; i++) {
        if (this.yoga_send_data[i] == valuecard) {
          this.yoga_send_data.splice(i, 1);
          break;
        }
      }
    }
  }

  async sendNotificationBilling() {
    var sen_pass;
    if (this.userInfo.role != undefined) {
      if (this.userInfo.role == "Duty Medical Officer (DMO)") {
        // this.type= "dmo",
        // this.is_dmo="1"
      }
    }
    if (this.doctorname != undefined) {
      this.notificationText = this.doctor_name + " referred " + this.patient_name + "to " + this.doctorname;
    } else {
      this.notificationText = this.doctor_name + " completed the consultation of " + this.patient_name + " and need to collect the fee ";
    }

    if (this.userInfo.sub_id != null && this.userInfo.sub_id != undefined && this.userInfo.sub_id != "" && this.userInfo.sub_id.length != 0) {
      sen_pass = {
        client_reg_id: this.userInfo.Client_id,
        relation_id: this.userInfo.rel_id,
        sub_rel_id: this.userInfo.sub_id,
        content: this.notificationText,
        country: "IN",
        type: "Billing"
      }

    } else {
      sen_pass = {
        client_reg_id: this.userInfo.Client_id,
        relation_id: this.userInfo.rel_id,
        content: this.notificationText,
        country: "IN",
        type: "Billing"
      }
    }
    var response = await this.serviceAPI.PostData('gen/sc', sen_pass).toPromise();
    if (response) {
      // console.log("get the food history" + JSON.stringify(response));
      // var obj = JSON.parse(JSON.stringify(response));
      // if (obj.key != 0) {
      //   this.toastr.success("Notification send successfully");
      // } else {
      //   this.toastr.error("Can't send, try again later");
      // }
    }
  }

  async sendNotificationfrontdesk() {
    var sen_pass;
    if (this.userInfo.role != undefined) {
      if (this.userInfo.role == "Duty Medical Officer (DMO)") {
        // this.type= "dmo",
        // this.is_dmo="1"
      }
    }

    this.notificationText = this.doctor_name + " Suggested " + this.patient_name + " as an Inpatient "

    if (this.userInfo.sub_id != null && this.userInfo.sub_id != undefined && this.userInfo.sub_id != "" && this.userInfo.sub_id.length != 0) {
      sen_pass = {
        client_reg_id: this.userInfo.Client_id,
        relation_id: this.userInfo.rel_id,
        sub_rel_id: this.userInfo.sub_id,
        content: this.notificationText,
        country: "IN",
        type: "front-desk"
      }
    } else {
      sen_pass = {
        client_reg_id: this.userInfo.Client_id,
        relation_id: this.userInfo.rel_id,
        content: this.notificationText,
        country: "IN",
        type: "front-desk"
      }
    }
    var response = await this.serviceAPI.PostData('gen/sc', sen_pass).toPromise();
    if (response) {
      // console.log("get the food history" + JSON.stringify(response));
      // var obj = JSON.parse(JSON.stringify(response));
      // if (obj.key != 0) {
      //   this.toastr.success("Notification send successfully");
      // } else {
      //   this.toastr.error("Can't send, try again later");
      // }
    }
  }

  async sendNotificationnurse() {
    var sen_pass;

    this.notificationText = this.doctor_name + " Suggested " + this.patient_name + " as an Inpatient "

    if (this.userInfo.sub_id != null && this.userInfo.sub_id != undefined && this.userInfo.sub_id != "" && this.userInfo.sub_id.length != 0) {
      sen_pass = {
        client_reg_id: this.userInfo.Client_id,
        relation_id: this.userInfo.rel_id,
        sub_rel_id: this.userInfo.sub_id,
        // content : this.notificationText,
        country: "IN",
        type: "nurse"
      }
    } else {
      sen_pass = {
        client_reg_id: this.userInfo.Client_id,
        relation_id: this.userInfo.rel_id,
        //content : this.notificationText,
        country: "IN",
        type: "nurse"
      }
    }
    console.log("get the nurse history" + JSON.stringify(sen_pass));

    var response = await this.serviceAPI.PostData('gen/sc', sen_pass).toPromise();
    if (response) {
      //var obj = JSON.parse(JSON.stringify(response));
      // if (obj.key != 0) {
      //   this.toastr.success("Notification send successfully");
      // } else {
      //   this.toastr.error("Can't send, try again later");
      // }
      // $("#staticBackdrop").modal("hide");
    }
  }

  async sendNotificationdoctor() {
    var sen_pass;
    this.notificationText = this.doctor_name + " referred " + this.patient_name + " for your opinion "


    if (this.userInfo.sub_id != null && this.userInfo.sub_id != undefined && this.userInfo.sub_id != "" && this.userInfo.sub_id.length != 0) {
      sen_pass = {
        doc_reg_id: this.doctorId,
        client_reg_id: this.userInfo.Client_id,
        relation_id: this.userInfo.rel_id,
        sub_rel_id: this.userInfo.sub_id,
        content: this.notificationText,
        country: "IN",
        type: "doctor"
      }
    } else {
      sen_pass = {
        doc_reg_id: this.doctorId,
        client_reg_id: this.userInfo.Client_id,
        relation_id: this.userInfo.rel_id,
        content: this.notificationText,
        country: "IN",
        type: "doctor"
      }
    }
    console.log("get the Doctor notification" + JSON.stringify(sen_pass));

    var response = await this.serviceAPI.PostData('gen/sc', sen_pass).toPromise();
    if (response) {
      console.log("get the food history" + JSON.stringify(response));
      var obj = JSON.parse(JSON.stringify(response));
      // if (obj.key != 0) {
      //   this.toastr.success("Notification send successfully");
      // } else {
      //   this.toastr.error("Can't send, try again later");
      // }
      // $("#staticBackdrop").modal("hide");
    }
  }

  getSpecializations() {
    console.log(",lk/jiyhu" + JSON.stringify(this.userInfo.hptl_clinic_id))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.splUrl, JSON.stringify({ hptl_clinic_id: this.userInfo.hptl_clinic_id }),
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(data["_body"]);
          console.log(",lk/jiyhu" + JSON.stringify(dataval))

          this.splList = [];
          if (dataval.specializations != null) {
            this.splList = dataval.specializations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  getDoctorList(e) {
    this.appTypeTokenFlag = true;
    if (e != null)
      document.getElementById("mandatory_specfication").style.borderColor = "#2ca8fe";

    this.splName = e;
    var splname = this.splName;
    for (var i = 0; i < this.splList.length; i++) {
      if (this.splName == this.splList[i].spl_name)
        this.splId = this.splList[i].spl_id;
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.doctorUrl,
      {
        hptl_clinic_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
        spl_name: splname,
      },
      { headers: headers }).subscribe(
        data => {
          this.doctorListData = [];
          this.doctorList = [];
          var dataobj = JSON.parse(data["_body"]);
          console.log("dataobj = " + JSON.stringify(dataobj))
          this.doctorListData = dataobj.doctors;

          if (this.doctorListData != undefined) {
            for (var i = 0; i < this.doctorListData.length; i++) {
              var docname = "";
              docname = this.doctorListData[i].middle_name != undefined ? this.doctorListData[i].first_name + " " + this.doctorListData[i].middle_name + " " + this.doctorListData[i].last_name : this.doctorListData[i].first_name + " " + this.doctorListData[i].last_name;

              this.doctorList.push({
                docname: docname,
                prov_id: this.doctorListData[i].prov_id,
                tokenFlag: this.doctorListData[i].token_flag,
              });

              this.doctorId = this.doctorList[0].prov_id;
              this.appTypeTokenFlag = true;
            }

            if (this.doctorList != undefined && this.doctorList.length != 0) {
              for (var d = 0; d < this.doctorList.length; d++) {
                if (this.docid == this.doctorList[d].prov_id) {
                  this.doctorname = this.doctorList[d].docname
                }
              }
            }
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  getDoctorDetails(doctorname) {
    var check = this.doctorList.filter(department => department.docname == doctorname)
    if (check.length != 0) {
      this.doctorIdvalue = check[0].prov_id;
    }
  }

  appointmentbooking() {
    this.dataForApptBook = [];
    if ((this.userInfo.clntmiddle_name) != undefined && (this.userInfo.clntmiddle_name) != "") {
      this.middle_name = encrypt_decript.Decript(this.userInfo.clntmiddle_name);
    }
    var Provider = "doctor";
    var address: any = [];
    address.push({
      address1: this.userInfo.clientaddress1,
      address2: this.userInfo.clientaddress2,
      location: this.userInfo.clientlocation_id,
      city: this.userInfo.clientcity_id,
      state: this.userInfo.clientstate_id,
      country: this.userInfo.clientcountry_id,
      zipcode: (this.userInfo.clientzipcode),
    });

    if (this.userInfo.sub_id != null && this.userInfo.sub_id != undefined && this.userInfo.sub_id != "" && this.userInfo.sub_id.length != 0) {
      this.dataForApptBook = {
        profile_image: this.profileImg,
        prov_id: this.doctorIdvalue,
        provider: Provider,
        client_id: this.client_reg_id,
        relation_id: this.relation_id,
        sub_relation_id: this.userInfo.sub_id,
        salutation: this.userInfo.salutation,
        first_name: this.userInfo.clntfirst_name,
        last_name: this.userInfo.clntlast_name,
        middle_name: this.middle_name,
        hptl_id: this.userInfo.hptl_clinic_id,
        gender: this.userInfo.Gender_data,
        age: this.userInfo.Age_data,
        dob: this.userInfo.dob,
        mem_mobile: this.userInfo.mobile,
        symptom: this.complaints,
        address: address,
        medicare_id: "1",
        specialization_id: this.splId,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        date: (this.currentDate),
        ref_by: this.referredDoctor,
        height: this.height,
        height_measure: this.hmeasure,
        weight: this.weight,
        weight_measure: this.wmeasure,
        bmi: this.bmi,
        temparature: this.temperature,
        spo2: this.spo2,
        pulse: this.pulse,
        cvs: this.cvs_txt,
        rstxt: this.rstxt,
        cns_txt: this.cns_txt,
        abd_txt: this.abd_txt,
        pr_txt: this.pr_txt,
        rs_text: this.rs_text, cbd_txt: this.cbd,
        newsymptoms: this.complaints,
        country: ipaddress.country_code,
        token_on_arrival: this.userInfo.token_on_arrival,
        app_type: "1",
        f_time: "1",
        patient_id: this.patientid,
        barcode: this.barcode,
        time: "1",
        token: "true",
        refdoc: "1",
        // rs_text:this.rs_text, cbd_txt:this.cbd,
        assigned_to: this.doctorIdvalue,
        assigned_spl: this.splId,
        cur_app_id: this.doc_app_id,
      };

    } else {
      this.dataForApptBook = {
        profile_image: this.profileImg,
        prov_id: this.doctorIdvalue,
        provider: Provider,
        client_id: this.client_reg_id,
        relation_id: this.relation_id,
        salutation: this.userInfo.salutation,
        first_name: this.userInfo.clntfirst_name,
        last_name: this.userInfo.clntlast_name,
        middle_name: this.middle_name,
        hptl_id: this.userInfo.hptl_clinic_id,
        gender: this.userInfo.Gender_data,
        age: this.userInfo.Age_data,
        dob: this.userInfo.dob,
        mem_mobile: this.userInfo.mobile,
        symptom: this.complaints,
        address: address,
        medicare_id: "1",
        specialization_id: this.splId,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        date: (this.currentDate),
        ref_by: this.referredDoctor,
        height: this.height,
        height_measure: this.hmeasure,
        weight: this.weight,
        weight_measure: this.wmeasure,
        bmi: this.bmi,
        temparature: this.temperature,
        spo2: this.spo2,
        pulse: this.pulse,
        cvs: this.cvs_txt,
        rstxt: this.rstxt,
        cns_txt: this.cns_txt,
        abd_txt: this.abd_txt,
        pr_txt: this.pr_txt,
        newsymptoms: this.complaints,
        country: ipaddress.country_code,
        token_on_arrival: this.userInfo.token_on_arrival,
        app_type: "1",
        f_time: "1",
        patient_id: this.patientid,
        barcode: this.barcode,
        time: "1",
        token: "true",
        refdoc: "1",
        rs_text: this.rs_text, cbd_txt: this.cbd,
        assigned_to: this.doctorIdvalue,
        assigned_spl: this.splId,
        cur_app_id: this.doc_app_id,
      };
    }
    console.log("app booking---" + JSON.stringify(this.dataForApptBook))

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.apptBookUrl, this.dataForApptBook, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(data["_body"]);
        console.log("app booking---" + JSON.stringify(obj))
        if (obj.key == "1") {
          this.toastr.success("Sucessfully assigned the appointment");

        } else {
          this.toastr.error("Unable to assign the appointment, try later");
        }
      },
      error => {
        this.toastr.error(error.json().result);
      })
  }
  spo2value(value) {
    if (value < 90) {
      this.spo2colorstyle = true;
    } else {
      this.spo2colorstyle = false;
    }
  }

  pulsevalue(value) {
    if (value < 60 || value > 100) {
      this.pulsecolorstyle = true;
    } else {
      this.pulsecolorstyle = false;
    }
  }

  rrvalue(value) {
    if (value < 14 || value > 20) {
      this.rrcolorstyle = true;
    } else {
      this.rrcolorstyle = false;
    }
  }
  cbgvalue(value) {
    if (value < 90 || value > 250) {
      this.cbgcolorstyle = true;
    } else {
      this.cbgcolorstyle = false;
    }
  }
  allergyChange1(e) {
    if (this.allergy == 'yes') {
      this.allergyFlag = true;
    } else {
      this.allergyFlag = false;
      this.drugDetailsFlag = false;
      this.engDetailsFlag = false;
      this.foodDetailsFlag = false;
    }
  }
  changeAllery1(type, id, value) {
    if (type == 'main') {
      if (value == 'yes') {
        this.ynopt8 = true;
        this.allergy = 'yes';
        this.allergyChange1('New');
      } else {
        this.ynopt8 = false;
        this.allergy = 'no';
        this.allergyChange1('Existing');
      }
    } else if (type == 'drug') {
      if (value == 'yes') {
        this.ynopt9 = true;
        this.drug_value = 'yes';
        this.allergyType1('drug', 'Yes');
      } else {
        this.ynopt9 = false;
        this.drug_value = 'no';
        this.allergyType1('drug', 'No');
      }
    } else if (type == 'evn') {
      if (value == 'yes') {
        this.ynopt10 = true;
        this.env_value = 'yes';
        this.allergyType1('evn', 'Yes');
      } else {
        this.ynopt10 = false;
        this.env_value = 'no';
        this.allergyType1('evn', 'No');
      }
    } else {
      if (value == 'yes') {
        this.ynopt11 = true;
        this.food_value = 'yes';
        this.allergyType1('food', 'Yes');
      } else {
        this.ynopt11 = false;
        this.food_value = 'no';
        this.allergyType1('food', 'No');
      }
    }
  }
  allergyChange(e) {
    if (this.allergy == 'yes') {
      this.allergyFlag = true;
    } else {
      this.allergyFlag = false;
      this.drugDetailsFlag = false;
      this.engDetailsFlag = false;
      this.foodDetailsFlag = false;
      this.ckgflag = false;
    }
  }

  allergyType1(type, value) {
    if (type == 'drug') {
      this.drugDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'evn') {
      this.engDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    } else {
      this.foodDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    }
  }

  chnagetemp(e) {
    this.temp_read = e;
  }

  print() {
    let printContents, popupWin, margin_top;
    if (this.print_template != undefined && this.print_template == 'banner') {
      printContents = this.printbanner.nativeElement.innerHTML;
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          }
          * {
            font-size: 12px !important;
            line-height: 1 !important;
            overflow: visible;
          }
          @media print {
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }
          }
          .address_visibility {
            visibility: hidden;
          }
          table.report-container {
            /* Removed page-break-after property */
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            /* Removed page-break-after property */
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 { width: 8%;  float: left; }
          .col-print-2 { width: 16%; float: left; }
          .col-print-3 { width: 25%; float: left; }
          .col-print-4 { width: 33%; float: left; }
          .col-print-5 { width: 42%; float: left; }
          .col-print-6 { width: 50%; float: left; }
          .col-print-7 { width: 58%; float: left; }
          .col-print-8 { width: 66%; float: left; }
          .col-print-9 { width: 75%; float: left; }
          .col-print-10 { width: 83%; float: left; }
          .col-print-11 { width: 92%; float: left; }
          .col-print-12 { width: 100%; float: left; }
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }
}