<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Excel Upload</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="back_Nav()"
              src="../../../assets/ui_icons/buttons/Back_button.svg" />
            <img *ngIf="inventory.length!=0" src="../../../assets/ui_icons/buttons/update_button.svg"
              (click)="saveData()" class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Pharmacy Location<br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="pharmacyLocation">
                <option disabled>Select</option>
                <option *ngFor="let loc of locationArray" value={{loc.pharma_facility_id}}>{{loc.location}}
                </option>
              </select>
            </mat-label>
          </div> -->

          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Purchase Order <br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="poNO" (change)="getManufacturer()">
                <option disabled>Select</option>
                <option *ngFor="let po of productArray" value={{po.po_id}}>{{po.po_id}}</option>
              </select>
            </mat-label>
          </div> -->

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" [hidden]="supplierflag">Supplier / distributor name<br>
              <input type="text" class="ipcss widthappt" required [(ngModel)]="manufacturer" matInput list="dropValues"
                (change)="supplier_changee()" (click)="clearSupplier()">
              <datalist [(ngModel)]="manufacturer" id="dropValues" clickable>
                <option type="button" *ngFor="let supplier of supplierList" value={{supplier.name}}>
                  {{supplier.name}}
                </option>
              </datalist>
            </mat-label>

            <mat-label [hidden]="inputsupplierflag" class="matlabel">Supplier / distributor name<br>
              <input type="text" class="ipcss widthappt" [(ngModel)]="manufacturer" disabled>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel" style="margin-top: 7px;">Purchase Order<br>
              <mat-select disableOptionCentering class="ipcss " [(ngModel)]="poNO"
                (change)="getPurchaseDetails();getsupplierid()">
                <mat-option disabled>Select</mat-option>
                Select all
                <input type="checkbox" [(ngModel)]="filtercheckbox" [checked]="multicheck"
                  style="width:14px;height:14px;margin-left:150px" (change)="checkboxfunction()">

                <mat-option *ngFor="let po of productArray" value={{po.purchase_order_id}}>
                  <!-- [(ngModel)]="po.checked" -->
                  <input type="checkbox" [(ngModel)]="po.checked" name="list_name" value="{{po.purchase_order_id}}"
                    (click)="isAllSelectedPO(po,po.checked)">
                  {{po.po_id}} </mat-option>
              </mat-select>
            </mat-label>
          </div>
          
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Invoice No<br>
              <input type="text" class="ipcss widthappt" [(ngModel)]="invoiceNO" required maxlength="25" matInput />
            </mat-label>
          </div>



          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel" style="margin-top: 7px;">Invoice date<br>
                <input type="date" class="ipcss " [(ngModel)]="invDate" max="{{currentDate}}" matInput />
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Columns<br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="pharColumn"

                (change)="columnOrder(pharColumn,'')">
                <option *ngFor="let column of inventoryColumnArray" [ngValue]="column">
                  {{column}}</option>
              </select>
            </mat-label>

          </div> -->

         

          <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-8">
            <mat-label class="matlabel">Column order<br>
              <input type="text" class="ipcss widthappt" [(ngModel)]="invoice_colm" (focusout)="onInputFocusOut()" />
            </mat-label>
          </div> -->

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <label class="matlabel" for="file">Select excel file</label>
            <input type="file" (change)="readExcel($event)" name="filUpload" #excelreader id="file" class="ipcss" />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel" style="margin-top: 7px;">Invoice amount<br>
                <input type="text" class="ipcss " [(ngModel)]="actual_invoiceAmount"  matInput />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
            <div class="row">
              <div class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                <mat-label class="matlabel" style="margin-top: 7px"> Discount type <br>
                  <mat-select class="ipcss" [(ngModel)]="discount_option">
                    <mat-option value="select" (click)="updateTotalCost()">Select</mat-option>
                    <mat-option value="product_wise" (click)="isDiscountOptionSelected(discount_option)">Product
                      wise</mat-option>
                    <mat-option value="percentage"
                      (click)="isDiscountOptionSelected(discount_option)">Percentage</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5" *ngIf="discountpercentageflag">
                <mat-label class="matlabel"> Percentage <br>
                  <input type="text" class="ipcss " (keyup)="discountcal()" [(ngModel)]="Discount_value" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5" *ngIf="this.discountpriceflag">
                <mat-label class="matlabel"> Discount value <br>
                  <input class="ipcss widthappt" [(ngModel)]="discountamount" (keyup)="discountcal()">
                </mat-label>
              </div>
            </div>
          </div>

        </div>
        <div class="row">
          <div [hidden]="data_flag">
            <img src="../../../assets/img/captcha_refresh.png" (click)="clear_data()" class="refresh_btn"
              alt="clear data">
          </div>
        </div>
        <div class="row">
          <table class="table table-hover table-dynamic" style="font-size: 11px; ">
            <thead>
              <tr>
                <th *ngFor="let newcolumn of realignColumnsArray">{{newcolumn}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of columnArray">
                <td>{{data[0]}}</td>
                <td>{{data[1]}}</td>
                <td *ngIf="data.length >2">{{data[2]}}</td>
                <td *ngIf="data.length >3">{{data[3]}}</td>
                <td *ngIf="data.length >4">{{data[4]}}</td>
                <td *ngIf="data.length >5">{{data[5]}}</td>
                <td *ngIf="data.length >6">{{data[6]}}</td>
                <td *ngIf="data.length >7">{{data[7]}}</td>
                <td *ngIf="data.length >8">{{data[8]}}</td>
                <td *ngIf="data.length >9">{{data[9]}}</td>
                <td *ngIf="data.length >10">{{data[10]}}</td>
                <td *ngIf="data.length >11">{{data[11]}}</td>
                <td *ngIf="data.length >12">{{data[12]}}</td>
                <td *ngIf="data.length >13">{{data[13]}}</td>
                <td *ngIf="data.length >14">{{data[14]}}</td>
                <td *ngIf="data.length >15">{{data[15]}}</td>
                <td *ngIf="data.length >16">{{data[16]}}</td>
                <td *ngIf="data.length >17">{{data[17]}}</td>
              </tr>
            </tbody>
          </table>

          <div class="col-0 col-sm-9 col-md-5 col-lg-12 col-xl-12" [hidden]="columnArray.length == 0">
            <div class="row" style="margin: 10px 0px;">
              <div class="col-6">
                <table>
                  <tr>
                    <td><strong style="font-weight: 500;">Total Price (In-Words)</strong></td>
                    <td>{{constpriceinwords}}</td>
                  </tr>
                </table>
              </div>
              <div class="col-6">
                <table style="margin-left:auto">
                  <tr>
                    <td style="width:200px !important"><strong style="font-weight: 500;">Total Price</strong></td>
                    <td class="align_right">{{tcprice}}</td>
                  </tr>

                  <tr>
                    <td style="width:200px !important"><strong style="font-weight: 500;">Discount</strong></td>
                    <td class="align_right">{{disamt}}</td>
                  </tr>

                  <tr>
                    <td style="width:200px !important"><strong style="font-weight: 500;">GST</strong></td>
                    <td class="align_right">{{GSTamount}}</td>
                  </tr>

                  <tr>
                    <td style="width:200px !important"><strong style="font-weight: 500;">Invoice amount</strong></td>
                    <td class="align_right">{{invoiceAmount}}</td>
                  </tr>

                  <tr>
                    <td style="width:200px !important"><strong style="font-weight: 500;">Returns</strong></td>
                    <td class="align_right">{{returnAmount}}</td>
                  </tr>

                  <tr>
                    <td style="width:200px !important"><strong style="font-weight: 500;">Balance amount</strong></td>
                    <td class="align_right">{{balance}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>