import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Time_Formate, convertNumberToWords } from 'src/assets/js/common';
import { Diagnosis_Helper } from '../../Diagnosis_module/Diagnosis_Helper';
declare var $: any;

@Component({
  selector: 'app-recept-bill-create',
  templateUrl: './recept-bill-create.component.html',
  styleUrls: ['./recept-bill-create.component.css'],
})
export class ReceptBillCreateComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('focusMRNo') focusMRNo: ElementRef;
  @ViewChild('focusMobileNo') focusMobileNo: ElementRef;
  @ViewChild('focusFName') focusFName: ElementRef;
  public showhideflag: boolean = true;
  public appointmentId;
  public temp_key;
  public sendData;
  public billDate;
  public chargeType: string;
  public chargeId: string;
  public amount;
  public finalAmount;
  public inGST: boolean;
  public ipaddress;
  public concession;
  public sendConcession;
  public session: string;
  public amountAftCon;
  public gstAmount;
  public advanceBill;
  public currentDate;
  public appointDate;
  public billingList = [];
  public doctorName;
  public hospitalName;
  public middleName: string;
  public billTreatmentPlan = [];
  public gstRetriveList = [];
  public billAddList: any = [];
  public doctorList = [];
  public gstDataList = [];
  public payTypeList = [];
  public insurerList = [];
  public chargeName;
  public estimateId = [];
  public remainingBalance: any = 0;
  public hospital_remainingBalance;
  public diag_remainingBalance;
  public package_remainingBalance;
  public sendAdvanceAmount;
  public sendTotalCollected;
  public cgst;
  public sgst;
  public clientRegId;
  public relationId;
  public subRelId;
  public hptlClinicId: string;
  public docRegId: string;
  public payType: string;
  public insurerId: string = '';
  public insurFlag: boolean;
  public doctorFullName: string;
  public userInfo;
  dtOptions: DataTables.Settings = {};
  public patientName: string;
  public prevBillId;
  public showBalanceFlag: boolean;
  public amountCollected: boolean;
  public showPrevBalanceFlag: boolean;
  public prevBalance;
  public totalCollected;
  public transId;
  public discount: any = 0;
  public paidBill: any = 0;
  public hospital_paidBill: any = 0;
  public diag_paidBill: any = 0;
  public package_paidBill: any = 0;
  public sign;
  public tempRoundoff = 0;
  public transactionFlag: boolean;
  public transactionId;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public bankName;
  public serviceType = [];
  public quantity: any = 1;
  public saveFlag: boolean = true;
  public getBils;
  public bill_qty_flag: boolean = false;
  public bill_cons_flag: boolean = false;
  public paytypreq: boolean = false;
  public payType1;
  public amountval: any;
  public paymentsarray: any = [];
  public tamt: number = 0;
  private sno: number = 1;
  public cardreqflag: boolean = false;
  public transreqflag: boolean = false;
  public chequereqflag: boolean = false;
  public insreqflag: boolean = false;
  public rate_card;
  public RateCardArray = [];
  public hosp_credits: any = [];
  public credit_flag: boolean = true;
  public credit_bill;
  public credit_type = '';
  public filter: string;
  public firstNameFlag: boolean;
  public fromFlag: boolean;
  public toFlag: boolean;
  public uhidFlag: boolean;
  public mobileFlag: boolean;
  public doctorFlag: boolean;
  public fromDate;
  public fdateFlag: boolean;
  public tdateFlag: boolean;
  public toDate;
  public firstName: string;
  public lastName: string;
  public patientId: string;
  public mobileNo: string;
  public billList = [];
  public ynopt1: boolean = true;
  public billingType;
  public centerId: string;
  public bulkAddFlag: boolean;
  public createpage_flag: boolean = false;
  public mrno;
  public patient_name;
  public clnt_age;
  public gender;
  public mobile_no;
  public hospital_logo;
  public hospital_name;
  public hospitalAddress;
  public Hospital_location;
  public hospitals;
  public telephone;
  public credit_person;
  public billing_print_array = [];
  public inpatientEdit: boolean = false;
  public Moduleidlist = [];
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public printlogostyle;
  public bill_head;
  public numToWords: string;
  public billId;
  public hospital_billId = '';
  public diag_billId = '';
  public package_billId = '';
  public print_size: any = null;
  public timevalue;
  public cashDenomination: { value: number; count: number }[] = [
    { value: 2000, count: 0 },
    { value: 500, count: 0 },
    { value: 200, count: 0 },
    { value: 100, count: 0 },
    { value: 50, count: 0 },
    { value: 20, count: 0 },
    { value: 10, count: 0 },
    { value: 5, count: 0 },
    { value: 2, count: 0 },
    { value: 1, count: 0 },
  ];
  public showModal: boolean = false;
  public totalAmount: number;
  public hospitalLogo: any;
  public currentTime: any;
  public chargeTypeFilterArray: any[];
  public filteredChargeTypeArray: any[];
  public mr_no_flag: boolean;
  public is_new: any;
  public address: any;
  public address1: any;
  public address2: any;
  public location: any;
  public city: any;
  public state: any;
  public dr_name: any;
  public spl_name: any;
  public print_template: any;
  public profile_image: string;
  public em_contact: any;
  public discount_amount;
  public totalBillAmount: any;
  public tot_pay_array_amount: any;
  public total: string;
  public package_id: any;
  public packageFlag: boolean = false;
  public packages = [];
  public diag_app_id: any;
  public diag_apps: any;
  public testArray = [];
  public addBillArray = [];
  public addPackageBillArray = [];
  public doc_apps;
  public doc_apps_flag = false;
  public diagFinalAmount;
  public totalBillList: any = [];
  public hospital_finalAmount;
  public diag_finalAmount;
  public package_finalAmount;
  public hospital_totalBillAmount;
  public diag_totalBillAmount;
  public package_totalBillAmount;
  public hospital_discount_amount;
  public diag_discount_amount;
  public package_discount_amount;
  public hospital_gstAmount;
  public diag_gstAmount;
  public package_gstAmount;
  public hospital_totalCollected;
  public diag_totalCollected;
  public package_totalCollected;
  public hospital_gstDataList: any[];
  public diag_gstDataList: any[];
  public package_gstDataList: any[];
  public doc_bills_array: any[];
  public diag_bills_array: any[];
  public package_bills_array: any[];
  public diagCenterID: any;
  public pres_diag_id: any;
  public hospital_paid_percentage = [];
  public diag_paid_percentage = [];
  public hospital_paymentsarray: any = [];
  public diag_paymentsarray = [];
  public package_paid_percentage = [];
  public package_paymentsarray = [];
  public diag_pay_arrry_tot_amt;
  public diagEqualSplitValue;
  public package_pay_arrry_tot_amt;
  public packageEqualSplitValue;
  public hospital_pay_arrry_tot_amt;
  public hospitalEqualSplitValue;
  public package_array: any = [];
  public logUserName;
  public discount_flag: boolean = false;
  public percentage;
  public open_app_billing;
  public barcode_flag: boolean = false;
  public barcode_url;
  public barcode_style: string;
  public nobanner_style;
  public amount_td_style;
  public heading_name: string;
  public paymentsarray_total;
  public p_tag_style: any;
  public package_pay_arrry_tot_amt1: any;
  public rep_admin: any;
  public doctor_flag: boolean = false;
  public packageEqualSplitValue1: any;
  public prev_charges;
  displayValue = true;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 1.0;
  barcodeheight = 23;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';
  public consult_fee_accept:string;

  constructor(public toastr: ToastrService, public http: Http,
    public service: MenuViewService, public cdr: ChangeDetectorRef) {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.firstNameFlag = true;
    this.fromFlag = true;
    this.toFlag = true;
    this.uhidFlag = false;
    this.mobileFlag = true;
    this.doctorFlag = true;
    this.mr_no_flag = false;
    this.bulkAddFlag = true;
    this.discount_flag = false;
  }

  ngOnInit(): void {
    this.saveFlag = true;
    this.profile_image = '../../assets/img/default.jpg';
    $(document).ready(function () {
      $('input').attr('autocomplete', 'off');
    });

    this.payType = 'Cash';
    this.userInfo = Helper_Class.getInfo();
    console.log(this.userInfo);
    if (this.userInfo.first_name != undefined) {
      this.logUserName = this.userInfo.first_name;
      if (this.userInfo.last_name != undefined) {
        this.logUserName = this.userInfo.first_name + " " + this.userInfo.last_name;
      }
    }

    if (this.userInfo.open_app_billing != null) {
      this.open_app_billing = this.userInfo.open_app_billing;
    }

    if (this.userInfo.user_type == "Admin") {
      this.rep_admin = "yes";
    } else {
      this.rep_admin = "no";
    }

    this.diagCenterID = this.userInfo.diag_center_id;
    this.print_size = this.userInfo.bill_print_paper;
    this.hospitals = this.userInfo.hospitals[0];
    this.hospital_logo = ipaddress.Ip_with_img_address + this.hospitals.logo;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;

    this.hospital_name = this.hospitals.hptl_name;
    var hospital;
    if (this.hospitals.address2 != undefined) {
      hospital = this.hospitals.address1 + ',' + this.hospitals.address2;
    } else {
      hospital = this.hospitals.address1;
    }
    this.hospitalAddress = hospital;
    this.Hospital_location =
      this.hospitals.location_desc +
      ', ' +
      this.hospitals.city_desc +
      ' - ' +
      this.hospitals.state_desc +
      ' ' +
      this.hospitals.country_desc +
      '  ' +
      this.hospitals.zipcode;
    this.telephone = this.hospitals.telephone;

    this.hptlClinicId = this.userInfo.hospitals[0].hptl_clinic_id;
    if (this.userInfo.bill_pay_type_req == '1') {
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }

    this.hospitalLogo =
      ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.ipaddress = Helper_Class.getIPAddress();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      lengthMenu: [
        [25, 50, -1],
        [25, 50, 'All'],
      ],
      columnDefs: [{ orderable: false, targets: [2, 3, 4, 5, 6] }],
      language: {
        search: "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: 'Search chargetypes by name',
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>',
    };

    this.Moduleidlist = Helper_Class.getmodulelist();
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == '7') {
          if (this.Moduleidlist[i].edit == '1') {
            this.editbutton = true;
          }
          if (this.Moduleidlist[i].create == '1') {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == '1') {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == '1') {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == '1') {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    if (this.userInfo.hptl_name == 'SGH') {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;margin-top: 10px;";
    } else {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;";
    }

    this.getDoctors();
    this.getPaymentType();
    this.getInsurers();
    this.RateCardData();
    this.finalAmount = 0;
    this.discount_amount = 0;
    this.hospital_discount_amount = 0;
    this.diag_discount_amount = 0;
    this.package_discount_amount = 0;
    this.totalBillAmount = 0;
    this.gstAmount = 0;
    this.advanceBill = 0;
    this.concession = 0;
    this.inGST = false;
    this.gstRetriveList = [];
    this.billAddList = [];
    this.totalBillList = [];
    this.addBillArray = [];
    this.packages = [];
    this.addPackageBillArray = [];
    this.getGstData();
    this.estimateId = [];
    this.showPrevBalanceFlag = true;
    this.showBalanceFlag = true;
    this.appointDate = this.appointDate;

    if (this.currentDate == null) this.getCurrentDate();

    this.getCreditTypes();
    if (Helper_Class.getBillingFlow() != undefined) {
      this.getBils = Helper_Class.getBillingFlow();
    }

    if (this.userInfo.bulk_add_req === '1') {
      this.bulkAddFlag = false;
    }

    if (this.userInfo.bill_qty_flag == 0) {
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }

    if (this.userInfo.bill_cons_flag == 0) {
      this.bill_cons_flag = true;
    } else {
      this.bill_cons_flag = false;
    }

    if (FrontDesk_Helper.getBillFromDate() != undefined) {
      this.fromDate = FrontDesk_Helper.getBillFromDate();
      this.toDate = FrontDesk_Helper.getBillToDate();
    } else {
      this.fromDate = FrontDesk_Helper.getAppDate();
      this.toDate = FrontDesk_Helper.getAppDate();
    }

    if (this.fromDate == undefined) {
      this.getCurrentDate();
    }

    if (this.userInfo.bill_print_template != undefined) {
      this.print_template = this.userInfo.bill_print_template;
    } else {
      this.print_template = 'noheader';
    }

    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.billingType = Helper_Class.getBillType();

    if (this.billingType == 'package') {
      this.packageFlag = true;
    }
    if (this.billingType == 'All') {
      this.doctor_flag = true;
    }
    this.filter = 'Patient_ID';
    this.cdr.detectChanges();
    this.focusMRNoInput();
  }

  focusMRNoInput() {
    this.patientId = '';
    document.getElementById('focusMRNo').focus();
    this.focusMRNo.nativeElement.focus();
  }

  focusMobileNoInput() {
    this.mobileNo = '';
    document.getElementById('focusMobileNo').focus();
    this.focusMobileNo.nativeElement.focus();
  }

  focusFirst_nameInput() {
    this.firstName = '';
    document.getElementById('focusFName').focus();
    this.focusFName.nativeElement.focus();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'adm/curdate',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.currentTime = obj.current_time;
            this.timevalue = Time_Formate(this.currentTime);
            this.fromDate = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.appointDate =
              get_date[2] + '-' + get_date[1] + '-' + get_date[0];
            this.billDate = this.appointDate;
          }
        },
        (error) => { }
      );
  }

  showCreditOption() {
    this.credit_flag = !this.credit_flag;
  }

  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => { }
      );
  }

  getDoctors() {
    var type = this.billingType == 'Diagnosis'
      ? 'diagnosis'
      : this.billingType == 'dietician'
        ? 'dietician'
        : this.billingType == 'package'
          ? 'package'
          : 'front-desk';
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'adm/docbyrep',
        JSON.stringify({
          country: ipaddress.country_code,
          reg_id: this.userInfo.user_id,
          imei: this.ipaddress,
          type: type,
          hosp_id: this.userInfo.hptl_clinic_id,
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(response['_body']);
          if (obj.doctors != undefined && obj.doctors.length != 0) {
            for (var i = 0; i < obj.doctors.length; i++) {
              this.doctorFullName = '';
              if (
                obj.doctors[i].middle_name != '' &&
                obj.doctors[i].middle_name != undefined
              ) {
                this.doctorFullName =
                  obj.doctors[i].first_name +
                  ' ' +
                  obj.doctors[i].middle_name +
                  ' ' +
                  obj.doctors[i].last_name;
              } else {
                this.doctorFullName =
                  obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
              }

              this.doctorList.push({
                doc_name: this.doctorFullName,
                doc_id: obj.doctors[i].prov_id,
              });
            }
          }
        },
        (error) => { }
      );
  }

  changeFilter() {
    this.billingList = [];
    this.clearData();
    if (this.filter == 'Doctor') {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.doctorFlag = false;
      this.mr_no_flag = false;

    } else if (this.filter == 'Date') {
      this.firstNameFlag = true;
      this.fromFlag = false;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.mr_no_flag = false;
      this.searchPatient();

    } else if (this.filter == 'Name') {
      this.firstNameFlag = false;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.mr_no_flag = true;
      this.cdr.detectChanges();
      this.focusFirst_nameInput();

    } else if (this.filter == 'Patient_ID') {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMRNoInput();

    } else if (this.filter == 'Mobile_No') {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
      this.doctorFlag = true;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMobileNoInput();
    }
  }

  selectFromDate(e) {
    FrontDesk_Helper.setBillFromDate(undefined);
    this.fromDate = e;
    FrontDesk_Helper.setBillFromDate(e);
    this.fdateFlag = true;
    this.clearData();
    this.appointmentId = '';
    this.billingList = [];
    this.searchPatient();
  }

  clearData() {
    this.createpage_flag = false;
    this.totalBillList = [];
    this.billAddList = [];
    this.addBillArray = [];
    this.addPackageBillArray = [];
    this.totalBillAmount = 0;
    this.discount_amount = 0;
    this.hospital_discount_amount = 0;
    this.diag_discount_amount = 0;
    this.package_discount_amount = 0;
    this.firstName = '';
    this.lastName = '';
    this.mobileNo = '';
    this.finalAmount = 0;
    this.remainingBalance = 0;
    this.hospital_remainingBalance = 0;
    this.diag_remainingBalance = 0;
    this.package_remainingBalance = 0;
    this.amountval = 0;
    this.paidBill = 0;
    this.hospital_paidBill = 0;
    this.diag_paidBill = 0;
    this.package_paidBill = 0;
    this.payType1 = this.payTypeList[0].pay_id;
    this.paymentsarray = [];
    this.gstAmount = 0;
    this.advanceBill = 0;
    this.estimateId = [];
    this.showPrevBalanceFlag = true;
    this.showBalanceFlag = true;
    this.patientName = '';
    this.amount = '';
    this.inGST = false;
  }

  selectToDate(e) {
    FrontDesk_Helper.setBillToDate(undefined);
    this.toDate = e;
    FrontDesk_Helper.setBillToDate(e);
    this.tdateFlag = true;
  }

  back() {
    if (Helper_Class.getLoginType() == 'Admin') {
      this.service.sendMessage('frontdeskadminbilling');
    } else {
      this.service.sendMessage('frontdeskbilling');
    }
  }

  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase().trim();
  }

  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase().trim();
  }

  getPatientListByMRNo(patientId) {
    if (patientId != undefined && patientId.length > 8) {
      this.searchPatient();
    }
  }

  getPatientListByMobile_no(mobileNo) {
    if (mobileNo != undefined && mobileNo.length > 9) {
      this.searchPatient();
    }
  }

  searchPatient() {
    this.billingList = [];
    this.appointmentId = '';
    var fieldvalue;
    var type =
      this.billingType == 'Diagnosis'
        ? 'diagnosis'
        : this.billingType == 'dietician'
          ? 'dietician'
          : this.billingType == 'package'
            ? 'package'
            : this.billingType == 'All' ? null : 'doctor';

    if (this.filter == 'Patient_ID') {
      fieldvalue = {
        hptl_clinic_id: this.hptlClinicId,
        patient_id: this.patientId,
        type: type,
      };
    } else if (this.filter == 'Name') {
      fieldvalue = {
        hptl_clinic_id: this.hptlClinicId,
        first_name: this.firstName,
        last_name: this.lastName,
        type: type,
      };
    } else if (this.filter == 'Date') {
      fieldvalue = {
        hptl_clinic_id: this.hptlClinicId,
        date: this.fromDate,
        type: type,
      };
    } else if (this.filter == 'Mobile_No') {
      fieldvalue = {
        hptl_clinic_id: this.hptlClinicId,
        mobile: this.mobileNo,
        type: type,
      };
    } else if (this.filter == 'Doctor') {
      fieldvalue = {
        hptl_clinic_id: this.hptlClinicId,
        doc_reg_id: this.docRegId,
        type: type,
      };
    }

    if (this.rep_admin == "yes") {
      fieldvalue.rep_admin = this.rep_admin;
    }

    console.log('BILL FILEDS ' + JSON.stringify(fieldvalue));
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'bill/getappf', fieldvalue, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response['_body']);
          console.log(obj);
          if (obj.appointment != null && obj.appointment.length != 0) {
            for (var i = 0; i < obj.appointment.length; i++) {
              var appointment_id;
              if (obj.appointment[i].doc_app_id != undefined) {
                if (obj.appointment[i].middle_name != undefined) {
                  this.doctorName =
                    obj.appointment[i].doc_app_id +
                    ' - ' +
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].middle_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                } else {
                  this.doctorName =
                    obj.appointment[i].doc_app_id +
                    ' - ' +
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                }
                appointment_id = obj.appointment[i].doc_app_id;

              } else if (obj.appointment[i].diag_app_id != undefined) {
                if (obj.appointment[i].middle_name != undefined) {
                  this.doctorName =
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].middle_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                } else {
                  this.doctorName =
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                }
                appointment_id = obj.appointment[i].diag_app_id;

              } else {
                if (obj.appointment[i].middle_name != undefined) {
                  this.doctorName =
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].middle_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                } else {
                  this.doctorName =
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                }
                appointment_id = obj.appointment[i].client_reg_id;
              }

              //PatientName
              var patient_name;
              if (obj.appointment[i].middle_name != undefined) {
                patient_name =
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].middle_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name);
              } else {
                patient_name =
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name);
              }

              var cname;
              if (obj.appointment[i].salutation_desc != undefined) {
                cname = obj.appointment[i].salutation_desc + '.' + patient_name;
              } else {
                cname = patient_name;
              }

              var address1 = '',
                address2 = '';
              if (
                obj.appointment[i].address2 != null &&
                obj.appointment[i].address2 != undefined &&
                obj.appointment[i].address1 != undefined
              ) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
                if (
                  obj.appointment[i].address2 != ' ' &&
                  obj.appointment[i].address2 != 'null'
                ) {
                  if (
                    encrypt_decript.Decript(obj.appointment[i].address2) == ''
                  ) {
                    address2 == '';
                  } else {
                    address2 =
                      encrypt_decript.Decript(obj.appointment[i].address2) +
                      ',';
                  }
                }
              } else if (obj.appointment[i].address1 != undefined) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
              } else if (obj.appointment[i].address2 != undefined) {
                address2 =
                  encrypt_decript.Decript(obj.appointment[i].address2) + ',';
              }

              var location = '';
              if (
                obj.appointment[i].location != undefined &&
                obj.appointment[i].location != null
              ) {
                location = obj.appointment[i].location + ',';
              }

              var city = '';
              if (
                obj.appointment[i].city != undefined &&
                obj.appointment[i].city != null
              ) {
                city = obj.appointment[i].city + '-';
              }

              var zipcode;
              if (
                obj.appointment[i].zipcode != undefined &&
                obj.appointment[i].zipcode != null
              ) {
                zipcode =
                  encrypt_decript.Decript(obj.appointment[i].zipcode) + ',';
              }

              var state = '';
              if (
                obj.appointment[i].state != undefined &&
                obj.appointment[i].state != null
              ) {
                state = obj.appointment[i].state + '-';
              }

              var country = '';
              if (
                obj.appointment[i].country != undefined &&
                obj.appointment[i].country != null
              ) {
                country = obj.appointment[i].country + '.';
              }

              var mobile;
              if (
                obj.appointment[i].mobile != undefined &&
                obj.appointment[i].mobile != null
              ) {
                mobile = encrypt_decript.Decript(obj.appointment[i].mobile);
              }

              //Doctor Name
              var docName;
              if (
                obj.appointment[i].dr_middle_name != undefined &&
                obj.appointment[i].dr_middle_name != null
              ) {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_middle_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              } else if (
                obj.appointment[i].dr_first_name != undefined &&
                obj.appointment[i].dr_last_name != undefined
              ) {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              } else if (obj.appointment[i].dr_first_name != undefined) {
                docName = 'Dr.' + obj.appointment[i].dr_first_name;
              } else {
                docName = '';
              }

              if (obj.appointment[i].doc_qualif != undefined && obj.appointment[i].doc_qualif != null && obj.appointment[i].doc_qualif != "") {
                docName = docName + " " + obj.appointment[i].doc_qualif;
              }

              if (obj.appointment[i].profile_image != '') {
                var image =
                  ipaddress.Ip_with_img_address +
                  obj.appointment[i].profile_image;
              }

              var em_contact =
                obj.appointment[i].emerg_contact != undefined
                  ? encrypt_decript.Decript(obj.appointment[i].emerg_contact)
                  : '';
              var package_array = [];
              this.package_id = [];
              if (obj.appointment[i].packages != undefined) {
                package_array = obj.appointment[i].packages;
                for (var p = 0; p < package_array.length; p++) {
                  this.package_id.push(package_array[p].health_pkg_id);
                }
              }

              var diag_app_id;
              if (obj.appointment[i].diag_app_id != undefined) {
                diag_app_id = obj.appointment[i].diag_app_id;
              }
              var barcode;
              if (obj.appointment[i].barcode != undefined && obj.appointment[i].barcode != null) {
                this.barcode_flag = true;
                barcode = ipaddress.Ip_with_img_address + obj.appointment[i].barcode;
              }

              this.billingList.push({
                doc_reg_id: obj.appointment[i].doc_reg_id,
                doc_app_id: appointment_id,
                package_id: this.package_id,
                diag_app_id: diag_app_id,
                client_reg_id: obj.appointment[i].client_reg_id,
                relation_id: obj.appointment[i].relation_id,
                docid_name: this.doctorName,
                profile_image: image,
                patient_name: cname,
                age: obj.appointment[i].age,
                mobile: mobile,
                em_contact: em_contact,
                mr_no: obj.appointment[i].mr_no,
                gender: obj.appointment[i].gender,
                address1: address1,
                address2: address2,
                location: location,
                city: city + zipcode,
                state: state + country,
                dr_name: docName,
                is_new: obj.appointment[i].is_new,
                package_name: obj.appointment[i].package_name,
                price: obj.appointment[i].price,
                diag_apps: obj.appointment[i].diag_apps,
                doc_apps: obj.appointment[i].doc_apps,
                packages: obj.appointment[i].packages,
                barcode: barcode,
                spl_id: obj.appointment[i].spl_id,
                spl_name: obj.appointment[i].spl_name,
                consult_fee_accept:obj.appointment[i].is_consult_fee_accept,
              });
            }
          }

          if (this.billingList != undefined && this.billingList.length === 1) {
            if (this.billingType == 'package') {
              this.appointmentId = this.billingList[0].client_reg_id;
              this.getPackageDetails();

            } else {
              this.appointmentId = this.billingList[0].client_reg_id;
              this.changeAppointmentId();
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  RateCardData() {
    var requestData = {
      centre_id: this.hptlClinicId,
      center_type: 'hospital',
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'mas/grcd', requestData, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          var RateCard = obj.rate_cards;
          console.log(RateCard);
          if (RateCard != undefined && RateCard.length != 0) {
            for (var i = 0; i < RateCard.length; i++) {
              this.RateCardArray.push({
                discount: RateCard[i].discount,
                rate_card_desc: RateCard[i].rate_card_desc,
                rate_card_id: RateCard[i].rate_card_id,
                default: RateCard[i].default,
              });
            }
            this.rate_card = this.RateCardArray[0].rate_card_id;
            for (var i = 0; i < this.RateCardArray.length; i++) {
              if (this.RateCardArray[i].default == '1') {
                this.rate_card = this.RateCardArray[i].rate_card_id;
              }
            }
            this.getConcession();
          }
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  }

  getBillType() {
    //Get bill type
    this.billAddList = [];
    this.chargeName = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/chgtype',
        JSON.stringify({
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          provider: 'doctor',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj.charges != undefined && obj.charges.length != 0) {
            if (this.is_new == '1') {
              this.chargeName = obj.charges;

            } else if (this.is_new == '0') {
              for (var j = 0; j < obj.charges.length; j++) {
                if (obj.charges[j].one_time == '1') {
                  obj.charges.splice(j, 1);
                }
              }
              this.chargeName = obj.charges;
            } 
          
            if(this.consult_fee_accept == "1") {
              for (var j = 0; j < obj.charges.length; j++) {
                if (obj.charges[j].description == 'Consultation Fee') {
                  obj.charges.splice(j, 1);
                }
              }
              this.chargeName = obj.charges;
            }

            if (this.prev_charges !== undefined && this.prev_charges.length !== 0) {
              for (var i = 0; i < this.prev_charges.length; i++) {
                for (var j = 0; j < obj.charges.length; j++) {
                  if (this.prev_charges[i].charge_type === obj.charges[j].charge_id) {
                    // Remove only the matching element
                    obj.charges.splice(j, 1);
                    // Since we've modified the array, we need to decrement j to avoid skipping the next element
                    j--;
                    break; // Exit the inner loop after removing the element
                  }
                }
              }
              this.chargeName = obj.charges;
            }

            console.log("CHARGE TYPES =========== " + JSON.stringify(this.chargeName));
            if (this.chargeName != undefined && this.chargeName.length != 0) {
              for (var i = 0; i < this.chargeName.length; i++) {
                if (
                  this.chargeName[i].auto_generate != null &&
                  this.chargeName[i].auto_generate == '1'
                ) {
                  this.chargeType = this.chargeName[i].description;
                  this.amount = parseFloat(this.chargeName[i].amount);
                  this.sendConcession = parseFloat(this.concession);
                  this.amountAftCon = this.getamountAftCon1(this.chargeName[i].amount);
                  this.chargeId = this.chargeName[i].charge_id;
                  this.insurerId = this.insurerId,
                    this.transId = this.transId,
                    this.quantity = this.quantity;
                  this.addBill();
                }
              }
            }
          }
          console.log("CHARGE TYPES ((((())))) " + JSON.stringify(this.chargeName));
          // if (this.billAddList.length == 0) {
          //   this.addNewBillDetails();
          // }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  addBill() {
    //Bill add table
    var flag = true;
    if (this.appointmentId == undefined) {
      this.toastr.error(Message_data.sltAppId);
      flag = false;
    } else if (this.chargeType == undefined) {
      flag = false;
      this.toastr.error(Message_data.sltBillType);
    } else if (this.amount == undefined || this.amount == '') {
      this.toastr.error(Message_data.enterAmt);
      flag = false;
    } else if (this.quantity == undefined) {
      this.toastr.error(Message_data.enterQty);
      flag = false;
    }

    if (this.finalAmount == 0) {
      this.inGST == false;
    }

    for (var i = 0; i < this.billAddList.length; i++) {
      if (
        this.billAddList[i].biill_type == this.chargeType &&
        this.billAddList[i].paytype == this.payType
      ) {
        flag = false;
        break;
      }
    }

    if (flag == true) {
      if (this.billingType != 'package') {
        if (this.chargeName.length != 0) {
          for (var i = 0; i < this.chargeName.length; i++) {
            if (this.chargeType == this.chargeName[i].description) {
              this.chargeId = this.chargeName[i].charge_id;
            }
          }
        }
      }

      var paydesc;
      if (this.payTypeList.length != 0) {
        for (var i = 0; i < this.payTypeList.length; i++) {
          if (this.payType == this.payTypeList[i].pay_id) {
            paydesc = this.payTypeList[i].pay_desc;
          }
        }
      }

      if (this.concession == '') {
        this.sendConcession = 0;
      } else {
        this.sendConcession = this.concession;
      }

      var total = 0;
      total = (parseFloat(this.quantity) * parseFloat(this.amount));
      var result = this.finekeyInArray_billaddChargeType(
        this.billAddList,
        this.chargeId
      );
      if (result == true) {
        this.toastr.error('Charge type already exists');
      } else if (this.paytypreq == true) {
        this.billAddList.push({
          sno: this.sno,
          biill_type: this.chargeType,
          amount_txt: parseInt(this.amount),
          concession: parseFloat(this.sendConcession),
          fee: parseFloat(this.amountAftCon),
          charge_type: this.chargeId,
          insurer: this.insurerId,
          ref_code: this.transId,
          quantity: this.quantity,
          total: total,
          type: 'hospital',
          edit_flag: true
        });
      } else {
        this.billAddList.push({
          sno: this.sno,
          biill_type: this.chargeType,
          amount_txt: parseInt(this.amount),
          concession: parseFloat(this.sendConcession),
          fee: parseFloat(this.amountAftCon),
          charge_type: this.chargeId,
          pay_type: paydesc,
          paytype: this.payType,
          insurer: this.insurerId,
          ref_code: this.transId,
          quantity: this.quantity,
          total: total,
          type: 'hospital',
          edit_flag: true
        });
      }
      this.sno = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].sno = this.sno;
        this.sno += 1;
      }
      this.amount = '';
      this.amountAftCon = '';
      this.quantity = 1;
      this.chargeType = '';
    }

    if (this.finalAmount == 0)
      this.inGST == false;

    this.calculateGst('0', '0');
    this.calculateHospitalGst('0', '0');
  }

  addPackageBill() {
    //Bill add table
    var flag = true;
    if (this.appointmentId == undefined) {
      this.toastr.error(Message_data.sltAppId);
      flag = false;
    } else if (this.chargeType == undefined) {
      flag = false;
      this.toastr.error(Message_data.sltBillType);
    } else if (this.amount == undefined || this.amount == '') {
      this.toastr.error(Message_data.enterAmt);
      flag = false;
    } else if (this.quantity == undefined) {
      this.toastr.error(Message_data.enterQty);
      flag = false;
    }

    if (this.finalAmount == 0) this.inGST == false;

    for (var i = 0; i < this.addPackageBillArray.length; i++) {
      if (
        this.addPackageBillArray[i].biill_type == this.chargeType &&
        this.addPackageBillArray[i].paytype == this.payType
      ) {
        flag = false;
        break;
      }
    }

    if (flag == true) {
      if (this.billingType != 'package') {
        if (this.chargeName.length != 0) {
          for (var i = 0; i < this.chargeName.length; i++) {
            if (this.chargeType == this.chargeName[i].description) {
              this.chargeId = this.chargeName[i].charge_id;
            }
          }
        }
      }

      var paydesc;
      if (this.payTypeList.length != 0) {
        for (var i = 0; i < this.payTypeList.length; i++) {
          if (this.payType == this.payTypeList[i].pay_id) {
            paydesc = this.payTypeList[i].pay_desc;
          }
        }
      }

      if (this.concession == '') {
        this.sendConcession = 0;
      } else {
        this.sendConcession = this.concession;
      }

      var total = 0;
      total = (parseFloat(this.quantity) * parseFloat(this.amount));
      var result = this.finekeyInArray_billaddChargeType(
        this.addPackageBillArray,
        this.chargeId
      );
      if (result == true) {
        this.toastr.error('Charge type already exists');
      } else if (this.paytypreq == true) {
        this.addPackageBillArray.push({
          sno: this.sno,
          biill_type: this.chargeType,
          amount_txt: parseInt(this.amount),
          concession: this.sendConcession,
          fee: parseFloat(this.amountAftCon),
          charge_type: this.chargeId,
          insurer: this.insurerId,
          ref_code: this.transId,
          quantity: this.quantity,
          total: total,
          type: 'package',
          edit_flag: true
        });
      } else {
        this.addPackageBillArray.push({
          sno: this.sno,
          biill_type: this.chargeType,
          amount_txt: parseInt(this.amount),
          concession: this.sendConcession,
          fee: parseFloat(this.amountAftCon),
          charge_type: this.chargeId,
          pay_type: paydesc,
          paytype: this.payType,
          insurer: this.insurerId,
          ref_code: this.transId,
          quantity: this.quantity,
          total: total,
          type: 'package',
          edit_flag: true
        });
      }
      this.sno = 1;
      for (var i = 0; i < this.addPackageBillArray.length; i++) {
        this.addPackageBillArray[i].sno = this.sno;
        this.sno += 1;
      }
      this.amount = '';
      this.amountAftCon = '';
      this.quantity = 1;
      this.chargeType = '';
    }

    if (this.finalAmount == 0)
      this.inGST == false;

    this.calculateGst('0', '0');
    this.calculatePackageGst('0', '0');
  }

  finekeyInArray_billaddChargeType(array, charge_id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_type == charge_id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  addNewBillDetails() {
    this.billAddList.push({
      sno: this.sno,
      biill_type: '',
      amount_txt: '',
      concession: '',
      fee: '',
      charge_type: '',
      insurer: '',
      ref_code: '',
      quantity: '',
      total: '',
      type: 'hospital'
    });
    this.sno = 1;
    for (var i = 0; i < this.billAddList.length; i++) {
      this.billAddList[i].sno = this.sno;
      this.sno += 1;
    }
    this.calculateGst('0', '0');
    setTimeout(function () {
      $(".getChargeDesc").last().focus();
    }, 200);
  }

  getamountAftCon1(amount) {//RateCard[i].discount
    var RateCard = this.RateCardArray;
    for (var i = 0; i < RateCard.length; i++) {
      if (this.rate_card == RateCard[i].rate_card_id) {
        if (RateCard[i].discount == 0 && RateCard[i].rate_card_desc != 'Service wise') {
          return (this.amountAftCon = parseFloat(amount) * this.quantity);
        } else {
          return (this.amountAftCon =
            parseFloat(amount) * this.quantity -
            (this.concession / 100) *
            (parseFloat(amount) * this.quantity));
        }
      }
    }
  }

  updateBillList(charge) {
    var results = this.finekeyInArray_billadd(
      this.billAddList,
      charge.charge_type
    );
    if (charge.selected) {
      if (results == false) {
        this.chargeType = charge.biill_type;
        this.amount = parseFloat(charge.amount_txt);
        this.sendConcession = charge.concession;
        this.amountAftCon = parseFloat(charge.fee);
        this.chargeId = charge.charge_type;
        this.insurerId = charge.insurer;
        this.transId = charge.ref_code;
        this.quantity = charge.quantity;
        setTimeout(() => {
          this.addBill();
        }, 500);
      }
    } else {
      this.deleteHospitalBill(charge);
    }
  }

  deleteBill(data) {
    if (data.type == 'hospital') {
      this.deleteHospitalBill(data);
    }
    if (data.type == 'diagnosis') {
      this.diagDeleteBill(data);
    }
    if (data.type == 'package') {
      this.deletePackageBill(data.biill_type, data.amount_txt, data.charge_type);
    }
  }

  deleteHospitalBill(data) {
    //Delete Hospital Bill
    if (data.biill_type.length != 0) {
      if (this.billAddList.length != 0) {
        for (var i = 0; i < this.billAddList.length; i++) {
          if (
            this.billAddList[i].biill_type == data.biill_type &&
            this.billAddList[i].amount_txt == data.amount_txt
          ) {
            this.billAddList.splice(i, 1);
            this.calculateGst('0', '0');
            this.calculateHospitalGst('0', '0');
            break;
          }
        }
      }
      this.sno = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].sno = this.sno;
        this.sno += 1;
      }
      if (this.finalAmount == 0) this.inGST == false;
      this.calculateGst('0', '0');
      this.calculateHospitalGst('0', '0');
    } else {
      for (var i = 0; i < this.billAddList.length; i++) {

        if (data.sno == this.billAddList[i].sno) {
          if (data.biill_type == '') {
            this.billAddList.splice(i, 1);
            this.calculateGst('0', '0');
            this.calculateHospitalGst('0', '0');
            break;
          }
        }
      }
      this.sno = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].sno = this.sno;
        this.sno += 1;
      }
      this.calculateGst('0', '0');
      this.calculateHospitalGst('0', '0');
    }

    // if (this.billAddList.length == 0) {
    //   this.addNewBillDetails();
    //   this.calculateGst('0','0');
    //   this.calculateHospitalGst('0','0');
    // }
    this.getTableHeading();
  }

  diagDeleteBill(data) {
    //Delete Diagnosis Bill
    if (this.addBillArray.length != 0) {
      for (var i = 0; i < this.addBillArray.length; i++) {
        if (this.addBillArray[i].test_id == data.test_id) {
          this.addBillArray.splice(i, 1);
          this.calculateGst("0", "0");
          this.calculateDiagGst("0", "0");
          break;
        }
      }
      var sno = 1;
      for (var i = 0; i < this.addBillArray.length; i++) {
        this.addBillArray[i].sno = sno;
        sno += 1;
      }
    }

    if (this.finalAmount == 0) {
      this.inGST == false;
    }
    this.calculateGst("0", "0");
    this.calculateDiagGst("0", "0");
    this.getTableHeading();
  }

  deletePackageBill(type, amount, charge_type) {
    //Delete Package Bill
    if (type.length != 0) {
      if (this.addPackageBillArray.length != 0) {
        for (var i = 0; i < this.addPackageBillArray.length; i++) {
          if (
            this.addPackageBillArray[i].biill_type == type &&
            this.addPackageBillArray[i].amount_txt == amount
          ) {
            this.addPackageBillArray.splice(i, 1);
            if (this.package_id.length != 0) {
              for (var p = 0; p < this.package_id.length; p++) {
                if (charge_type == this.package_id[p]) {
                  this.package_id.splice(i, 1);
                }
              }
            }
            this.calculateGst('0', '0');
            this.calculatePackageGst('0', '0');
            break;
          }
        }
      }
      this.sno = 1;
      for (var i = 0; i < this.addPackageBillArray.length; i++) {
        this.addPackageBillArray[i].sno = this.sno;
        this.sno += 1;
      }
      if (this.finalAmount == 0) this.inGST == false;
      this.calculateGst('0', '0');
      this.calculatePackageGst('0', '0');
    } else {
      for (var i = 0; i < this.addPackageBillArray.length; i++) {
        if (
          this.addPackageBillArray[i].biill_type == '' &&
          this.addPackageBillArray[i].amount_txt == ''
        ) {
          this.addPackageBillArray.splice(i, 1);
          this.calculateGst('0', '0');
          this.calculatePackageGst('0', '0');
          break;
        }
      }
      this.sno = 1;
      for (var i = 0; i < this.addPackageBillArray.length; i++) {
        this.addPackageBillArray[i].sno = this.sno;
        this.sno += 1;
      }
    }
    this.getTableHeading();
  }

  finekeyInArray_billadd(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_type === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  getPaymentType() {
    //Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .get(ipaddress.getIp.toString() + 'bill/paytype', { headers: headers })
      .subscribe(
        (response) => {
          var obj = response.json();
          this.payTypeList = obj.payments;
          console.log(this.payTypeList);
          this.payType = this.payTypeList[0].pay_id;
          this.payType1 = this.payTypeList[0].pay_id;
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  changePayType() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == '2') {
      this.transactionFlag = false;
      this.cardFlag = false;
      this.cardreqflag = true;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.payType == '3') {
      this.cardreqflag = false;
      this.insurFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = false;
      this.insreqflag = true;
    } else if (this.payType == '4') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.payType == '5') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.payType == '6') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.payType == '7') {
      this.chequeFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = true;
      this.insreqflag = false;
    }
    this.pathFunction();
  }

  getInsurers() {
    //Get payment type
    this.insurerList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .get(ipaddress.getIp.toString() + 'bill/gins', { headers: headers })
      .subscribe(
        (response) => {
          var obj = response.json();
          this.insurerList = obj.insurers;
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  getConcession() {
    for (var i = 0; i < this.RateCardArray.length; i++) {
      if (this.RateCardArray[i].rate_card_id == this.rate_card) {
        if (this.RateCardArray[i].rate_card_desc == 'Service wise') {
          this.discount_flag = false;
          if (this.concession != '') {
            this.concession = this.concession;
          } else {
            this.concession = parseFloat(this.RateCardArray[i].discount);
          }
        } else {
          this.discount_flag = true;
        }
        if (this.RateCardArray[i].rate_card_desc != 'Service wise') {
          this.concession = parseFloat(this.RateCardArray[i].discount);
        }
        this.flag_function();
      }
    }

    if (this.billAddList != undefined && this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].concession = this.concession;
        this.changeamount1(this.billAddList[i], 0);
      }
    }
    if (this.addBillArray != undefined && this.addBillArray.length != 0) {
      for (var i = 0; i < this.addBillArray.length; i++) {
        this.addBillArray[i].concession = this.concession;
        this.diagChangeAmount(this.addBillArray[i], 0);
      }
    }
    if (this.addPackageBillArray != undefined && this.addPackageBillArray.length != 0) {
      for (var i = 0; i < this.addPackageBillArray.length; i++) {
        this.addPackageBillArray[i].concession = this.concession;
        this.changePackageAmount(this.addPackageBillArray[i], 0);
      }
    }
  }

  flag_function() {
    if (this.bill_qty_flag == true && this.discount_flag == true) {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
    } else {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse";
    }
  }

  changeChargeType1(charge, person) {
    var result = this.finekeyInArray_billaddChargeType(
      this.billAddList,
      charge.charge_id
    );
    if (result == true) {
      this.toastr.error('Charge type already exists');
      person.biill_type = '';
      this.serviceType = [];
    } else {
      person.biill_type = charge.description;
      person.edit_flag = true;
      person.charge_type = charge.charge_id;
      person.amount_txt = parseFloat(charge.amount);
      person.quantity = this.quantity;
      person.concession = this.concession;
      person.fee = this.getamountAftCon1(charge.amount);
      person.total = (parseFloat(this.quantity) * parseFloat(charge.amount));
      this.calculateHospitalGst('0', '0');
      this.calculateGst('0', '0');
      this.serviceType = [];
      this.addNewBillDetails();
    }
    this.getTableHeading();
  }

  getAppointments() {
    this.clearData();
    this.billingList = [];
    var type =
      this.billingType == 'Diagnosis'
        ? 'diagnosis'
        : this.billingType == 'dietician'
          ? 'dietician'
          : this.billingType == 'package'
            ? 'package'
            : 'doctor';
    console.log({
      hptl_clinic_id: this.userInfo.hptl_clinic_id,
      date: this.currentDate,
      doc_reg_id: this.docRegId,
      type: type,
      rep_admin: 'yes',
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/getapp',
        JSON.stringify({
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          date: this.currentDate,
          doc_reg_id: this.docRegId,
          type: type,
          rep_admin: 'yes',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(response['_body']);
          console.log(obj);
          if (obj.appointment != null && obj.appointment.length != 0) {
            for (var i = 0; i < obj.appointment.length; i++) {
              var appointment_id;
              if (obj.appointment[i].doc_app_id != undefined) {
                if (obj.appointment[i].middle_name != undefined) {
                  this.doctorName =
                    obj.appointment[i].doc_app_id +
                    ' - ' +
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].middle_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                } else {
                  this.doctorName =
                    obj.appointment[i].doc_app_id +
                    ' - ' +
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                }
                appointment_id = obj.appointment[i].doc_app_id;
              } else if (obj.appointment[i].health_pkg_id = undefined) {
                if (obj.appointment[i].middle_name != undefined) {
                  this.doctorName =
                    obj.appointment[i].health_pkg_id +
                    ' - ' +
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].middle_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                } else {
                  this.doctorName =
                    obj.appointment[i].health_pkg_id +
                    ' - ' +
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                }
                appointment_id = obj.appointment[i].health_pkg_id;
              } else {
                if (obj.appointment[i].middle_name != undefined) {
                  this.doctorName =
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].middle_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                } else {
                  this.doctorName =
                    encrypt_decript.Decript(obj.appointment[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointment[i].last_name);
                }
                appointment_id = obj.appointment[i].client_reg_id;
              }

              //PatientName
              var patient_name;
              if (obj.appointment[i].middle_name != undefined) {
                patient_name =
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].middle_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name);
              } else {
                patient_name =
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name);
              }
              appointment_id = obj.appointment[i].client_reg_id;

              var cname;
              if (obj.appointment[i].salutation_desc != undefined) {
                cname = obj.appointment[i].salutation_desc + '.' + patient_name;
              } else {
                cname = patient_name;
              }

              var sessiondata = null;
              if (
                obj.appointment[i].session != undefined &&
                obj.appointment[i].session != undefined &&
                obj.appointment[i].session != null
              ) {
                sessiondata = encrypt_decript.Decript(
                  obj.appointment[i].session
                );
              }

              var address1 = '',
                address2 = '';
              if (
                obj.appointment[i].address2 != null &&
                obj.appointment[i].address2 != undefined &&
                obj.appointment[i].address1 != undefined
              ) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
                if (
                  obj.appointment[i].address2 != ' ' &&
                  obj.appointment[i].address2 != 'null'
                ) {
                  if (
                    encrypt_decript.Decript(obj.appointment[i].address2) == ''
                  ) {
                    address2 == '';
                  } else {
                    address2 =
                      encrypt_decript.Decript(obj.appointment[i].address2) +
                      ',';
                  }
                }
              } else if (obj.appointment[i].address1 != undefined) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
              } else if (obj.appointment[i].address2 != undefined) {
                address2 =
                  encrypt_decript.Decript(obj.appointment[i].address2) + ',';
              }

              var location;
              if (
                obj.appointment[i].location != undefined &&
                obj.appointment[i].location != null
              ) {
                location = obj.appointment[i].location + ',';
              }

              var city = '';
              if (
                obj.appointment[i].city != undefined &&
                obj.appointment[i].city != null
              ) {
                city = obj.appointment[i].city + '-';
              }

              var mobile;
              if (
                obj.appointment[i].mobile != undefined &&
                obj.appointment[i].mobile != null
              ) {
                mobile = encrypt_decript.Decript(obj.appointment[i].mobile);
              }

              var zipcode;
              if (
                obj.appointment[i].zipcode != undefined &&
                obj.appointment[i].zipcode != null
              ) {
                zipcode =
                  encrypt_decript.Decript(obj.appointment[i].zipcode) + ',';
              }

              var state = '';
              if (
                obj.appointment[i].state != undefined &&
                obj.appointment[i].state != null
              ) {
                state = obj.appointment[i].state + '-';
              }

              var country = '';
              if (
                obj.appointment[i].country != undefined &&
                obj.appointment[i].country != null
              ) {
                country = obj.appointment[i].country + '.';
              }

              var docName;
              if (
                obj.appointment[i].dr_middle_name != undefined &&
                obj.appointment[i].dr_middle_name != null
              ) {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_middle_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              } else {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              }

              if (obj.appointment[i].doc_qualif != undefined && obj.appointment[i].doc_qualif != null && obj.appointment[i].doc_qualif != "") {
                docName = docName + " " + obj.appointment[i].doc_qualif;
              }

              if (obj.appointment[i].profile_image != '') {
                var image =
                  ipaddress.Ip_with_img_address +
                  obj.appointment[i].profile_image;
              }

              var em_contact =
                obj.appointment[i].emerg_contact != undefined
                  ? encrypt_decript.Decript(obj.appointment[i].emerg_contact)
                  : '';
              var package_id =
                obj.appointment[i].health_pkg_id != undefined
                  ? obj.appointment[i].health_pkg_id
                  : 0;

              this.billingList.push({
                doc_reg_id: this.docRegId,
                doc_app_id: appointment_id,
                package_id: package_id,
                client_reg_id: obj.appointment[i].client_reg_id,
                relation_id: obj.appointment[i].relation_id,
                sub_rel_id: obj.appointment[i].sub_rel_id,
                first_name: obj.appointment[i].first_name,
                middle_name: obj.appointment[i].middle_name,
                last_name: obj.appointment[i].last_name,
                hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                session: sessiondata,
                docid_name: this.doctorName,
                profile_image: image,
                patient_name: cname,
                age: obj.appointment[i].age,
                mobile: mobile,
                em_contact: em_contact,
                mr_no: obj.appointment[i].mr_no,
                gender: obj.appointment[i].gender,
                address1: address1,
                address2: address2,
                location: location,
                city: city + zipcode,
                state: state + country,
                dr_name: docName,
                is_new: obj.appointment[i].is_new,
                diag_apps: obj.appointment[i].diag_apps,
                doc_apps: [obj.appointment[i].doc_app_id],
                packages: obj.appointment[i].packages,
                spl_id: obj.appointment[i].spl_id,
                spl_name: obj.appointment[i].spl_name,
              });
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  pathFunction() {
    if (this.billingType == 'package') {
      this.getPackageDetails();
    } else {
      this.changeAppointmentId();
    }
  }

  getPackageDetails() {
    this.patientName = '';
    this.billing_print_array = [];
    this.mrno = '';
    this.patient_name = '';
    this.clnt_age = '';
    this.gender = '';
    this.address1 = '';
    this.address2 = '';
    this.location = '';
    this.city = '';
    this.state = '';
    this.mobile_no = '';
    this.em_contact = '';
    this.profile_image = '';
    this.package_array = [];
    this.addPackageBillArray = [];
    this.diag_apps = undefined;
    this.package_id = [];
    this.docRegId = "";
    this.clearData();

    for (var i = 0; i < this.billingList.length; i++) {
      if (this.appointmentId == this.billingList[i].client_reg_id) {
        console.log("AppointmentId ", "BillingListId", this.appointmentId, this.billingList[i].client_reg_id);
        this.docRegId = this.billingList[i].doc_reg_id;
        this.createpage_flag = true;
        this.mrno = this.billingList[i].mr_no;
        this.is_new = this.billingList[i].is_new;
        this.patient_name = this.billingList[i].patient_name;
        this.profile_image = this.billingList[i].profile_image;
        this.clnt_age = this.billingList[i].age;
        if (
          this.billingList[i].gender != null &&
          this.billingList[i].gender != undefined
        ) {
          this.gender = encrypt_decript.Decript(this.billingList[i].gender);
          this.gender = this.gender[0];
          if (this.gender != undefined && this.gender[0] == 'T') {
            this.gender = 'Tgen';
          }
        }

        this.address1 = this.billingList[i].address1;
        this.address2 = this.billingList[i].address2;
        this.location = this.billingList[i].location;
        this.city = this.billingList[i].city;
        this.state = this.billingList[i].state;
        this.dr_name = this.billingList[i].dr_name;
        this.spl_name = this.billingList[i].spl_name;
        this.mobile_no = this.billingList[i].mobile;
        this.em_contact = this.billingList[i].em_contact;
        if (this.billingList[i].package_id != undefined && this.billingList[i].package_id.length != 0) {
          this.package_id = this.billingList[i].package_id;
        }
        console.log(this.package_id);
        if (this.billingList[i].diag_apps != undefined && this.billingList[i].diag_apps.length != 0) {
          this.diag_apps = this.billingList[i].diag_apps;
          this.diag_apps = this.diag_apps[0];
          console.log(this.diag_apps);
        }

        this.billing_print_array.push({
          address1: this.billingList[i].address1,
          address2: this.billingList[i].address2,
          location: this.billingList[i].location,
          city: this.billingList[i].city,
          state: this.billingList[i].state,
          mobile: this.billingList[i].mobile,
          dr_name: this.billingList[i].dr_name,
          spl_name: this.billingList[i].spl_name,
        });

        this.addPackageBillArray = [];

        this.package_array = this.billingList[i].packages;
        if (this.package_array != undefined && this.package_array.length != 0) {
          for (var p = 0; p < this.package_array.length; p++) {
            this.chargeType = this.package_array[p].package_name;
            this.chargeId = this.package_array[p].health_pkg_id;
            this.amount = parseFloat(this.package_array[p].price);
            this.sendConcession = this.concession;
            this.amountAftCon = this.getamountAftCon1(this.package_array[p].price);
            this.insurerId = this.insurerId;
            this.transId = this.transId;
            this.quantity = this.quantity;
            this.addPackageBill();
          }
        }
      }
    }
  }

  changeAppointmentId() {
    this.clearData();
    this.hospitalName = '';
    this.patientName = '';
    this.billTreatmentPlan = [];
    this.billing_print_array = [];
    this.billAddList = [];
    this.totalBillList = [];
    this.addBillArray = [];
    this.packages = [];
    this.addPackageBillArray = [];
    this.mrno = '';
    this.patient_name = '';
    this.clnt_age = '';
    this.gender = '';
    this.address1 = '';
    this.address2 = '';
    this.location = '';
    this.city = '';
    this.state = '';
    this.mobile_no = '';
    this.dr_name = '';
    this.spl_name = '';
    this.em_contact = '';
    this.profile_image = '';
    this.diag_apps = undefined;
    this.doc_apps = undefined;
    this.doc_apps_flag = false;
    this.package_id = [];
    this.prev_charges = [];
    this.consult_fee_accept = '';

    for (var i = 0; i < this.billingList.length; i++) {
      if (this.appointmentId == this.billingList[i].client_reg_id) {
        this.docRegId = this.billingList[i].doc_reg_id;
        if (this.billingList[i].diag_apps != undefined && this.billingList[i].diag_apps.length != 0) {
          this.diag_apps = this.billingList[i].diag_apps;
          this.diag_apps = this.diag_apps[0];
        }
        console.log("Diag_Apps", this.diag_apps);
        if (this.billingList[i].doc_apps != undefined && this.billingList[i].doc_apps.length != 0) {
          this.doc_apps = this.billingList[i].doc_apps;
          this.doc_apps = this.doc_apps[0];
          this.doc_apps_flag = true;
        }
        console.log("Doc_apps", this.doc_apps);
        this.packages = this.billingList[i].packages;
        if (this.billingList[i].package_id != undefined && this.billingList[i].package_id.length != 0) {
          this.package_id = this.billingList[i].package_id;
        }
        console.log("Package_id", this.package_id);
        this.createpage_flag = true;
        this.mrno = this.billingList[i].mr_no;
        this.is_new = this.billingList[i].is_new;
        this.patient_name = this.billingList[i].patient_name;
        this.profile_image = this.billingList[i].profile_image;
        this.clnt_age = this.billingList[i].age;
        this.consult_fee_accept = this.billingList[i].consult_fee_accept;
        console.log(this.is_new)
        
        if (
          this.billingList[i].gender != null &&
          this.billingList[i].gender != undefined
        ) {
          this.gender = encrypt_decript.Decript(this.billingList[i].gender);
          this.gender = this.gender[0];
          if (this.gender != undefined && this.gender[0] == 'T') {
            this.gender = 'Tgen';
          }
        }
        this.address1 = this.billingList[i].address1;
        this.address2 = this.billingList[i].address2;
        this.location = this.billingList[i].location;
        this.city = this.billingList[i].city;
        this.state = this.billingList[i].state;
        this.dr_name = this.billingList[i].dr_name;
        this.spl_name = this.billingList[i].spl_name;
        this.mobile_no = this.billingList[i].mobile;
        this.em_contact = this.billingList[i].em_contact;
        this.barcode_url = this.billingList[i].barcode;

        this.billing_print_array.push({
          address1: this.billingList[i].address1,
          address2: this.billingList[i].address2,
          location: this.billingList[i].location,
          city: this.billingList[i].city,
          state: this.billingList[i].state,
          mobile: this.billingList[i].mobile,
          dr_name: this.billingList[i].dr_name,
          spl_name: this.billingList[i].spl_name,
        });
      }
    }

    var type =
      this.billingType == 'Diagnosis'
        ? 'diagnosis'
        : this.billingType == 'dietician'
          ? 'dietician'
          : this.billingType == 'package'
            ? 'package'
            : 'doctor';

    console.log({
      appointment_id: this.doc_apps,
      doc_reg_id: this.docRegId,
      diag_app_id: this.diag_apps,
      type: type,
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/getappdet',
        JSON.stringify({
          appointment_id: this.doc_apps,
          doc_reg_id: this.docRegId,
          diag_app_id: this.diag_apps,
          type: type,
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          this.prev_charges = [];
          var obj = JSON.parse(response['_body']);
          console.log('PatientDetails', obj);
          if (obj.key != undefined || obj.key != '0') {
            if (obj.first_name != undefined || this.doc_apps != undefined) {
              if (obj.prev_charges != undefined && obj.prev_charges.length != 0) {
                this.prev_charges = obj.prev_charges;
              }
              console.log(this.prev_charges);
              var saldata;
              saldata = obj.salutation != undefined ? obj.salutation : '';
              this.hospitalName = obj.hosp_name;
              if (
                obj.middle_name != null &&
                obj.middle_name != '' &&
                obj.middle_name != undefined
              ) {
                this.middleName = encrypt_decript.Decript(obj.middle_name);
              }
              if (obj.middle_name != undefined && obj.middle_name != '') {
                this.patientName =
                  saldata +
                  ' ' +
                  encrypt_decript.Decript(obj.first_name) +
                  ' ' +
                  this.middleName +
                  ' ' +
                  encrypt_decript.Decript(obj.last_name);
              } else {
                this.patientName =
                  saldata +
                  ' ' +
                  encrypt_decript.Decript(obj.first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.last_name);
              }

              if (obj.phases != null) {
                for (var i = 0; i < obj.phases.length; i++) {
                  this.billTreatmentPlan.push({
                    treatment_desc: obj.phases[i].treatment_desc,
                    estimate: obj.phases[i].estimate,
                    den_treatment_plan_id: obj.phases[i].den_treatment_plan_id,
                  });
                }
              }

              if (obj.advance != null)
                this.advanceBill = parseFloat(obj.advance);

              if (obj.prev_bill_id != null) {
                this.prevBillId = obj.prev_bill_id;
              } else {
                this.prevBillId = undefined;
              }

              if (obj.balance != '0') {
                this.amountCollected = true;
                this.showBalanceFlag = false;
                this.showPrevBalanceFlag = false;
                this.prevBalance = parseFloat(obj.balance);
                this.finalAmount = parseFloat(obj.balance);
                this.totalBillAmount = parseFloat(obj.balance);
                this.totalCollected = parseFloat(obj.balance);
              } else {
                this.amountCollected = false;
                this.showBalanceFlag = true;
                this.showPrevBalanceFlag = true;
                this.prevBalance = parseFloat(obj.balance);
                this.finalAmount = parseFloat(obj.balance);
                this.totalBillAmount = parseFloat(obj.balance);
                this.totalCollected = parseFloat(obj.balance);
              }
              this.getBillType();
            }


            var paydesc;
            if (this.payTypeList.length != 0) {
              for (var i = 0; i < this.payTypeList.length; i++) {
                if (this.payType == this.payTypeList[i].pay_id) {
                  paydesc = this.payTypeList[i].pay_desc;
                }
              }
            }

            if (obj.tests != undefined && obj.tests.length != 0) {
              if (obj.pres_diag_id != null && obj.pres_diag_id != undefined) {
                this.pres_diag_id = obj.pres_diag_id;
              }
              this.testArray = [];
              this.addBillArray = [];
              this.diagFinalAmount = 0;
              if (obj.lab_test != null) {
                for (var i = 0; i < obj.lab_test.length; i++) {
                  if (this.paytypreq == true) {
                    this.addBillArray.push({
                      test_id: obj.lab_test[i].diag_test_id,
                      test_name: obj.lab_test[i].diag_test_name,
                      type_of_test: "test",
                      fee: this.getamountAftCon1(obj.lab_test[i].cost),
                      quantity: this.quantity,
                      concession: this.concession,
                      amount_txt: parseInt(obj.lab_test[i].cost),
                      total: (parseFloat(this.quantity) * parseFloat(obj.lab_test[i].cost)),
                      insurer: this.insurerId,
                      ref_code: this.transId,
                      type: 'diagnosis',
                      edit_flag: true
                    });
                  } else {
                    this.addBillArray.push({
                      test_id: obj.lab_test[i].diag_test_id,
                      test_name: obj.lab_test[i].diag_test_name,
                      type_of_test: "test",
                      fee: this.getamountAftCon1(obj.lab_test[i].cost),
                      quantity: this.quantity,
                      concession: this.concession,
                      amount_txt: parseFloat(obj.lab_test[i].cost),
                      total: (parseFloat(this.quantity) * parseFloat(obj.lab_test[i].cost)),
                      pay_type: paydesc,
                      paytype: this.payType,
                      insurer: this.insurerId,
                      ref_code: this.transId,
                      type: 'diagnosis',
                      edit_flag: true
                    });
                  }

                  this.addBillArray.forEach(value => this.diagChangeAmount(value, 0));
                }
              }

              if (obj.profile_test != null) {
                for (var i = 0; i < obj.profile_test.length; i++) {
                  if (this.paytypreq == true) {
                    this.addBillArray.push({
                      test_id: obj.profile_test[i].diag_test_id,
                      test_name: obj.profile_test[i].diag_test_name,
                      type_of_test: "profiles",
                      fee: this.getamountAftCon1(obj.profile_test[i].cost),
                      quantity: this.quantity,
                      concession: this.concession,
                      amount_txt: parseFloat(obj.profile_test[i].cost),
                      total: (parseFloat(this.quantity) * parseFloat(obj.profile_test[i].cost)),
                      insurer: this.insurerId,
                      ref_code: this.transId,
                      type: 'diagnosis',
                      edit_flag: true
                    });
                  } else {
                    this.addBillArray.push({
                      test_id: obj.profile_test[i].diag_test_id,
                      test_name: obj.profile_test[i].diag_test_name,
                      type_of_test: "profiles",
                      fee: this.getamountAftCon1(obj.profile_test[i].cost),
                      quantity: this.quantity,
                      concession: this.concession,
                      amount_txt: parseFloat(obj.profile_test[i].cost),
                      total: (parseFloat(this.quantity) * parseFloat(obj.profile_test[i].cost)),
                      pay_type: paydesc,
                      paytype: this.payType,
                      insurer: this.insurerId,
                      ref_code: this.transId,
                      type: 'diagnosis',
                      edit_flag: true
                    });
                  }

                  this.addBillArray.forEach(value => this.diagChangeAmount(value, 0));
                }
              }

              if (obj.radio_test != null) {
                for (var i = 0; i < obj.radio_test.length; i++) {
                  if (this.paytypreq == true) {
                    this.addBillArray.push({
                      test_id: obj.radio_test[i].diag_test_id,
                      test_name: obj.radio_test[i].diag_test_name,
                      type_of_test: "Radiology",
                      fee: this.getamountAftCon1(obj.radio_test[i].cost),
                      quantity: this.quantity,
                      concession: this.concession,
                      amount_txt: parseFloat(obj.radio_test[i].cost),
                      total: (parseFloat(this.quantity) * parseFloat(obj.radio_test[i].cost)),
                      insurer: this.insurerId,
                      ref_code: this.transId,
                      type: 'diagnosis',
                      edit_flag: true
                    });
                  } else {
                    this.addBillArray.push({
                      test_id: obj.radio_test[i].diag_test_id,
                      test_name: obj.radio_test[i].diag_test_name,
                      type_of_test: "Radiology",
                      fee: this.getamountAftCon1(obj.radio_test[i].cost),
                      quantity: this.quantity,
                      concession: this.concession,
                      amount_txt: parseFloat(obj.radio_test[i].cost),
                      total: (parseFloat(this.quantity) * parseFloat(obj.radio_test[i].cost)),
                      pay_type: paydesc,
                      paytype: this.payType,
                      insurer: this.insurerId,
                      ref_code: this.transId,
                      type: 'diagnosis',
                      edit_flag: true
                    });
                  }
                  this.addBillArray.forEach(value => this.diagChangeAmount(value, 0));
                }
              }

              if (this.addBillArray.length != 0) {
                for (var i = 0; i < this.addBillArray.length; i++) {
                  this.addBillArray[i].sno = i + 1;
                  this.addBillArray[i].biill_type = this.addBillArray[i].test_name;
                  this.addBillArray[i].charge_type = this.addBillArray[i].test_name;
                }
              }
              this.calculateGst('0', '0');
              this.calculateDiagGst('0', '0');
            }

            if (this.packages != undefined && this.packages.length != 0) {
              this.addPackageBillArray = [];
              for (var i = 0; i < this.packages.length; i++) {
                if (this.paytypreq == true) {
                  this.addPackageBillArray.push({
                    charge_type: this.packages[i].health_pkg_id,
                    biill_type: this.packages[i].package_name,
                    amount_txt: parseFloat(this.packages[i].price),
                    concession: this.concession,
                    fee: this.getamountAftCon1(this.packages[i].price),
                    quantity: this.quantity,
                    total: (parseFloat(this.quantity) * parseFloat(this.packages[i].price)),
                    insurer: this.insurerId,
                    ref_code: this.transId,
                    type: 'package',
                    edit_flag: true
                  })
                } else {
                  this.addPackageBillArray.push({
                    charge_type: this.packages[i].health_pkg_id,
                    biill_type: this.packages[i].package_name,
                    amount_txt: parseFloat(this.packages[i].price),
                    concession: this.concession,
                    fee: this.getamountAftCon1(this.packages[i].price),
                    quantity: this.quantity,
                    total: (parseFloat(this.quantity) * parseFloat(this.packages[i].price)),
                    pay_type: paydesc,
                    paytype: this.payType,
                    insurer: this.insurerId,
                    ref_code: this.transId,
                    type: 'package',
                    edit_flag: true
                  })
                }
              }

              if (this.addPackageBillArray.length != 0) {
                for (var i = 0; i < this.addPackageBillArray.length; i++) {
                  this.addPackageBillArray[i].sno = i + 1;
                }
              }
              this.calculateGst('0', '0');
              this.calculatePackageGst('0', '0');
            }

            setTimeout(() => {
              this.getTableHeading();
              if (this.doc_apps != undefined && this.billAddList.length === 0) {
                console.log(this.doc_apps);
                this.calculateGst('0', '0');
                // this.addNewBillDetails();
              }
            }, 500)
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  getTableHeading() {
    if (this.billAddList.length != 0 && this.addBillArray.length != 0 && this.addPackageBillArray.length != 0) {
      this.heading_name = "Service/Test/Package";
    } else if ((this.billAddList.length == 0 && this.addBillArray.length == 0 && this.addPackageBillArray.length == 0)) {
      this.heading_name = "Service";
    } else if (this.billAddList.length != 0 && this.addBillArray.length == 0 && this.addPackageBillArray.length == 0) {
      this.heading_name = "Service";
    } else if (this.billAddList.length != 0 && this.addBillArray.length != 0 && this.addPackageBillArray.length == 0) {
      this.heading_name = "Service/Test";
    } else if (this.billAddList.length != 0 && this.addBillArray.length == 0 && this.addPackageBillArray.length != 0) {
      this.heading_name = "Service/Package";
    } else if (this.billAddList.length == 0 && this.addBillArray.length != 0 && this.addPackageBillArray.length == 0) {
      this.heading_name = "Test";
    } else if (this.billAddList.length == 0 && this.addBillArray.length != 0 && this.addPackageBillArray.length != 0) {
      this.heading_name = "Test/Package";
    } else if (this.billAddList.length == 0 && this.addBillArray.length == 0 && this.addPackageBillArray.length != 0) {
      this.heading_name = "Package";
    }
  }

  changeAmountPath(charge, num) {
    if (charge.type == 'hospital') {
      this.changeamount1(charge, num);
    }
    if (charge.type == 'diagnosis') {
      this.diagChangeAmount(charge, num);
    }
    if (charge.type == 'package') {
      this.changePackageAmount(charge, num);
    }
  }

  changeamount1(charge, num) {
    for (var i = 0; i < this.billAddList.length; i++) {
      if (charge.biill_type == this.billAddList[i].biill_type) {
        if (num != 2) {
          var concec;
          if (charge.concession == 'NaN') {
            charge.concession = 0;
            concec = 0;
          } else {
            concec = charge.concession;
          }
          charge.fee =
            charge.quantity *
            (charge.amount_txt - charge.amount_txt * (concec / 100));
          charge.fee = parseFloat(charge.fee);

          charge.total = charge.quantity * charge.amount_txt;
          charge.total = parseFloat(charge.total);
        } else {
          var fee;
          if (charge.fee == 'NaN') {
            charge.fee = 0;
            fee = 0;
          } else {
            fee = charge.fee;
          }

          if (charge.amount_txt != 0 && charge.quantity != 0 && charge.fee != '') {
            charge.concession = (100 - ((fee / (charge.amount_txt * charge.quantity)) * 100));
          } else {
            charge.concession = 0;
          }
        }
      }
    }
    this.calculateGst('0', num);
    this.calculateHospitalGst('0', '0');
  }

  diagChangeAmount(charge, num) {
    for (var i = 0; i < this.addBillArray.length; i++) {
      if (charge.test_name == this.addBillArray[i].test_name) {
        if (num != 2) {
          var concec;
          if (charge.concession == 'NaN') {
            charge.concession = 0;
            concec = 0;
          } else {
            concec = charge.concession;
          }
          charge.fee = (charge.quantity * (charge.amount_txt - charge.amount_txt * (concec / 100)));
          charge.fee = parseFloat(charge.fee);

          charge.total = (charge.quantity * charge.amount_txt);
          charge.total = parseFloat(charge.total);
        } else {
          var fee;
          if (charge.fee == 'NaN') {
            charge.fee = 0;
            fee = 0;
          } else {
            fee = charge.fee;
          }

          if (charge.amount_txt != 0 && charge.quantity != 0 && charge.fee != '') {
            charge.concession = (100 - ((fee / (charge.amount_txt * charge.quantity)) * 100));
          } else {
            charge.concession = 0;
          }
        }
      }
    }
    this.calculateGst("0", num);
    this.calculateDiagGst('0', '0');
  }

  changePackageAmount(charge, num) {
    for (var i = 0; i < this.addPackageBillArray.length; i++) {
      if (charge.biill_type == this.addPackageBillArray[i].biill_type) {
        if (num != 2) {
          var concec;
          if (charge.concession == 'NaN') {
            charge.concession = 0;
            concec = 0;
          } else {
            concec = charge.concession;
          }
          charge.fee =
            charge.quantity *
            (charge.amount_txt - charge.amount_txt * (concec / 100));
          charge.fee = parseFloat(charge.fee);
        } else {
          var fee;
          if (charge.fee == 'NaN') {
            charge.fee = 0;
            fee = 0;
          } else {
            fee = charge.fee;
          }

          if (charge.amount_txt != 0 && charge.quantity != 0 && charge.fee != '') {
            charge.concession = (100 - ((fee / (charge.amount_txt * charge.quantity)) * 100));
          } else {
            charge.concession = 0;
          }
        }
      }
    }
    this.calculateGst("0", num);
    this.calculatePackageGst('0', '0');
  }

  getGstData() {
    //Get gst
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .get(ipaddress.getIp.toString() + 'gen/tax/', { headers: headers })
      .subscribe(
        (response) => {
          var obj = response.json();
          this.gstRetriveList = obj.taxes;
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  includeGst(gst_click) {
    // Include GST
    this.calculateGst('0', '0');
    if (this.billAddList != undefined && this.billAddList.length != 0)
      this.calculateHospitalGst('0', '0');
    if (this.addBillArray != undefined && this.addBillArray.length != 0)
      this.calculateDiagGst('0', '0');
    if (this.addPackageBillArray != undefined && this.addPackageBillArray.length != 0)
      this.calculatePackageGst('0', '0');
  }

  calculateHospitalGst(gst_click, concession) {
    this.hospital_finalAmount = 0;
    this.hospital_totalBillAmount = 0;
    this.hospital_discount_amount = 0;
    this.hospital_gstAmount = 0;
    this.hospital_totalCollected = 0;
    this.hospital_gstDataList = [];

    if (this.billAddList.length != 0) {
      this.inpatientEdit = true;
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].fee == '') {
          this.billAddList[i].fee = 0;
        }
        if (this.billAddList[i].amount_txt == '') {
          this.billAddList[i].amount_txt = 0;
        }
        if (this.billAddList[i].quantity == '') {
          this.billAddList[i].quantity = 0;
        }
        if (this.billAddList[i].concession == '') {
          this.billAddList[i].concession = 0;
        }

        this.hospital_discount_amount = (
          parseFloat(this.hospital_discount_amount) +
          parseFloat(this.billAddList[i].amount_txt) *
          parseFloat(this.billAddList[i].quantity) *
          (parseFloat(this.billAddList[i].concession) / 100)
        );
        this.hospital_finalAmount = (
          parseFloat(this.hospital_finalAmount) + parseFloat(this.billAddList[i].fee)
        );

        this.hospital_totalBillAmount = (
          parseFloat(this.hospital_totalBillAmount) +
          parseFloat(this.billAddList[i].amount_txt) *
          parseFloat(this.billAddList[i].quantity)
        );
        this.hospital_gstAmount = (
          parseFloat(this.hospital_gstAmount) + parseFloat(this.billAddList[i].fee)
        );

        if (parseFloat(this.advanceBill) < parseFloat(this.hospital_finalAmount)) {
          this.showBalanceFlag = false;
          this.hospital_remainingBalance = (
            parseFloat(this.hospital_finalAmount) - parseFloat(this.advanceBill)
          );
        }

        if (parseFloat(this.advanceBill) > parseFloat(this.hospital_finalAmount)) {
          this.showBalanceFlag = false;
          this.hospital_remainingBalance = (
            parseFloat(this.advanceBill) - parseFloat(this.hospital_finalAmount)
          );
          this.hospital_totalCollected = 0;
        }

        if (this.hospital_totalCollected == 0) this.amountCollected = false;

        if (this.hospital_remainingBalance == 0) this.showBalanceFlag = true;

      }
      this.hospital_discount_amount = parseInt(this.hospital_discount_amount);
      if (this.inGST == false) {
        this.hospital_finalAmount = Math.round(parseFloat(this.hospital_finalAmount));
      }

      this.hospital_finalAmount = parseFloat(this.hospital_finalAmount);
    }

    for (var i = 0; i < this.billAddList.length; i++) {
      if (
        this.billAddList[i].fee == 0 &&
        this.billAddList[i].biill_type == ''
      ) {
        this.billAddList[i].fee = '';
      }
      if (this.billAddList[i].amount_txt == 0 && this.billAddList[i].biill_type == '') {
        this.billAddList[i].amount_txt = '';
      }
      if (this.billAddList[i].quantity == 0 && (this.billAddList[i].biill_type == '' || this.billAddList[i].biill_type != '')) {
        this.billAddList[i].quantity = '';
      }
      if (this.billAddList[i].total == 0 && this.billAddList[i].biill_type == '') {
        this.billAddList[i].total = '';
      }
      if (this.billAddList[i].biill_type == '') {
        this.billAddList[i].concession = '';
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gstRetriveList.length; i++) {
        this.hospital_gstDataList.push({
          Gst_Descrip:
            this.gstRetriveList[i].tax_desc + ' ' + '(' + parseInt(this.gstRetriveList[i].tax) + ' %' + ')',
          Gst_amount: Math.round(
            parseFloat(this.hospital_gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100)
          ),
        });

        this.hospital_finalAmount = (parseFloat(this.hospital_finalAmount) +
          Math.round(parseFloat(this.hospital_gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100)));

        this.hospital_totalBillAmount =
          parseFloat(this.hospital_totalBillAmount) +
          Math.round(parseFloat(this.hospital_gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100));
      }
      this.hospital_finalAmount = parseFloat(this.hospital_finalAmount);
      this.hospital_totalBillAmount = parseFloat(this.hospital_totalBillAmount);
    }

    if (this.prevBalance != undefined) {
      this.hospital_finalAmount = (
        parseFloat(this.hospital_finalAmount) + parseFloat(this.prevBalance)
      );
      this.hospital_totalBillAmount = (
        parseFloat(this.hospital_totalBillAmount) + parseFloat(this.prevBalance)
      );
    }

    //amount to be collected
    this.hospital_remainingBalance = (
      parseFloat(this.hospital_finalAmount) - parseFloat(this.advanceBill)
    );
    if (this.estimateId.length == 0 && this.billAddList.length == 0) {
      this.inGST = false;
      this.hospital_gstDataList = [];
      this.advanceBill = 0;
      this.hospital_finalAmount = 0;
      this.hospital_totalBillAmount = 0;
      this.hospital_gstAmount = 0;
      this.hospital_totalCollected = 0;
      this.hospital_remainingBalance = 0;
    }

    if (parseFloat(this.advanceBill) > parseFloat(this.hospital_finalAmount)) {
      this.hospital_remainingBalance = 0;
    }

    this.paidCalculation();
    console.log("HospitalTotalAmount", this.hospital_totalBillAmount);
    console.log("HospitalDiscountAmount", this.hospital_discount_amount);
    console.log("HospitalFinalAmount", this.hospital_finalAmount);
    console.log("HospitalRemainingBalance", this.hospital_remainingBalance);
    console.log("HospitalGstDataList", this.hospital_gstDataList);
  }

  calculateDiagGst(gst_click, planmodal) {
    this.diag_finalAmount = 0;
    this.diag_totalBillAmount = 0;
    this.diag_discount_amount = 0;
    this.diag_gstAmount = 0;
    this.diag_totalCollected = 0;
    this.diag_gstDataList = [];

    if (this.addBillArray.length != 0) {
      this.inpatientEdit = true;
      for (var i = 0; i < this.addBillArray.length; i++) {
        if (this.addBillArray[i].fee == '') {
          this.addBillArray[i].fee = 0;
        }
        if (this.addBillArray[i].amount_txt == '') {
          this.addBillArray[i].amount_txt = 0;
        }
        if (this.addBillArray[i].quantity == '') {
          this.addBillArray[i].quantity = 0;
        }
        if (this.addBillArray[i].concession == '') {
          this.addBillArray[i].concession = 0;
        }

        this.diag_discount_amount = (
          parseFloat(this.diag_discount_amount) +
          parseFloat(this.addBillArray[i].amount_txt) *
          parseFloat(this.addBillArray[i].quantity) *
          (parseFloat(this.addBillArray[i].concession) / 100)
        );
        this.diag_finalAmount = (
          parseFloat(this.diag_finalAmount) + parseFloat(this.addBillArray[i].fee)
        );

        this.diag_totalBillAmount = (
          parseFloat(this.diag_totalBillAmount) +
          parseFloat(this.addBillArray[i].amount_txt) *
          parseFloat(this.addBillArray[i].quantity)
        );
        this.diag_gstAmount = (
          parseFloat(this.diag_gstAmount) + parseFloat(this.addBillArray[i].fee)
        );

        if (parseFloat(this.advanceBill) < parseFloat(this.diag_finalAmount)) {
          this.showBalanceFlag = false;
          this.diag_remainingBalance = (
            parseFloat(this.diag_finalAmount) - parseFloat(this.advanceBill)
          );
        }

        if (parseFloat(this.advanceBill) > parseFloat(this.diag_finalAmount)) {
          this.showBalanceFlag = false;
          this.diag_remainingBalance = (
            parseFloat(this.advanceBill) - parseFloat(this.diag_finalAmount)
          );
          this.diag_totalCollected = 0;
        }

        if (this.diag_totalCollected == 0) this.amountCollected = false;

        if (this.diag_remainingBalance == 0) this.showBalanceFlag = true;

      }
      this.diag_discount_amount = parseInt(this.diag_discount_amount);
      if (this.inGST == false) {
        this.diag_finalAmount = Math.round(parseFloat(this.diag_finalAmount));
      }

      this.diag_finalAmount = parseFloat(this.diag_finalAmount);
    }

    for (var i = 0; i < this.addBillArray.length; i++) {
      if (
        this.addBillArray[i].fee == 0 &&
        this.addBillArray[i].test_name == ''
      ) {
        this.addBillArray[i].fee = '';
      }
      if (this.addBillArray[i].amount_txt == 0 && this.addBillArray[i].test_name == '') {
        this.addBillArray[i].amount_txt = '';
      }
      if (this.addBillArray[i].quantity == 0 && (this.addBillArray[i].test_name == '' || this.addBillArray[i].test_name != '')) {
        this.addBillArray[i].quantity = '';
      }
      if (this.addBillArray[i].total == 0 && this.addBillArray[i].test_name == '') {
        this.addBillArray[i].total = '';
      }
      if (this.addBillArray[i].test_name == '') {
        this.addBillArray[i].concession = '';
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gstRetriveList.length; i++) {
        this.diag_gstDataList.push({
          Gst_Descrip:
            this.gstRetriveList[i].tax_desc + ' ' + '(' + parseInt(this.gstRetriveList[i].tax) + ' %' + ')',
          Gst_amount: Math.round(
            parseFloat(this.diag_gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100)
          ),
        });


        this.diag_finalAmount = (parseFloat(this.diag_finalAmount) +
          Math.round(parseFloat(this.diag_gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100)));


        this.diag_totalBillAmount =
          parseFloat(this.diag_totalBillAmount) + Math.round(parseFloat(this.diag_gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100));
      }
      this.diag_finalAmount = parseFloat(this.diag_finalAmount);
      this.diag_totalBillAmount = parseFloat(this.diag_totalBillAmount);
    }

    if (this.prevBalance != undefined) {
      this.diag_finalAmount = (
        parseFloat(this.diag_finalAmount) + parseFloat(this.prevBalance)
      );
      this.diag_totalBillAmount = (
        parseFloat(this.diag_totalBillAmount) + parseFloat(this.prevBalance)
      );
    }

    //amount to be collected
    this.diag_remainingBalance = (
      parseFloat(this.diag_finalAmount) - parseFloat(this.advanceBill)
    );
    if (this.estimateId.length == 0 && this.addBillArray.length == 0) {
      this.inGST = false;
      this.diag_gstDataList = [];
      this.advanceBill = 0;
      this.diag_finalAmount = 0;
      this.diag_totalBillAmount = 0;
      this.diag_gstAmount = 0;
      this.diag_totalCollected = 0;
      this.diag_remainingBalance = 0;
    }

    if (parseFloat(this.advanceBill) > parseFloat(this.diag_finalAmount)) {
      this.diag_remainingBalance = 0;
    }

    this.paidCalculation();
    console.log("DiagTotalAmount", this.diag_totalBillAmount);
    console.log("DiagDiscountAmount", this.diag_discount_amount);
    console.log("DiagFinalAmount", this.diag_finalAmount);
    console.log("DiagRemainingBalance", this.diag_remainingBalance);
    console.log("DiagGstDataList", this.diag_gstDataList);
  }

  calculatePackageGst(gst_click, planmodal) {
    this.package_finalAmount = 0;
    this.package_totalBillAmount = 0;
    this.package_discount_amount = 0;
    this.package_gstAmount = 0;
    this.package_totalCollected = 0;
    this.package_gstDataList = [];

    if (this.addPackageBillArray.length != 0) {
      this.inpatientEdit = true;
      for (var i = 0; i < this.addPackageBillArray.length; i++) {
        if (this.addPackageBillArray[i].fee == '') {
          this.addPackageBillArray[i].fee = 0;
        }
        if (this.addPackageBillArray[i].amount_txt == '') {
          this.addPackageBillArray[i].amount_txt = 0;
        }
        if (this.addPackageBillArray[i].quantity == '') {
          this.addPackageBillArray[i].quantity = 0;
        }
        if (this.addPackageBillArray[i].concession == '') {
          this.addPackageBillArray[i].concession = 0;
        }

        this.package_discount_amount = (
          parseFloat(this.package_discount_amount) +
          parseFloat(this.addPackageBillArray[i].amount_txt) *
          parseFloat(this.addPackageBillArray[i].quantity) *
          (parseFloat(this.addPackageBillArray[i].concession) / 100)
        );
        this.package_finalAmount = (
          parseFloat(this.package_finalAmount) + parseFloat(this.addPackageBillArray[i].fee)
        );

        this.package_totalBillAmount = (
          parseFloat(this.package_totalBillAmount) +
          parseFloat(this.addPackageBillArray[i].amount_txt) *
          parseFloat(this.addPackageBillArray[i].quantity)
        );
        this.package_gstAmount = (
          parseFloat(this.package_gstAmount) + parseFloat(this.addPackageBillArray[i].fee)
        );

        if (parseFloat(this.advanceBill) < parseFloat(this.package_finalAmount)) {
          this.showBalanceFlag = false;
          this.package_remainingBalance = (
            parseFloat(this.package_finalAmount) - parseFloat(this.advanceBill)
          );
        }

        if (parseFloat(this.advanceBill) > parseFloat(this.package_finalAmount)) {
          this.showBalanceFlag = false;
          this.package_remainingBalance = (
            parseFloat(this.advanceBill) - parseFloat(this.package_finalAmount)
          );
          this.package_totalCollected = 0;
        }

        if (this.package_totalCollected == 0) this.amountCollected = false;

        if (this.package_remainingBalance == 0) this.showBalanceFlag = true;

      }
      this.package_discount_amount = parseInt(this.package_discount_amount);
      if (this.inGST == false) {
        this.package_finalAmount = Math.round(parseFloat(this.package_finalAmount));
      }
      this.package_finalAmount = parseFloat(this.package_finalAmount);
    }

    for (var i = 0; i < this.addPackageBillArray.length; i++) {
      if (
        this.addPackageBillArray[i].fee == 0 &&
        this.addPackageBillArray[i].biill_type == ''
      ) {
        this.addPackageBillArray[i].fee = '';
      }

      if (this.addPackageBillArray[i].amount_txt == 0 && this.addBillArray[i].biill_type == '') {
        this.addPackageBillArray[i].amount_txt = '';
      }

      if (this.addPackageBillArray[i].quantity == 0 && (this.addBillArray[i].biill_type == '' || this.addBillArray[i].biill_type != '')) {
        this.addPackageBillArray[i].quantity = '';
      }

      if (this.addPackageBillArray[i].total == 0 && this.addBillArray[i].biill_type == '') {
        this.addPackageBillArray[i].total = '';
      }
      if (this.addPackageBillArray[i].biill_type == '') {
        this.addPackageBillArray[i].concession = '';
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gstRetriveList.length; i++) {
        this.package_gstDataList.push({
          Gst_Descrip:
            this.gstRetriveList[i].tax_desc + ' ' + '(' + parseInt(this.gstRetriveList[i].tax) + ' %' + ')',
          Gst_amount: Math.round(
            parseFloat(this.package_gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100)
          ),
        });

        this.package_finalAmount = (parseFloat(this.package_finalAmount) +
          Math.round(parseFloat(this.package_gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100)));

        this.package_totalBillAmount =
          parseFloat(this.package_totalBillAmount) + Math.round(parseFloat(this.package_gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100));
      }
      this.package_finalAmount = parseFloat(this.package_finalAmount);
      this.package_totalBillAmount = parseFloat(this.package_totalBillAmount);
    }

    if (this.prevBalance != undefined) {
      this.package_finalAmount = (
        parseFloat(this.package_finalAmount) + parseFloat(this.prevBalance)
      );
      this.package_totalBillAmount = (
        parseFloat(this.package_totalBillAmount) + parseFloat(this.prevBalance)
      );
    }

    //amount to be collected
    this.package_remainingBalance = (
      parseFloat(this.package_finalAmount) - parseFloat(this.advanceBill)
    );
    if (this.estimateId.length == 0 && this.addPackageBillArray.length == 0) {
      this.inGST = false;
      this.package_gstDataList = [];
      this.advanceBill = 0;
      this.package_finalAmount = 0;
      this.package_totalBillAmount = 0;
      this.package_gstAmount = 0;
      this.package_totalCollected = 0;
      this.package_remainingBalance = 0;
    }

    if (parseFloat(this.advanceBill) > parseFloat(this.package_finalAmount)) {
      this.package_remainingBalance = 0;
    }

    this.paidCalculation();
    console.log("PackageTotalAmount", this.package_totalBillAmount);
    console.log("PackageDiscountAmount", this.package_discount_amount);
    console.log("PackageFinalAmount", this.package_finalAmount);
    console.log("PackageRemainingBalance", this.package_remainingBalance);
    console.log("PackageGstDataList", this.package_gstDataList);
  }

  calculateGst(gst_click, num) {
    this.finalAmount = 0;
    this.totalBillAmount = 0;
    if (num != 1) {
      this.discount_amount = 0;
    }
    this.gstAmount = 0;
    this.totalCollected = 0;
    this.gstDataList = [];
    this.totalBillList = [];

    if (this.billAddList != undefined && this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        this.totalBillList.push(this.billAddList[i]);
      }
    }
    if (this.addBillArray != undefined && this.addBillArray.length != 0) {
      for (var i = 0; i < this.addBillArray.length; i++) {
        this.totalBillList.push(this.addBillArray[i]);
      }
    }
    if (this.addPackageBillArray != undefined && this.addPackageBillArray.length != 0) {
      for (var i = 0; i < this.addPackageBillArray.length; i++) {
        this.totalBillList.push(this.addPackageBillArray[i]);
      }
    }
    console.log("TotalBillList", this.totalBillList);

    if (this.totalBillList.length == 0 && this.doc_apps_flag == true) {
      this.addNewBillDetails();
    }

    if (this.totalBillList.length != 0) {
      for (var i = 0; i < this.totalBillList.length; i++) {
        this.totalBillList[i].sno = i + 1;
      }
    }

    if (this.billTreatmentPlan.length != 0) {
      for (var i = 0; i < this.billTreatmentPlan.length; i++) {
        for (var j = 0; j < this.estimateId.length; j++) {
          if (
            this.estimateId[j] ==
            this.billTreatmentPlan[i].den_treatment_plan_id
          ) {
            this.finalAmount = (
              parseFloat(this.finalAmount) +
              parseFloat(this.billTreatmentPlan[i].estimate)
            );
            this.totalBillAmount = (
              parseFloat(this.totalBillAmount) +
              parseFloat(this.billTreatmentPlan[i].estimate)
            );
            this.gstAmount = (
              parseFloat(this.gstAmount) +
              parseFloat(this.billTreatmentPlan[i].estimate)
            );
            if (parseFloat(this.advanceBill) < parseFloat(this.finalAmount)) {
              this.showBalanceFlag = false;
              this.totalCollected = (
                parseFloat(this.finalAmount) - parseFloat(this.advanceBill)
              );
              this.remainingBalance = (
                parseFloat(this.finalAmount) - parseFloat(this.advanceBill)
              );
            }
            if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount)) {
              this.showBalanceFlag = false;
              this.remainingBalance = (
                parseFloat(this.advanceBill) - parseFloat(this.finalAmount)
              );
            }
            this.amountCollected = true;

            if (this.totalCollected == 0)
              this.amountCollected = false;

            if (this.remainingBalance == 0)
              this.showBalanceFlag = true;

            break;
          }
        }
      }
    }

    if (this.totalBillList.length != 0) {
      this.inpatientEdit = true;
      for (var i = 0; i < this.totalBillList.length; i++) {
        if (this.totalBillList[i].fee == '') {
          this.totalBillList[i].fee = 0;
        }
        if (this.totalBillList[i].amount_txt == '') {
          this.totalBillList[i].amount_txt = 0;
        }
        if (this.totalBillList[i].quantity == '') {
          this.totalBillList[i].quantity = 0;
        }
        if (this.totalBillList[i].concession == '') {
          this.totalBillList[i].concession = 0;
        }
        if (num != 1) {
          this.discount_amount = (
            parseFloat(this.discount_amount) +
            parseFloat(this.totalBillList[i].amount_txt) *
            parseFloat(this.totalBillList[i].quantity) *
            (parseFloat(this.totalBillList[i].concession) / 100)
          );
        }

        this.finalAmount = (
          parseFloat(this.finalAmount) + parseFloat(this.totalBillList[i].fee)
        );
        this.totalBillAmount = (
          parseFloat(this.totalBillAmount) +
          parseFloat(this.totalBillList[i].amount_txt) *
          parseFloat(this.totalBillList[i].quantity)
        );

        this.gstAmount = (
          parseFloat(this.gstAmount) + parseFloat(this.totalBillList[i].fee)
        );
        if (parseFloat(this.advanceBill) < parseFloat(this.finalAmount)) {
          this.showBalanceFlag = false;
          this.remainingBalance = (
            parseFloat(this.finalAmount) - parseFloat(this.advanceBill)
          );
        }

        if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount)) {
          this.showBalanceFlag = false;
          this.remainingBalance = (
            parseFloat(this.advanceBill) - parseFloat(this.finalAmount)
          );
          this.totalCollected = 0;
        }

        if (this.totalCollected == 0) this.amountCollected = false;

        if (this.remainingBalance == 0) this.showBalanceFlag = true;

      }
      // this.discount_amount = parseInt(this.discount_amount);
      if (this.discount_amount != '' && this.discount_amount != 0) {
        var floatValue = this.discount_amount;
        var floatString = floatValue.toString();
        var parts = floatString.split('.');
        var digitsAfterDecimal = parts[1] ? parts[1] : 0;
        if (digitsAfterDecimal[0] > 5) {
          this.discount_amount = Math.round(this.discount_amount);
          this.discount_amount = parseInt(this.discount_amount);
        } else {
          this.discount_amount = parseInt(this.discount_amount);
        }
      }
      if (this.inGST == false) {
        this.finalAmount = Math.round(parseFloat(this.finalAmount));
      }

      this.finalAmount = parseFloat(this.finalAmount);
    }
    
    for (var i = 0; i < this.totalBillList.length; i++) {
      if (this.totalBillList[i].fee == 0 && this.totalBillList[i].biill_type == '') {
        this.totalBillList[i].fee = '';
      }

      if (this.totalBillList[i].amount_txt == 0 && this.totalBillList[i].biill_type == '') {
        this.totalBillList[i].amount_txt = '';
      }

      if (this.totalBillList[i].quantity == 0 && (this.totalBillList[i].biill_type == '' || this.totalBillList[i].biill_type != '')) {
        this.totalBillList[i].quantity = '';
      }
      if (this.totalBillList[i].total == 0 && this.totalBillList[i].biill_type == '') {
        this.totalBillList[i].total = '';
      }
      if (this.totalBillList[i].biill_type == '') {
        this.totalBillList[i].concession = '';
      }
      if (this.totalBillList[i].concession != '') {
        var floatValue = this.totalBillList[i].concession;
        var floatString = floatValue.toString();
        var parts = floatString.split('.');
        var digitsAfterDecimal = parts[1] ? parts[1].length : 0;

        if (digitsAfterDecimal === 2) {
          // console.log("Value has 2 digits after the decimal point.");
        } else if (digitsAfterDecimal === 3) {
          this.totalBillList[i].concession = parseFloat(this.totalBillList[i].concession);
          // console.log("Value has 3 digits after the decimal point.");
        } else {
          // console.log("Value doesn't have 2 or 3 digits after the decimal point.");
        }
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gstRetriveList.length; i++) {
        this.gstDataList.push({
          Gst_Descrip:
            this.gstRetriveList[i].tax_desc + ' ' + '(' + parseInt(this.gstRetriveList[i].tax) + ' %' + ')',
          Gst_amount: Math.round(
            parseFloat(this.gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100)
          ),
        });

        this.finalAmount = (parseFloat(this.finalAmount) +
          Math.round(parseFloat(this.gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100)));

        this.totalBillAmount =
          (parseFloat(this.totalBillAmount) + Math.round(parseFloat(this.gstAmount) *
            (parseFloat(this.gstRetriveList[i].tax) / 100))
          );
      }
      this.finalAmount = Math.round(parseFloat(this.finalAmount));
      this.totalBillAmount = parseFloat(this.totalBillAmount);
    }

    if (this.prevBalance != undefined) {
      this.finalAmount = (
        parseFloat(this.finalAmount) + parseFloat(this.prevBalance)
      );
      this.totalBillAmount = (
        parseFloat(this.totalBillAmount) + parseFloat(this.prevBalance)
      );
    }

    //amount to be collected
    this.remainingBalance = (
      parseFloat(this.finalAmount) - parseFloat(this.advanceBill)
    );
    if (this.estimateId.length == 0 && this.totalBillList.length == 0) {
      this.inGST = false;
      this.gstDataList = [];
      this.advanceBill = 0;
      this.finalAmount = 0;
      this.totalBillAmount = 0;
      this.gstAmount = 0;
      this.totalCollected = 0;
      this.remainingBalance = 0;
    }

    if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount)) {
      this.remainingBalance = 0;
    }

    this.changeAdvance();
    setTimeout(() => {
      this.changePaid();
    }, 500);
  }

  changeDiscount() {
    if (this.discount_amount != '') {
      this.finalAmount = 0;
      this.totalBillAmount = 0;
      if (this.totalBillList.length != 0) {
        for (var i = 0; i < this.totalBillList.length; i++) {
          if (this.totalBillList[i].fee == '') {
            this.totalBillList[i].fee = 0;
          }
          if (this.totalBillList[i].amount_txt == '') {
            this.totalBillList[i].amount_txt = 0;
          }
          if (this.totalBillList[i].quantity == '') {
            this.totalBillList[i].quantity = 0;
          }
          if (this.totalBillList[i].concession == '') {
            this.totalBillList[i].concession = 0;
          }

          this.finalAmount = (
            parseFloat(this.finalAmount) + parseFloat(this.totalBillList[i].amount_txt)
          );
          this.totalBillAmount = (
            parseFloat(this.totalBillAmount) +
            parseFloat(this.totalBillList[i].amount_txt) *
            parseFloat(this.totalBillList[i].quantity)
          );
        }
      }

      if (this.discount_amount <= this.finalAmount) {
        this.finalAmount = (parseFloat(this.finalAmount) - parseFloat(this.discount_amount));
        if (this.inGST == false) {
          this.finalAmount = Math.round(parseFloat(this.finalAmount));
        }
        this.finalAmount = parseFloat(this.finalAmount);
      }
      this.calculatePercentage();
    }

    if (this.discount_amount == '') {
      this.discount_amount = 0;
    }
  }

  calculatePercentage() {
    if (this.totalBillAmount && this.discount_amount) {
      this.concession = ((this.discount_amount / this.totalBillAmount) * 100).toFixed(3);
    } else {
      this.concession = 0;
    }

    if (this.concession != '') {
      if (this.billAddList != undefined && this.billAddList.length != 0) {
        for (var i = 0; i < this.billAddList.length; i++) {
          this.billAddList[i].concession = this.concession;
          this.changeamount1(this.billAddList[i], 1);
        }
      }
      if (this.addBillArray != undefined && this.addBillArray.length != 0) {
        for (var i = 0; i < this.addBillArray.length; i++) {
          this.addBillArray[i].concession = this.concession;
          this.diagChangeAmount(this.addBillArray[i], 1);
        }
      }
      if (this.addPackageBillArray != undefined && this.addPackageBillArray.length != 0) {
        for (var i = 0; i < this.addPackageBillArray.length; i++) {
          this.addPackageBillArray[i].concession = this.concession;
          this.changePackageAmount(this.addPackageBillArray[i], 1);
        }
      }
      console.log("Calculated_Concession", this.concession);
    }
  }

  changeAdvance() {
    //add the bill details
    this.advanceBill = this.advanceBill == '' ? 0 : this.advanceBill;
    this.remainingBalance = (
      parseFloat(this.finalAmount) -
      parseFloat(this.discount) -
      (parseFloat(this.paidBill) + parseFloat(this.advanceBill))
    );
  }

  addRound(data) {
    this.sign = data;
    this.discount = this.discount == '' ? 0 : this.discount;
    if (data == 'puls') {
      this.remainingBalance = (
        parseFloat(this.finalAmount) +
        parseFloat(this.discount) -
        (parseFloat(this.paidBill) + parseFloat(this.advanceBill))
      );
    } else {
      this.remainingBalance = (
        parseFloat(this.finalAmount) -
        parseFloat(this.discount) -
        (parseFloat(this.paidBill) + parseFloat(this.advanceBill))
      );
    }
    this.tempRoundoff = this.discount;
  }

  changePaid() { //add the bill details
    if (this.paymentsarray.length == 0) {
      this.payType1 = this.payTypeList[0].pay_id;
    } else {
      this.paymentsarray_total = 0;
      for (var i = 0; i < this.paymentsarray.length; i++) {
        this.paymentsarray_total = (parseFloat(this.paymentsarray_total) + parseFloat(this.paymentsarray[i].amount));
      }
      console.log('PaymentsArrayTotal' + this.paymentsarray_total + " " + 'FinalAmount' + this.finalAmount);
      if (this.paymentsarray_total > this.finalAmount) {
        this.paymentsarray = [];
        this.payType1 = this.payTypeList[0].pay_id;
      }
    }
    this.paidBill = this.paidBill == '' ? 0 : this.paidBill;
    this.remainingBalance = (
      parseFloat(this.finalAmount) -
      parseFloat(this.discount) -
      (parseFloat(this.paidBill) + parseFloat(this.advanceBill))
    );
    if (parseFloat(this.paidBill) > this.finalAmount) {
      this.amountval = parseFloat(this.finalAmount);
      this.numToWords = convertNumberToWords(
        this.finalAmount,
        ipaddress.country_code
      );
    } else {
      this.amountval = parseFloat(this.paidBill);
      this.numToWords = convertNumberToWords(
        this.paidBill,
        ipaddress.country_code
      );
    }
    this.paidCalculation();
    if (this.paidBill != undefined && this.paidBill != "") {
      if (parseFloat(this.paidBill) > parseFloat(this.finalAmount)) {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.finalAmount);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.finalAmount)) {
            this.amountval = parseFloat(this.finalAmount);
          } else {
            this.amountval = (parseFloat(this.finalAmount) - payArr_Amt);
          }
        }
      } else {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.paidBill);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.paidBill)) {
            this.amountval = parseFloat(this.paidBill);
          } else {
            this.amountval = (parseFloat(this.paidBill) - payArr_Amt);
          }
        }
      }
    } else {
      this.paidBill = 0;
      this.remainingBalance = this.finalAmount;
    }
  }

  paidCalculation() {
    var temp_paidBill: any;
    temp_paidBill = parseFloat(this.paidBill);
    console.log("Temp_PaidBill", temp_paidBill);
    if (this.billAddList != undefined && this.billAddList.length != 0 && this.hospital_finalAmount != 0) {
      if (temp_paidBill != '') {
        if (temp_paidBill >= this.hospital_finalAmount) {
          this.hospital_paidBill = this.hospital_finalAmount;
          this.hospital_remainingBalance = 0;
          temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.hospital_finalAmount));
        } else {
          this.hospital_paidBill = parseFloat(temp_paidBill);
          this.hospital_remainingBalance = (parseFloat(this.hospital_finalAmount) - parseFloat(temp_paidBill));
          temp_paidBill = 0;
        }
      }
      console.log('temp_paidBill', temp_paidBill);
      console.log("HospitalPaidBill", this.hospital_paidBill);
      console.log("HospitalRemainingBalance", this.hospital_remainingBalance);
    }
    if (this.addBillArray != undefined && this.addBillArray.length != 0 && this.diag_finalAmount != 0) {
      console.log(temp_paidBill);
      if (temp_paidBill != undefined) {
        console.log("Temp_paidBill", temp_paidBill, "Diag_finalAmount", this.diag_finalAmount);
        if (temp_paidBill >= this.diag_finalAmount) {
          this.diag_paidBill = this.diag_finalAmount;
          this.diag_remainingBalance = 0;
          temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.diag_finalAmount));
        } else {
          this.diag_paidBill = parseFloat(temp_paidBill);
          this.diag_remainingBalance = (parseFloat(this.diag_finalAmount) - parseFloat(temp_paidBill));
          temp_paidBill = 0;
        }
      }
      console.log("DiagPaidBill", this.diag_paidBill);
      console.log("DiagRemainingBalance", this.diag_remainingBalance);
    }
    if (this.addPackageBillArray != undefined && this.addPackageBillArray.length != 0 && this.package_finalAmount != 0) {
      if (temp_paidBill != '') {
        if (temp_paidBill >= this.package_finalAmount) {
          this.package_paidBill = this.package_finalAmount;
          this.package_remainingBalance = 0;
          temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.package_finalAmount));
        } else {
          this.package_paidBill = parseFloat(temp_paidBill);
          this.package_remainingBalance = Math.ceil(parseFloat(this.package_finalAmount) - parseFloat(temp_paidBill));
          temp_paidBill = 0;
        }
      }
      console.log("PackagePaidBill", this.package_paidBill);
      console.log("PackageRemainingBalance", this.package_remainingBalance);
      this.packagePaidCalculation();
    }
  }

  packagePaidCalculation() {
    var temp_paidBill: any;
    temp_paidBill = parseFloat(this.package_paidBill);
    console.log(temp_paidBill);
    if (this.addPackageBillArray != undefined && this.addPackageBillArray.length != 0) {
      for (var i = 0; i < this.addPackageBillArray.length; i++) {
        if (temp_paidBill != undefined) {
          console.log('temp_paidBill', temp_paidBill, '-------', 'this.addPackageBillArray[i].fee', this.addPackageBillArray[i].fee);
          if (parseInt(temp_paidBill) >= parseInt(this.addPackageBillArray[i].fee)) {
            this.addPackageBillArray[i].package_paid_amount = this.addPackageBillArray[i].fee;
            this.addPackageBillArray[i].package_remainingBalance = 0;
            temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.addPackageBillArray[i].fee));
            console.log('temp_paidBill', temp_paidBill);
          } else {
            temp_paidBill = Math.round(temp_paidBill);
            this.addPackageBillArray[i].package_paid_amount = (parseFloat(temp_paidBill));
            this.addPackageBillArray[i].package_remainingBalance = Math.round(parseFloat(this.addPackageBillArray[i].fee) - parseFloat(temp_paidBill));
            temp_paidBill = 0;
          }
        }
        console.log("PackagePaidBill", this.addPackageBillArray);
        console.log("PackageRemainingBalance", this.package_remainingBalance);
      }
    }
  }

  paymentArrayCalculation() {
    this.hospital_paid_percentage = [];
    this.hospital_paymentsarray = [];
    this.diag_paid_percentage = [];
    this.diag_paymentsarray = [];
    this.package_paid_percentage = [];
    this.package_paymentsarray = [];
    this.hospital_pay_arrry_tot_amt = 0;
    this.hospitalEqualSplitValue = 0;
    this.diag_pay_arrry_tot_amt = 0;
    this.diagEqualSplitValue = 0;
    this.package_pay_arrry_tot_amt = 0;
    this.packageEqualSplitValue = 0;

    if (this.paymentsarray.length != 0) {
      if (this.billAddList.length != 0) {
        if (this.hospital_finalAmount != '' && this.hospital_finalAmount != 0) {
          if (this.hospital_paidBill != '' && this.hospital_paidBill != 0) {
            for (var i = 0; i < this.paymentsarray.length; i++) {
              if (this.paymentsarray[i].amount != 0) {
                if (parseInt(this.hospital_paidBill) != parseInt(this.paidBill)) {
                  this.hospital_paymentsarray.push({
                    pay_id: this.paymentsarray[i].pay_id,
                    pay_desc: this.paymentsarray[i].pay_desc,
                    amount: Math.round((this.hospital_finalAmount / this.finalAmount) * parseFloat(this.paymentsarray[i].amount))
                  })
                } else {
                  this.hospital_paymentsarray.push({
                    pay_id: this.paymentsarray[i].pay_id,
                    pay_desc: this.paymentsarray[i].pay_desc,
                    amount: Math.round(parseFloat(this.paymentsarray[i].amount))
                  })
                }

              }
            }

            if (this.hospital_paymentsarray.length != 0) {
              for (var i = 0; i < this.hospital_paymentsarray.length; i++) {
                this.hospital_pay_arrry_tot_amt = parseInt(this.hospital_pay_arrry_tot_amt) + parseInt(this.hospital_paymentsarray[i].amount);
              }
              if (parseInt(this.hospital_pay_arrry_tot_amt) < parseInt(this.hospital_paidBill)) {
                this.hospitalEqualSplitValue = ((parseFloat(this.hospital_paidBill) - parseFloat(this.hospital_pay_arrry_tot_amt)) / this.hospital_paymentsarray.length);
                for (var i = 0; i < this.hospital_paymentsarray.length; i++) {
                  this.hospital_paymentsarray[i].amount = (parseFloat(this.hospital_paymentsarray[i].amount) + parseFloat(this.hospitalEqualSplitValue));
                }
              } else if (parseInt(this.hospital_pay_arrry_tot_amt) > parseInt(this.hospital_paidBill)) {
                this.hospitalEqualSplitValue = ((parseFloat(this.hospital_pay_arrry_tot_amt) - parseFloat(this.hospital_paidBill)) / this.hospital_paymentsarray.length);
                for (var i = 0; i < this.hospital_paymentsarray.length; i++) {
                  this.hospital_paymentsarray[i].amount = (parseFloat(this.hospital_paymentsarray[i].amount) - parseFloat(this.hospitalEqualSplitValue));
                }
              }
            }
            console.log('HospitalPaymentsArray', this.hospital_paymentsarray);
          }
        }
      }

      if (this.addBillArray.length != 0) {
        if (this.diag_finalAmount != '' && this.diag_finalAmount != 0) {
          if (this.diag_paidBill != '' && this.diag_paidBill != 0) {
            for (var i = 0; i < this.paymentsarray.length; i++) {
              if (this.paymentsarray[i].amount != 0) {
                if (parseInt(this.diag_paidBill) != parseInt(this.paidBill)) {
                  this.diag_paymentsarray.push({
                    pay_id: this.paymentsarray[i].pay_id,
                    pay_desc: this.paymentsarray[i].pay_desc,
                    amount: Math.round((this.diag_finalAmount / this.finalAmount) * parseFloat(this.paymentsarray[i].amount))
                  })
                } else {
                  this.diag_paymentsarray.push({
                    pay_id: this.paymentsarray[i].pay_id,
                    pay_desc: this.paymentsarray[i].pay_desc,
                    amount: Math.round(parseFloat(this.paymentsarray[i].amount))
                  })
                }

              }
            }

            if (this.diag_paymentsarray.length != 0) {
              for (var i = 0; i < this.diag_paymentsarray.length; i++) {
                this.diag_pay_arrry_tot_amt = parseInt(this.diag_pay_arrry_tot_amt) + parseInt(this.diag_paymentsarray[i].amount);
              }
              if (parseInt(this.diag_pay_arrry_tot_amt) < parseInt(this.diag_paidBill)) {
                this.diagEqualSplitValue = ((parseFloat(this.diag_paidBill) - parseFloat(this.diag_pay_arrry_tot_amt)) / this.diag_paymentsarray.length);
                for (var i = 0; i < this.diag_paymentsarray.length; i++) {
                  this.diag_paymentsarray[i].amount = (parseFloat(this.diag_paymentsarray[i].amount) + parseFloat(this.diagEqualSplitValue));
                }
              } else if (parseInt(this.diag_pay_arrry_tot_amt) > parseInt(this.diag_paidBill)) {
                this.diagEqualSplitValue = ((parseFloat(this.diag_pay_arrry_tot_amt) - parseFloat(this.diag_paidBill)) / this.diag_paymentsarray.length);
                for (var i = 0; i < this.diag_paymentsarray.length; i++) {
                  this.diag_paymentsarray[i].amount = (parseFloat(this.diag_paymentsarray[i].amount) - parseFloat(this.diagEqualSplitValue));
                }
              }
            }
            console.log('DiagPaymentsArray', this.diag_paymentsarray);
          }
        }
      }

      if (this.addPackageBillArray.length != 0) {
        if (this.package_finalAmount != '' && this.package_finalAmount != 0) {
          if (this.package_paidBill != '' && this.package_paidBill != 0) {
            for (var i = 0; i < this.paymentsarray.length; i++) {
              if (this.paymentsarray[i].amount != 0) {
                if (parseInt(this.package_paidBill) != parseInt(this.paidBill)) {
                  this.package_paymentsarray.push({
                    pay_id: this.paymentsarray[i].pay_id,
                    pay_desc: this.paymentsarray[i].pay_desc,
                    amount: Math.round((this.package_finalAmount / this.finalAmount) * parseFloat(this.paymentsarray[i].amount))
                  })
                } else {
                  this.package_paymentsarray.push({
                    pay_id: this.paymentsarray[i].pay_id,
                    pay_desc: this.paymentsarray[i].pay_desc,
                    amount: Math.round(parseFloat(this.paymentsarray[i].amount))
                  })
                }

              }
            }

            if (this.package_paymentsarray.length != 0) {
              for (var i = 0; i < this.package_paymentsarray.length; i++) {
                this.package_pay_arrry_tot_amt = parseInt(this.package_pay_arrry_tot_amt) + parseInt(this.package_paymentsarray[i].amount);
              }
              if (parseInt(this.package_pay_arrry_tot_amt) < parseInt(this.package_paidBill)) {
                this.packageEqualSplitValue = ((parseFloat(this.package_paidBill) - parseFloat(this.package_pay_arrry_tot_amt)) / this.package_paymentsarray.length);
                for (var i = 0; i < this.package_paymentsarray.length; i++) {
                  this.package_paymentsarray[i].amount = (parseFloat(this.package_paymentsarray[i].amount) + parseFloat(this.packageEqualSplitValue));
                }
              } else if (parseInt(this.package_pay_arrry_tot_amt) > parseInt(this.package_paidBill)) {
                this.packageEqualSplitValue = ((parseFloat(this.package_pay_arrry_tot_amt) - parseFloat(this.package_paidBill)) / this.package_paymentsarray.length);
                for (var i = 0; i < this.package_paymentsarray.length; i++) {
                  this.package_paymentsarray[i].amount = (parseFloat(this.package_paymentsarray[i].amount) - parseFloat(this.packageEqualSplitValue));
                }
              }
            }
            console.log('PackagePaymentsArray', this.package_paymentsarray);
          }
        }
      }
    }
    this.packagePaymentArrayCalculation();
  }

  packagePaymentArrayCalculation() {
    if (this.addPackageBillArray.length != 0) {
      for (var p = 0; p < this.addPackageBillArray.length; p++) {
        this.package_pay_arrry_tot_amt1 = 0;
        this.packageEqualSplitValue1 = 0;
        if (this.addPackageBillArray[p].fee != '') {
          console.log("package_paymentsarray", this.package_paymentsarray);
          if (this.addPackageBillArray[p].package_paid_amount != '') {
            if (true) {
              this.addPackageBillArray[p].package_paymentsarray = [];
            }
            if (this.package_paymentsarray != undefined && this.package_paymentsarray.length != 0) {
              for (var i = 0; i < this.package_paymentsarray.length; i++) {
                if (this.package_paymentsarray[i].amount != 0 && this.addPackageBillArray[p].package_paid_amount != 0) {
                  this.addPackageBillArray[p].package_paymentsarray.push({
                    pay_id: this.package_paymentsarray[i].pay_id,
                    pay_desc: this.package_paymentsarray[i].pay_desc,
                    amount: Math.round((this.addPackageBillArray[p].fee / this.package_finalAmount) * parseFloat(this.package_paymentsarray[i].amount))
                  })
                }
              }
              if (this.addPackageBillArray[p].package_paymentsarray.length != 0) {
                for (var i = 0; i < this.addPackageBillArray[p].package_paymentsarray.length; i++) {
                  this.package_pay_arrry_tot_amt1 = parseFloat(this.package_pay_arrry_tot_amt1) + parseFloat(this.addPackageBillArray[p].package_paymentsarray[i].amount);
                }
                if (this.package_pay_arrry_tot_amt1 < this.addPackageBillArray[p].package_paid_amount) {
                  this.packageEqualSplitValue1 = ((parseFloat(this.addPackageBillArray[p].package_paid_amount) - parseFloat(this.package_pay_arrry_tot_amt1)) / this.addPackageBillArray[p].package_paymentsarray.length);
                  for (var i = 0; i < this.addPackageBillArray[p].package_paymentsarray.length; i++) {
                    this.addPackageBillArray[p].package_paymentsarray[i].amount = (parseFloat(this.addPackageBillArray[p].package_paymentsarray[i].amount) + parseFloat(this.packageEqualSplitValue1));
                  }
                } else if (this.package_pay_arrry_tot_amt1 > this.addPackageBillArray[p].package_paid_amount) {
                  this.packageEqualSplitValue1 = ((parseFloat(this.package_pay_arrry_tot_amt1) - parseFloat(this.addPackageBillArray[p].package_paid_amount)) / this.addPackageBillArray[p].package_paymentsarray.length);
                  for (var i = 0; i < this.addPackageBillArray[p].package_paymentsarray.length; i++) {
                    this.addPackageBillArray[p].package_paymentsarray[i].amount = (parseFloat(this.addPackageBillArray[p].package_paymentsarray[i].amount) - parseFloat(this.packageEqualSplitValue1));
                  }
                }
              }
              console.log('PackagePaymentsArray', this.addPackageBillArray[p].package_paymentsarray);
            }
          }
        }
      }
    }
  }

  save() {
    this.getCurrentDate();
    this.paymentArrayCalculation();
    this.doc_bills_array = [];
    this.diag_bills_array = [];
    this.package_bills_array = [];
    var flag = true;
    var paytype;
    var instype;

    if (this.billAddList != undefined && this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        if (
          this.billAddList[i].biill_type == '' &&
          this.billAddList[i].amount_txt == ''
        ) {
          this.billAddList.splice(i, 1);
        }
      }

      this.sno = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].sno = this.sno;
        this.sno += 1;
      }
    }

    if (this.totalBillList !== undefined && this.totalBillList.length !== 0) {
      console.log(this.totalBillList);
      for (var i = 0; i < this.totalBillList.length; i++) {
        if (
          this.totalBillList[i].biill_type == '' &&
          this.totalBillList[i].amount_txt == ''
        ) {
          this.totalBillList.splice(i, 1);
        }
      }

      for (var i = 0; i < this.totalBillList.length; i++) {
        if (this.totalBillList[i].biill_type == '') {
          this.toastr.error("Remove the empty charge type rows");
          flag = false;
          break;
        }
      }

      this.sno = 1;
      for (var i = 0; i < this.totalBillList.length; i++) {
        if (this.totalBillList[i].quantity == '') {
          this.totalBillList[i].quantity = 0;
        }
        this.totalBillList[i].sno = this.sno;
        this.sno += 1;
      }
    }
    console.log(this.totalBillList);

    if (this.billAddList.length == 0 && this.estimateId.length == 0 && this.addBillArray.length == 0 && this.addPackageBillArray.length == 0) {
      this.toastr.error(Message_data.getAddBill());
      flag = false;
    } else {
      for (var i = 0; i < this.totalBillList.length; i++) {
        paytype = this.totalBillList[i].paytype;
        instype = this.totalBillList[i].insurer;
        if (this.totalBillList[i].biill_type != '' && (this.totalBillList[i].amount_txt == '0.00' || this.totalBillList[i].amount_txt == 0 || this.totalBillList[i].amount_txt == '0')) {
          this.toastr.error('Service charge cannot be zero');
          flag = false;
          break;
        }
      }
    }

    if (flag == true) {
      if (this.billingList.length != 0) {
        for (var i = 0; i < this.billingList.length; i++) {
          if (this.appointmentId == this.billingList[i].client_reg_id) {
            this.clientRegId = this.billingList[i].client_reg_id;
            this.relationId = this.billingList[i].relation_id;
            if (this.billingList[i].sub_rel_id != undefined) {
              this.subRelId = this.billingList[i].sub_rel_id;
            }
          }
        }
      }

      this.sendAdvanceAmount =
        this.advanceBill != undefined ? this.advanceBill : 0;
      this.sendTotalCollected =
        this.totalCollected != undefined ? this.totalCollected : 0;

      if (this.inGST == true && this.gstRetriveList.length != 0) {
        this.cgst = this.gstRetriveList[0].tax;
        this.sgst = this.gstRetriveList[1].tax;
      }

      var userid = Helper_Class.getInfo().user_id;

      var cbill;
      var credit_person;
      if (this.credit_bill == true) {
        cbill = this.credit_type;
        credit_person = this.credit_person;
      }

      if (this.credit_type == '') {
        this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;";
        this.nobanner_style = "width: 100%; height: 95px;";
      } else {
        this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -45px;";
        this.nobanner_style = "width: 100%; height: 110px;";
      }
      console.log("finalAmount", this.finalAmount);
      console.log("paidBill", this.paidBill);
      console.log("remainingBalance", this.remainingBalance);
      var flag = true, total_paid_amount: any = 0;
      if (parseInt(this.paidBill) != 0 && this.paidBill != "") {
        if (this.paymentsarray.length == 0) {
          this.toastr.error("Please Check the payment details");
          flag = false;
        } else if (this.paymentsarray.length != 0) {
          for (var p = 0; p < this.paymentsarray.length; p++) {
            total_paid_amount = parseFloat(total_paid_amount) + parseFloat(this.paymentsarray[p].amount);
          }
          console.log("this.paidBill  ", parseFloat(this.paidBill) + "   " + "total_paid_amount  ", parseFloat(total_paid_amount));
          if (parseFloat(this.paidBill) != parseFloat(total_paid_amount)) {
            this.toastr.error("Please Check the payment details");
            flag = false;
          }
        } else {
          console.log("this.paidBill  ", parseFloat(this.paidBill) + "   " + "total_paid_amount  ", parseFloat(total_paid_amount));
          flag = true;
        }
      }
      if (flag == true) {
        if (this.billAddList.length != 0) {
          this.hospital_save_bill(paytype, instype, userid, cbill, credit_person);
        } else {
          if (this.addBillArray.length != 0) {
            this.diag_save_bill(paytype, instype, userid, cbill, credit_person, undefined);
          }
          if (this.addPackageBillArray.length != 0) {
            this.package_save_bill(paytype, instype, userid, cbill, credit_person);
          }
        }
      }
    }
  }

  hospital_save_bill(paytype, instype, userid, cbill, credit_person) {
    //Hospital SaveBill Details
    var sendData;
    if (this.doc_apps != undefined && this.billAddList.length != 0) {
      var sno = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].quantity == '') {
          this.billAddList[i].quantity = 0;
        }
        this.billAddList[i].sno = sno;
        sno += 1;
      }
      var hospital_paid_flag;
      if (parseInt(this.hospital_finalAmount) != 0 && parseInt(this.hospital_remainingBalance) != 0 && parseInt(this.hospital_finalAmount) == parseInt(this.hospital_remainingBalance)) {
        hospital_paid_flag = 'Un paid';
      } else if (parseInt(this.hospital_finalAmount) > parseInt(this.hospital_paidBill)) {
        hospital_paid_flag = 'Partially paid';
      } else if (this.sendAdvanceAmount != 0 && this.hospital_finalAmount > this.sendAdvanceAmount) {
        hospital_paid_flag = 'Partially paid';
      } else if (
        this.hospital_remainingBalance == 0 ||
        this.hospital_remainingBalance == '0' || parseInt(this.hospital_remainingBalance) < 0
      ) {
        hospital_paid_flag = 'Fully paid';
      }

      if (parseFloat(this.hospital_paidBill) > parseFloat(this.hospital_finalAmount)) {
        this.hospital_paidBill = this.hospital_finalAmount;
        hospital_paid_flag = 'Fully paid';
      }

      var hospital_balance = parseInt(this.hospital_remainingBalance) <= 0 ? 0 : this.hospital_remainingBalance.toString();
      var hospital_status = parseInt(this.hospital_remainingBalance) <= 0 ? '0' : '1';

      var hospital_finalAmount;
      var temp_discount_amount = parseFloat(this.hospital_discount_amount).toFixed(2);
      var temp_final_amount = parseFloat(this.hospital_finalAmount).toFixed(2);
      FrontDesk_Helper.setfrontdeskbillcreatesent_bill(this.hospital_finalAmount);
      if (parseInt(this.hospital_finalAmount) == 0) {
        hospital_finalAmount = encrypt_decript.Encript(temp_discount_amount).toString();
      } else {
        hospital_finalAmount = encrypt_decript.Encript(temp_final_amount).toString();
      }

      sendData = ({
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        appointment_id: this.doc_apps,
        app_type: "doctor",
        doc_reg_id: this.docRegId,
        bill_amount: hospital_finalAmount,
        paid_amount: this.hospital_paidBill,
        paid_flag: hospital_paid_flag,
        created_by: this.docRegId,
        bills: this.billAddList,
        country: ipaddress.country_code,
        estimates: this.estimateId,
        client_reg_id: this.clientRegId,
        relation_id: this.relationId,
        sub_rel_id: this.subRelId,
        advance: this.sendAdvanceAmount,
        balance: hospital_balance,
        round_off: this.discount.toString(),
        cgst: this.cgst,
        sgst: this.sgst,
        prev_bal: this.prevBalance,
        prev_bill_id: this.prevBillId,
        pay_type: paytype,
        insurer: instype,
        card_no: this.cardNumber,
        card_holder_name: this.cardHolderName,
        transaction_no: this.transactionId,
        bank_name: this.bankName,
        userid: userid,
        payments: this.hospital_paymentsarray,
        credit_type: cbill,
        credit_person: credit_person,
        rate_card: this.rate_card,
        status: hospital_status,
        discount: parseInt(this.hospital_discount_amount),
        open_app_billing: this.open_app_billing,
        bill_type:"OP"
      })

      var headers = new Headers();
      var url = 'bill/savebill/';
      headers.append('Content-Type', 'application/json');
      this.http
        .post(ipaddress.getIp.toString() + url, JSON.stringify(sendData), {
          headers: headers,
        })
        .subscribe(
          (response) => {
            var obj = response.json();
            console.log(obj);
            if (obj.key === '1') {
              this.hospital_billId = obj.bill_no;
              if (this.addBillArray.length != 0) {
                this.diag_save_bill(paytype, instype, userid, cbill, credit_person, obj.bill_no);
              }

              if (this.addPackageBillArray.length != 0) {
                this.package_save_bill(paytype, instype, userid, cbill, credit_person);
              }

              if (this.temp_key === 'print') {
                this.hospital_billId = obj.bill_no;
                if (this.addBillArray.length == 0 && this.addPackageBillArray.length == 0) {
                  this.cdr.detectChanges();
                  this.print_area1();
                  this.diag_billId = '';
                  this.package_billId = '';
                }
              }
              if (this.addBillArray.length == 0 && this.addPackageBillArray.length == 0) {
                this.saveFlag = false;
                this.toastr.success("Bill generated successfully");
                this.back();
              }
            }
          },
          (error) => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        );
    }
  }

  diag_save_bill(paytype, instype, userid, cbill, credit_person, doc_bill_no) {
    var sendData;
    //Diagnosis SaveBill Details
    if (this.diag_apps != undefined && this.addBillArray.length != 0) {
      for (var i = 0; i < this.addBillArray.length; i++) {
        if (this.addBillArray[i].quantity == '') {
          this.addBillArray[i].quantity = 0;
        }
      }
      var diagnosis_paid_flag;
      if (parseInt(this.diag_finalAmount) != 0 && parseInt(this.diag_remainingBalance) != 0 && parseInt(this.diag_finalAmount) == parseInt(this.diag_remainingBalance)) {
        diagnosis_paid_flag = 'Un paid';
      } else if (parseInt(this.diag_finalAmount) > parseInt(this.diag_paidBill)) {
        diagnosis_paid_flag = 'Partially paid';
      } else if (this.sendAdvanceAmount != 0 && this.diag_finalAmount > this.sendAdvanceAmount) {
        diagnosis_paid_flag = 'Partially paid';
      } else if (
        this.diag_remainingBalance == 0 ||
        this.diag_remainingBalance == '0' || parseInt(this.diag_remainingBalance) < 0
      ) {
        diagnosis_paid_flag = 'Fully paid';
      }

      if (parseFloat(this.diag_paidBill) > parseFloat(this.diag_finalAmount)) {
        this.diag_paidBill = this.diag_finalAmount;
        diagnosis_paid_flag = 'Fully paid';
      }

      var diag_balance = parseInt(this.diag_remainingBalance) <= 0 ? 0 : this.diag_remainingBalance.toString();
      var diag_status = parseInt(this.diag_remainingBalance) <= 0 ? '0' : '1';
      Diagnosis_Helper.setbillcreate(this.diag_finalAmount);

      var diag_finalAmount;
      var temp_discount_amount = parseFloat(this.diag_discount_amount).toFixed(2);
      var temp_final_amount = parseFloat(this.diag_finalAmount).toFixed(2);
      if (parseInt(this.diag_finalAmount) == 0) {
        diag_finalAmount = encrypt_decript.Encript(temp_discount_amount).toString();
      } else {
        diag_finalAmount = encrypt_decript.Encript(temp_final_amount).toString();
      }

      sendData = ({
        pres_diag_id: this.pres_diag_id,
        diag_centre_id: this.diagCenterID,
        appointment_id: this.diag_apps,
        app_type: "diagnosis",
        client_reg_id: this.clientRegId,
        relation_id: this.relationId,
        sub_rel_id: this.subRelId,
        bill_amount: diag_finalAmount,
        advance: this.sendAdvanceAmount,
        balance: diag_balance,
        prev_bal: this.prevBalance,
        created_by: userid,
        cgst: this.cgst,
        sgst: this.sgst,
        bills: this.addBillArray,
        country: ipaddress.country_code,
        // prev_bill_id: this.prevBillId,
        paid_amt: this.diag_paidBill,
        paid_flag: diagnosis_paid_flag,
        inpatient_flag: false,
        card_no: this.cardNumber,
        card_holder_name: this.cardHolderName,
        transaction_no: this.transactionId,
        bank_name: this.bankName,
        payments: this.diag_paymentsarray,
        credit_type: cbill,
        credit_person: credit_person,
        rate_card: this.rate_card,
        status: diag_status,
        discount: parseInt(this.diag_discount_amount),
        open_app_billing: this.open_app_billing,
        doc_bill_no: doc_bill_no,
        bill_type:"OP"
      })
      console.log("DiagSaveBillData", sendData)
      var headers = new Headers();
      var url = 'bill/savebill/';
      headers.append('Content-Type', 'application/json');
      this.http
        .post(ipaddress.getIp.toString() + url, JSON.stringify(sendData), {
          headers: headers,
        })
        .subscribe(
          (response) => {
            var obj = response.json();
            console.log(obj);
            if (obj.key === '1') {
              this.diag_billId = obj.bill_no;
              if (this.temp_key === 'print') {
                this.diag_billId = obj.bill_no;
                if (this.billAddList.length != 0 && this.addPackageBillArray.length == 0) {
                  this.diag_billId = '';
                  this.cdr.detectChanges();
                  this.print_area1();
                  this.package_billId = '';
                } else if (this.billAddList.length == 0 && this.addPackageBillArray.length == 0) {
                  this.cdr.detectChanges();
                  this.print_area1();
                  this.package_billId = '';
                }
              }
              if (this.addPackageBillArray.length == 0) {
                this.saveFlag = false;
                this.toastr.success("Bill generated successfully");
                this.back();
              }
            }
          },
          (error) => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        );
    }
  }

  package_save_bill(paytype, instype, userid, cbill, credit_person) {
    var sendData;
    //Package SaveBill Details
    console.log('package_finalAmount', this.package_finalAmount, "   ", "package_paidBill", this.package_paidBill, " ", 'sendAdvanceAmount', this.sendAdvanceAmount);
    if (this.packages.length != 0 || this.addPackageBillArray.length != 0) {
      sendData = ({
        hptl_clinic_id: this.diagCenterID,
        app_type: "package",
        doc_reg_id: this.docRegId,
        created_by: this.docRegId,
        country: ipaddress.country_code,
        estimates: this.estimateId,
        client_reg_id: this.clientRegId,
        relation_id: this.relationId,
        sub_rel_id: this.subRelId,
        cgst: this.cgst,
        sgst: this.sgst,
        // prev_bal: this.prevBalance,
        // prev_bill_id: this.prevBillId,
        pay_type: paytype,
        insurer: instype,
        card_no: this.cardNumber,
        card_holder_name: this.cardHolderName,
        transaction_no: this.transactionId,
        bank_name: this.bankName,
        userid: userid,
        credit_type: cbill,
        credit_person: credit_person,
        rate_card: this.rate_card,
        diag_app_id: this.diag_apps,
        open_app_billing: this.open_app_billing
      })

      if (this.addPackageBillArray.length != 0) {
        if (true) {
          sendData.packages = [];
        }
        for (var i = 0; i < this.addPackageBillArray.length; i++) {
          if (this.addPackageBillArray[i].quantity == '') {
            this.addPackageBillArray[i].quantity = 0;
          }
          var package_paid_flag;
          if (parseInt(this.addPackageBillArray[i].fee) != 0 && parseInt(this.addPackageBillArray[i].package_remainingBalance) != 0 && parseInt(this.addPackageBillArray[i].fee) == parseInt(this.addPackageBillArray[i].package_remainingBalance)) {
            package_paid_flag = 'Un paid';
          } else if (parseInt(this.addPackageBillArray[i].fee) > parseInt(this.addPackageBillArray[i].package_paid_amount)) {
            package_paid_flag = 'Partially paid';
          } else if (this.sendAdvanceAmount != 0 && this.addPackageBillArray[i].fee > this.sendAdvanceAmount) {
            package_paid_flag = 'Partially paid';
          } else if (
            this.addPackageBillArray[i].package_remainingBalance == 0 ||
            this.addPackageBillArray[i].package_remainingBalance == '0' || parseInt(this.addPackageBillArray[i].package_remainingBalance) < 0
          ) {
            package_paid_flag = 'Fully paid';
          }

          if (parseFloat(this.addPackageBillArray[i].package_paid_amount) > parseFloat(this.addPackageBillArray[i].fee)) {
            this.addPackageBillArray[i].package_paid_amount = this.addPackageBillArray[i].fee;
            package_paid_flag = 'Fully paid';
          }

          var package_balance = parseInt(this.addPackageBillArray[i].package_remainingBalance) <= 0 ? 0 : this.addPackageBillArray[i].package_remainingBalance;//.toString()
          var package_status = parseInt(this.addPackageBillArray[i].package_remainingBalance) <= 0 ? '0' : '1';
          this.package_discount_amount = 0;
          this.package_discount_amount = (
            parseFloat(this.package_discount_amount) +
            parseFloat(this.addPackageBillArray[i].amount_txt) *
            parseFloat(this.addPackageBillArray[i].quantity) *
            (parseFloat(this.addPackageBillArray[i].concession) / 100)
          );
          var package_finalAmount;
          var temp_discount_amount = parseFloat(this.package_discount_amount).toFixed(2);
          var temp_final_amount = parseFloat(this.addPackageBillArray[i].fee).toFixed(2);
          if (parseInt(this.addPackageBillArray[i].fee) == 0) {
            package_finalAmount = encrypt_decript.Encript(temp_discount_amount).toString();
          } else {
            package_finalAmount = encrypt_decript.Encript(temp_final_amount).toString();
          }

          sendData.packages.push({
            bill_amount: package_finalAmount,
            paid_amount: this.addPackageBillArray[i].package_paid_amount,
            paid_flag: package_paid_flag,
            discount: parseInt(this.package_discount_amount),
            advance: this.sendAdvanceAmount,
            round_off: this.addPackageBillArray[i].concession,
            concession: this.addPackageBillArray[i].concession,
            fee: this.addPackageBillArray[i].fee,
            amount_txt: this.addPackageBillArray[i].amount_txt,
            package_id: this.addPackageBillArray[i].charge_type,
            balance: package_balance,
            payments: this.addPackageBillArray[i].package_paymentsarray,
            status: package_status,
          })
        }
      }

      console.log("PackageSaveBillData", sendData)
      var headers = new Headers();
      var url = 'bill/savebill/';
      headers.append('Content-Type', 'application/json');
      this.http
        .post(ipaddress.getIp.toString() + url, JSON.stringify(sendData), {
          headers: headers,
        })
        .subscribe(
          (response) => {
            var obj = response.json();
            console.log(obj);
            if (obj.key === '1') {
              this.package_billId = obj.pbill_no;
              if (this.temp_key === 'print') {
                this.package_billId = obj.pbill_no;
                if (this.billAddList.length != 0 && this.addBillArray.length != 0) {
                  this.diag_billId = '';
                  this.package_billId = '';
                } else if (this.billAddList.length != 0 && this.addBillArray.length == 0) {
                  this.diag_billId = '';
                  this.package_billId = '';
                } else if (this.billAddList.length == 0 && this.addBillArray.length != 0) {
                  this.hospital_billId = '';
                  this.package_billId = '';
                }

                this.cdr.detectChanges();
                this.print_area1();
              }
              this.saveFlag = false;
              this.toastr.success("Bill generated successfully");
              this.back();
            }
          },
          (error) => {
            this.toastr.error("Unable to generate bill, please try later");
          }
        );
    }
  }

  print_area(print) {
    this.temp_key = print;
    var flag = true;
    if (this.totalBillList.length == 0 && this.estimateId.length == 0) {
      this.toastr.error(Message_data.getAddBill());
    } else {
      if (this.inpatientEdit == true) {
        this.save();
      }
    }
  }

  print_area1() {
    var margin_top;
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == 'banner') {
      printContents = this.printbanner.nativeElement.innerHTML;
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  getServiceType(bill_type) {
    this.serviceType = [];
    if (bill_type.length >= 2 && bill_type !== undefined) {
      // if (this.chargeName.length !== 0) {
      //   const filteredItems = this.chargeName.filter((o) =>
      //     o.description.toLowerCase().includes(bill_type.toLowerCase())
      //   );
      //   if (filteredItems.length > 0) {
      //     filteredItems.forEach((o) => {
      //       this.serviceType.push({
      //         description: o.description,
      //         amount: o.amount,
      //         charge_id: o.charge_id,
      //       });
      //     });
      //   } else {
      //     this.serviceType = [];
      //   }
      // }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "bill/gsbnd/",
        JSON.stringify({
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          charge_name:bill_type,
          ip_op:"op"

        }), { headers: headers }).subscribe(
          data => {
            this.serviceType=[];
            var obj =  data.json().charge_details;
            for(var i=0;i<obj.length;i++) {
              this.serviceType.push({
                description:obj[i].charge_desc,
                amount:obj[i].amount,
                charge_id:obj[i].bill_charge_id
              })
            }
          });

    }
  }

  handleKeyUp(event: KeyboardEvent, biill_type, person) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var biill_type;
      this.getServiceType(biill_type);
    }
    if (key === 'Enter') {
      let charge;
      for (let i = 0; i < this.serviceType.length; i++) {
        if (this.serviceType[i].description === biill_type) {
          const { description, amount, charge_id } = this.serviceType[i];
          charge = {
            description,
            amount,
            charge_id,
          };
          break;
        }
      }
      if (biill_type) {
        this.changeChargeType1(charge, person);
      }
    }
  }

  showCashModal() {
    this.payType1 = '1';
    this.showModal = true;
  }

  saveCashDenomination() {
    this.amountval = this.totalAmount;
    this.showModal = false;
  }

  calculateTotalAmount() {
    this.totalAmount = this.cashDenomination.reduce((total, denomination) => {
      return total + denomination.value * denomination.count;
    }, 0);
  }

  addPaymentDetails() {
    var bamt: any = 0,
      balance = 0;
    var results = this.finekeyInArray_Payment(
      this.paymentsarray,
      this.payType1
    );
    if (this.payType1 != undefined && this.payType1 != '') {
      for (var i = 0; i < this.payTypeList.length; i++) {
        if (this.payType1 == this.payTypeList[i].pay_id) {
          if (this.paymentsarray.length != 0) {
            for (var j = 0; j < this.paymentsarray.length; j++) {
              bamt += parseInt(this.paymentsarray[j].amount);
            }
            bamt += parseFloat(this.amountval);
          } else {
            bamt += parseFloat(this.amountval);
          }

          if (bamt > this.paidBill) {
            this.toastr.error('Amount cannot be more than bill amount');
          } else if (this.amountval == 0 || this.amountval < 0) {
            this.toastr.error('Amount cannot be zero or minus');
          } else if (results == true) {
            this.toastr.error('Payment mode already exists');
          } else {
            if (
              this.amountval < parseFloat(this.paidBill) ||
              this.amountval == parseFloat(this.paidBill)
            ) {
              if (parseFloat(this.paidBill) > parseFloat(this.finalAmount)) {
                if (bamt > parseFloat(this.finalAmount)) {
                  this.toastr.error('Amount cannot be more than bill amount');
                } else {
                  this.paymentsarray.push({
                    pay_id: this.payTypeList[i].pay_id,
                    pay_desc: this.payTypeList[i].pay_desc,
                    amount: parseFloat(this.amountval),
                  });
                  this.amountval = parseFloat(this.finalAmount) - bamt;
                  this.amountval = parseFloat(this.amountval);
                  this.payType1 = '';
                }
              } else {
                if (bamt > parseFloat(this.paidBill)) {
                  this.toastr.error('Amount cannot be more than bill amount');
                } else {
                  this.paymentsarray.push({
                    pay_id: this.payTypeList[i].pay_id,
                    pay_desc: this.payTypeList[i].pay_desc,
                    amount: parseFloat(this.amountval),
                  });
                  this.amountval = parseFloat(this.paidBill) - bamt;
                  this.amountval = parseFloat(this.amountval);
                  this.payType1 = '';
                }
              }
            } else {
              this.amountval = 0;
            }
          }
        }
      }
    } else {
      this.toastr.error('Please select the payment mode');
    }
  }

  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = parseInt(data.amount);
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }
}
