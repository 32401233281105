import { Component, OnInit, HostListener } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../../app/providers/common-data.service';
import { Helper_Class } from '../../../../app/helper_class';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-phrama-supplier-product-mst',
  templateUrl: './phrama-supplier-product-mst.component.html',
  styleUrls: ['./phrama-supplier-product-mst.component.scss']
})
export class PhramaSupplierProductMstComponent implements OnInit {
  public supplierList: any = [];
  public hsn_no: string = "";
  public shortName: string = "";
  public genericName: string = "";
  public productName: string;
  public productTypeID;
  public costPrice: any;
  public sellingPrice: any;
  public MRP: any = "";
  public prodGST: any;
  public prodDiscount;
  public product: any = [];
  public pharmacyID;
  public PharmauserID;
  public index = this.product.length;
  public productMasterAddArray = [];
  public productMasterIndex = 0;
  public pageNavigate: boolean;
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public newFlag: boolean;
  public existFlag: boolean;
  public prodType;
  public currentPage;
  public prodDataCount;
  public pagelist;
  public pageSize;
  public noOfPages: any
  public userInfo;
  public suppID;
  public productids = [];
  //added by me
  public forassignedprod: boolean = false;
  public backbutton: boolean = false;
  public filteredprodList: any = [];
  public productNameTxt;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public pagetype;
  public pagetitle;
  public supprod: boolean;
  public prostr: boolean;
  public departname;
  public storename;
  public departmentarray = [];
  public storearray = [];
  public stores_drpdwn = true;
  public userID;
  public hsp_id;
  public user_type;
  public data = [];
  public productarray:any=[];
  public accepted: boolean;
  dtOptions: DataTables.Settings = {};
  public product_exist_flag:boolean;
  public department_id;
  public store_id;
  public headerdisable:boolean=false;

  constructor(public gservice: CommonDataService, public http: Http, public toastr: ToastrService) {
    this.pageNavigate = true;
    this.pagelist = "50";
    this.currentPage = 0;
    this.pageSize = 50;
    this.newFlag = false;
    this.existFlag = true;
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.userID = this.userInfo.user_id;
    // this.hsp_id = this.userInfo.hosp_id;
    this.pagetype = Helper_Class.getReportType();
    // this.pagetype = Helper_Class.getReportType();
    this.user_type = Helper_Class.getInfo().user_type;
    // for (var i = 0; i < this.userInfo.hospitals.length; i++) {
    //   this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    // }
    this.department_id=this.userInfo.hospitals[0].department_id;
    this.store_id=this.userInfo.hospitals[0].store_id	;

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = this.userInfo.pharma_id;
      this.hsp_id = this.userInfo.hptl_clinic_id;
      this.PharmauserID = this.userInfo.po_approver; 
    }else if(Helper_Class.getInfo().user_type == 'pharmacy'){
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
      this.hsp_id = this.userInfo.hosp_id;
      this.PharmauserID = this.userInfo.user_id;
    }
    else{
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
      this.hsp_id = this.userInfo.hosp_id;
      this.PharmauserID = this.userInfo.user_id;
    }
  
    this.getSuppliers();
    //this.getProductCount();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [{ "orderable": false, "targets": [3] },],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by product name, type, quantity etc"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if (Helper_Class.getmodulelist() != undefined) {
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "51" && Helper_Class.getmodulelist()[i].description == "Supplier master") {
          if (Helper_Class.getmodulelist()[i].edit == "1") {
            this.editbutton = true;
          }

          if (Helper_Class.getmodulelist()[i].create == "1") {
            this.newbutton = true;
          }

          if (Helper_Class.getmodulelist()[i].delete == "1") {
            this.deletebutton = true;
          }

          if (Helper_Class.getmodulelist()[i].print == "1") {
            this.printbutton = true;
          }

          if (Helper_Class.getmodulelist()[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }
    this.supprod = false;
    this.prostr = false;
    this.pagetypefunction()
    this.getdepart();
  }

  pagetypefunction() {
    if (this.pagetype == 'suppro') {
      this.pagetitle = "Supplier product";
      this.supprod = true;
    } else if (this.pagetype == 'prodstore') {
  
      this.prostr = true;
      this.pagetitle = "Product store";
    }
  }

  getSuppliers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsup/",
      JSON.stringify({
        pharmacy_id: this.pharmacyID,
        dept_id:this.department_id,
        store_id:this.store_id
      }), { headers: headers }).subscribe(
        data => {
          this.supplierList = [];
          var obj = data.json().supplier_details;
          console.log("SUPPLIERS ((())) "+JSON.stringify(obj))
          if (data.json().supplier_details != null && data.json().supplier_details.length != 0) {
            
            
            // this.supplierList = data.json().supplier_details;

            for(var i=0;i<obj.length;i++){
              var exists = this.supplierList.some(function(supplier) {
                return supplier.supp_id === obj[i].supp_id;
            });
        
            // If not present, push the object into supplierList
            if (!exists) {
                this.supplierList.push({
                    supp_id: obj[i].supp_id,
                    supp_code:obj[i].supp_code,
                    name:obj[i].name,
                    gst_no:	obj[i].gst_no,
                    received_amount:obj[i].received_amount,
                    refund_amount:obj[i].refund_amount,
                    product_returns_id:obj[i].product_returns_id
                });
            }
            }



            console.log("supplier list" + JSON.stringify(this.supplierList))
            this.suppID = data.json().supplier_details[0].supp_id;
            this.getProductCount();
            //this.getSuppliersProduct();
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchsupp);
        }
      )
  }

  getdepart() {
    console.log("pharmacy Id =" + this.pharmacyID)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gdbh", JSON.stringify({ hptl_clinic_id: this.pharmacyID, type: this.user_type }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()
        console.log(" DEPARTMENT DATA" + JSON.stringify(obj))
        this.departmentarray = obj.departments;
      }
    )
  }

  pobase_department(value) {
    this.stores_drpdwn = false;
    this.getstorename(this.departname)
  }

  getstorename(value) {
    var senddata = {
      hptl_clinic_id: this.pharmacyID,
      department_id: value
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gsdbh", JSON.stringify({ hptl_clinic_id: this.hsp_id, department_id: value }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()
        console.log(" store DATA" + JSON.stringify(obj));
        this.storearray = obj.stores;
        // this.departmentarray=obj.departments;
      },
      error => {
        console.log("error on retriving the store name")
      }
    )
  }
  // getSuppliersProduct() {
  //   this.getProductID=[];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp + "pharmacontrol/gps",
  //     JSON.stringify({
  //       pharmacy_id: this.pharmacyID,
  //       supp_id: this.suppID
  //     }), { headers: headers }).subscribe(
  //       data => {
  //         var obj = data.json();
  //         if (obj.products.length != 0) {
  //           this.getProductID = obj.products;
  //           //this.retrieveProduct();
  //         } 
  //       },
  //       error => {
  //         this.toastr.error(Message_data.unableToFetchDocDet);
  //       }
  //     )
  // }

  getProductCount() {
    this.productMasterAddArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gcount',
      JSON.stringify({
        pharma_id: this.pharmacyID,
        supp_id: this.suppID
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.product_count > 50) {
            this.pageNavigate = false;
          } else {
            this.pageNavigate = true;
          }
          this.prodDataCount = obj.product_count;
          this.retrieveProduct()
        },
        error => {
        }
      )
  }

  retrieveProduct() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpcbyphar",
      JSON.stringify({
        pharmacist_id: this.PharmauserID,
        pharmacy_id: this.pharmacyID,
        imei: Helper_Class.getIPAddress(),
        limit: this.pageSize,
        start_no: this.currentPage,
        supp_id: this.suppID,
        dept_id:this.department_id,
        store_id:this.store_id
      }),
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if (obj != null && obj.products != null) {
            console.log("PRODUCTS " + JSON.stringify(obj))
            this.productMasterAddArray = [];
            for (var i = 0; i < obj.products.length; i++) {
              this.productids.push({
                product_id:obj.products[i].product_id,
                is_new:"0",
              })
              var pharid;
              if(this.PharmauserID != undefined) {
                pharid = this.PharmauserID.toString();
              }
              if (this.pagetype == "suppro") {
                this.productMasterAddArray.push({
                  Index: this.productMasterIndex,
                  hsn_no: obj.products[i].hsn_no,
                  product_id: obj.products[i].product_id,
                  pharmacist_id: pharid,
                  name: obj.products[i].name,
                  short_name: obj.products[i].short_name,
                  generic_name: obj.products[i].generic_name,
                  medicare_id: obj.products[i].medicare_id,
                  medicare_name: obj.products[i].medicare_name,
                  product_type_id: obj.products[i].product_type_id,
                  product_type_desc: obj.products[i].product_type_desc,
                  category_type_id: obj.products[i].category_type_id,
                  category_name: obj.products[i].category_name,
                  uom_code: obj.products[i].uom_code,
                  uom_desc: obj.products[i].uom_desc,
                  mfg_id: obj.products[i].mfg_id,
                  mfg_name: obj.products[i].mfg_name,
                  per_unit: obj.products[i].per_unit,
                  cost_price: obj.products[i].cost_price,
                  sales_price: obj.products[i].sales_price,
                  MRP: obj.products[i].MRP,
                  prod_gst: obj.products[i].prod_gst,
                  prod_disc: obj.products[i].prod_disc,
                  supp_id: obj.products[i].supp_id,
                  supp_name: obj.products[i].supp_name,
                  checked: true
                });

              } else if(this.pagetype == "prodstore") {
                this.productMasterAddArray.push({
                  Index: this.productMasterIndex,
                  hsn_no: obj.products[i].hsn_no,
                  name: obj.products[i].name,
                  short_name: obj.products[i].short_name,
                  generic_name: obj.products[i].generic_name,
                  medicare_id: obj.products[i].medicare_id,
                  medicare_name: obj.products[i].medicare_name,
                  product_type_id: obj.products[i].product_type_id,
                  product_type_desc: obj.products[i].product_type_desc,
                  category_type_id: obj.products[i].category_type_id,
                  category_name: obj.products[i].category_name,
                  uom_code: obj.products[i].uom_code,
                  uom_desc: obj.products[i].uom_desc,
                  mfg_id: obj.products[i].mfg_id,
                  mfg_name: obj.products[i].mfg_name,
                  product_id: obj.products[i].product_id,
                  pharmacist_id: pharid,
                  per_unit: obj.products[i].per_unit,
                  cost_price: obj.products[i].cost_price,
                  sales_price: obj.products[i].sales_price,
                  MRP: obj.products[i].MRP,
                  prod_gst: obj.products[i].prod_gst,
                  prod_disc: obj.products[i].prod_disc,
                  supp_id: obj.products[i].supp_id,
                  supp_name: obj.products[i].name,
                });
              }
            }
          }
        },
        error => {
        }
      )
  }

  // Assignedproduct() {
  //   console.log("name")
  //   this.forassignedprod = true;
  //   this.backbutton = true;
  //   var send_data = {
  //     pharmacist_id: this.PharmauserID,
  //     pharmacy_id: this.pharmacyID,
  //     imei: Helper_Class.getIPAddress(),
  //     limit: this.pageSize,
  //     start_no: this.currentPage,
  //   }
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp + "pharmacontrol/gpcbyphar",
  //     JSON.stringify(send_data),
  //     { headers: headers }).subscribe(
  //       data => {
  //         var obj = data.json();
  //         if (obj != null && obj.products != null) {
  //           this.productMasterAddArray = [];
  //           for (var i = 0; i < obj.products.length; i++) {
  //             this.productMasterAddArray.push({
  //               Index: this.productMasterIndex,
  //               hsn_no: obj.products[i].hsn_no,
  //               name: obj.products[i].name,
  //               short_name: obj.products[i].short_name,
  //               generic_name: obj.products[i].generic_name,
  //               medicare_id: obj.products[i].medicare_id,
  //               medicare_name: obj.products[i].medicare_name,
  //               product_type_id: obj.products[i].product_type_id,
  //               product_type_desc: obj.products[i].product_type_desc,
  //               category_type_id: obj.products[i].category_type_id,
  //               category_name: obj.products[i].category_name,
  //               uom_code: obj.products[i].uom_code,
  //               uom_desc: obj.products[i].uom_desc,
  //               mfg_id: obj.products[i].mfg_id,
  //               mfg_name: obj.products[i].mfg_name,
  //               product_id: obj.products[i].product_id,
  //               pharmacist_id: this.PharmauserID.toString(),
  //               per_unit: obj.products[i].per_unit,
  //               cost_price: obj.products[i].cost_price,
  //               sales_price: obj.products[i].sales_price,
  //               MRP: obj.products[i].MRP,
  //               prod_cgst: obj.products[i].prod_cgst,
  //               prod_sgst: obj.products[i].prod_sgst,
  //               prod_disc: obj.products[i].prod_disc,
  //               supp_id: obj.products[i].supp_id,
  //               supp_name: obj.products[i].supp_name,
  //               schedule_type_id: obj.products[i].schedule_type_id,
  //               schedule_type_desc: obj.products[i].schedule_type_desc,
  //               type: obj.products[i].type,
  //               rackid: obj.products[i].rackid,
  //               binid: obj.products[i].binid,
  //             });
  //             this.productMasterIndex++;
  //           }
  //         }
  //       },
  //       error => { }
  //     )
  // }

  // todisplayassignedproduct() {
  //   this.forassignedprod = false;
  //   this.backbutton = false;
  // }

  productFilter() {
    if (this.productName !== "" && this.productName != undefined && this.productName.length > 2) {
      console.log("PRODUCT NAME "+this.productName)
      this.pageNavigate = true;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/prodByName/',
        JSON.stringify({
          prod_name: this.productName,
          pharmacy_id: this.pharmacyID,
          dept_id:this.department_id,
          store_id:this.store_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.productarray=[];
           // this.productMasterAddArray = [];
            var obj = JSON.parse(response["_body"]);
            console.log("productFilter_data = "+JSON.stringify(obj))
            if (obj.products != null) {
              //this.productMasterAddArray = [];
              this.pagelist = "50";
              this.pageNavigate = false;
              this.productMasterIndex = 0;
              for (var i = 0; i < obj.products.length; i++) {
                this.productarray.push({
                  Index: this.productMasterIndex,
                  hsn_no: obj.products[i].hsn_no,
                  name: obj.products[i].name,
                  short_name: obj.products[i].short_name,
                  generic_name: obj.products[i].generic_name,
                  medicare_id: obj.products[i].medicare_id,
                  medicare_name: obj.products[i].medicare_name,
                  product_type_id: obj.products[i].product_type_id,
                  product_type_desc: obj.products[i].product_type_desc,
                  category_type_id: obj.products[i].category_type_id,
                  category_name: obj.products[i].category_name,
                  uom_code: obj.products[i].uom_code,
                  uom_desc: obj.products[i].uom_desc,
                  mfg_id: obj.products[i].mfg_id,
                  mfg_name: obj.products[i].mfg_name,
                  product_id: obj.products[i].product_id,
                  pharmacist_id: this.PharmauserID.toString(),
                  per_unit: obj.products[i].per_unit,
                  cost_price: obj.products[i].cost_price,
                  sales_price: obj.products[i].sales_price,
                  MRP: obj.products[i].MRP,
                  prod_cgst: obj.products[i].prod_cgst,
                  prod_sgst: obj.products[i].prod_sgst,
                  prod_disc: obj.products[i].prod_disc,
                  supp_id: obj.products[i].supp_id,
                  supp_name: obj.products[i].supp_name,
                  schedule_type_id: obj.products[i].schedule_type_id,
                  schedule_type_desc: obj.products[i].schedule_type_desc,
                  type: obj.products[i].type,
                  rackid: obj.products[i].rackid,
                  binid: obj.products[i].binid,
                });
                this.productMasterIndex++;
              }
            } else {
              this.toastr.error(Message_data.noPrd);
            }
          },
          error => { }
        )
    } 
  }

  selectItem(item){
    this.product_exist_flag=false;
    var product_id=item.product_id;
    for (var i=0; i < this.productMasterAddArray.length;i++){
      if(this.productMasterAddArray[i].product_id == product_id){
        this.toastr.error(Message_data.productmap);
        this.product_exist_flag=true;
      }
    }
    // alert("productMasterIndex = "+this.productMasterIndex)
    if(!this.product_exist_flag){
      this.headerdisable = true
    this.productMasterAddArray.push({
      Index: this.productMasterIndex,
      hsn_no: item.hsn_no,
      name: item.name,
      short_name: item.short_name,
      generic_name: item.generic_name,
      medicare_id: item.medicare_id,
      medicare_name: item.medicare_name,
      product_type_id: item.product_type_id,
      product_type_desc: item.product_type_desc,
      category_type_id: item.category_type_id,
      category_name: item.category_name,
      uom_code: item.uom_code,
      uom_desc: item.uom_desc,
      mfg_id: item.mfg_id,
      mfg_name: item.mfg_name,
      product_id: item.product_id,
      pharmacist_id: this.PharmauserID.toString(),
      per_unit: item.per_unit,
      cost_price: item.cost_price,
      sales_price: item.sales_price,
      MRP: item.MRP,
      prod_cgst: item.prod_cgst,
      prod_sgst: item.prod_sgst,
      prod_disc: item.prod_disc,
      supp_id: item.supp_id,
      supp_name: item.supp_name,
      schedule_type_id: item.schedule_type_id,
      schedule_type_desc: item.schedule_type_desc,
      type: item.type,
      rackid: item.rackid,
      binid: item.binid,
    });
    this.productids.push({
      product_id:item.product_id,
      is_new:"1",
    })
    this.saveSupplerProducts()
    }
  }

  pagelistChange() {
   this.pageSize = this.pagelist;

    this.currentPage = 1;
    this.getProductCount();
    if (this.pagelist == "50") {
      if (this.prodDataCount > 50) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;

      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }

    } else if (this.pagelist == "100") {
      if (this.prodDataCount > 100) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;

      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }

    } else if (this.pagelist == "200") {
      if (this.prodDataCount > 200) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;

      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    }
    this.retrieveProduct();
  }

  pageLimitnext() {
    this.currentPage = this.currentPage + 1;
    this.pageSize = this.pagelist;
    this.getProductCount();
    this.retrieveProduct();
    this.prevPageCount = false;
    if (this.currentPage == this.noOfPages) {
      this.nextPageCount = true;
    }
  }

  pageLimitPrevious() {
    this.pageSize = this.pagelist;
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.getProductCount();
      this.retrieveProduct();
      this.nextPageCount = false;
      if (this.currentPage == "1") {
        this.prevPageCount = true;
      }

    } else {
      this.prevPageCount = true;
    }
  }

  numberOfPages() {
    this.noOfPages = Math.ceil(this.prodDataCount / this.pagelist);
    return Math.ceil(this.prodDataCount / this.pagelist);
  }

  product_filter() {
    if (this.productName !== "" && this.productName.length > 2) {
      this.pageNavigate = true;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/prodByName/',
        JSON.stringify({
          prod_name: this.productName,
          pharmacy_id: this.pharmacyID,
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.productMasterAddArray = [];
            var obj = JSON.parse(response["_body"]);
            if (obj.products != null) {
              this.productMasterAddArray = [];
              this.pagelist = "50";
              this.pageNavigate = false;
              for (var i = 0; i < obj.products.length; i++) {
                this.productMasterAddArray.push({
                  Index: this.productMasterIndex,
                  hsn_no: obj.products[i].hsn_no,
                  name: obj.products[i].name,
                  short_name: obj.products[i].short_name,
                  generic_name: obj.products[i].generic_name,
                  medicare_id: obj.products[i].medicare_id,
                  medicare_name: obj.products[i].medicare_name,
                  product_type_id: obj.products[i].product_type_id,
                  product_type_desc: obj.products[i].product_type_desc,
                  category_type_id: obj.products[i].category_type_id,
                  category_name: obj.products[i].category_name,
                  uom_code: obj.products[i].uom_code,
                  uom_desc: obj.products[i].uom_desc,
                  mfg_id: obj.products[i].mfg_id,
                  mfg_name: obj.products[i].mfg_name,
                  product_id: obj.products[i].product_id,
                  pharmacist_id: this.PharmauserID.toString(),
                  per_unit: obj.products[i].per_unit,
                  cost_price: obj.products[i].cost_price,
                  sales_price: obj.products[i].sales_price,
                  MRP: obj.products[i].MRP,
                  prod_gst: obj.products[i].prod_gst,
                  prod_disc: obj.products[i].prod_disc,
                });
                this.productMasterIndex++;
              }

            } else {
              this.toastr.error(Message_data.noPrd);
            }
          },
          error => {
          }
        )

    } else {
      this.pageNavigate = false;
      this.pagelist = "50";
    }
  }

  isAllSelected(list, hsn, id, checked) {
    for(var i=0;i<this.productids.length;i++){
      if(this.productids[i].product_id == id){
        if (checked == false) {
          this.productids[i].is_new="2";
      
        } else {
          this.productids[i].is_new="0";
          //this.data.push(list);
        }
      }
    }
    console.log("PRODUCT IDS "+JSON.stringify(this.productids))
    //var check = this.productids;
  }

  saveSupplerProducts() {
    if (this.pagetype == "suppro") {
      if (this.productids.length == 0) {
        this.toastr.error(Message_data.selectOnePrd);

      } else if (this.suppID == "" || this.suppID == undefined || this.suppID == null) {
        this.toastr.error(Message_data.suppORdistributor);

      } else {
        var send_data;
        send_data = {
          pharma_id: this.pharmacyID,
          supp_id: this.suppID,
          product_id: this.productids,
          dept_id:this.department_id,
          store_id:this.store_id
        }
        console.log("send_data = "+JSON.stringify(send_data))
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'inv/sps', send_data,
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              if (obj.key == "1") {
                this.headerdisable = false;
                this.toastr.success(Message_data.prdUpdSuccess);
                this.productMasterAddArray=[];
                this.retrieveProduct();
              } else {
              }
            },
            error => {

            });
      }
    } else if (this.pagetype == "prodstore") {
      send_data = {
        products: this.data,
        store_id: this.storename,
        department_id: this.departname,
        dept_id:this.department_id,

      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inv/sprodcode', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log(" DATA = " + JSON.stringify(obj))
            if (obj.key == "1") {
              if (this.pagetype == "suppro") {
                this.toastr.success(Message_data.prdUpdSuccess);

              } else if (this.pagetype == "prodstore") {
                this.toastr.success(Message_data.prodstorenamesucc);
                this.accepted = false
              }

            } else {
              if (this.pagetype == "suppro") {
                this.toastr.error(Message_data.errorinupdate);
              } else if (this.pagetype == "prodstore") {
                this.toastr.error(Message_data.prodstorename);
              }
            }
          },
          error => {

          });
    }
  }
}
