import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { FormControl } from '@angular/forms';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { Date_Formate,Time_Formate } from 'src/assets/js/common';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-pharma-receivable-report',
  templateUrl: './pharma-receivable-report.component.html',
  styleUrls: ['./pharma-receivable-report.component.css']
})
export class PharmaReceivableReportComponent implements OnInit {
  @ViewChild('tbl') tbl: ElementRef;
  @ViewChild('hospitalwithnamelogo') hospitalwithnamelogo: ElementRef;
  tmr = new Date();
  public pharmacyID: string;
  public fromDate;
  public toDate;
  public receiveData = [];
  public noData: boolean;
  public hasData: boolean;
  public currentDate;
  public userInfo;
  public pharmacistID;
  public type = "all";
  public MedicineNameArray = [];
  public medicineArray = [];
  public MedicneData = [];
  public productName;
  public productFlag:boolean = false;
  public suplierFlag:boolean = false;
  public supllierName;
  public productID;
  public selected_filter: any = [];
  public columns = {
    supplier:true,
      gr_no:true,
      invo_no :true,
      product_name :true,
      po_no :true,
      podate_time :true,
      invo_date :true,
      received_date :true,
      po_quantity :true,
      receive_qty :true,
      gst :true,
      pending_qty :true,
      discount:true,
      cp :true,
      sp :true,
      mrp:true,
      
  }
  public forcoverdiv: boolean = true;
  public backbutton: boolean = false;
  public print_template;
  public printlogostyle;
  public hospital_logo;
  public hosp_name;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public loader;
  public receivables_header:any=[];

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.noData = false;
    this.hasData = true;
    this.selected_filter=['supplier','gr_no','invo_no', 'product_name', 'po_no', 'podate_time', 'invo_date', 'received_date', 'po_quantity', 'receive_qty', 'gst', 'pending_qty', 'cp' ,'sp', 'mrp','discount']
  }
  ngOnInit(): void {
    this.receivables_header=['Supplier', 'Gr no', 'Invoice no', 'Product name', 'PO no', 'PO date/time', 'Invoice date', 'Received date', 'PO qty', 'Received qty', 'GST' ,'On hand', 'Cost price', 'Sales price', 'MRP', 'Discount']
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
 
    if(this.userInfo.user_type =="pharmacy") {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    } else {
      this.pharmacyID = this.userInfo.pharma_id;
    }
    this.print_template = Helper_Class.getInfo().bill_print_template;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.getDate();
    // this.getRetrievalData();
    this.receivables_header=[]
  }
  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate() + 10);
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          
          this.type == "all"
          this.changeProductType()
          this.getReceivablesData();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }
  getDateMatePicker(e) {
    var month = ((e.value.getMonth() + 1) < 10 ? '0' : '') + (e.value.getMonth() + 1);
    var day = (e.value.getDate() < 10 ? '0' : '') + e.value.getDate();
    var apt = e.value.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }
  ChangeMatePicker(date_value) {
    var apt = new FormControl(new Date(date_value));
    return apt;
  }
  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }
  getReceivablesData() {
    
    var send;
    if( this.type == "supplier" ){
      send = {
        pharmacy_id: this.pharmacyID,
        from_date: this.fromDate,
        to_date: this.toDate,
        type : this.type,
        supp_id : this.supplierID
      }
    }else if( this.type == "product" ){
      send = {
        pharmacy_id: this.pharmacyID,
        from_date: this.fromDate,
        to_date: this.toDate,
        type : this.type,
        product_id : this.productID
      }
    } else {
      send = {
        pharmacy_id: this.pharmacyID,
        from_date: this.fromDate,
        to_date: this.toDate,
      }
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
   
   
    this.http.post(ipaddress.getIp + "inv/gprep/", JSON.stringify(send),
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          console.log(JSON.stringify(dataval))
          if (dataval != null) {
            this.noData = true;
            this.hasData = false;
           
            
            this.receiveData = [];
            if (dataval.receivables != undefined) {
              this.forcoverdiv = false;
              this.backbutton = true;
              for (var i = 0; i < dataval.receivables.length; i++) {
                var poDate = dataval.receivables[i].po_date != undefined ? Date_Formate(dataval.receivables[i].po_date) : "";
                var poTime = dataval.receivables[i].po_time != undefined ? Time_Formate(dataval.receivables[i].po_time) : "";
                var invoiceDate = dataval.receivables[i].invoice_date != undefined ? Date_Formate(dataval.receivables[i].invoice_date) : "";
                var receivedDate = dataval.receivables[i].received_date != undefined ? Date_Formate(dataval.receivables[i].received_date) : "";
                // var invoiceTime = dataval.receivables[i]. != undefined ? Time_Formate(dataval.receivables[i].) : "";
                this.receiveData.push({
                  hsn_no: dataval.receivables[i].hsn_no,
                  product_name: dataval.receivables[i].product_name,
                  po_code: dataval.receivables[i].po_code,
                  po_qty: dataval.receivables[i].po_qty,
                  mrp : dataval.receivables[i].mrp,
                  poDate : poDate,
                  potime : poTime,
                  qty_received: dataval.receivables[i].qty_received,
                  balance: dataval.receivables[i].balance,
                  supName : dataval.receivables[i].name,
                  costPrice : dataval.receivables[i].cost_price,
                  salsePrice : dataval.receivables[i].sales_price,
                  invoice_date: invoiceDate,
                  invoice_no: dataval.receivables[i].invoice_no,
                  gst: dataval.receivables[i].gst,
                  discount:dataval.receivables[i].discount,
                  gr_no:dataval.receivables[i].gr_no,
                  received_date:receivedDate
                })
              }
            } else {
              this.receiveData = [];
              this.noData = false;
              this.hasData = true;
              this.forcoverdiv = true;
            }
          }
        },
        error => { });
  }
  changeProductType(){
    this.productFlag = false; 
    this.suplierFlag = false
      if( this.type == "supplier" ){
          this.suplierFlag = true;
      }else if( this.type == "product" ){
          this.productFlag = true
      }
  }
  changeMedicineName(e) {
    var senddetails = JSON.stringify({
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type : "medicine"
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.med_details != null) {
              
              this.MedicneData = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
               // this.MedicneData.push(medname);
                this.MedicneData.push({ name : medname, quantity : obj.med_details[i].quantity, med_id: obj.med_details[i].med_id});
              }
              // this.MedicineNameArray = this.MedicneData.filter(function (this: any, el) {
              //   return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
              // }.bind(this));
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }
 
  selectMedicineItem(item) {
    this.productID = item.med_id;
    this.MedicineNameArray = [];
  }
  public supplierArray = [];
  public supplierID;
  changeSupplier(e){
    var senddetails = JSON.stringify({
      name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
   
      
      this.http.post(ipaddress.getIp.toString() + 'inv/gsupp', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            this.supplierArray = response.json().suppliers;
           console.log("OBJ" + JSON.stringify(this.supplierArray));
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }
  selectSupplierName(supplier){
      this.supplierID = supplier.supp_id;
  }
  setTable(data){
    this.columns={
      supplier:false,
      gr_no:false,
      invo_no :false,
      product_name :false,
      po_no :false,
      podate_time :false,
      invo_date :false,
      received_date :false,
      po_quantity :false,
      receive_qty :false,
      gst :false,
      pending_qty :false,
      discount:false,
      cp :false,
      sp :false,
      mrp:false,
      
    }
    for(var i=0;i < data.length;i++){
      this.columns[data[i]] = !this.columns[data[i]];
    }
  }

  todisplaycoverdiv() {
    this.forcoverdiv = true;
    this.backbutton = false;
    // this.amountcard = false;
    // this.ishide = false;
    this.receiveData = [];
  }

  getRetrievalData() {
    var send = {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
    }
    var headers = new Headers();
    console.log("get retrieval data" + send)
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", send,
      { headers: headers })
      .subscribe(
        response => {
          // var values = response.json();
          var values = JSON.parse(JSON.stringify(response));
          console.log("retrive obj" + JSON.stringify(values));
          if (values != undefined || values != null) {

            this.hosp_name = values.hptl_name;

            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;

            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }


          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  print_area(){
    let tbl,printContents,popupWin,logo,hospitaldetails;
    printContents = this.tbl.nativeElement.innerHTML;
    logo = document.getElementById('printimage').innerHTML;
    hospitaldetails = this.hospitalwithnamelogo.nativeElement.innerHTML;

    if(this.print_template != undefined && this.print_template == "banner"){
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`

  <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
     
      <table style="border:'0';width:100%; margin: 20px;">  
      <thead>
      <tr>
      ${logo}
      </tr>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>`
      );

      popupWin.document.close();
    }else if(this.print_template != undefined && this.print_template == "logowithname"){
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();


      popupWin.document.write(`
  
      <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
      <table style="border:'0';width:100%">  
      <thead>
      <tr>
      ${logo}
      </tr>
      <div>
      ${hospitaldetails}
      </div>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>
   

    `);

      popupWin.document.close();
    }else{
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();


      popupWin.document.write(`

  <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
     
      <table style="border:'0';width:100%; margin: 15px;">  
      <thead>
      <tr>
      <td><div style="height: 130px;"></div></td>    
      </tr>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
      
   </body>
    </html>`
      );

      popupWin.document.close();

    }
  }



getExcelData(type){
  var receivables=[];
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet('Receivables report');
  worksheet.getColumn(1).width = 10;
  worksheet.getColumn(2).width = 10;
  worksheet.getColumn(3).width = 20;
  worksheet.getColumn(4).width = 30;
  worksheet.getColumn(5).width = 20;
  worksheet.getColumn(6).width = 15;
  worksheet.getColumn(7).width = 20;
  worksheet.getColumn(8).width = 20;
  worksheet.getColumn(9).width = 20;
  worksheet.getColumn(10).width = 20;
  worksheet.getColumn(11).width = 20;
  worksheet.getColumn(12).width = 20;
  var headerRow = worksheet.addRow(this.receivables_header);
  headerRow.eachCell((cell, number) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4167B8' },
      bgColor: { argb: '' }
    }
    cell.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 12
    }
  });

  for (let receivable_data of this.receiveData) {
    var datetime= receivable_data.poDate+"/"+receivable_data.potime
    receivables.push({
      supName:receivable_data.supName,
      gr_no:receivable_data.gr_no,
      invoice_no:receivable_data.invoice_no,
      product_name:receivable_data.product_name,
      po_code:receivable_data.po_code,
      poDate:datetime,
      invoice_date:receivable_data.invoice_date,
      received_date:receivable_data. received_date,
      po_qty:receivable_data.po_qty,
      qty_received:receivable_data.qty_received,
      gst:receivable_data.gst,
      balance:receivable_data.balance,
      costPrice:receivable_data.costPrice,
      salsePrice:receivable_data.salsePrice,
      mrp:receivable_data.mrp,
      discount:receivable_data.discount
    })
  }

  for (let x1 of receivables) {
    let x2 = Object.keys(x1);
    let temp = []
    for (let y of x2) {
      temp.push(x1[y])
    }
    let chilrow = worksheet.addRow(temp)
    let salesamt = chilrow.getCell(5);
    salesamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    let costprice = chilrow.getCell(13);
    costprice.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    let salesprice = chilrow.getCell(14);
    salesprice.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    let mrp = chilrow.getCell(15);
    mrp.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
  }

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, "Receivables report" + '-' + new Date().valueOf() + '.xlsx');
  });


}

}
